import React, { Component } from "react";
import axios from "axios";
import { tl } from "framework/utils/Translator";
import { mapStateToProps, mapDispatchToProps } from "framework/utils/Redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Tooltip from "@material-ui/core/Tooltip";
import PeekIcon from "@material-ui/icons/RemoveRedEye";

class PasswordReset extends Component {
  constructor(props) {
    super(props);

    var urlArray = props.location.pathname.split("/");
    this.state = {
      token: urlArray[3] ? urlArray[3] : "",
      password: "",
      passwordConfirmation: "",
      peek: false,
      peekConfirm: false,
      respond: "",
    };
    this.handleClickReset = this.handleClickReset.bind(this);
    this.handleClickPeek = this.handleClickPeek.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
  }

  handleClickPeek(field) {
    this.setState({ [field]: !this.state[field] });
  }

  handleClickReset() {
    axios
      .post(this.props.Session.host + "/user/password", {
        forgotPasswordToken: this.state.token,
        password: this.state.password,
        passwordConfirmation: this.state.passwordConfirmation,
      })
      .then(() => {
        this.setState(
          {
            respond: "Your password has been reset",
          },
          () => {
            setTimeout(() => {
              this.props.history.push("/auth/login");
            }, 2000);
          },
        );
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.errorDescription
        ) {
          this.setState({
            respond: error.response.data.errorDescription,
          });
        }
      });
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <Grid container justify="center">
          <Grid item xs={12} sm={6} md={4}>
            <Card login className={classes[this.state.cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="rose"
              >
                <h4 className={classes.cardTitle}>{tl("resetYourPassword")}</h4>
              </CardHeader>
              <CardBody>
                <div style={{ display: "none" }}>
                  <CustomInput
                    labelText="Token"
                    disabled={true}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      defaultValue: this.state.token,
                      id: "token",
                      onChange: (event) => this.handleChange(event),
                    }}
                  />
                </div>
                <CustomInput
                  labelText={tl("newPassword")}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) => this.handleChange(event),
                    id: "password",
                    type: this.state.peek ? "text" : "password",
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip
                          title={
                            this.state.peek
                              ? tl("hidePassword")
                              : tl("peekPassword")
                          }
                          placement="top"
                        >
                          <Button
                            tabIndex="-1"
                            justIcon
                            simple
                            onClick={() => this.handleClickPeek("peek")}
                          >
                            <PeekIcon
                              style={{
                                color: this.state.peek ? "#03a9f4" : "#616161",
                              }}
                            />
                          </Button>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
                <CustomInput
                  labelText={tl("confirmPassword")}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) => this.handleChange(event),
                    id: "passwordConfirmation",
                    type: this.state.peekConfirm ? "text" : "password",
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip
                          title={
                            this.state.peekConfirm
                              ? tl("hidePassword")
                              : tl("peekPassword")
                          }
                          placement="top"
                        >
                          <Button
                            justIcon
                            tabIndex="-1"
                            simple
                            onClick={() => this.handleClickPeek("peekConfirm")}
                          >
                            <PeekIcon
                              style={{
                                color: this.state.peekConfirm
                                  ? "#03a9f4"
                                  : "#616161",
                              }}
                            />
                          </Button>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
                <span
                  style={{
                    display: this.state.respond === "" ? "none" : "flex",
                  }}
                >
                  {this.state.respond}
                </span>
                <Button
                  color="rose"
                  fullWidth={true}
                  onClick={() => this.handleClickReset()}
                >
                  {tl("reset")}
                </Button>
              </CardBody>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(loginPageStyle)(withRouter(PasswordReset)));
