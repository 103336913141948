//translation
import {tl} from "framework/utils/Translator"
import Cookies from 'js-cookie'

// @material-ui/icons
import RestaurantIcon from "@material-ui/icons/Restaurant";

//activities
import RestaurantOrderActivity from 'app/modules/Restaurant/activities/RestaurantOrderActivity'

var Routes = [
    {
    	collapse	: true,
	    name		: tl(Cookies.get("lang"),"Restaurant"),
	    menu		: "ACCT",
	    icon 		: RestaurantIcon,
	    state		: "RSTN",
	    views		:[
			{
				path		: "/restaurant-order/:id?",
				name		: tl(Cookies.get("lang"),"RestaurantOrder"),
				menu		: "ACCT",
				icon 		: RestaurantIcon,
				component 	: RestaurantOrderActivity,
				layout		: "/admin"
			},
		]
	},
]

export default Routes