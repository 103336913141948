import React, { Component } from "react";
import Activity from "framework/containers/Activity";

class ChangePasswordActivity extends Component {
  constructor(props) {
    super(props);
    this.RenderedComponent = import("framework/pages/ChangePasswordForm");
  }

  render() {
    return (
      <Activity
        name="changePassword"
        RenderedComponent={this.RenderedComponent}
        context="changePassword"
      />
    );
  }
}

export default ChangePasswordActivity;
