const Translation = {
    id:{
        "Restaurant":"Restoran",
        "RestaurantOrder":"Pesanan Resto",
        "restaurant_order":"Pesanan Resto",
        "RestaurantOrder.tableNumber":"Nomor Meja",

        //options
        "filesystem":"Filesystem",
        "database":"Database",
        "s3":"Amazon S3",
        "simple":"Sederhana",
        "detailed":"Detil",
        "branch":"Detil Cabang",
        "order":"Pesan",
        "prepare":"Siapkan",
        "serve":"Sajikan",
        "finish":"Selesaikan",
    },
    en:{
        "Restaurant":"Restaurant",
        "RestaurantOrder":"Restaurant Order",
        "restaurant_order":"Restaurant Order",
        "RestaurantOrder.tableNumber":"Table Number",

        //options
        "filesystem":"Filesystem",
        "database":"Database",
        "s3":"Amazon S3",
        "simple":"Simple",
        "detailed":"Detailed",
        "branch":"Detailed Branch",
        "order":"Order",
        "prepare":"Prepare",
        "serve":"Serve",
        "finish":"Finish",
    }
}

export default Translation