import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import LazyContainer from "framework/containers/LazyContainer";
import withWidth from "@material-ui/core/withWidth";

// const classes = {
// 	customPaper:{
// 		overflow:"visible"
// 	}
// }

class Modal extends Component {
  handleClose = (event) => {
    if (event.keyCode === 27) {
      this.props.payload.closeCallback();
    } else {
      event.stopPropagation();
    }
  };

  getFullscreen = () => {
    if (this.props.fullScreen || this.props.width === "xs") {
      return true;
    }
  };

  render() {
    return (
      <Dialog
        fullScreen={this.getFullscreen()}
        onClose={(event) => this.handleClose(event)}
        open={this.props.open}
        fullWidth={true}
        maxWidth={this.props.maxWidth ? this.props.maxWidth : "md"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="custom-modal"
        {...(this.props.overflow
          ? {
              classes: {
                // paper:classes.customPaper
              },
            }
          : {})}
      >
        {this.props.payload !== null && (
          <LazyContainer payload={this.props.payload} />
        )}
        <div className="modal-filler" />
      </Dialog>
    );
  }
}

export default withWidth()(Modal);
