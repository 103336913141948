import React, { Component } from "react";
import Button from "components/CustomButtons/Button.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import withWidth from "@material-ui/core/withWidth";
import Tooltip from "@material-ui/core/Tooltip";
import { tl } from "framework/utils/Translator";
import Cookies from "js-cookie";
import axios from "axios";
import { mapStateToProps, mapDispatchToProps } from "framework/utils/Redux";
import { connect } from "react-redux";
import _ from "lodash";
import Config from "company/Config";

import SettingsIcon from "@material-ui/icons/Settings";
import ErrorIcon from "@material-ui/icons/Report";

const styles = {
  container: {
    position: "relative",
  },
  paneContainer: {
    position: "absolute",
    right: "0px",
    zIndex: "1000",
    "&.closed": {
      display: "none",
    },
  },
  paneContainerMobile: {
    position: "fixed",
    bottom: "0px",
    left: "0px",
    width: "100%",
    zIndex: "1000",
    transition: "0.3s ease-in-out",
    "&.closed": {
      opacity: "0",
      bottom: "-100%",
    },
  },
  callout: {
    display: "flex",
    justifyContent: "flex-end",
    width: "400px",
    padding: "7px",
    zIndex: "1001",
    marginLeft: "7px",
    position: "relative",
  },
  calloutNip: {
    boxShadow: "0px 10px 10px rgba(0,0,0,0.2)",
    width: "25px",
    height: "25px",
    transform: "rotate(45deg)",
    backgroundColor: "#fff",
  },
  pane: {
    borderRadius: "10px",
    backgroundColor: "#fff",
    marginTop: "-25px",
    boxShadow: "0px 10px 10px rgba(0,0,0,0.2)",
    width: "400px",
    height: "400px",
    zIndex: "1002",
    position: "relative",
    padding: "0px 10px",
    "&.mobile": {
      width: "100%",
      height: "80vh",
      boxShadow: "0px -10px 100px rgba(0,0,0,0.2)",
    },
  },
  paneTitle: {
    width: "100%",
    fontWeight: "bold",
    padding: "10px",
  },
  formContainer: {
    width: "100%",
    padding: "0px 10px",
    height: "340px",
    overflowY: "scroll",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: "10px",
      marginRight: "10px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#eee",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ddd",
      borderRadius: "10px",
      transition: "0.2s ease-in-out",
      "&:hover": {
        backgroundColor: "#ccc",
      },
    },
    "&.mobile": {
      height: "calc(100% - 61px)",
    },
  },
  errorIconContainer: {
    position: "absolute",
    top: "5px",
    right: "0px",
    pointerEvents: "none",
  },
  errorIcon: {
    fontSize: "0.9rem",
    color: "#f44336",
    pointerEvents: "none",
  },
};

class ConfigForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paneOpen: false,
      record: {},
      errors: props.errors,
      error: false,
    };

    this.requestHeader = {
      headers: {
        Authorization: "Bearer " + Cookies.get("accessToken"),
      },
    };

    this.url = this.props.Session.host + "/api/" + this.props.model;
    if (Config.isOfflineClient) {
      this.url = this.url.replace("/api/", "/api/offline/");
    }
  }

  togglePane = () => {
    if (!this.state.paneOpen) {
      document.addEventListener("click", this.handleClickOutSide, false);
    } else {
      document.removeEventListener("click", this.handleClickOutSide, false);
    }
    this.setState({
      paneOpen: !this.state.paneOpen,
    });
  };

  handleClickOutSide = (e) => {
    if (!e.target.id.includes("react-select")) {
      if (this.node && !this.node.contains(e.target)) {
        this.togglePane();
      }
    }
  };

  componentDidMount() {
    this.mounted = true;
    if (this.mounted) {
      if (this.props.customRef) {
        this.props.customRef({
          triggerSaveConfig: this.triggerSaveConfig,
        });
      }
      axios
        .get(this.url, this.requestHeader)
        .then((res) => {
          let rawConfig = res.data;

          let newConfig = this.createConfigStructureForForm(rawConfig);
          this.setState(
            {
              record: rawConfig,
            },
            () => {
              if (this.props.onConfigLoaded) {
                this.props.onConfigLoaded(newConfig);
              }
            },
          );
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 502 || error.response.status === 500)
          ) {
            this.props.setNotification(
              true,
              tl("configNotLoadedServerError"),
              "danger",
            );
            setTimeout(() => {
              this.props.setNotification(
                false,
                tl("configNotLoadedServerError"),
                "danger",
              );
            }, 3000);
          }
        });
    }
  }

  createConfigStructureForForm = (newConfig) => {
    let rawConfig = { ...newConfig };
    delete rawConfig.id;
    delete rawConfig.userId;

    return {
      config: _.pick(rawConfig, this.props.configFields),
      formRecord: _.pick(rawConfig, this.props.formRecordFields),
      errors: {},
    };
  };

  handleFormChange = (formChanges) => {
    let configChanges = {
      ...this.state.record,
      ...formChanges.record,
    };
    if (this.props.onFormChange) {
      let configToForm = this.createConfigStructureForForm(configChanges);
      this.props.onFormChange(configToForm);
    }
    this.saveChanges(configChanges, true);
  };

  triggerSaveConfig = (changes) => {
    console.log("main form requested some changes");
    this.setState(
      {
        record: {
          ...this.state.record,
          ...changes,
        },
      },
      () => {
        this.saveChanges(this.state.record);
      },
    );
  };

  saveChanges = (configChanges, setToState) => {
    console.log("saving Changes");
    axios
      .post(this.url + "/create", configChanges, this.requestHeader)
      .then((res) => {
        if (setToState) {
          let rawConfig = res.data;
          delete rawConfig.id;
          delete rawConfig.userId;
          this.setState({
            record: rawConfig,
          });
        }
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          (error.response.status === 502 || error.response.status === 500)
        ) {
          this.props.setNotification(
            true,
            tl("configNotSavedServerError"),
            "danger",
          );
          setTimeout(() => {
            this.props.setNotification(
              false,
              tl("configNotSavedServerError"),
              "danger",
            );
          }, 3000);
        }
      });
  };

  render() {
    const { classes } = this.props;
    let isError = false;
    this.props.configFields.map((field) => {
      if (this.props.errors[field]) {
        isError = true;
      }
    });
    return (
      <div ref={(node) => (this.node = node)} className={classes.container}>
        <Tooltip title={tl("configuration")}>
          <Button
            justIcon
            size="sm"
            color="transparent"
            onClick={() => this.togglePane()}
          >
            <SettingsIcon />
          </Button>
        </Tooltip>
        {isError && (
          <div className={classes.errorIconContainer}>
            <ErrorIcon className={classes.errorIcon} />
          </div>
        )}
        <div
          className={
            classes[
              this.props.width === "xs"
                ? "paneContainerMobile"
                : "paneContainer"
            ] + ` ${this.state.paneOpen ? "" : "closed"}`
          }
        >
          <div className={classes.callout}>
            <div className={classes.calloutNip} />
          </div>
          <div
            className={
              classes.pane + ` ${this.props.width === "xs" && "mobile"}`
            }
          >
            <div className={classes.paneTitle}>
              {this.props.title
                ? this.props.title
                : tl(Cookies.get("lang", "configuration"))}
            </div>
            <div
              className={
                classes.formContainer +
                ` ${this.props.width === "xs" && "mobile"}`
              }
            >
              {React.createElement(this.props.form, {
                record: this.state.record,
                errors: this.state.errors,
                onFormChange: this.handleFormChange,
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withWidth()(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ConfigForm)),
);
