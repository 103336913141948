import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { tl } from "framework/utils/Translator";

//icon
import NextIcon from "@material-ui/icons/KeyboardArrowRight";
import PrevIcon from "@material-ui/icons/KeyboardArrowLeft";

const styles = {
  container: {
    display: "flex",
  },
  pageButton: {
    width: "30px",
    height: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    borderRadius: "5px",
    transition: "0.2s ease-out",
    "&:hover": {
      backgroundColor: "#eee",
    },
    "&.disabled": {
      cursor: "default",
      opacity: "0.5",
    },
  },
  pageIndicator: {
    height: "30px",
    padding: "0px 10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

class Pagination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxPage: props.maxPage ? props.maxPage : 0,
      currentPage: props.currentPage ? props.currentPage : 0,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.maxPage !== this.state.maxPage) {
      this.setState({ maxPage: nextProps.maxPage });
    }
    if (nextProps.currentPage !== this.state.currentPage) {
      this.setState({ currentPage: nextProps.currentPage });
    }
  }

  handlePageChange = (mode) => {
    this.setState(
      {
        currentPage:
          mode === "+"
            ? this.state.currentPage + 1
            : this.state.currentPage - 1,
      },
      () => {
        if (this.props.onPageChange) {
          this.props.onPageChange(this.state.currentPage);
        }
      },
    );
  };

  render() {
    const { classes } = this.props;
    if (this.state.maxPage !== 0) {
      return (
        <div className={classes.container}>
          <div
            className={
              classes.pageButton +
              ` ${this.state.currentPage === 0 ? "disabled" : ""}`
            }
            onClick={() => {
              if (this.state.currentPage > 0) {
                this.handlePageChange("-");
              }
            }}
          >
            <PrevIcon />
          </div>
          <div className={classes.pageIndicator}>
            {tl("page")} {this.state.currentPage + 1} / {this.state.maxPage}
          </div>
          <div
            className={
              classes.pageButton +
              ` ${
                this.state.currentPage === this.state.maxPage - 1
                  ? "disabled"
                  : ""
              }`
            }
            onClick={() => {
              if (this.state.currentPage < this.state.maxPage - 1) {
                this.handlePageChange("+");
              }
            }}
          >
            <NextIcon />
          </div>
        </div>
      );
    }
    return <div></div>;
  }
}

export default withStyles(styles)(Pagination);
