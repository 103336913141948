import React, { Component } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import Config from "company/Config";
import { mapStateToProps, mapDispatchToProps } from "framework/utils/Redux";
import { connect } from "react-redux";
// import AsterisLogo from 'assets/img/logos/logo.svg'
import Cookies from "js-cookie";
import axios from "axios";
import { Helmet } from "react-helmet";
import { tl } from "framework/utils/Translator";
import moment from "moment-timezone";
import Lottie from "react-lottie";
import * as AnimationData from "assets/animations/asteris2.json";

//layouts
import AuthLayout from "layouts/Auth.jsx";
import AdminLayout from "layouts/Admin.jsx";
import PageNotFound from "layouts/PageNotFound.jsx";

class RemoteConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isAnimated: false,
      errorCode: null,
      reroute: null,
    };
  }

  componentDidMount() {
    if (
      Config.saasConfig &&
      !this.props.Session.configLoaded &&
      Config.saasHost
    ) {
      this.setState({
        isLoading: true,
        isAnimated: true,
      });
      let domain = window.location.host;
      axios
        .post(
          Config.saasHost + "/api/saas_client/config",
          { domain },
          {
            headers: {
              "Content-Type": "application/json",
            },
          },
        )
        .then((response) => {
          const { data } = response;
          let loadedConfig = {
            host: data.host || null,
            title: data.title || null,
            defaultPage: data.defaultPage || null,
            webUrl: data.webUrl || null,
            favicon: data.favicon
              ? Config.saasHost + "/" + data.favicon.file
              : null,
            loginLogo: data.loginLogo
              ? Config.saasHost + "/" + data.loginLogo.file
              : null,
            companyLogo: data.companyLogo
              ? Config.saasHost + "/" + data.companyLogo.file
              : null,
            sidebarLogo: data.sidebarLogo
              ? Config.saasHost + "/" + data.sidebarLogo.file
              : null,
            loginBackground: data.loginBackground
              ? Config.saasHost + "/" + data.loginBackground.file
              : null,
            loginCardColor: data.loginCardColor || null,
            loginButtonColor: data.loginButtonColor || null,
            activeSidebarColor: data.activeSidebarColor || null,
            activeMenuShadowColor: data.activeMenuShadowColor || null,
            saasCenter: data.saasCenter || false,
            accounting: data.accounting || false,
            property: data.property || false,
            hr: data.hr || false,
            base: data.base || false,
            shop: data.shop || false,
            web: data.web || false,
            freight: data.freight || false,
            realEstate: data.realEstate || false,
            themePark: data.themePark || false,
            bakery: data.bakery || false,
            restaurant: data.restaurant || false,
            blogFontName: data.blogFontName || null,
            blogFontFile: data.blogFontFile ? data.blogFontFile.file : null,
            mapsApiKey: data.mapsApiKey || null,
            hideAccounting: data.hideAccounting || false,
            enableOfflineMode: data.enableOfflineMode || false,
            offlineModeURL: data.offlineModeURL || "",
            instantSalesItemPick: data.instantSalesItemPick || "simple",
            disableMarketplace: data.disableMarketplace || false,
            unitRepresentedByAnItem: data.unitRepresentedByAnItem || false,
            separateBranchAccounting: data.separateBranchAccounting || false,
            center: data.center || false,
            branchCode: data.branchCode || "",
            advancedProcurement: data.advancedProcurement || false,
            instantSalesStrictMode: data.instantSalesStrictMode || false,
            bank:data.bank || "",
            bankName:data.bankName || "",
            bankAccount:data.bankAccount || "",
            draftMode:data.draftMode || "no_draft",
            draftStrictMode:data.draftStrictMode || false,
            autoApplyPromotion:data.autoApplyPromotion || false,
            accumulateItems:data.accumulateItems || false,
          };
          this.props.setRemoteConfig(loadedConfig);
          Cookies.set("remoteConfig", JSON.stringify(loadedConfig), {
            path: "/",
            expires: moment().add("years", 1).toDate(),
          });

          if (!Cookies.get("accessToken")) {
            if (Cookies.get("refreshToken")) {
              axios
                .post(data.host + "/api/oauth/token", {
                  refresh_token: Cookies.get("refreshToken"),
                })
                .then((response) => {
                  Cookies.set("accessToken", response.data.accessToken, {
                    path: "/",
                    expires: moment.unix(response.data.atExpiredAt).toDate(),
                  });
                  Cookies.set("refreshToken", response.data.refreshToken, {
                    path: "/",
                    expires: moment.unix(response.data.rtExpiredAt).toDate(),
                  });
                  //access rights safeguard
                  axios
                    .post(
                      data.host + "/api/user/get/accessRights",
                      {},
                      {
                        headers: {
                          Authorization: "Bearer " + response.data.accessToken,
                        },
                      },
                    )
                    .then((response) => {
                      this.props.setAccessRights(
                        response.data.accessRights,
                        response.data.menus,
                      );
                    });
                })
                .catch((error) => {
                  if (error) {
                    Cookies.remove("refreshToken", { path: "/" });
                  }
                });
            } else {
              if (window.location.pathname !== "/admin/dashboard") {
                let encodedPath = encodeURIComponent(window.location.pathname);
                this.setState({
                  reroute: "/auth/login?redirect=" + encodedPath,
                });
              } else {
                this.setState({
                  reroute: "/auth/login",
                });
              }
            }
          }

          axios
            .post(
              data.host + "/api/user/get/accessRights",
              {},
              {
                headers: {
                  Authorization: "Bearer " + Cookies.get("accessToken"),
                },
              },
            )
            .then((response) => {
              this.props.setAccessRights(
                response.data.accessRights,
                response.data.menus,
              );
            })
            .catch((error) => {
              if (error) {
                Cookies.remove("accessToken", { path: "/" });
                Cookies.remove("refreshToken", { path: "/" });
                this.setState({
                  reroute: "/auth/login",
                });
              }
            });

          if (!Cookies.get("username") && Cookies.get("accessToken")) {
            axios
              .get(data.host + "/user", {
                headers: {
                  Authorization: "Bearer " + Cookies.get("accessToken"),
                },
              })
              .then((res) => {
                Cookies.set("username", res.data.name, {
                  path: "/",
                  expires: moment().add(2, "days").toDate(),
                });
              })
              .catch((error) => {
                if (
                  error &&
                  error.response &&
                  error.response.status &&
                  error.response.status === 401
                ) {
                  Cookies.remove("accessToken", { path: "/" });
                }
              });
          }

          if (this.state.errorCode !== null) {
            this.setState({
              errorCode: null,
            });
          }
        })
        .catch((error) => {
          console.log(error.response);
          if (error && error.response && error.response.status === 400) {
            //when the server can't find the domain
            this.setState({
              errorCode: "notFound",
            });
            let cookieConfigs = JSON.parse(Cookies.get("remoteConfig"));
            if (cookieConfigs && cookieConfigs.domain === domain) {
              //remove cookies if there's cookie with same domain
              Cookies.remove("remoteConfig", { path: "/" });
            }
          } else if (Cookies.get("remoteConfig")) {
            //if cookies found while remoteConfig server fails
            let cookieConfigs = JSON.parse(Cookies.get("remoteConfig"));
            //check the cookie, is the domain same?
            if (cookieConfigs.domain === domain) {
              this.props.setRemoteConfig(cookieConfigs);
            } else {
              this.setState({
                errorCode: "generalError",
              });
            }
          } else {
            // general error (if request just fails / internal server error)
            this.setState({
              errorCode: "generalError",
            });
          }
        });
      setTimeout(() => {
        this.setState({
          isLoading: false,
          isAnimated: false,
        });
      }, 3000);
    }
  }

  render() {
    const { isLoading } = this.state;
    let manifest = null
    if (Config.saasConfig) {
      manifest = {
        "name":this.props.Session.title,
        "short_name":this.props.Session.title,
        "icons": [
          {
            "src": "favicon.ico",
            "sizes": "64x64 32x32 24x24 16x16",
            "type": "image/x-icon"
          }
        ],
        "start_url": "./index.html",
        "display": "standalone",
        "theme_color": "#000000",
        "background_color": "#ffffff"
      }
    }
    return (
      <>
        <Helmet defer={false}>
          <title>
            {Config.saasConfig ? this.props.Session.title : Config.title}
          </title>
          {manifest && <link rel="manifest" href={"data:application/manifest+json,"+(encodeURIComponent(JSON.stringify(manifest)))} />}
          <link
            rel="shortcut icon"
            href={
              Config.saasConfig
                ? this.props.Session.favicon
                  ? this.props.Session.favicon
                  : Config.favicon
                : Config.favicon
            }
          />
          {Config.midtransClientKey && (
            <script
              type="text/javascript"
              src="https://app.sandbox.midtrans.com/snap/snap.js"
              data-client-key={Config.midtransClientKey}
            />
          )}
          {/* {(Config.saasConfig && this.props.Session.mapsApiKey) ? <script 
                        key="placesAPI"
                        async
                        defer
                        url={`https://maps.googleapis.com/maps/api/js?key=${this.props.Session.mapsApiKey}&libraries=places&language=id;`}       
                    /> 
                    : Config.mapsApiKey 
                        ? <script 
                        key="placesAPI"
                        async
                        defer
                        url={`https://maps.googleapis.com/maps/api/js?key=${Config.mapsApiKey}&libraries=places&language=id;`}
                    /> : <></>} */}
        </Helmet>
        <Router history={this.props.history}>
          <Switch>
            <Route path="/auth" component={AuthLayout} />
            {this.state.reroute && <Redirect to={this.state.reroute} />}
            <Route path="/admin" component={AdminLayout} />
            <Redirect
              from="/"
              to={
                Config.saasConfig
                  ? this.props.Session.defaultPage || "/"
                  : Config.defaultPage
              }
            />
            <Route component={PageNotFound} />
          </Switch>
        </Router>
        <div
          style={{
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000000000",
            position: "fixed",
            opacity: isLoading || this.state.errorCode !== null ? 1 : 0,
            pointerEvents:
              isLoading || this.state.errorCode !== null ? "auto" : "none",
            transition: "0.3s ease-in-out",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* <img 
                            src={AsterisLogo}
                            style={{
                                height:"45px",
                                width:"auto",
                                margin:"10px 0px",
                                justifyContent:"center"
                            }}
                        /> */}
            <Lottie
              options={{
                loop: false,
                autoplay: false,
                animType: "svg",
                animationData: AnimationData.default,
                rendererSettings: {
                  progressiveLoad: true,
                },
              }}
              height={100}
              isStopped={!this.state.isAnimated}
              isPaused={false}
            />
            <div style={{ textAlign: "center" }}>
              {this.state.errorCode === null
                ? ""
                : this.state.errorCode === "notFound"
                  ? tl(Cookies.get("lang"), "domainNotFound")
                  : tl(Cookies.get("lang"), "serverMaintenance")}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RemoteConfig);
