const styles = {
  containerMessage: {
    display: "flex",
    justifyContent: "flex-end",
    "&.opposite": {
      display: "unset",
      justifyContent: "unset",
    },
  },
  message: {
    color: "white",
    margin: "10px 0px",
    padding: 5,
    width: "fit-content",
    borderRadius: "10px 0px 10px 10px",
    marginLeft: "auto",
    flexDirection: "column",
    display: "flex",
    backgroundColor: "#e0e0e0",
    fontSize: "13px",
    "&.opposite": {
      marginLeft: "unset",
      borderRadius: "0px 10px 10px 10px",
      backgroundColor: "#f5f5f5",
      color: "#747474 !important",
      "& span": {
        color: "#aaaaaa",
      },
    },
    "& p": {
      fontSize: "0.85rem",
      margin: "0px",
      color: "#000000",
    },
    "& span": {
      width: "inherit",
      marginLeft: "auto",
      fontSize: "0.75rem",
      color: "#747474",
    },
  },
  chatName: {
    fontSize: "0.95rem",
    margin: "0px 5px",
    color: "#3c4858",
  },
  messageWrapper: {
    display: "flex",
    flexDirection: "row-reverse",
    maxWidth: "80%",
    "&.opposite": {
      flexDirection: "unset",
    },
  },
  triangle: {
    width: 0,
    height: 0,
    borderTop: "10px solid #e0e0e0",
    borderRight: "10px solid transparent",
    margin: "10px 0px",
    "&.opposite": {
      borderTop: "10px solid #f5f5f5",
      borderLeft: "10px solid transparent",
      borderRight: "unset",
      margin: "10px 0px",
    },
  },
  containerChat: {
    height: "560px",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      backgroundColor: "#f5f5f5 !important",
      width: "10px !important",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#f5f5f5 !important",
      borderRadius: "10px !important",
    },
    "&::-webkit-scrollbar-track:hover": {
      backgroundColor: "#eeeeee !important",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#babac0 !important",
      borderRadius: "10px !important",
      border: "1px solid #f5f5f5 !important",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#a0a0a5 !important",
      border: "1px solid #eeeeee !important",
    },
    "&::-webkit-scrollbar-button": {
      display: "none !important",
    },
    "@media (max-width : 1366px)": {
      height: "unset",
      alignItems: "stretch",
      flex: "auto",
    },
  },
  chatSection: {
    display: "flex",
    flexDirection: "column",
    padding: "0px 30px",
  },
  identity: {
    width: "100%",
    padding: "0px 10px",
    height: 65,
    display: "flex",
    alignItems: "center",

    "& h4:first-of-type": {
      marginLeft: "5px",
      marginTop: "0px",
      marginBottom: "0px",
    },
    "& label ": {
      marginLeft: "5px",
    },
  },
  form: {
    margin: "0px 10px",
    alignItems: "stretch",
    flex: "auto",
  },
  inputChat: {
    borderRadius: "5px",
    padding: "5px",
    resize: "none",
    fontFamily: "Quicksand",
    lineHeight: 1.2,
    border: "1px solid #f5f5f5",
    overflowY: "auto",
    fontSize: "16px",
    width: "100%",
    display: "flex",
    "&::-webkit-scrollbar": {
      backgroundColor: "#f5f5f5",
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#f5f5f5",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track:hover": {
      backgroundColor: "#eeeeee",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#babac0",
      borderRadius: "10px",
      border: "1px solid #f5f5f5",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#a0a0a5",
      border: "1px solid #eeeeee",
    },
    "&::-webkit-scrollbar-button": {
      display: "none",
    },
    "&.error": {
      border: "1px solid #de252e",
    },
  },
  headerChat: {
    borderBottom: "1px solid #f5f5f5",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 10px",
  },
  buttonSend: {
    width: 50,
    height: 50,
  },
  chatAction: {
    alignItems: "center",
    padding: "5px",
    display: "flex",
    justifyContent: "space-between",
    borderTop: "1px solid #f5f5f5",
    height: "auto",
    "&.unvisible": {
      display: "none",
    },
  },
  hrText: {
    margin: "0px auto",
    backgroundColor: "#f0f4c3",
    width: "fit-content",
    padding: "5px",
    borderRadius: "5px",
    fontSize: "0.75rem",
  },
  btnSendImage: {
    width: "40px",
    height: "40px",
    display: "block",
    marginLeft: "auto",
  },
};

export default styles;
