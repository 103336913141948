const Translations = {
	id:{
		"accounting":"accounting",

		//Common Accounting Translation
		"Acct.vat":"PPN",
		"Acct.defaultVat":"PPN Standar",
		"Acct.delayedVat":"PPN Ditunda",
		"Acct.delayedVatDate":"Tanggal PPN Ditunda",

		//Accounting Policy
		"AccountingPolicy":"Polis Akunting",
		"accounting_policy":"Polis Akunting",
		"AccountingPolicy.code":"Kode",
		"AccountingPolicy.name":"Nama",
		"AccountingPolicy.value":"Nilai",
		//Account Fiscal
		"AccountFiscal":"Akun Fiskal",
		"account_fiscal":"Akun Fiskal",
		"AccountFiscal.fiscalYear":"Tahun Fiskal",
		"AccountFiscal.startDate":"Tanggal Mulai",
		"AccountFiscal.endDate":"Tanggal Akhir",
		//Account Period
		"AccountPeriod":"Akun Periode",
		"account_period":"Akun Periode",
		"AccountPeriod.code":"Kode",
		"AccountPeriod.name":"nama",
		"AccountPeriod.fiscalYearId":"Tahun Fiskal",
		"AccountPeriod.fiscalYear":"Tahun Fiskal",
		"AccountPeriod.dateStart":"Tanggal Mulai",
		"AccountPeriod.dateStop":"Tanggal Akhir",
		"AccountPeriod.state":"Status",

		"AccountPeriod.open":"Buka",
		"AccountPeriod.confirmTitle.action/open":"Buka",
		"AccountPeriod.confirmContent.action/open":"Apakah Anda yakin ingin membuka Akun Periode ini?",
		"AccountPeriod.success.action/open":"Akun Periode telah berhasil dibuka",

		"AccountPeriod.close":"Tutup",
		"AccountPeriod.confirmTitle.action/close":"Tutup",
		"AccountPeriod.confirmContent.action/close":"Apakah Anda yakin ingin menutup Akun Periode ini?",
		"AccountPeriod.success.action/close":"Akun Periode telah berhasil ditutup",

		"AccountPeriod.hold":"Tahan",
		"AccountPeriod.confirmTitle.action/hold":"Tahan",
		"AccountPeriod.confirmContent.action/hold":"Apakah Anda yakin ingin menahan Akun Periode ini?",
		"AccountPeriod.success.action/hold":"Akun Periode telah berhasil ditahan",

		//Account
		"Account":"Akun",
		"account":"Akun",
		"account.activate":"Aktifkan",
		"Account.confirmTitle.action/activate":"Aktifkan",
		"Account.confirmContent.action/activate":"Apakah Anda yakin ingin mengaktifkan Akun ini?",
		"Account.success.action/activate":"Akun telah berhasil diaktifkan",
		"account.deactivate":"Nonaktifkan",
		"Account.confirmTitle.action/deactivate":"Nonaktifkan",
		"Account.confirmContent.action/deactivate":"Apakah Anda yakin ingin menonaktifkan Akun ini?",
		"Account.success.action/deactivate":"Akun telah berhasil dinonaktifkan",
		"Account.name":"Nama",
		"Account.code":"Kode",
		"Account.position":"Posisi",
		"Account.category":"Kategori",
		"Account.type":"Tipe",
		"Account.defaultCurrencyId":"Mata Uang Default",
		"Account.defaultCurrency":"Mata Uang Default",
		"Account.parentId":"Induk",
		"Account.parent":"Induk",
		"Account.view":"Induk",
		"Account.state":"Status",
		"Account.otherBalanceSource":"Sumber Saldo Alternatif",
		"Account.group":"Kelompok Sumber",
		"Account.otherBalancePeriod":"Periode Saldo Alternatif",
		"Account.accountHolder":"Penanggung Jawab Akun",
		"Account.trialBalance":"Neraca Saldo",

		//Account Group
		"AccountGroup":"Kelompok Akun",
		"account_group":"Kelompok Akun",
		"account_group.activate":"Aktifkan",
		"AccountGroup.confirmTitle.action/activate":"Aktifkan",
		"AccountGroup.confirmContent.action/activate":"Apakah Anda yakin ingin mengaktifkan Kelompok Akun ini?",
		"AccountGroup.success.action/activate":"Kelompok Akun telah berhasil diaktifkan",
		"account_group.deactivate":"Nonaktifkan",
		"AccountGroup.confirmTitle.action/deactivate":"Nonaktifkan",
		"AccountGroup.confirmContent.action/deactivate":"Apakah Anda yakin ingin menonaktifkan Kelompok Akun ini?",
		"AccountGroup.success.action/deactivate":"Kelompok Akun telah berhasil dinonaktifkan",
		"AccountGroup.name":"Nama",
		"AccountGroup.code":"Kode",
		"AccountGroup.normalBalance":"Saldo Normal",
		"AccountGroup.scontroColumn":"Kolom Skontro",
		"AccountGroup.renderType":"Jenis Tampilan",
		"AccountGroup.lines":"Baris",
		"AccountGroup.expanded":"Diperluas",
		"AccountGroup.showCode":"Tampilkan Kode",
		"AccountGroup.sequence":"Urutan",
		"AccountGroup.scontroColumn":"Kolom Skontro",
		"AccountGroup.state":"Status",
		"AccountGroup.report":"Laporan",

		//Account Group Line
		"AccountGroupLine":"Baris Kelompok Akun",
		"account_group_line":"Baris Kelompok Akun",
		"AccountGroupLine.sequence":"Urutan",
		"AccountGroupLine.accountId":"Akun",
		"AccountGroupLine.account":"Akun",
		"AccountGroupLine.groupId":"Kelompok",
		"AccountGroupLine.group":"Kelompok",
		"AccountGroupLine.normalBalance":"Saldo Normal",
		"AccountGroupLine.expanded":"Diperluas",
		"AccountGroupLine.fetchLevel":"Tingkat",
		"AccountGroupLine.skipLevel":"Lewati",

		//Currency
		"Currency":"Mata Uang",
		"currency":"Mata Uang",
		"currency.activate":"Aktifkan",
		"Currency.confirmTitle.action/activate":"Aktifkan",
		"Currency.confirmContent.action/activate":"Apakah Anda yakin ingin mengaktifkan Mata Uang ini?",
		"Currency.success.activate":"Mata Uang telah berhasil diaktifkan",
		"currency.deactivate":"Nonaktifkan",
		"Currency.confirmTitle.action/deactivate":"Nonaktifkan",
		"Currency.confirmContent.action/deactivate":"Apakah Anda yakin ingin menonaktifkan Mata Uang ini?",
		"Currency.success.deactivate":"Mata Uang telah berhasil dinonaktifkan",
		"Basic Information":"Informasi Dasar",
		"Currency.name":"Nama",
		"Currency.code":"Kode",
		"Currency.symbol":"Simbol",
		"Accounting":"Akunting",
		"Currency.defaultPayableAccountId":"Akun Utang",
		"Currency.defaultPayableAccount":"Akun Utang",
		"Currency.defaultReceivableAccountId":"Akun Piutang",
		"Currency.defaultReceivableAccount":"Akun Piutang",
		"Currency.advanceReceiveAccountId":"Akun Penerimaan Di Muka",
		"Currency.advanceReceiveAccount":"Akun Penerimaan Di Muka",
		"Currency.advancePaymentAccountId":"Akun Pembayaran Di Muka",
		"Currency.advancePaymentAccount":"Akun Pembayaran Di Muka",
		"Currency.salesDiscountAccountId":"Akun Potongan Penjualan",
		"Currency.salesDiscountAccount":"Akun Potongan Penjualan",
		"Currency.realizedGainOrLossAccountId":"Laba/Rugi Selisih Kurs Terealisasi",
		"Currency.realizedGainOrLossAccount":"Laba/Rugi Selisih Kurs Terealisasi",
		"Currency.unrealizedGainOrLossAccountId":"Laba/Rugi Selisih Kurs Belum Terealisasi",
		"Currency.unrealizedGainOrLossAccount":"Laba/Rugi Selisih Kurs Belum Terealisasi",
		"Currency.state":"Status",
		//Daily Exchange Rate
		"ExchangeRateDaily":"Nilai Tukar Harian",
		"exchange_rate_daily":"Nilai Tukar Harian",
		"ExchangeRateDaily.date":"Tanggal",
		"ExchangeRateDaily.periodId":"Periode",
		"ExchangeRateDaily.period":"Periode",
		"ExchangeRateDaily.currencyFromId":"Kurs Asal",
		"ExchangeRateDaily.currencyFrom":"Kurs Asal",
		"ExchangeRateDaily.currencyToId":"Kurs Tujuan",
		"ExchangeRateDaily.currencyTo":"Kurs Tujuan",
		"ExchangeRateDaily.rate":"Nilai Tukar",
		"ExchangeRateDaily.description":"Deskripsi",
		//Taxes
		"Taxes":"Pajak",
		"taxes":"Pajak",
		"taxes.activate":"Aktifkan",
		"Taxes.confirmTitle.action/activate":"Aktifkan",
		"Taxes.confirmContent.action/activate":"Apakah Anda yakin ingin mengaktifkan Pajak ini?",
		"Taxes.success.activate":"Pajak telah berhasil diaktifkan",
		"taxes.deactivate":"Nonaktifkan",
		"Taxes.confirmTitle.action/deactivate":"Nonaktifkan",
		"Taxes.confirmContent.action/deactivate":"Apakah Anda yakin ingin menonaktifkan Pajak ini?",
		"Taxes.success.deactivate":"Pajak telah berhasil dinonaktifkan",
		"Taxes.name":"Nama",
		"Taxes.type":"Tipe",
		"Taxes.value":"Persentase (%)",
		"Taxes.salesAccountId":"Akun Penjualan",
		"Taxes.salesAccount":"Akun Penjualan",
		"Taxes.purchaseAccountId":"Akun Pembelian",
		"Taxes.purchaseAccount":"Akun Pembelian",
		"Taxes.taxedIn":"Dikenakan Pada",
		"Taxes.state":"Status",
		//Employee
		"Employee":"Pegawai",
		"employee":"Pegawai",
		"employee.activate":"Aktifkan",
		"Employee.confirmTitle.activate":"Aktifkan",
		"Employee.confirmContent.activate":"Apakah Anda yakin ingin mengaktifkan Pegawai ini?",
		"Employee.success.activate":"Pegawai telah berhasil diaktifkan",
		"employee.deactivate":"Nonaktifkan",
		"Employee.confirmTitle.deactivate":"Nonaktifkan",
		"Employee.confirmContent.deactivate":"Apakah Anda yakin ingin menonaktifkan Pegawai ini?",
		"Employee.success.deactivate":"Pegawai telah berhasil dinonaktifkan",
		"Employee.name":"Nama",
		"Employee.state":"Status",
		//Project
		"Project":"Proyek",
		"project":"Proyek",
		"project.activate":"Aktifkan",
		"Project.confirmTitle.action/activate":"Aktifkan",
		"Project.confirmContent.action/activate":"Apakah Anda yakin ingin mengaktifkan Proyek ini?",
		"Project.success.action/activate":"Proyek telah berhasil diaktifkan",
		"project.deactivate":"Nonaktifkan",
		"Project.confirmTitle.action/deactivate":"Nonaktifkan",
		"Project.confirmContent.action/deactivate":"Apakah Anda yakin ingin menonaktifkan Proyek ini?",
		"Project.success.action/deactivate":"Proyek telah berhasil dinonaktifkan",
		"Project.code":"Kode",
		"Project.name":"Nama",
		"Project.customerId":"Pelanggan",
		"Project.customer":"Pelanggan",
		"Project.coordinators":"Koordinator",
		"Project.state":"Status",
		//Project Coordinator
		"ProjectCoordinator":"Penanggung Jawab Proyek",
		"project_coordinator":"Penanggung Jawab Proyek",
		"ProjectCoordinator.projectId":"Proyek",
		"ProjectCoordinator.project":"Proyek",
		"ProjectCoordinator.userId":"Koordinator",
		"ProjectCoordinator.user":"Koordinator",
		//Warehouse
		"Warehouse":"Gudang",
		"warehouse":"Gudang",
		"warehouse.activate":"Aktifkan",
		"Warehouse.confirmTitle.action/activate":"Aktifkan",
		"Warehouse.confirmContent.action/activate":"Apakah Anda yakin ingin mengaktifkan Gudang ini?",
		"Warehouse.success.activate":"Gudang telah berhasil diaktifkan",
		"warehouse.deactivate":"Nonaktifkan",
		"Warehouse.confirmTitle.action/deactivate":"Nonaktifkan",
		"Warehouse.confirmContent.action/deactivate":"Apakah Anda yakin ingin menonaktifkan Gudang ini?",
		"Warehouse.success.deactivate":"Gudang telah berhasil dinonaktifkan",
		"Warehouse.name":"Nama",
		"Warehouse.code":"Kode",
		"Warehouse.branchId":"Cabang",
		"Warehouse.branch":"Cabang",
		"Warehouse.pic":"Penanggung Jawab",
		"Warehouse.expensed":"Dibebankan",
		"Warehouse.address":"Alamat",
		"Warehouse.description":"Deksripsi",
		"Warehouse.state":"Status",
		"Warehouse.sequence":"Urutan",
		//Location
		"Location":"Lokasi",
		"location":"Lokasi",
		"location.activate":"Aktifkan",
		"Location.confirmTitle.action/activate":"Aktifkan",
		"Location.confirmContent.action/activate":"Apakah Anda yakin ingin mengaktifkan Lokasi ini?",
		"Location.success.activate":"Lokasi telah berhasil diaktifkan",
		"location.deactivate":"Nonaktifkan",
		"Location.confirmTitle.action/deactivate":"Nonaktifkan",
		"Location.confirmContent.action/deactivate":"Apakah Anda yakin ingin menonaktifkan Lokasi ini?",
		"Location.success.deactivate":"Lokasi telah berhasil dinonaktifkan",
		"Location.code":"Kode",
		"Location.name":"Nama",
		"Location.warehouseId":"Gudang",
		"Location.warehouse":"Gudang",
		"Location.branchId":"Cabang",
		"Location.branch":"Cabang",
		"Location.description":"Deskripsi",
		"Location.sequence":"Urutan",
		"Location.state":"Status",
		//Branch Warehouse Location
		"BranchWarehouseLocation":"Lokasi Gudang Cabang",
		"branch_warehouse_location":"Lokasi Gudang Cabang",
		"BranchWarehouseLocation.branchId":"Cabang",
		"BranchWarehouseLocation.branch":"Cabang",
		"BranchWarehouseLocation.warehouseId":"Gudang",
		"BranchWarehouseLocation.warehouse":"Gudang",
		"BranchWarehouseLocation.locationId":"Lokasi",
		"BranchWarehouseLocation.location":"Lokasi",
		//Unit
		"Unit":"Satuan",
		"unit":"Satuan",
		"unit.activate":"Aktifkan",
		"Unit.confirmTitle.action/activate":"Aktifkan",
		"Unit.confirmContent.action/activate":"Apakah Anda yakin ingin mengaktifkan Satuan ini?",
		"Unit.success.activate":"Satuan telah berhasil diaktifkan",
		"unit.deactivate":"Nonaktifkan",
		"Unit.confirmTitle.action/deactivate":"Nonaktifkan",
		"Unit.confirmContent.action/deactivate":"Apakah Anda yakin ingin menonaktifkan Satuan ini?",
		"Unit.success.deactivate":"Satuan telah berhasil dinonaktifkan",
		"Unit.name":"Nama",
		"Unit.timeMeasured":"Diukur Waktu",
		"Unit.durationInHour":"Durasi dalam Jam",
		"Unit.units":"Konversi Satuan",
		"Unit.state":"Status",
		//Unit Conversion
		"UnitConversion":"Konversi Unit",
		"unit_conversion":"Konversi Unit",
		"unit_conversion.activate":"Aktifkan",
		"UnitConversion.confirmTitle.activate":"Aktifkan",
		"UnitConversion.confirmContent.activate":"Apakah Anda yakin ingin mengaktifkan Konversi Unit ini?",
		"UnitConversion.success.activate":"Konversi Unit telah berhasil diaktifkan",
		"unit_conversion.deactivate":"Nonaktifkan",
		"UnitConversion.confirmTitle.deactivate":"Nonaktifkan",
		"UnitConversion.confirmContent.deactivate":"Apakah Anda yakin ingin menonaktifkan Konversi Unit ini?",
		"UnitConversion.success.deactivate":"Konversi Unit telah berhasil dinonaktifkan",
		"UnitConversion.unitFromId":"Satuan Asal",
		"UnitConversion.unitFrom":"Satuan Asal",
		"UnitConversion.unitToId":"Satuan Tujuan",
		"UnitConversion.unitTo":"Satuan Tujuan",
		"UnitConversion.conversionRate":"Nilai Konversi",
		"UnitConversion.state":"Status",
		//Item Category
		"ItemCategory":"Kategori Barang",
		"item_category":"Kategori Barang",
		"item_category.activate":"Aktifkan",
		"ItemCategory.confirmTitle.action/activate":"Aktifkan",
		"ItemCategory.confirmContent.action/activate":"Apakah Anda yakin ingin mengaktifkan Kategori Barang ini?",
		"ItemCategory.success.activate":"Kategori Barang telah berhasil diaktifkan",
		"item_category.deactivate":"Nonaktifkan",
		"ItemCategory.confirmTitle.action/deactivate":"Nonaktifkan",
		"ItemCategory.confirmContent.action/deactivate":"Apakah Anda yakin ingin menonaktifkan Kategori Barang ini?",
		"ItemCategory.success.deactivate":"Kategori Barang telah berhasil dinonaktifkan",
		"ItemCategory.code":"Kode",
		"ItemCategory.name":"Nama",
		"ItemCategory.cleanUrl":"Clean URL",
		"ItemCategory.parentId":"Kategori Induk",
		"ItemCategory.parent":"Kategori Induk",
		"ItemCategory.image":"Gambar",
		"ItemCategory.thumbnail":"Cuplikan",
		"ItemCategory.state":"Status",
		"ItemCategory.hideOnReport":"Sembunyikan Dari Laporan",
		"ItemCategory.saleableOnline":"Dapat Dijual Online",
		
		//Additional Charge
		"AdditionalCharge":"Biaya Tambahan",
		"additional_charge":"Biaya Tambahan",
		"additional_charge.activate":"Aktifkan",
		"AdditionalCharge.confirmTitle.activate":"Aktifkan",
		"AdditionalCharge.confirmContent.activate":"Apakah Anda yakin ingin mengaktifkan Biaya Tambahan ini?",
		"AdditionalCharge.success.activate":"Biaya Tambahan telah berhasil diaktifkan",
		"additional_charge.deactivate":"Nonaktifkan",
		"AdditionalCharge.confirmTitle.deactivate":"Nonaktifkan",
		"AdditionalCharge.confirmContent.deactivate":"Apakah Anda yakin ingin menonaktifkan Biaya Tambahan ini?",
		"AdditionalCharge.success.deactivate":"Biaya Tambahan telah berhasil dinonaktifkan",
		"AdditionalCharge.name":"Nama",
		"AdditionalCharge.code":"Kode",
		"AdditionalCharge.type":"Tipe",
		"AdditionalCharge.value":"Nominal",
		"AdditionalCharge.valuePercentage":"Persentase (%)",
		"AdditionalCharge.salesAccountId":"Akun Penjualan",
		"AdditionalCharge.salesAccount":"Akun Penjualan",
		"AdditionalCharge.expenseAccountId":"Akun Beban",
		"AdditionalCharge.expenseAccount":"Akun Beban",		
		"AdditionalCharge.state":"Status",
		//Merek
		"Brand":"Merek",
		"brand":"Merek",
		"brand.activate":"Aktifkan",
		"Brand.confirmTitle.activate":"Aktifkan",
		"Brand.confirmContent.activate":"Apakah Anda yakin ingin mengaktifkan Brand ini?",
		"Brand.success.activate":"Brand telah berhasil diaktifkan",
		"brand.deactivate":"Nonaktifkan",
		"Brand.confirmTitle.deactivate":"Nonaktifkan",
		"Brand.confirmContent.deactivate":"Apakah Anda yakin ingin menonaktifkan Brand ini?",
		"Brand.success.deactivate":"Brand telah berhasil dinonaktifkan",
		"Brand.code":"Kode",
		"Brand.name":"Nama",
		"Brand.cleanUrl":"Clean URL",
		"Brand.sequence":"Urutan",
		"Brand.image":"Gambar",
		"Brand.thumbnail":"Cuplikan",
		"Brand.state":"Status",
		//Tag
		"Tag":"Tag",
		"tag":"Tag",
		"tag.activate":"Aktifkan",
		"Tag.confirmTitle.activate":"Aktifkan",
		"Tag.confirmContent.activate":"Apakah Anda yakin ingin mengaktifkan Tag ini?",
		"Tag.success.activate":"Tag telah berhasil diaktifkan",
		"tag.deactivate":"Nonaktifkan",
		"Tag.confirmTitle.deactivate":"Nonaktifkan",
		"Tag.confirmContent.deactivate":"Apakah Anda yakin ingin menonaktifkan Tag ini?",
		"Tag.success.deactivate":"Tag telah berhasil dinonaktifkan",
		"Tag.name":"Nama",
		"Tag.image":"Gambar",
		"Tag.thumbnail":"Cuplikan",
		"Tag.state":"Status",
		//Item Image
		"ItemImage":"Gambar Barang",
		"item_image":"Gambar Barang",
		"ItemImage.title":"Judul",
		"ItemImage.image":"Gambar",
		"ItemImage.itemId":"Barang",
		"ItemImage.item":"Barang",
		//Item Attachment
		"ItemAttachment":"Lampiran Barang",
		"item_attachment":"Lampiran Barang",
		"ItemAttachment.attachment":"Lampiran",
		"ItemAttachment.itemId":"Barang",
		"ItemAttachment.item":"Barang",
		//Item Attribute
		"ItemAttribute":"Atribut Barang",
		"item_attribute":"Atribut Barang",
		"ItemAttribute.name":"Atribut",
		"ItemAttribute.value":"Nilai",
		"ItemAttribute.itemId":"Barang",
		"ItemAttribute.item":"Barang",
		"ItemAttribute.sequence":"Urutan",
		"ItemAttribute.measurementUnit":"Satuan",
		//Item
		"Item":"Barang",
		"item":"Barang",
		"item.activate":"Aktifkan",
		"Item.confirmTitle.action/activate":"Aktifkan",
		"Item.confirmContent.action/activate":"Apakah Anda yakin ingin mengaktifkan Barang ini?",
		"Item.success.activate":"Barang telah berhasil diaktifkan",
		"item.deactivate":"Nonaktifkan",
		"Item.confirmTitle.action/deactivate":"Nonaktifkan",
		"Item.confirmContent.action/deactivate":"Apakah Anda yakin ingin menonaktifkan Barang ini?",
		"Item.success.deactivate":"Barang telah berhasil dinonaktifkan",
		"Item.barcode_print":"Cetak Barcode",
		"Item.confirmTitle.barcode_print":"Cetak Barcode",
		"Item.confirmContent.barcode_print":"Apakah Anda yakin ingin mencetak barcode untuk barang ini?",
		"Item.success.barcode_print":"Barcode telah berhasil dicetak",
		"Item Information":"Informasi Barang / Jasa",
		"Item.code":"Kode",
		"Item.name":"Nama",
		"Item.categoryId":"Kategori",
		"Item.category":"Kategori",
		"Item.type":"Tipe",
		"Item.additionalCharges":"Biaya Tambahan",
		"Item.brandId":"Merek",
		"Item.brand":"Merek",
		"Item.tags":"Tag",
		"Item.attributes":"Atribut",
		"Item.position":"Posisi Urutan",
		"Item.description":"Deskripsi",
		"Prices":"Daftar Harga",
		"Prices":"Daftar Harga",
		"Item.defaultUnitId":"Satuan Dasar",
		"Item.defaultUnit":"Satuan Dasar",
		"Item.defaultPurchaseUnit":"Satuan Dasar Pembelian",
		"Item.vendor":"Vendor Utama",
		"Item.purchase":"Pembelian",
		"Item.timeMeasured":"Diukur Waktu",
		"Item.UnitId":"Satuan",
		"Item.Unit":"Satuan",
		"Item.units":"Satuan Yang Dapat Dipilih",
		"Item.defaultPrice":"Harga Standar",
		"Item.purchasePrice":"Harga Pembelian",
		"Item.branchPrices":"Harga Per Cabang",
		"Item.additionalCharges":"Biaya Tambahan",
		"Inventory":"Persediaan",
		"Inventory":"Persediaan",
		"Item.inventoryType":"Tipe Persediaan",
		"Item.minQuantity":"Jumlah Minimum Stok",
		"Item.warehouseId":"Gudang Standar",
		"Item.warehouse":"Gudang Standar",
		"Item.defaultLocation":"Lokasi Standar Masuk",
		"Item.defaultInLocation":"Lokasi Standar Keluar",
		"Item.hasUpc":"Punya UPC",
		"Item.hasSku":"Punya SKU",
		"Item.generateSKU":"SKU Otomatis",
		"Item.length":"Panjang",
		"Item.width":"Lebar",
		"Item.thickness":"Ketebalan",
		"Online Store":"Toko Online",
		"Item.saleableOnline":"Dapat Dijual Online",
		"Item.dropshipped":"Dropship",
		"Item.position":"Posisi Urutan",
		"Item.image":"Gambar",
		"Item.thumbnail":"Cuplikan",
		"Item.images":"Daftar Gambar",
		"Item.attachments":"Lampiran",
		"Item.cleanUrl":"URL Sederhana",
		"Item.onlineDescription":"Deskripsi Online",
		"Item.listingDate":"Tanggal Listing",
		"Item.viewCount":"Jumlah View",
		"Item.variants":"Daftar Varian",
		"Item.codes":"Daftar Kode",
		"Item.options":"Pilihan",
		"Accounting":"Akunting",
		"Item.inventoryAccountId":"Akun Persediaan",
		"Item.inventoryAccount":"Akun Persediaan",
		"Item.salesAccountId":"Akun Penjualan",
		"Item.salesAccount":"Akun Penjualan",
		"Item.salesReturnAccountId":"Akun Retur Penjualan",
		"Item.salesReturnAccount":"Akun Retur Penjualan",
		"Item.salesItemDiscAccountId":"Akun Diskon Penjualan",
		"Item.salesItemDiscAccount":"Akun Diskon Penjualan",
		"Item.goodsInTransitAccountId":"Akun Barang Dalam Transit",
		"Item.goodsInTransitAccount":"Akun Barang Dalam Transit",
		"Item.purchaseReturnAccountId":"Akun Retur Pembelian",
		"Item.purchaseReturnAccount":"Akun Retur Pembelian",
		"Item.unbilledPayableAccountId":"Akun Utang Yang Belum Ditagihkan",
		"Item.unbilledPayableAccount":"Akun Utang Yang Belum Ditagihkan",
		"Item.unbilledReceivableAccountId":"Akun Piutang Yang Belum Ditagihkan",
		"Item.unbilledReceivableAccount":"Akun Piutang Yang Belum Ditagihkan",
		"Item.salesCommissionAccount":"Akun Komisi Penjualan",
		"Item.salesCommissionAccountId":"Akun Komisi Penjualan",
		"Item.expenseAccountId":"Akun Beban",
		"Item.expenseAccount":"Akun Beban",
		"Item.cogsAccountId":"Akun HPP",
		"Item.cogsAccount":"Akun HPP",
		"Item.assetAccountId":"Akun Aset",
		"Item.assetAccount":"Akun Aset",
		"Item.depreciationAccountId":"Akun Depresiasi",
		"Item.depreciationAccount":"Akun Depresiasi",
		"Item.accumulatedDepreciationAccountId":"Akun Akumulasi Depresiasi",
		"Item.accumulatedDepreciationAccount":"Akun Akumulasi Depresiasi",
		"Item.gainOrLossOnSalesAccountId":"Akun Keuntungan Kerugian Penjualan",
		"Item.gainOrLossOnSalesAccount":"Akun Keuntungan Kerugian Penjualan",
		"Item.gainOrLossOnRevaluationAccountId":"Akun Keuntungan Kerugian Penilaian Ulang",
		"Item.gainOrLossOnRevaluationAccount":"Akun Keuntungan Kerugian Penilaian Ulang",
		"Item.lossOnScrapAccountId":"Akun Kerugian Penghapusan",
		"Item.lossOnScrapAccount":"Akun Kerugian Penghapusan",
		"Item.disallowSellBelowPrice":"Tolak Penjualan Dibawah Harga",
		"Item.priceIncludesVAT":"Harga Termasuk PPN",
		"Item.byPassPriceCheck":"Abaikan Pengecekan Harga",
		"Item.allowSplitAccount":"Perbolehkan Pemisahan Akun",
		"Item.state":"Status",
		"Item.hideOnReport":"Sembunyikan Dari Laporan",
		"Item.metaDescription":"Deskripsi Meta",
		"Item.marketplaces":"Marketplace",
		"Item.variant":"Varian",
		"Item.variantPrices":"Varian Harga",
		"Item.packagingPrices":"Daftar Harga Kemasan",
		"Item.codeAdded":"Kode Ditambahkan",
		"Item.upcAdded":"UPC Ditambahkan",
		"Item.skuAdded":"SKU Ditambahkan",
		"Item.marketplacePrices":"Daftar Harga Marketplace",
		"Item.MarketplacePricesLine.marketplace":"Marketplace",
		"Item.MarketplacePricesLine.choice":"Pilihan Varian",
		"Item.MarketplacePricesLine.unit":"Satuan",
		"Item.MarketplacePricesLine.price":"Harga",
		"Item.rack":"Rak",
		"Item.purchaseQuantity":"Kuantitas Pembelian",
		"Item.grade":"Grade",
		"Item.relatedItem":"Relasi Barang",
		"Item.relatedItemConversion":"Konversi Relasi Barang",
		"Item.commission":"Komisi",
		"Item.commissionType":"Tipe Komisi",
		"Item.commissionPercentage":"Persentase Komisi (%)",
		"Item.commissionValue":"Nilai Komisi",
		"Item.tax":"Pajak",
		"Item.customAdditionalTax":"Pajak Tambahan",
		"Item.customAdditionalTaxPercentage":"Persentase Pajak Tambahan",
		"Item.vendorLeadTimeDays":"Lead Time (Hari)",
		"Item.turnoverRate":"Kecepatan Perputaran",
		"Item.itemSpecs":"Spesifikasi",

		//Item Branch Price
		"ItemUnit":"Harga Barang Per Cabang",
		"item_unit":"Harga Barang Per Cabang",
		"ItemUnit.itemId":"Barang",
		"ItemUnit.item":"Barang",
		"ItemUnit.branchId":"Cabang",
		"ItemUnit.branch":"Cabang",
		"ItemUnit.unit":"Satuan",
		"ItemUnit.conversionRate":"Nilai Konversi",
		"ItemUnit.price":"Harga",		
		//Item Branch Price
		"ItemBranchPrice":"Harga Barang Per Cabang",
		"item_branch_price":"Harga Barang Per Cabang",
		"ItemBranchPrice.itemId":"Barang",
		"ItemBranchPrice.item":"Barang",
		"ItemBranchPrice.branchId":"Cabang",
		"ItemBranchPrice.branch":"Cabang",
		"ItemBranchPrice.price":"Harga",
		"ItemBranchPrice.purchasePrice":"Harga Pembelian",
		"ItemBranchPrice.minimumPrice":"Harga Min.",
		//Item Branch Price
		"ItemPrice":"Harga Barang Per Cabang",
		"item_price":"Harga Barang Per Cabang",
		"ItemPrice.date":"Tanggal",
		"ItemPrice.price":"Harga",
		//Item Additonal Charge
		"ItemAdditionalCharge.additionalChargeId":"Tambahan",
		"ItemAdditionalCharge.additionalCharge":"Tambahan",
		"ItemAdditionalCharge.price":"Harga",
		//Item Value
		"ItemValue":"Nilai Barang",
		"item_value":"Nilai Barang",
		"ItemValue.itemId":"Barang",
		"ItemValue.item":"Barang",
		"ItemValue.dateIn":"Tanggal Masuk",
		"ItemValue.reference":"Referensi",
		"ItemValue.quantityIn":"Jumlah Masuk",
		"ItemValue.valueIn":"Nilai Masuk",
		"ItemValue.itemValueMoves":"Pergerakan Nilai Barang",
		//Item Value Move
		"ItemValueMove":"Pergerakan Nilai Barang",
		"item_value_move":"Pergerakan Nilai Barang",
		"ItemValueMove.itemId":"Barang",
		"ItemValueMove.item":"Barang",
		"ItemValueMove.dateOut":"Tanggal Keluar",
		"ItemValueMove.QuantityOut":"Jumlah Keluar",
		"ItemValueMove.ValueOut":"Nilai Keluar",
		"ItemValueMove.returnId":"Retur",
		"ItemValueMove.return":"Retur",
		"ItemValueMove.deliveryOrderId":"Surat Jalan",
		"ItemValueMove.deliveryOrder":"Surat Jalan",
		"ItemValueMove.adjustmentId":"Penyesuaian Persediaan",
		"ItemValueMove.adjustment":"Penyesuaian Persediaan",
		"ItemValueMove.inventoryIssueId":"Pembuangan Persediaan",
		"ItemValueMove.inventoryIssue":"Pembuangan Persediaan",
		"ItemValueMove.materialReleaseId":"Pemakaian Persediaan",
		"ItemValueMove.materialRelease":"Pemakaian Persediaan",
		"ItemValueMove.warehouseMoveId":"Perpindahan Persediaan",
		"ItemValueMove.warehouseMove":"Perpindahan Persediaan",
		//codes
		"CodesLine.name":"Nama",
		"CodesLine.code":"Kode",

		//Item barcode
		"ItemBarcode.paperSize":"Ukuran Kertas",
		"ItemBarcode.orientation":"Orientasi",
		"ItemBarcode.column":"Jumlah Kolom",
		"ItemBarcode.copy":"Jumlah Salinan",
		"ItemBarcode.margin":"Margin",
		"ItemBarcode.width":"Lebar",
		"ItemBarcode.height":"Tinggi",

		//Item Option
		"ItemOption":"Pilihan Barang",
		"item_option":"Pilihan Barang",
		"ItemOption.name":"Nama",
		"ItemOption.state":"Status",
		"ItemOption.activate":"Aktifkan",
		"ItemOption.confirmTitle.action/activate":"Aktifkan",
		"ItemOption.confirmContent.action/activate":"Apakah Anda yakin ingin mengaktifkan Pilihan Barang ini?",
		"ItemOption.success.action/activate":"Piilihan Barang telah berhasil diaktifkan",
		"ItemOption.deactivate":"Nonaktifkan",
		"ItemOption.confirmTitle.action/deactivate":"Nonaktifkan",
		"ItemOption.confirmContent.action/deactivate":"Apakah Anda yakin ingin menonaktifkan Pilihan Barang ini?",
		"ItemOption.success.action/deactivate":"Pilihan Barang telah berhasil dinonaktifkan",
		"ItemOptionLine.option":"Pilihan",
		"ItemOptionLine.sequence":"Urutan",

		//Partner
		"Partner":"Partner",
		"partner":"Partner",
		"partner.activate":"Aktifkan",
		"Partner.confirmTitle.activate":"Aktifkan",
		"Partner.confirmContent.activate":"Apakah Anda yakin ingin mengaktifkan Partner ini?",
		"Partner.success.activate":"Partner telah berhasil diaktifkan",
		"partner.deactivate":"Nonaktifkan",
		"Partner.confirmTitle.deactivate":"Nonaktifkan",
		"Partner.confirmContent.deactivate":"Apakah Anda yakin ingin menonaktifkan Partner ini?",
		"Partner.success.deactivate":"Partner telah berhasil dinonaktifkan",
		"Partner Information":"Informasi Partner",
		"Partner.name":"Nama",
		"Partner.isSupplier":"Supplier",
		"Partner.isCustomer":"Pelanggan",
		"Partner.email":"Email",
		"Partner.mobile":"Nomor HP",
		"Partner.contact":"Nomor Telepon Rumah",
		"Partner.street":"Alamat",
		"Partner.cityId":"Kota",
		"Partner.city":"Kota",
		"Partner.provinceId":"Provinsi",
		"Partner.province":"Provinsi",
		"Partner.countryId":"Negara",
		"Partner.country":"Negara",
		"Partner.zip":"Kode Pos",
		"Partner.ssid":"Nomor KTP",
		"Partner.idAttachment":"Lampiran KTP",
		"Finance and Accounting":"Keuangan and Akunting",
		"Partner.unlimitedCredit":"Kredit Tidak Terbatas",
		"Partner.creditLimit":"Plafon Kredit",
		"Partner.credit":"Kredit",
		"Partner.debitLimit":"Plafon Debit",
		"Partner.debit":"Debit",
		"Partner.taxable":"Kena Pajak",
		"Partner.npwp":"NPWP",
		"Partner.defaultCurrencyId":"Kurs Standar",
		"Partner.defaultCurrency":"Kurs Standar",
		"Partner.purchaseInvoiceDaysDue":"Jatuh Tempo Tagihan Pembelian (Hari)",
		"Partner.salesInvoiceDaysDue":"Jatuh Tempo Tagihan Penjualan (Hari)",
		"Branch Information":"Informasi Cabang",
		"Partner.branchId":"Cabang",
		"Partner.branch":"Cabang",
		"Company Information":"Informasi Perusahaan",
		"Partner.isCompany":"Perusahaan",
		"Partner.companyName":"Nama Perusahaan",
		"Partner.companyNpwp":"NPWP Perusahaan",
		"Partner.companyAddress":"Alamat Perusahaan",
		"Partner.companyCityId":"Kota Perusahaan",
		"Partner.companyCity":"Kota Perusahaan",
		"Partner.companyProvinceId":"Provinsi Perusahaan",
		"Partner.companyProvince":"Provinsi Perusahaan",
		"Partner.companyCountryId":"Negara Perusahaan",
		"Partner.companyCountry":"Negara Perusahaan",
		"Partner.companyZip":"Kode Pos Perusahaan",
		"Other Information":"Informasi Lain",
		"Partner.nodeId":"Titik",
		"Partner.node":"Titik",
		"Partner.joinDate":"Tanggal Bergabung",
		"Partner.media":"Media",
		"Partner.class":"Kelas",
		"Partner.code":"Kode",
		"Partner.state":"Status",
		"Partner.purchasing":"Pembelian",
		"Partner.purchasingUsers":"Penanggung Jawab",
		"Partner.user":"Nama Staf",
		"Partner.birthDate":"Tanggal Lahir",
		"Partner.idNumber":"No. KTP",
		"Partner.idAddress":"Alamat KTP",
		"Partner.address":"Alamat Lengkap",
		"Partner.occupation":"Pekerjaan",
		"Partner.maritalStatus":"Status Pernikahan",
		"Partner.incomeRange":"Kisaran Penghasilan",
		//Journal Entries
		"AccountMove":"Daftar Jurnal",
		"account_move":"Daftar Jurnal",
		"account_move.post":"Publikasikan",
		"AccountMove.confirmTitle.action/post":"Publikasikan",
		"AccountMove.confirmContent.action/post":"Publikasikan?",
		"AccountMove.success.action/post":"Daftar Jurnal berhasil di posting",
		"account_move.unpost":"Batalkan Posting",
		"AccountMove.confirmTitle.action/unpost":"Batalkan",
		"AccountMove.confirmContent.action/unpost":"Apakah Anda yakin ingin membatalkan posting Daftar Jurnal ini?",
		"AccountMove.success.action/unpost":"Status Posting Daftar Jurnal telah berhasil dibatalkan",
		"AccountMove.name":"Nomor",
		"AccountMove.ref":"Referensi",
		"AccountMove.partnerId":"Partner",
		"AccountMove.partner":"Partner",
		"AccountMove.date":"Tanggal",
		"AccountMove.periodId":"Periode",
		"AccountMove.period":"Periode",
		"AccountMove.lines":"Jurnal",
		"AccountMove.totalDebit":"Total Debit",
		"AccountMove.totalCredit":"Total Credit",
		"AccountMove.state":"Status",
		//Journal Item
		"AccountMoveLine":"Item Jurnal",
		"account_move_line":"Item Jurnal",
		"AccountMoveLine.description":"Deskripsi",
		"AccountMoveLine.accountId":"Akun",
		"AccountMoveLine.account":"Akun",
		"AccountMoveLine.periodId":"Periode",
		"AccountMoveLine.period":"Periode",
		"AccountMoveLine.date":"Tanggal",
		"AccountMoveLine.partnerId":"Partner",
		"AccountMoveLine.partner":"Partner",
		"AccountMoveLine.debit":"Debit",
		"AccountMoveLine.credit":"Kredit",
		//Inventory
		"Inventory":"Persediaan",
		"inventory":"Persediaan",
		"Inventory.name":"Nomor",
		"Inventory.reference":"Referensi",
		"Inventory.itemId":"Barang",
		"Inventory.item":"Barang",
		"Inventory.warehouseId":"Gudang",
		"Inventory.warehouse":"Gudang",
		"Inventory.locationId":"Lokasi",
		"Inventory.location":"Lokasi",
		"Inventory.dateIn":"Tanggal Masuk",
		"Inventory.quantityIn":"Jumlah Masuk",
		"Inventory.balance":"Saldo",
		"Inventory.branchId":"Cabang",
		"Inventory.branch":"Cabang",
		"Inventory.sku":"SKU",
		"Inventory.taxed":"Pajak",
		"Inventory.adjustmentId":"Penyesuaian Persediaan",
		"Inventory.adjustment":"Penyesuaian Persediaan",
		"Inventory.returnId":"Retur",
		"Inventory.return":"Retur",
		"Inventory.state":"Status",
		"Inventory.inventoryMoves":"Pergerakan Barang",
		//Inventory Move
		"InventoryMove":"Pergerakan Barang",
		"inventory_move":"Pergerakan Barang",
		"InventoryMove.name":"Nomor",
		"InventoryMove.reference":"Referensi",
		"InventoryMove.type":"Tipe",
		"InventoryMove.dateOut":"Tanggal Keluar",
		"InventoryMove.QuantityOut":"Quantity Out",
		"InventoryMove.deliveryOrderId":"Surat Jalan",
		"InventoryMove.deliveryOrder":"Surat Jalan",
		"InventoryMove.adjustmentId":"Penyesuaian Persediaan",
		"InventoryMove.adjustment":"Penyesuaian Persediaan",
		"InventoryMove.inventoryIssueId":"Pemakaian Persediaan",
		"InventoryMove.inventoryIssue":"Pemakaian Persediaan",
		"InventoryMove.materialReleaseId":"Pemakaian Material",
		"InventoryMove.materialRelease":"Pemakaian Material",
		"InventoryMove.returnId":"Retur",
		"InventoryMove.return":"Retur",
		"InventoryMove.warehouseMoveId":"Perpindahan Persediaan",
		"InventoryMove.warehouseMove":"Perpindahan Persediaan",
		//Inventory Move Plan
		"InventoryMovePlan":"Rencana Pergerakan Barang",
		"inventory_move_plan":"Rencana Pergerakan Barang",
		"InventoryMovePlan.itemId":"Barang",
		"InventoryMovePlan.item":"Barang",
		"InventoryMovePlan.unitId":"Satuan",
		"InventoryMovePlan.unit":"Satuan",
		"InventoryMovePlan.lines":"List Rencana Pergerakan Barang",
		"InventoryMovePlan.direction":"Arah",
		"InventoryMovePlan.quantity":"Jumlah",
		"InventoryMovePlan.state":"Status",
		"InventoryMovePlan.alert.changed":"RPB untuk semua barang telah diubah",
		"InventoryMovePlan.alert.noLocation":"Cabang yang dipilih tidak memiliki lokasi gudang",
		//Inventory Move Plan Line
		"InventoryMovePlanLine":"List Rencana Pergerakan Barang",
		"inventory_move_plan_line":"List Rencana Pergerakan Barang",
		"InventoryMovePlanLine.locationId":"Lokasi",
		"InventoryMovePlanLine.location":"Lokasi",
		"InventoryMovePlanLine.quantity":"Jumlah",
		"InventoryMovePlanLine.sku":"SKU",
		//Goods Receive Notes
		"GoodsReceiveNote":"Penerimaan Barang",
		"goods_receive_note":"Penerimaan Barang",
		"goods_receive_note.confirm":"Konfirmasi",
		"GoodsReceiveNote.confirmTitle.action/confirm":"Konfirmasi",
		"GoodsReceiveNote.confirmContent.action/confirm":"Apakah Anda yakin ingin mengkonfirmasi Penerimaan Barang ini?",
		"GoodsReceiveNote.success.action/confirm":"Penerimaan Barang telah berhasil dikonfirmasi",
		"goods_receive_note.cancel":"Batalkan",
		"GoodsReceiveNote.confirmTitle.action/cancel":"Batalkan",
		"GoodsReceiveNote.confirmContent.action/cancel":"Apakah Anda yakin ingin membatalkan Penerimaan Barang ini?",
		"GoodsReceiveNote.success.action/cancel":"Penerimaan Barang telah berhasil dibatalkan",
		"goods_receive_note.draft":"Draf",
		"GoodsReceiveNote.confirmTitle.action/draft":"Draf",
		"GoodsReceiveNote.confirmContent.action/draft":"Draf?",
		"GoodsReceiveNote.success.action/draft":"Penerimaan Barang berhasil",
		"GoodsReceiveNote.confirmTitle.print":"Konfirmasi Cetak",
		"GoodsReceiveNote.confirmContent.print":"Apakah Anda yakin ingin mencetak Penerimaan Barang ini?",
		"GoodsReceiveNote.success.print":"Penerimaan Barang telah berhasil dicetak",
		"General Information":"Informasi Umum",
		"GoodsReceiveNote.name":"Nomor",
		"GoodsReceiveNote.date":"Tanggal",
		"GoodsReceiveNote.periodId":"Periode",
		"GoodsReceiveNote.period":"Periode",
		"GoodsReceiveNote.vendorId":"Pemasok",
		"GoodsReceiveNote.vendor":"Pemasok",
		"GoodsReceiveNote.branchId":"Cabang",
		"GoodsReceiveNote.branch":"Cabang",
		"GoodsReceiveNote.note":"Catatan",
		"GoodsReceiveNote.currencyId":"Mata Uang",
		"GoodsReceiveNote.currency":"Mata Uang",
		"GoodsReceiveNote.exchangeRate":"Nilai Tukar Mata Uang",
		"GoodsReceiveNote.taxed":"Dikenakan Pajak",
		"GoodsReceiveNote.paymentTerm":"Term Pembayaran (Hari)",
		"Received Goods":"Daftar Penerimaan Barang",
		"Received Goods":"Daftar Penerimaan Barang",
		"GoodsReceiveNote.lines":"Daftar Barang",
		"GoodsReceiveNote.totalDiscount":"Total Diskon",
		"GoodsReceiveNote.subtotal":"Subtotal",
		"GoodsReceiveNote.totalTax":"Total Pajak",
		"GoodsReceiveNote.total":"Grand Total",
		"Accounting":"Akunting",
		"Accounting":"Akunting",
		"GoodsReceiveNote.accountMove":"Jurnal",
		"GoodsReceiveNote.state":"Status",
		"GoodsReceiveNote.needFinance":"Butuh Proses Keuangan",
		//Goods Receive Notes Lines
		"GoodsReceiveNoteLine":"List Penerimaan Barang",
		"goods_receive_note_line":"List Penerimaan Barang",
		"GoodsReceiveNoteLine.purchaseOrderLineId":"Pembelian",
		"GoodsReceiveNoteLine.purchaseOrderLine":"Pembelian",
		"GoodsReceiveNoteLine.purchaseOrder":"No. PO",
		"GoodsReceiveNoteLine.name":"Nomor",
		"GoodsReceiveNoteLine.itemId":"Barang",
		"GoodsReceiveNoteLine.item":"Barang",
		"GoodsReceiveNoteLine.itemCode":"Kode",
		"GoodsReceiveNoteLine.itemName":"Nama",
		"GoodsReceiveNoteLine.unitId":"Satuan",
		"GoodsReceiveNoteLine.unit":"Satuan",
		"GoodsReceiveNoteLine.quantity":"Jumlah",
		"GoodsReceiveNoteLine.unitPrice":"Harga",
		"GoodsReceiveNoteLine.discountPercentage":"D(%)",
		"GoodsReceiveNoteLine.discountAmount":"Diskon",
		"GoodsReceiveNoteLine.subtotal":"Subtotal",
		"GoodsReceiveNoteLine.taxes":"Pajak",
		"GoodsReceiveNoteLine.taxAmount":"Jumlah Pajak",
		"GoodsReceiveNoteLine.total":"Total",
		"GoodsReceiveNoteLine.expireDate":"Tgl Kadaluarsa",
		"GoodsReceiveNoteLine.quantityBalance":"Sisa",
		"GoodsReceiveNoteLine.inventoryMovePlanId":"RPB",
		"GoodsReceiveNoteLine.inventoryMovePlan":"RPB",
		"GoodsReceiveNoteLine.itemRequest":"No. Permintaan",
		//Delivery Order
		"DeliveryOrder":"Surat Jalan",
		"delivery_order":"Surat Jalan",
		"delivery_order.confirm":"Konfirmasi",
		"DeliveryOrder.confirmTitle.action/confirm":"Konfirmasi",
		"DeliveryOrder.confirmContent.action/confirm":"Apakah Anda yakin ingin mengkonfirmasi Surat Jalan ini?",
		"DeliveryOrder.success.action/confirm":"Surat Jalan telah berhasil dikonfirmasi",
		"delivery_order.draft":"Draf",
		"DeliveryOrder.confirmTitle.action/draft":"Draf",
		"DeliveryOrder.confirmContent.action/draft":"Draf?",
		"DeliveryOrder.success.action/draft":"Surat Jalan berhasil",
		"delivery_order.cancel":"Batalkan",
		"DeliveryOrder.confirmTitle.action/cancel":"Batalkan",
		"DeliveryOrder.confirmContent.action/cancel":"Apakah Anda yakin ingin membatalkan Surat Jalan ini?",
		"DeliveryOrder.success.action/cancel":"Surat Jalan telah berhasil dibatalkan",
		"DeliveryOrder.confirmTitle.print":"Konfirmasi Cetak",
		"DeliveryOrder.confirmContent.print":"Apakah Anda yakin ingin mencetak Surat Jalan ini?",
		"DeliveryOrder.success.print":"Surat Jalan telah berhasil dicetak",
		"DeliveryOrder.confirmTitle.print_finance":"Konfirmasi Cetak",
		"DeliveryOrder.confirmContent.print_finance":"Apakah Anda yakin ingin mencetak Surat Jalan ini?",
		"DeliveryOrder.success.print_finance":"Surat Jalan telah berhasil dicetak",
		"DeliveryOrder.name":"Nomor",
		"DeliveryOrder.date":"Tanggal",
		"DeliveryOrder.periodId":"Periode",
		"DeliveryOrder.period":"Periode",
		"DeliveryOrder.customerId":"Pelanggan",
		"DeliveryOrder.customer":"Pelanggan",
		"DeliveryOrder.alias":"Alias",
		"DeliveryOrder.branchId":"Cabang",
		"DeliveryOrder.branch":"Cabang",
		"DeliveryOrder.branchDestination":"Cabang Tujuan",
		"DeliveryOrder.branchOriginId":"Cabang Asal",
		"DeliveryOrder.branchOrigin":"Cabang Asal",
		"DeliveryOrder.note":"Catatan",
		"DeliveryOrder.currencyId":"Mata Uang",
		"DeliveryOrder.currency":"Mata Uang",
		"DeliveryOrder.deliveryRequestId":"Permintaan Pengiriman",
		"DeliveryOrder.deliveryRequest":"Permintaan Pengiriman",
		"DeliveryOrder.exchangeRate":"Nilai Tukar Mata Uang",
		"Items":"Daftar Barang",
		"Items":"Daftar Barang",
		"Invoices":"Daftar Tagihan",
		"DeliveryOrder.lines":"Daftar Barang",
		"DeliveryOrder.totalDiscount":"Total Diskon",
		"DeliveryOrder.subtotal":"Subtotal",
		"DeliveryOrder.totalTax":"Total Pajak",
		"DeliveryOrder.total":"Grand Total",
		"Accounting":"Akunting",
		"Accounting":"Akunting",
		"DeliveryOrder.accountMove":"Jurnal",
		"DeliveryOrder.state":"Status",
		"DeliveryOrder.taxed":"Dikenakan Pajak",
		"DeliveryOrder.acctQtyVoid":"Persediaan Tdk Mencukupi",
		//Delivery Order Line
		"DeliveryOrderLine":"Baris Surat Jalan",
		"delivery_order_line":"Baris Surat Jalan",
		"delivery_order_line.confirm":"Konfirmasi",
		"DeliveryOrderLine.confirmTitle.action/confirm":"Konfirmasi",
		"DeliveryOrderLine.confirmContent.action/confirm":"Apakah Anda yakin ingin mengkonfirmasi Baris Surat Jalan ini?",
		"DeliveryOrderLine.success.action/confirm":"Baris Surat Jalan telah berhasil dikonfirmasi",
		"delivery_order_line.draft":"Draf",
		"DeliveryOrderLine.confirmTitle.action/draft":"Draf",
		"DeliveryOrderLine.confirmContent.action/draft":"Draf?",
		"DeliveryOrderLine.success.action/draft":"Baris Surat Jalan berhasil",
		"delivery_order_line.cancel":"Batalkan",
		"DeliveryOrderLine.confirmTitle.action/cancel":"Batalkan",
		"DeliveryOrderLine.confirmContent.action/cancel":"Apakah Anda yakin ingin membatalkan Baris Surat Jalan ini?",
		"DeliveryOrderLine.success.action/cancel":"Baris Surat Jalan telah berhasil dibatalkan",
		"DeliveryOrderLine.name":"Nomor",
		"DeliveryOrderLine.itemId":"Barang",
		"DeliveryOrderLine.item":"Barang",
		"DeliveryOrderLine.quantity":"Jumlah",
		"DeliveryOrderLine.unitId":"Satuan",
		"DeliveryOrderLine.unit":"Satuan",
		"DeliveryOrderLine.unitPrice":"Harga Satuan",
		"DeliveryOrderLine.discountPercentage":"D(%)",
		"DeliveryOrderLine.discountAmount":"Diskon",
		"DeliveryOrderLine.subtotal":"Subtotal",
		"DeliveryOrderLine.taxes":"Pajak",
		"DeliveryOrderLine.taxAmount":"Jumlah Pajak",
		"DeliveryOrderLine.total":"Total",
		"DeliveryOrderLine.quantityBalance":"Jumlah Sisa",
		"DeliveryOrderLine.inventoryMovePlanId":"RPB",
		"DeliveryOrderLine.inventoryMovePlan":"RPB",
		"DeliveryOrderLine.itemRequest":"No. Permintaan",

		//Instant Sales & lines
		"InstantSales":"Penjualan Instan",
		"instant_sales":"Penjualan Instan",
		"InstantSales.confirmTitle.action/cancel":"Batalkan Penjualan Instan",
		"InstantSales.confirmContent.action/cancel":"Apakah Anda yakin ingin membatalkan penjualan instan ini?",
		"InstantSales.cancelled":"Penjualan instan telah dibatalkan",
		"recentInstantSales":"Penjualan Instan Terbaru",
		"InstantSalesConfiguration":"Konfigurasi penjualan instan",
		"InstantSales.name":"Nomor",
		"InstantSales.date":"Tanggal",
		"InstantSales.customer":"Pelanggan",
		"InstantSales.customerName":"Nama Pelanggan",
		"InstantSales.customerMobile":"No HP",
		"InstantSales.notes":"Catatan",
		"InstantSales.paymentType":"Metode Pembayaran",
		"InstantSales.branch":"Cabang",
		"InstantSales.specificLocation":"Lokasi Spesifik",
		"InstantSales.currency":"Mata Uang",
		"InstantSales.exchangeRate":"Nilai Tukar Mata Uang",
		"InstantSales.taxed":"Dikenakan Pajak",
		"InstantSales.lines":"Daftar Barang",
		"InstantSales.totalDiscount":"Total Diskon",
		"InstantSales.totalTax":"Total Pajak",
		"InstantSales.totalIncomeTax":"Total Pajak Penghasilan",
		"InstantSales.subtotal":"Subtotal",
		"InstantSales.total":"Total",
		"InstantSales.noPaymentType":"Cara Pembayaran Kosong",
		"InstantSalesLine.item":"Barang",
		"InstantSalesLine.quantity":"Jumlah",
		"InstantSalesLine.unit":"Satuan",
		"InstantSalesLine.unitPrice":"Harga Satuan",
		"InstantSalesLine.discountPercentage":"D(%)",
		"InstantSalesLine.discountAmount":"Jml Diskon",
		"InstantSalesLine.taxes":"Pajak",
		"InstantSalesLine.taxAmount":"Jml Pajak",
		"InstantSalesLine.incomeTaxAmount":"Jml Pajak Penghasilan",
		"InstantSalesLine.subtotal":"Subtotal",
		"InstantSalesLine.total":"Total",
		"InstantSalesLine.inventoryMovePlan":"RPB",
		"InstantSalesLine.onlyFromOriginUnit":"Hanya dari satuan asli",
		"InstantSalesLine.convertToRelatedItem":"Konversi ke Barang Terkait",
		"InstantSalesLine.usage":"Terpakai",
		"InstantSalesLine.dailyNumber":"No. Urut",
		"InstantSales.marketplace":"Marketplace",
		"InstantSales.promotions":"Promosi",
		"InstantSales.totalPay":"Pembayaran",
		"InstantSales.change":"Kembalian",
		"InstantSales.receivable":"Piutang",
		"InstantSales.VAT":"PPN",
		"InstantSales.giro":"Giro",
		"InstantSales.advance":"Uang Muka",
		"InstantSales.editGiro":"Ubah Giro",
		"InstantSales.giroNumber":"No. Giro",
		"InstantSales.giroBank":"Bank",
		"InstantSales.giroExpireDate":"Jatuh Tempo",
		"InstantSales.giroInterestPercentage":"Bunga (%)",
		"InstantSales.giroInterestValue":"Nilai Bunga",
		"InstantSales.belowStandardPrice":"Harga satuan di bawah standar harga penjualan",
		"InstantSales.confirmPayment":"Konfirmasi Pembayaran",
		"InstantSales.paymentFee":"Biaya Administrasi",
		"InstantSales.extraDiscount":"Diskon Tambahan",
		"InstantSales.extraPayment":"Pembayaran Tambahan",
		"InstantSales.extraPaymentType":"Cara Pembayaran +",
		"InstantSales.extraPaymentFee":"Biaya Administrasi +",
		"InstantSales.extraTotalPay":"Pembayaran +",
		"InstantSales.extraGiro":"Giro Tambahan",
		"InstantSales.extraReceivable":"Piutang Tambahan",
		"InstantSales.confirmTitle.action/cancelDraft":"Batalkan Draft",
		"InstantSales.confirmContent.action/cancelDraft":"Apa Anda yakin akan membatalkan draft penjualan instan ini?",
		"InstantSales.success.action/cancelDraft":"Draft Penjualan instan berhasil dibatalkan",
		"thisRow":"baris ini",
		"deleteRowTitle":"Apakah Anda yakin akan menghapus %item% dari daftar transaksi?",
		"decreaseQtyTitle":"Apakah Anda yakin akan mengurangi jumlah pesanan %item% di daftar transaksi?",
		"deleteRowContent":"Aksi akan dicatat oleh sistem, *disarankan untuk tidak terlalu sering* membatalkan barang yang sudah dimasukkan",
		"decreaseRowContent":"Aksi akan dicatat oleh sistem, *disarankan untuk tidak terlalu sering* mengurangi barang yang sudah dimasukkan",

		"PromotionLine.chosen":"Dipilih",
		"PromotionLine.promotion":"Promosi",
		"PromotionLine.active":"Aktif",
		"PromotionLine.stacks":"Kelipatan",
		"PromotionLine.discountAmount":"Jml. Diskon",
		"InstantSales.discounts":"Diskon Terpasang",
		"DiscountLine.promotion":"Dari Promosi",
		"DiscountLine.discountAmount":"Jumlah Diskon",
		"InstantSalesReport":"Penjualan Instan",
		"CashierReport":"Laporan Kasir",
		"CashierReport.startDate":"Tanggal Mulai",
		"CashierReport.endDate":"Tanggal Akhir",
		"CashierReport.clerk":"Kasir",
		"OfflineMode.startOfflineMode":"Mulai Mode Offline?",
		"OfflineMode.youreOffline":"Anda Offline",
		"OfflineMode.clientNotStarted":"ERP Offline belum diaktifkan",
		"OfflineMode.clientNotStarted.desc":"Silakan buka ERP Offline",
		"OfflineMode.loadingClient":"Memuat Mode Offline",
		"OfflineMode.loadingClient.desc":"Tunggu sebentar ...",
		"InstantPurchase.notEnoughPayment":"Pembayaran masih kurang dari total penjualan",
		"InstantSales.overPayment":"Kelebihan Pembayaran",
		"InstantSales.underPayment":"kekurangan Pembayaran",
		"InstantSales.overPayment.confirmation":"Apa anda yakin akan mengalokasikan ke %s",
		"InstantSales.underPayment.confirmation":"Apa anda yakin akan mengalokasikan ke %s",
		"InstantSales.allocateTo":"Alokasikan ke",
		"InstantSales.overPayment.change":"Kembalian",
		"InstantSales.overPayment.advance":"Uang Muka",
		"InstantSales.overPayment.income":"Pendapatan",
		"InstantSales.underPayment.write_off":"Dibiayakan",
		"InstantSales.underPayment.invoice":"Ditagihkan",
		"InstantSales.roundingDifference":"Selisih Pembulatan",
		"InstantSales.returnTotal":"Total Retur",
		"InstantSales.strictModeNotice":"Mode Ketat Aktif, klik untuk membuka",
		"InstantSales.login":"Buka Akses Supervisor",
		"InstantSales.loginDesc":"Masukkan username dan password supervisor",
		"InstantSales.openAccessDesc":"Ini akan membuka akses untuk:",
		"InstantSales.openAccessRules":[
			"Mengubah penjualan",
			"Membatalkan penjualan",
			"Set diskon manual",
			"Retur barang",
			"Cetak ulang struk"
		],
		"InstantSales.strictModeReenabled":"Mode Ketat Diaktifkan",
		"InstantSales.strictModeReenabledDesc":"Mode ketat diaktifkan kembali setelah satu transaksi",
		"InstantSales.viewPayments":"Lihat Pembayaran",
		"InstantSales.twoPaymentsWithFeeOrIncome":"Tidak diijinkan ada 2 pembayaran yang memiliki biaya admin / pendapatan tambahan",

		//IS Return
		"ISReturn":"Retur",
		"ISReturn.itemName":"Nama Barang",
		"ISReturn.quantityBalance":"Sisa",
		"ISReturn.quantity":"Jml Diretur",
		"ISReturn.unitPrice":"Nilai Retur",
		"ISReturn.totalReturn":"Total Retur",
		"ISReturn.updateReturn":"Perbarui Retur",
		"ISReturn.addReturn":"Tambah Retur",
		"ISReturn.returnAll":"Retur Semua",
		"ISReturn.reason":"Alasan",
		"ISReturn.instantSales":"Penjualan Instan",
		"ISReturn.instantSalesItems":"Barang Penjualan Instan",
		"ISReturn.nonInstantSalesItems":"Barang Non Penjualan Instan",
		"ISReturn.others":"Lainnya",
		
		//instant purchase
		"InstantPurchase":"Pembelian Instan",
		"InstantPurchase":"Pembelian Instan",
		"instant_purchase":"Pembelian Instant",
		"InstantPurchase.confirmTitle.cancel":"Batalkan Pembelian Instan",
		"InstantPurchase.confirmContent.cancel":"Apa Anda yakin akan membatalkan pembelian instan ini?",
		"InstantPurchase.cancelled":"Pembelian berhasil dibatalkan",
		"recentInstantPurchase":"Pembelian Instan Terbaru",
		"InstantPurchaseConfiguration":"Komfigurasi Pembelian Instant",
		"InstantPurchase.name":"No. Pembelian Instant",
		"InstantPurchase.date":"Tanggal",
		"InstantPurchase.vendor":"Vendor",
		"InstantPurchase.notes":"Catatan",
		"InstantPurchase.paymentType":"Metode Pembayaran",
		"InstantPurchase.branch":"Cabang",
		"InstantPurchase.specificLocation":"Lokasi Spesifik",
		"InstantPurchase.currency":"Mata Uang",
		"InstantPurchase.exchangeRate":"Nilai Tukar Mata Uang",
		"InstantPurchase.taxed":"Dikenakan Pajak",
		"InstantPurchase.lines":"Daftar Barang",
		"InstantPurchase.totalDiscount":"Total Diskon",
		"InstantPurchase.totalTax":"Total Pajak",
		"InstantPurchase.totalIncomeTax":"Total Pajak Penghasilan",
		"InstantPurchase.subtotal":"Subtotal",
		"InstantPurchase.total":"Total",
		"InstantPurchase.noPaymentType":"Cara Pembayaran Kosong",
		"InstantPurchaseLine.item":"Barang",
		"InstantPurchaseLine.quantity":"Jumlah",
		"InstantPurchaseLine.unit":"Satuan",
		"InstantPurchaseLine.unitPrice":"Harga Satuan",
		"InstantPurchaseLine.discountPercentage":"Disc(%)",
		"InstantPurchaseLine.discountAmount":"Disc Amount",
		"InstantPurchaseLine.taxes":"Pajak",
		"InstantPurchaseLine.taxAmount":"Jumlah Pajak",
		"InstantPurchaseLine.incomeTaxAmount":"Jml. Pajak Penghasilan",
		"InstantPurchaseLine.subtotal":"Subtotal",
		"InstantPurchaseLine.total":"Total",
		"InstantPurchaseLine.inventoryMovePlan":"RPB",
		"InstantPurchaseLine.onlyFromOriginUnit":"Hanya dari satuan asli",
		"InstantPurchase.marketplace":"Marketplace",
		"InstantPurchase.promotions":"Promo",
		"InstantPurchase.paymentFee":"Biaya Admin",
		"InstantPurchase.totalPay":"Total Bayar",
		"InstantPurchase.change":"Kembalian",
		"InstantPurchase.confirmPayment":"Konfirmasi Pembayaran",
		"InstantPurchase.payable":"Hutang",
		"InstantPurchase.giro":"Giro",
		"InstantPurchase.advance":"Uang Muka",
		"InstantPurchase.VAT":"VAT",
		"InstantPurchase.extraDiscount":"Diskon Tambahan",
		"InstantPurchase.extraPayment":"Pembayaran Tambahan",
		"InstantPurchase.extraPaymentType":"Cara Pembayaran +",
		"InstantPurchase.extraPaymentFee":"Biaya Admin +",
		"InstantPurchase.extraTotalPay":"Pembayaran +",
		"InstantPurchase.extraGiro":"Giro Tambahan",
		"InstantPurchase.extraReceivable":"Piutang Tambahan",
		"InstantPurchase.invoiceConfirmedNotice":"Tidak bisa membatalkan pembelian instan ini. Tagihan yang bersangkutan telah di konfirmasi",
		"PromotionLine.chosen":"Dipilih",
		"PromotionLine.promotion":"Promo",
		"PromotionLine.active":"Aktif",
		"InstantPurchase.discounts":"Diskon Terpasang",
		"DiscountLine.promotion":"Dari Promosi",
		"DiscountLine.dicountAmount":"Jumlah",
		"InstantPurchaseReport":"Laporan Pembelian Instan",
		"ProcurementReport":"Laporan Pengadaan Barang",
		"CashierReport":"Laporan Kasir",
		"CashierReport.startDate":"Tanggal Mulai",
		"CashierReport.endDate":"Tanggal Akhir",
		"CashierReport.clerk":"Kasir",
		"OfflineMode.startOfflineMode":"Mulai Mode Offline?",
		"OfflineMode.youreOffline":"Anda Sedang Offline",
		"OfflineMode.clientNotStarted":"ERP Offline belum diaktifkan",
		"OfflineMode.clientNotStarted.desc":"Silakan buka ERP Offline",
		"OfflineMode.loadingClient":"Memuat Mode Offline",
		"OfflineMode.loadingClient.desc":"Tunggu Sebentar ...",
		"InstantPurchase.notEnoughPayment":"Pembayaran masih kurang dari total pembelian",
		"InstantPurchase.overPayment":"Kelebihan Pembayaran",
		"InstantPurchase.overPayment.confirmation":"Apa anda yakin akan mengalokasikan ke %s",
		"InstantPurchase.allocateTo":"Alokasikan ke",
		"InstantPurchase.overPayment.change":"Kembalian",
		"InstantPurchase.overPayment.advance":"Uang Muka",
		"InstantPurchase.overPayment.income":"Pendapatan",
		"InstantPurchase.roundingDifference":"Selisih Pembulatan",

		//Instant Transaction (sales & purchase)
		"FinanceARInstantSalesBrowse":"Daftar Penjualan Instan",
		"FinanceAPInstantPurchaseBrowse":"Daftar Pembelian Instan",
		"InstantSalesBrowse":"Daftar Penjualan Instan",
		"InstantPurchaseBrowse":"Daftar Pembelian Instan",
		"instant_sales":"Penjualan Instan",
		"InstantTrx.confirmTitle.cancel":"Batalkan Penjualan Instan",
		"InstantTrx.confirmContent.cancel":"Apakah Anda yakin ingin membatalkan penjualan instan ini?",
		"InstantTrx.cancelled":"Penjualan instan telah dibatalkan",
		"recentInstantSales":"Penjualan Instan Terbaru",
		"InstantSalesConfiguration":"Konfigurasi penjualan instan",
		"InstantTrx.basicInfo":"Informasi Dasar",
		"InstantTrx.items":"Daftar Barang",
		"InstantTrx.promoList":"Daftar Promo",
		"InstantTrx.name":"Nomor",
		"InstantTrx.date":"Tanggal",
		"InstantTrx.customer":"Pelanggan",
		"InstantTrx.vendor":"Vendor",
		"InstantTrx.customerPhone":"Telepon",
		"InstantTrx.vendorPhone":"Telepon",
		"InstantTrx.PIC":"PIC",
		"InstantTrx.notes":"Catatan",
		"InstantTrx.vat":"PPN",
		"InstantTrx.defaultVat":"PPN Standar",
		"InstantTrx.paymentType":"Metode Pembayaran",
		"InstantTrx.branch":"Cabang",
		"InstantTrx.specificLocation":"Lokasi Spesifik",
		"InstantTrx.currency":"Mata Uang",
		"InstantTrx.exchangeRate":"Nilai Tukar Mata Uang",
		"InstantTrx.taxed":"Dikenakan Pajak",
		"InstantTrx.lines":"Daftar Barang",
		"InstantTrx.totalDiscount":"Total Diskon",
		"InstantTrx.totalTax":"Total Pajak",
		"InstantTrx.subtotal":"Subtotal",
		"InstantTrx.total":"Total",
		"InstantTrx.noPaymentType":"Cara Pembayaran Kosong",
		"InstantTrxLine.item":"Barang",
		"InstantTrxLine.itemName":"Nama Barang",
		"InstantTrxLine.quantity":"Jumlah",
		"InstantTrxLine.unit":"Satuan",
		"InstantTrxLine.unitPrice":"Harga Satuan",
		"InstantTrxLine.discountPercentage":"D(%)",
		"InstantTrxLine.discountAmount":"Jml Diskon",
		"InstantTrxLine.taxes":"Pajak",
		"InstantTrxLine.taxAmount":"Jml Pajak",
		"InstantTrxLine.subtotal":"Subtotal",
		"InstantTrxLine.subtotal":"Subtotal",
		"InstantTrxLine.total":"Total",
		"InstantTrxLine.inventoryMovePlan":"RPB",
		"InstantTrxLine.onlyFromOriginUnit":"Hanya dari satuan asli",
		"InstantTrx.promos.promo":"Nama Promo",
		"InstantTrx.promos.stacks":"Kelipatan",
		"InstantTrx.promos.discountAmount":"Jml Diskon",
		"InstantTrx.promos.totalDiscPromo":"Total Diskon Promo",
		"InstantTrx.promos.extraDiscount":"Diskon Tambahan",
		"InstantTrx.marketplace":"Marketplace",
		"InstantTrx.promotions":"Promosi",
		"InstantTrx.totalPay":"Pembayaran",
		"InstantTrx.change":"Kembalian",
		"InstantTrx.receivable":"Piutang",
		"InstantTrx.VAT":"PPN",
		"InstantTrx.giro":"Giro",
		"InstantTrx.editGiro":"Ubah Giro",
		"InstantTrx.giroNumber":"No. Giro",
		"InstantTrx.giroBank":"Bank",
		"InstantTrx.giroExpireDate":"Jatuh Tempo",
		"InstantTrx.giroInterestPercentage":"Bunga (%)",
		"InstantTrx.giroInterestValue":"Nilai Bunga",
		"InstantTrx.belowStandardPrice":"Harga satuan di bawah standar harga penjualan",
		"InstantTrx.confirmPayment":"Konfirmasi Pembayaran",
		"InstantTrx.paymentFee":"Biaya Administrasi",
		"InstantTrx.overpaymentAllocation":"Alokasi kelebihan pembayaran",
		"InstantTrx.discounts":"Diskon",
		"InstantTrx.payments":"Pembayaran",
		"InstantTrx.payment":"Pembayaran",
		"InstantTrx.extraPayment":"Pembayaran Tambahan",
		"InstantTrx.refund":"Refund",
		"InstantTrx.reschedule":"Jadwalkan Ulang",
		"InstantTrx.reschedule.title":"Masukkan tanggal main baru",
		"InstantTrx.reschedule.visitDate":"Tanggal Main",
		"InstantTrx.reschedule.success":"Tanggal Main berhasil diperbarui",
		"InstantTrx.return.reason":"Alasan",
		"InstantTrx.return.overpaymentAllocation":"Alokasi Kelebihan Dana",
		"InstantTrx.return.bank":"Bank/Rekening",
		"InstantTrx.return.paymentType":"Cara Pembayaran",
		"InstantTrx.return.success":"Retur Transaksi telah berhasil",
		"InstantTrx.accounting":"Akunting",
		"InstantTrx.accountMove":"Jurnal Akunting",
		"InstantTrx.state":"Status",

		//Due Search & price search
		"DueSearch.customerName":"Nama Pelanggan",
		"DueSearch.vendorName":"Nama Vendor",
		"DueSearch.customerNameSearch":"Cari Nama Pelanggan",
		"DueSearch.vendorNameSearch":"Cari Nama Vendor",
		"DueSearch.total":"Total",
		"DueSearch.isDue":"Jatuh Tempo",
		"DueSearch.dueDate":"Tanggal Jatuh Tempo",
		"PriceSearch.search":"Cari Nama Pelanggan atau Nama Barang",
		"PriceSearch.itemName":"Nama Barang",
		"PriceSearch.date":"Tanggal",
		"PriceSearch.customerName":"Nama Pelanggan",
		"PriceSearch.branch":"Cabang",
		"PriceSearch.price":"Harga",

		//Inventory Issue
		"InventoryIssue":"Pemakaian Persediaan",
		"inventory_issue":"Pemakaian Persediaan",
		"inventory_issue.confirm":"Konfirmasi",
		"InventoryIssue.confirmTitle.action/confirm":"Konfirmasi",
		"InventoryIssue.confirmContent.action/confirm":"Apakah Anda yakin ingin mengkonfirmasi Pemakaian Persediaan ini?",
		"InventoryIssue.success.action/confirm":"Pemakaian Persediaan telah berhasil dikonfirmasi",
		"inventory_issue.draft":"Draf",
		"InventoryIssue.confirmTitle.action/draft":"Draf",
		"InventoryIssue.confirmContent.action/draft":"Draf?",
		"InventoryIssue.success.action/draft":"Pemakaian Persediaan berhasil",
		"General Information":"Informasi Umum",
		"InventoryIssue.name":"Nama",
		"InventoryIssue.date":"Tanggal",
		"InventoryIssue.periodId":"Periode",
		"InventoryIssue.period":"Periode",
		"InventoryIssue.issuerId":"Penanggung Jawab",
		"InventoryIssue.issuer":"Penanggung Jawab",
		"InventoryIssue.branchId":"Cabang",
		"InventoryIssue.branch":"Cabang",
		"InventoryIssue.location":"Lokasi",
		"InventoryIssue.description":"Deskripsi",
		"InventoryIssue.note":"Catatan",
		"InventoryIssue.accountId":"Akun",
		"InventoryIssue.account":"Akun",
		"Items":"Daftar Barang",
		"Items":"Daftar Barang",
		"InventoryIssue.lines":"Daftar Barang",
		"Accounting":"Akunting",
		"Accounting":"Akunting",
		"InventoryIssue.accountMove":"Jurnal",
		"InventoryIssue.state":"Status",
		"InventoryIssue.acctQtyVoid":"Persediaan Tdk Mencukupi",
		//Inventory Issue Line
		"InventoryIssueLine":"Daftar Pembuangan Persediaan",
		"inventory_issue_line":"Daftar Pembuangan Persediaan",
		"InventoryIssueLine.itemId":"Barang",
		"InventoryIssueLine.item":"Barang",
		"InventoryIssueLine.unit":"Satuan",
		"InventoryIssueLine.quantity":"Jumlah",
		"InventoryIssueLine.inventoryMovePlanId":"RPB",
		"InventoryIssueLine.inventoryMovePlan":"RPB",
		//Material Release
		"MaterialRelease":"Pemakaian Bahan Baku",
		"material_release":"Pemakaian Bahan Baku",
		"material_release.confirm":"Konfirmasi",
		"MaterialRelease.confirmTitle.action/confirm":"Konfirmasi",
		"MaterialRelease.confirmContent.action/confirm":"Apakah Anda yakin ingin mengkonfirmasi Pemakaian Persediaan ini?",
		"MaterialRelease.success.action/confirm":"Pemakaian Persediaan telah berhasil dikonfirmasi",
		"material_release.draft":"Draf",
		"MaterialRelease.confirmTitle.action/draft":"Draf",
		"MaterialRelease.confirmContent.action/draft":"Draf?",
		"MaterialRelease.success.action/draft":"Pemakaian Persediaan berhasil",
		"Informasi Umum":"General Information",
		"MaterialRelease.name":"Nama",
		"MaterialRelease.date":"Tanggal",
		"MaterialRelease.periodId":"Periode",
		"MaterialRelease.period":"Periode",
		"MaterialRelease.issuerId":"Pemakai",
		"MaterialRelease.issuer":"Pemakai",
		"MaterialRelease.customerId":"Pelanggan",
		"MaterialRelease.customer":"Pelanggan",
		"MaterialRelease.branchId":"Cabang",
		"MaterialRelease.branch":"Cabang",
		"MaterialRelease.projectId":"Proyek",
		"MaterialRelease.project":"Proyek",
		"MaterialRelease.note":"Catatan",
		"Items":"Daftar Barang",
		"Items":"Daftar Barang",
		"MaterialRelease.lines":"Daftar Barang",
		"Accounting":"Akunting",
		"Accounting":"Akunting",
		"MaterialRelease.accountMove":"Jurnal",
		"MaterialRelease.state":"Status",
		"MaterialRelease.acctQtyVoid":"Inventory Tdk Mencukupi",
		//Material Release Line
		"MaterialReleaseLine":"Daftar Pemakaian Persediaan",
		"material_release_line":"Daftar Pemakaian Persediaan",
		"MaterialReleaseLine.itemId":"Barang",
		"MaterialReleaseLine.item":"Barang",
		"MaterialReleaseLine.quantity":"Jumlah",
		"MaterialReleaseLine.unitId":"Satuan",
		"MaterialReleaseLine.unit":"Satuan",
		"MaterialReleaseLine.inventoryMovePlanId":"RPB",
		"MaterialReleaseLine.inventoryMovePlan":"RPB",
		//Production Result
		"ProductionResult":"Hasil Produksi",
		"production_result":"Hasil Produksi",
		"production_result.confirm":"Konfirmasi",
		"ProductionResult.confirmTitle.action/confirm":"Konfirmasi",
		"ProductionResult.confirmContent.action/confirm":"Apakah Anda yakin ingin mengkonfirmasi Hasil Produksi ini?",
		"ProductionResult.success.action/confirm":"Hasil Produksi telah berhasil dikonfirmasi",
		"production_result.draft":"Draf",
		"ProductionResult.confirmTitle.action/draft":"Draf",
		"ProductionResult.confirmContent.action/draft":"Draf?",
		"ProductionResult.success.action/draft":"Hasil Produksi berhasil",
		"General Information":"Informasi Umum",
		"ProductionResult.name":"Nama",
		"ProductionResult.date":"Tanggal",
		"ProductionResult.periodId":"Periode",
		"ProductionResult.period":"Periode",
		"ProductionResult.picId":"PIC",
		"ProductionResult.pic":"PIC",
		"ProductionResult.customerId":"Pelanggan",
		"ProductionResult.customer":"Pelanggan",
		"ProductionResult.branchId":"Cabang",
		"ProductionResult.branch":"Cabang",
		"ProductionResult.projectId":"Proyek",
		"ProductionResult.project":"Proyek",
		"ProductionResult.note":"Catatan",
		"Production Results":"Daftar Hasil Produksi",
		"Production Results":"Daftar Hasil Produksi",
		"ProductionResult.lines":"Daftar Barang",
		"Accounting":"Akunting",
		"Accounting":"Akunting",
		"ProductionResult.accountMove":"Jurnal",
		"ProductionResult.state":"Status",
		//Production Result Line
		"ProductionResultLine":"Daftar Hasil Produksi",
		"production_result_line":"Daftar Hasil Produksi",
		"ProductionResultLine.itemId":"Barang",
		"ProductionResultLine.item":"Barang",
		"ProductionResultLine.unitId":"Satuan",
		"ProductionResultLine.unit":"Satuan",
		"ProductionResultLine.quantity":"Jumlah",
		"ProductionResultLine.inventoryMovePlanId":"RPB",
		"ProductionResultLine.inventoryMovePlan":"RPB",
		//Inventory Receive
		"InventoryReceive":"Penerimaan Barang",
		"inventory_receive":"Penerimaan Barang",
		"inventory_receive.confirm":"Konfirmasi",
		"InventoryReceive.confirmTitle.action/confirm":"Konfirmasi",
		"InventoryReceive.confirmContent.action/confirm":"Apakah Anda yakin ingin mengkonfirmasi Penerimaan Barang ini?",
		"InventoryReceive.success.action/confirm":"Penerimaan Barang telah berhasil dikonfirmasi",
		"inventory_receive.draft":"Draf",
		"InventoryReceive.confirmTitle.action/draft":"Draf",
		"InventoryReceive.confirmContent.action/draft":"Draf?",
		"InventoryReceive.success.action/draft":"Penerimaan Barang berhasil",
		"InventoryReceive.name":"Nama",
		"InventoryReceive.date":"Tanggal",
		"InventoryReceive.periodId":"Periode",
		"InventoryReceive.period":"Periode",
		"InventoryReceive.picId":"PIC",
		"InventoryReceive.pic":"PIC",
		"InventoryReceive.customerId":"Pelanggan",
		"InventoryReceive.customer":"Pelanggan",
		"InventoryReceive.branchId":"Cabang",
		"InventoryReceive.branch":"Cabang",
		"InventoryReceive.projectId":"Proyek",
		"InventoryReceive.project":"Proyek",
		"InventoryReceive.note":"Catatan",
		"InventoryReceive.lines":"Daftar Barang",
		"InventoryReceive.accountMove":"Jurnal",
		"InventoryReceive.state":"Status",
		//Inventory Receive Item
		"InventoryReceiveLine":"Baris Penerimaan Barang",
		"inventory_receive_line":"Baris Penerimaan Barang",
		"InventoryReceiveLine.itemId":"Barang",
		"InventoryReceiveLine.item":"Barang",
		"InventoryReceiveLine.unitId":"Satuan",
		"InventoryReceiveLine.unit":"Satuan",
		"InventoryReceiveLine.quantity":"Jumlah",
		"InventoryReceiveLine.inventoryMovePlanId":"RPB",
		"InventoryReceiveLine.inventoryMovePlan":"RPB",
		//Inventory Adjustment
		"InventoryAdjustment":"Penyesuaian Persediaan",
		"inventory_adjustment":"Penyesuaian Persediaan",
		"inventory_adjustment.confirm":"Konfirmasi",
		"InventoryAdjustment.confirmTitle.action/confirm":"Konfirmasi",
		"InventoryAdjustment.confirmContent.action/confirm":"Apakah Anda yakin ingin mengkonfirmasi Penyesuaian Persediaan ini?",
		"InventoryAdjustment.success.action/confirm":"Penyesuaian Persediaan telah berhasil dikonfirmasi",
		"inventory_adjustment.draft":"Draf",
		"InventoryAdjustment.confirmTitle.action/draft":"Draf",
		"InventoryAdjustment.confirmContent.action/draft":"Draf?",
		"InventoryAdjustment.success.action/draft":"Penyesuaian Persediaan berhasil",
		"inventory_adjustment.cancel":"Batalkan",
		"InventoryAdjustment.confirmTitle.action/cancel":"Batalkan",
		"InventoryAdjustment.confirmContent.action/cancel":"Apakah Anda yakin ingin membatalkan Penyesuaian Persediaan ini?",
		"InventoryAdjustment.success.action/cancel":"Penyesuaian Persediaan telah berhasil dibatalkan",
		"General Information":"Informasi Umum",
		"InventoryAdjustment.name":"Nomor",
		"InventoryAdjustment.date":"Tanggal",
		"InventoryAdjustment.periodId":"Periode",
		"InventoryAdjustment.period":"Periode",
		"InventoryAdjustment.branchId":"Cabang",
		"InventoryAdjustment.branch":"Cabang",
		"InventoryAdjustment.warehouseId":"Gudang",
		"InventoryAdjustment.warehouse":"Gudang",
		"InventoryAdjustment.locationId":"Lokasi",
		"InventoryAdjustment.location":"Lokasi",
		"InventoryAdjustment.picId":"PIC",
		"InventoryAdjustment.pic":"PIC",
		"InventoryAdjustment.description":"Deskripsi",
		"InventoryAdjustment.inventoryAdjustmentAccountId":"Akun",
		"InventoryAdjustment.inventoryAdjustmentAccount":"Akun",
		"Items":"Daftar Barang",
		"Items":"Daftar Barang",
		"InventoryAdjustment.lines":"Daftar Barang",
		"Accounting":"Akunting",
		"Accounting":"Akunting",
		"InventoryAdjustment.accountMove":"Jurnal",
		"InventoryAdjustment.state":"Status",
		"InventoryAdjustment.acctQtyVoid":"Persediaan Tdk Mencukupi",
		"InventoryAdjustment.hideZeroValues":"Sembunyikan Nilai Kosong",
		//Inventory Adjustment Lines
		"InventoryAdjustmentLine":"Daftar Penyesuaian Persediaan",
		"inventory_adjustment_line":"Daftar Penyesuaian Persediaan",
		"InventoryAdjustmentLine.itemId":"Barang",
		"InventoryAdjustmentLine.item":"Barang",
		"InventoryAdjustmentLine.beginningBalance":"Saldo Awal",
		"InventoryAdjustmentLine.endingBalance":"Saldo Akhir",
		"InventoryAdjustmentLine.adjustment":"Penyesuaian",
		"InventoryAdjustmentLine.unit":"Satuan",
		"InventoryAdjustmentLine.valuePerQuantity":"Nilai Per Kuantitas",
		"InventoryAdjustmentLine.totalValueAdjustment":"Total Penyesuaian Nilai",
		"InventoryAdjustmentLine.inventoryMovePlanId":"RPB",
		"InventoryAdjustmentLine.inventoryMovePlan":"RPB",
		//Inventory Move
		"WarehouseMove":"Perpindahan Persediaan",
		"warehouse_move":"Perpindahan Persediaan",
		"warehouse_move.confirm":"Konfirmasi",
		//out
		"WarehouseMove.confirmTitle.action/confirm/out":"Konfirmasi Keluar",
		"WarehouseMove.confirmContent.action/confirm/out":"Apakah Anda yakin ingin mengkonfirmasi bahwa persediaan ini sudah keluar?",
		"WarehouseMove.success.action/confirm/out":"Persediaan telah berhasil dikonfirmasi keluar",
		//in
		"WarehouseMove.confirmTitle.action/confirm/in":"Konfirmasi Masuk",
		"WarehouseMove.confirmContent.action/confirm/in":"Apakah Anda yakin ingin mengkonfirmasi bahwa persediaan ini sudah masuk?",
		"WarehouseMove.success.action/confirm/in":"Persediaan telah berhasil dikonfirmasi masuk",
		//two way
		"WarehouseMove.confirmTitle.action/confirm":"Konfirmasi Pemindahan Persediaan",
		"WarehouseMove.confirmContent.action/confirm":"Apakah Anda yakin ingin mengkonfirmasi bahwa persediaan ini sudah dipindahkan?",
		"WarehouseMove.success.action/confirm":"Persediaan telah berhasil dikonfirmasi dipindahkan",
		"warehouse_move.draft":"Draf",
		"WarehouseMove.confirmTitle.action/draft":"Draf",
		"WarehouseMove.confirmContent.action/draft":"Draf?",
		"WarehouseMove.success.action/draft":"Perpindahan Persediaan berhasil",
		"warehouse_move.cancel":"Batalkan",
		"WarehouseMove.confirmTitle.action/cancel":"Batalkan",
		"WarehouseMove.confirmContent.action/cancel":"Apakah Anda yakin ingin membatalkan Perpindahan Persediaan ini?",
		"WarehouseMove.success.action/cancel":"Perpindahan Persediaan telah berhasil dibatalkan",
		"WarehouseMove.confirmTitle.print":"Konfirmasi Cetak",
		"WarehouseMove.confirmContent.print":"Apakah Anda yakin ingin mencetak Perpindahan Persediaan ini?",
		"WarehouseMove.success.print":"Perpindahan Persediaan telah berhasil dicetak",
		"General Information":"Informasi Umum",
		"WarehouseMove.name":"Nama",
		"WarehouseMove.date":"Tanggal",
		"WarehouseMove.periodId":"Periode",
		"WarehouseMove.period":"Periode",
		"WarehouseMove.branchId":"Cabang Asal",
		"WarehouseMove.branch":"Cabang Asal",
		"WarehouseMove.branchInId":"Cabang Tujuan",
		"WarehouseMove.branchIn":"Cabang Tujuan",
		"WarehouseMove.location":"Lokasi Asal",
		"WarehouseMove.locationIn":"Lokasi Tujuan",
		"WarehouseMove.senderId":"Pengirim",
		"WarehouseMove.sender":"Pengirim",
		"WarehouseMove.receiverId":"Penerima",
		"WarehouseMove.receiver":"Penerima",
		"WarehouseMove.itemRequestId":"Permintaan Barang",
		"WarehouseMove.itemRequest":"Permintaan Barang",
		"WarehouseMove.description":"Deskripsi",
		"Items":"Daftar Barang",
		"Items":"Daftar Barang",
		"WarehouseMove.lines":"Daftar Barang",
		"Accounting":"Akunting",
		"Accounting":"Akunting",
		"WarehouseMove.accountMove":"Jurnal",
		"WarehouseMove.state":"Status",
		//Inventory Move Lines
		"WarehouseMoveLine":"Daftar Perpindahan Persediaan",
		"warehouse_move_line":"Daftar Perpindahan Persediaan",
		"WarehouseMoveLine.itemId":"Barang",
		"WarehouseMoveLine.item":"Barang",
		"WarehouseMoveLine.quantity":"Jumlah",
		"WarehouseMoveLine.unitId":"Satuan",
		"WarehouseMoveLine.unit":"Satuan",
		"WarehouseMoveLine.inventoryMovePlanIn":"Msk",
		"WarehouseMoveLine.inventoryMovePlanOut":"Kelr",
		//Delivery Request
		"DeliveryRequest":"Permintaan Pengiriman",
		"delivery_request":"Permintaan Pengiriman",
		"delivery_request.request":"Ajukan",
		"DeliveryRequest.confirmTitle.action/request":"Ajukan",
		"DeliveryRequest.confirmContent.action/request":"Ajukan?",
		"DeliveryRequest.confirmTitle.action/fulfill":"Terpenuhi",
		"DeliveryRequest.confirmContent.action/fulfill":"Apakah Anda yakin permintaan ini sudah terpenuhi?",
		"DeliveryRequest.success.action/fulfill":"Permintaan pengiriman sudah berhasil terpenuhi",
		"DeliveryRequest.confirmTitle.action/approve_finance":"Disetujui Keuangan",
		"DeliveryRequest.confirmContent.action/approve_finance":"Apakah Anda yakin permintaan ini sudah disetujui bagian keuangan?",
		"DeliveryRequest.success.action/approve_finance":"Permintaan ini sudah berhasil disetujui bagian keuangan",
		"DeliveryRequest.confirmTitle.action/approve_production":"Disetujui Produksi",
		"DeliveryRequest.confirmContent.action/approve_production":"Apakah Anda yakin permintaan ini sudah disetujui bagian produksi?",
		"DeliveryRequest.success.action/approve_production":"Permintaan ini sudah berhasil disetujui bagian produksi",
		"DeliveryRequest.success.action/request":"Permintaan Pengiriman berhasil",
		"delivery_request.cancel":"Batalkan",
		"DeliveryRequest.confirmTitle.action/cancel":"Batalkan",
		"DeliveryRequest.confirmContent.action/cancel":"Apakah Anda yakin ingin membatalkan Permintaan Pengiriman ini?",
		"DeliveryRequest.success.action/cancel":"Permintaan Pengiriman telah berhasil dibatalkan",
		"DeliveryRequest.name":"Nomor",
		"DeliveryRequest.date":"Tanggal",
		"DeliveryRequest.periodId":"Periode",
		"DeliveryRequest.period":"Periode",
		"DeliveryRequest.projectId":"Proyek",
		"DeliveryRequest.project":"Proyek",
		"DeliveryRequest.customerId":"Pelanggan",
		"DeliveryRequest.customer":"Pelanggan",
		"DeliveryRequest.requestorId":"Pemohon",
		"DeliveryRequest.requestor":"Pemohon",
		"DeliveryRequest.picId":"Penanggung Jawab",
		"DeliveryRequest.pic":"Penanggung Jawab",
		"DeliveryRequest.supervisorApproverId":"Penyetuju Keuangan",
		"DeliveryRequest.supervisorApprover":"Penyetuju Keuangan",
		"DeliveryRequest.productionApproverId":"Penyetuju Produksi",
		"DeliveryRequest.productionApprover":"Penyetuju Produksi",
		"DeliveryRequest.branchId":"Cabang",
		"DeliveryRequest.branch":"Cabang",
		"DeliveryRequest.note":"Catatan",
		"Items":"Daftar Barang",
		"Participants":"Peserta",
		"DeliveryRequest.lines":"Daftar Barang",
		"DeliveryRequest.state":"Status",
		//Delivery Request Line
		"DeliveryRequestLine":"Baris Permintaan Pengiriman",
		"delivery_request_line":"Baris Permintaan Pengiriman",
		"DeliveryRequestLine.name":"Nomor",
		"DeliveryRequestLine.itemId":"Barang",
		"DeliveryRequestLine.item":"Barang",
		"DeliveryRequestLine.inventoryId":"Persediaan",
		"DeliveryRequestLine.inventory":"Persediaan",
		"DeliveryRequestLine.quantity":"Jumlah",
		"DeliveryRequestLine.quantityBalance":"Jumlah Sisa",
		//Item Request
		"ItemRequest":"Permintaan Barang",
		"item_request":"Permintaan Barang",
		"item_request.request":"Ajukan",
		"ItemRequest.confirmTitle.action/request":"Ajukan",
		"ItemRequest.confirmContent.action/request":"Ajukan?",
		"ItemRequest.success.action/request":"Permintaan Barang berhasil",
		"item_request.cancel":"Batalkan",
		"ItemRequest.confirmTitle.action/cancel":"Batalkan",
		"ItemRequest.confirmContent.action/cancel":"Apakah Anda yakin ingin membatalkan Permintaan Barang ini?",
		"ItemRequest.success.action/cancel":"Permintaan Barang telah berhasil dibatalkan",
		"item_request.supervisor_approve":"Persetujuan Supervisor",
		"ItemRequest.confirmTitle.action/supervisor_approve":"Persetujuan Supervisor",
		"ItemRequest.confirmContent.action/supervisor_approve":"Apakah anda yakin akan menyetujui permintaan barang ini sebagai supervisor?",
		"ItemRequest.success.action/supervisor_approve":"Permintaan barang telah berhasil disetujui supervisor",
		"item_request.manager_approve":"Persetujuan Manajer",
		"ItemRequest.confirmTitle.action/manager_approve":"Persetujuan Manajer",
		"ItemRequest.confirmContent.action/manager_approve":"Apakah anda yakin akan menyetujui permintaan barang ini sebagai manajer?",
		"ItemRequest.success.action/manager_approve":"Permintaan barang telah berhasil disetujui manajer",
		"ItemRequest.name":"Nomor",
		"ItemRequest.date":"Tanggal",
		"ItemRequest.periodId":"Periode",
		"ItemRequest.period":"Periode",
		"ItemRequest.projectId":"Proyek",
		"ItemRequest.project":"Proyek",
		"ItemRequest.customerId":"Pelanggan",
		"ItemRequest.customer":"Pelanggan",
		"ItemRequest.requestorId":"Pemohon",
		"ItemRequest.requestor":"Pemohon",
		"ItemRequest.picId":"Penanggung Jawab",
		"ItemRequest.pic":"Penanggung Jawab",
		"ItemRequest.itemCategory":"Kategori",
		"ItemRequest.itemList":"Daftar Barang",
		"ItemRequest.location":"Lokasi",
		"ItemRequest.supervisorApproverId":"Penyetuju Supervisor",
		"ItemRequest.supervisorApprover":"Penyetuju Supervisor",
		"ItemRequest.productionApproverId":"Penyetuju Produksi",
		"ItemRequest.productionApprover":"Penyetuju Produksi",
		"ItemRequest.branchId":"Cabang",
		"ItemRequest.branch":"Cabang",
		"ItemRequest.note":"Catatan",
		"Items":"Daftar Barang",
		"Participants":"Peserta",
		"ItemRequest.lines":"Daftar Barang",
		"ItemRequest.state":"Status",
		"ItemRequest.fillItemList":"Isi Daftar Barang",
		"ItemRequest.sources":"Daftar Permintaan",
		//Item Request Line
		"ItemRequestLine":"Baris Permintaan Pengiriman",
		"item_request_line":"Baris Permintaan Pengiriman",
		"item_request_source":"Daftar Sumber Pengajuan",
		"ItemRequestLine.name":"Nomor",
		"ItemRequestLine.itemId":"Barang",
		"ItemRequestLine.item":"Barang",
		"ItemRequestLine.itemCategory":"Kategori",
		"ItemRequestLine.unit":"Satuan",
		"ItemRequestLine.averageSales":"Rata² Penjualan",
		"ItemRequestLine.currentStock":"Stok",
		"ItemRequestLine.inventoryId":"Persediaan",
		"ItemRequestLine.inventory":"Persediaan",
		"ItemRequestLine.quantity":"Pengajuan",
		"ItemRequestLine.quantityPicked":"Jumlah",
		"ItemRequestLine.quantityBalance":"Jumlah Sisa",
		"ItemRequestLine.itemRequest":"No. Permintaan",
		"ItemRequestLine.itemRequestQuantity":"Jml. Permintaan",
		"ItemRequestLine.itemRequestUnit":"Satuan Permintaan",
		"ItemRequestLine.itemRequestBranch":"Cabang",
		"ItemRequestLine.approved":"Disetujui",
		"ItemRequestLine.rejected":"Ditolak",
		"ItemRequestLine.fromStock":"Dari Stok Pusat",
		"ItemRequestLine.fromPurchase":"Pembelian",
		"ItemRequestLine.quantityBalance":"Sisa",
		"ItemRequestLine.purchasableQuantityBalance":"Sisa Pengajuan",

		"ItemRequestLine.purchaseQuantity":"Jumlah Dibeli",
		"ItemRequestLine.receivedQuantity":"Diterima",
		"ItemRequestLine.pendingShipment":"Belum Dikirim",
		"ItemRequestLine.notReceived":"Belum Diterima",
		"ItemRequestLine.shipped":"Terkirim",

		//Purchase Order
		"PurchaseOrder":"Pesanan Pembelian",
		"PurchaseOrderAdvanced":"Purchase Order Advanced",
		"purchase_order":"Pesanan Pembelian",
		"purchase_order.confirm":"Konfirmasi",
		"PurchaseOrder.confirmTitle.action/confirm":"Konfirmasi",
		"PurchaseOrder.confirmContent.action/confirm":"Apakah Anda yakin ingin mengkonfirmasi Purchase Order ini?",
		"PurchaseOrder.success.action/confirm":"Purchase Order telah berhasil dikonfirmasi",
		"purchase_order.cancel":"Batalkan",
		"PurchaseOrder.confirmTitle.action/cancel":"Batalkan",
		"PurchaseOrder.confirmContent.action/cancel":"Apakah Anda yakin ingin membatalkan Purchase Order ini?",
		"PurchaseOrder.success.action/cancel":"Purchase Order telah berhasil dibatalkan",
		"purchase_order.draft":"Draf",
		"PurchaseOrder.confirmTitle.action/draft":"Draf",
		"PurchaseOrder.confirmContent.action/draft":"Draf?",
		"PurchaseOrder.success.action/draft":"Purchase Order berhasil",
		"PurchaseOrder.name":"Nomor",
		"PurchaseOrder.date":"Tanggal",
		"PurchaseOrder.expectedDate":"Tanggal Ekspektasi Kedatangan",
		"PurchaseOrder.currencyId":"Mata Uang",
		"PurchaseOrder.currency":"Mata Uang",
		"PurchaseOrder.vendorId":"Pemasok",
		"PurchaseOrder.vendor":"Pemasok",
		"PurchaseOrder.address":"Alamat",
		"PurchaseOrder.state":"Status",
		"PurchaseOrder.branchId":"Cabang",
		"PurchaseOrder.branch":"Cabang",
		"PurchaseOrder.type":"Tipe",
		"PurchaseOrder.fillItemList":"Isi Daftar Barang",
		"Items":"Daftar Barang",
		"Items":"Daftar Barang",
		"PurchaseOrder.lines":"Daftar Barang",
		"PurchaseOrder.totalDiscount":"Total Diskon",
		"PurchaseOrder.subtotal":"Subtotal",
		"PurchaseOrder.totalTax":"Total Pajak",
		"PurchaseOrder.total":"Grand Total",
		//Purchase Order Item
		"PurchaseOrderLine":"Item Pembelian",
		"purchase_order_line":"Item Pembelian",
		"PurchaseOrderLine.name":"Nomor",
		"PurchaseOrderLine.itemId":"Barang",
		"PurchaseOrderLine.item":"Barang",
		"PurchaseOrderLine.unitId":"Satuan",
		"PurchaseOrderLine.unit":"Satuan",
		"PurchaseOrderLine.quantity":"Jumlah",
		"PurchaseOrderLine.request":"Pengajuan",
		"PurchaseOrderLine.unitPrice":"Harga",
		"PurchaseOrderLine.discountPercentage":"D(%)",
		"PurchaseOrderLine.discountAmount":"Diskon",
		"PurchaseOrderLine.subtotal":"Subtotal",
		"PurchaseOrderLine.taxes":"Pajak",
		"PurchaseOrderLine.taxAmount":"Jumlah Pajak",
		"PurchaseOrderLine.total":"Total",
		"PurchaseOrderLine.deleteTitle":"Hapus",
		"PurchaseOrderLine.deleteContent":"Apa Anda yakin akan menghapus baris ini?",
		//Term
		"Terms":"Term",
		"terms":"Term",
		"terms.activate":"Aktifkan",
		"Terms.confirmTitle.action/activate":"Aktifkan",
		"Terms.confirmContent.action/activate":"Apakah Anda yakin ingin mengaktifkan Term ini?",
		"Terms.success.activate":"Term telah berhasil diaktifkan",
		"terms.deactivate":"Nonaktifkan",
		"Terms.confirmTitle.action/deactivate":"Nonaktifkan",
		"Terms.confirmContent.action/deactivate":"Apakah Anda yakin ingin menonaktifkan Term ini?",
		"Terms.success.deactivate":"Term telah berhasil dinonaktifkan",
		"Terms.name":"Nama",
		"Terms.days":"Hari",
		"Terms.state":"Status",
		//Invoice
		"Invoice":"Faktur",
		"invoice":"Faktur",
		"invoice.draft":"Draf",
		"Invoice.confirmTitle.action/draft":"Draf",
		"Invoice.confirmContent.action/draft":"Draf?",
		"Invoice.success.action/draft":"Faktur berhasil",
		"invoice.confirm":"Konfirmasi",
		"Invoice.confirmTitle.action/confirm":"Konfirmasi",
		"Invoice.confirmContent.action/confirm":"Apakah Anda yakin ingin mengkonfirmasi Faktur ini?",
		"Invoice.success.action/confirm":"Faktur telah berhasil dikonfirmasi",
		"invoice.cancel":"Batalkan",
		"Invoice.confirmTitle.action/cancel":"Batalkan",
		"Invoice.confirmContent.action/cancel":"Apakah Anda yakin ingin membatalkan Faktur ini?",
		"Invoice.success.action/cancel":"Faktur telah berhasil dibatalkan",
		"Invoice.sendMail":"Kirim E-mail",
		"Invoice.confirmTitle.send_mail":"Kirim E-mail",
		"Invoice.confirmContent.send_mail":"Apa Anda yakin ingin mengirim tagihan ini melalui E-mail",
		"Invoice.success.send_mail":"Tagihan berhasil dikirim",
		"Invoice.confirmTitle.print":"Konfirmasi cetak",
		"Invoice.confirmContent.print":"Apakah Anda yakin akan mencetak tagihan ini?",
		"Invoice.success.print":"Tagihan telah berhasil dicetak",
		"Invoice Information":"Informasi Tagihan",
		"Invoice.name":"Nomor",
		"Invoice.reference":"Referensi",
		"Invoice.description":"Deskripsi",
		"Invoice.token":"Token",
		"Invoice.paymentMethod":"Metode Pembayaran",
		"Invoice.date":"Tanggal",
		"Invoice.dueDate":"Tanggal Jatuh Tempo",
		"Invoice.periodId":"Periode",
		"Invoice.period":"Periode",
		"Invoice.type":"Tipe",
		"Invoice.taxInvoiceNumber":"No. Faktur Pajak",
		"Invoice.shipment_date":"Tanggal Kirim",
		"Invoice.receive_date":"Tanggal Terima",
		"Invoice.termsId":"Ketentuan",
		"Invoice.terms":"Ketentuan",
		"Invoice.partnerId":"Partner",
		"Invoice.partner":"Partner",
		"Invoice.alias":"Alias",
		"Invoice.branchId":"Cabang",
		"Invoice.branch":"Cabang",
		"Invoice.currencyId":"Mata Uang",
		"Invoice.currency":"Mata Uang",
		"Invoice.exchangeRate":"Nilai Tukar Mata Uang",
		"Invoice.receivableAccountId":"Akun Piutang",
		"Invoice.receivableAccount":"Akun Piutang",
		"Invoice.payableAccountId":"Akun Utang",
		"Invoice.payableAccount":"Akun Utang",
		"Items":"Daftar Barang",
		"Items":"Daftar Barang",
		"Invoice.lines":"Daftar Barang",
		"Invoice.totalDiscount":"Total Diskon",
		"Invoice.subtotal":"Subtotal",
		"Invoice.totalTax":"Total Pajak",
		"Invoice.total":"Grand Total",
		"Taxes":"Pajak",
		"Taxes":"Pajak",
		"Invoice.taxSummaries":"Rangkuman Pajak",
		"Reconciliation":"Rekonsiliasi",
		"Reconciliation":"Rekonsiliasi",
		"Invoice.valueBalance":"Saldo",
		"Invoice.paid":"Terlunasi",
		"Invoice.advanced":"Dibayar Di Muka",
		"Invoice.discounted":"Diskon Susulan",
		"Invoice.returned":"Dikembalikan",
		"Invoice.writtenOff":"Dihapuskan",
		"Invoice.vouchers":"Daftar Pembayaran",
		"Invoice.advances":"Daftar Rekonsiliasi Uang Muka",
		"Invoice.returns":"Daftar Retur",
		"Invoice.writeoffs":"Daftar Penghapusan",
		"Accounting":"Akunting",
		"Accounting":"Akunting",
		"Invoice.accountMove":"Jurnal",
		"Invoicing":"Penagihan",
		"Invoicing":"Penagihan",
		"Invoice.collectiveInvoiceId":"Kontra Bon",
		"Invoice.collectiveInvoice":"Kontra Bon",
		"Invoice.state":"Status",

		//invoice payment
		"Invoice.paymentDate":"Tanggal Pembayaran",
		"Invoice.confirmTitle.action/payment":"Pembayaran",
		"Invoice.confirmContent.action/payment":"Silakan masukkan tanggal pembayaran",
		"Invoice.success.action/payment":"Tagihan berhasil dibayar",
		
		//Invoice Line
		"InvoiceLine":"Daftar Barang",
		"invoice_line":"Daftar Barang",
		"InvoiceLine.name":"Nomor",
		"InvoiceLine.itemId":"Barang",
		"InvoiceLine.item":"Barang",
		"InvoiceLine.description":"Deskripsi",
		"InvoiceLine.quantity":"Jumlah",
		"InvoiceLine.unitId":"Satuan",
		"InvoiceLine.unit":"Satuan",
		"InvoiceLine.price":"Harga",
		"InvoiceLine.discountPercentage":"D(%)",
		"InvoiceLine.discountAmount":"Diskon",
		"InvoiceLine.subtotal":"Subtotal",
		"InvoiceLine.taxes":"Pajak",
		"InvoiceLine.taxAmount":"Jumlah Pajak",
		"InvoiceLine.total":"Total",
		"InvoiceLine.quantityBalance":"Saldo Tidak Diretur",
		"InvoiceLine.valueBalance":"Belum Terlunasi",
		"InvoiceLine.grnLineId":"GRN",
		"InvoiceLine.grnLine":"GRN",
		"InvoiceLine.deliveryOrderLineId":"Surat Jalan",
		"InvoiceLine.deliveryOrderLine":"Surat Jalan",
		"InvoiceLine.discountedInvoiceId":"Tagihan Didiskon",
		"InvoiceLine.discountedInvoice":"Tagihan Didiskon",
		
		//Consignment
		"FinanceAPConsignmentPurchase":"Konsinyasi Pembelian",
		"FinanceARConsignmentSales":"Konsinyasi Penjualan",
		"Consignment":"Konsinyasi",
		"Invoice.parent":"Induk",
		"Invoice.children":"Anak",

		//Voucher Fund
		"TaxSummary":"Sumber Dana",
		"tax_summary":"Sumber Dana",
		"TaxSummary.taxId":"Pajak",
		"TaxSummary.tax":"Pajak",
		"TaxSummary.amount":"Jumlah",
		"TaxSummary.taxedIn":"Dikenakan Pada",
		//Voucher Fund
		"VoucherTaxSummary":"Sumber Dana",
		"voucher_tax_summary":"Sumber Dana",
		"VoucherTaxSummary.taxId":"Pajak",
		"VoucherTaxSummary.tax":"Pajak",
		"VoucherTaxSummary.amount":"Jumlah",
		"VoucherTaxSummary.taxedIn":"Dikenakan Pada",
		//Payment Type
		"PaymentType":"Metode Pembayaran",
		"payment_type":"Metode Pembayaran",
		"payment_type.activate":"Aktifkan",
		"PaymentType.confirmTitle.action/activate":"Aktifkan",
		"PaymentType.confirmContent.action/activate":"Apakah Anda yakin ingin mengaktifkan Metode Pembayaran ini?",
		"PaymentType.success.action/activate":"Metode Pembayaran telah berhasil diaktifkan",
		"payment_type.deactivate":"Nonaktifkan",
		"PaymentType.confirmTitle.action/deactivate":"Nonaktifkan",
		"PaymentType.confirmContent.action/deactivate":"Apakah Anda yakin ingin menonaktifkan Metode Pembayaran ini?",
		"PaymentType.success.action/deactivate":"Metode Pembayaran telah berhasil dinonaktifkan",
		"PaymentType.name":"Nama",
		"PaymentType.code":"Kode Ketika Cetak (Opsional)",
		"PaymentType.type":"Tipe",
		"PaymentType.branchId":"Cabang",
		"PaymentType.branch":"Cabang",
		"PaymentType.accountId":"Akun",
		"PaymentType.account":"Akun",
		"PaymentType.printPaymentCode":"Cetak Kode Pembayaran",
		"PaymentType.currencyId":"Mata Uang",
		"PaymentType.currency":"Mata Uang",
		"PaymentType.giroClearingAccountId":"Akun Pencairan Giro",
		"PaymentType.giroClearingAccount":"Akun Pencairan Giro",
		"PaymentType.giroPaymentAccountId":"Akun Pembayaran Giro",
		"PaymentType.giroPaymentAccount":"Akun Pembayaran Giro",
		"PaymentType.giroReceiveAccountId":"Akun Penerimaan Giro",
		"PaymentType.giroReceiveAccount":"Akun Penerimaan Giro",
		"PaymentType.state":"Status",
		"PaymentType.generalInfo":"Informasi Dasar",
		"PaymentType.giro":"Giro",
		"PaymentType.additionalFee":"Biaya Tambahan",
		"PaymentType.feeChargeTo":"Penanggung Biaya",
		"PaymentType.feeAmount":"Jumlah Biaya",
		"PaymentType.feePercentage":"Persentase Biaya",
		"PaymentType.feeAccount":"Akun Biaya",
		"PaymentType.additionalIncome":"Pendapatan Tambahan",
		"PaymentType.incomeAmount":"Jumlah Pendapatan",
		"PaymentType.incomePercentage":"Persentase Pendapatan",
		"PaymentType.incomeAccount":"Akun Pendapatan",
		"PaymentType.isManuallyProcessed":"Diproses Manual",
		"PaymentType.processTime":"Waktu Proses (Hari)",
		"PaymentType.autoRoundPayment":"Bulatkan Otomatis Pembayaran",
		"PaymentType.roundTo":"Dibulatkan ke",
		//Payment
		"Voucher":"Pembayaran",
		"voucher":"Pembayaran",
		"voucher.draft":"Draf",
		"Voucher.confirmTitle.action/draft":"Draf",
		"Voucher.confirmContent.action/draft":"Draf?",
		"Voucher.success.action/draft":"Pembayaran berhasil",
		"voucher.confirm":"Konfirmasi",
		"Voucher.confirmTitle.action/confirm":"Konfirmasi",
		"Voucher.confirmContent.action/confirm":"Apakah Anda yakin ingin mengkonfirmasi Pembayaran ini?",
		"Voucher.success.action/confirm":"Pembayaran telah berhasil dikonfirmasi",
		"voucher.cancel":"Batalkan",
		"Voucher.confirmTitle.action/cancel":"Batalkan",
		"Voucher.confirmContent.action/cancel":"Apakah Anda yakin ingin membatalkan Pembayaran ini?",
		"Voucher.success.action/cancel":"Pembayaran telah berhasil dibatalkan",
		"Voucher.name":"Nomor",
		"Voucher.date":"Tanggal",
		"Voucher.periodId":"Periode",
		"Voucher.period":"Periode",
		"Voucher.partnerId":"Partner",
		"Voucher.partner":"Partner",
		"Voucher.branchId":"Cabang",
		"Voucher.branch":"Cabang",
		"Voucher.currencyId":"Mata Uang",
		"Voucher.currency":"Mata Uang",
		"Voucher.description":"Deskripsi",
		"Voucher.paymentDescription":"Cara Pembayaran",
		"Voucher.total":"Total Pembayaran",
		"Voucher.allocated":"Teralokasi",
		"Voucher.balance":"Saldo",
		"Voucher.writeOffTotal":"Total Penghapusan",
		"Source Fund":"Sumber Dana",
		"Source Fund":"Sumber Dana",
		"Voucher.funds":"Dana",
		"Voucher.advanceReconciliations":"Alokasikan Uang Muka",
		"Voucher.autoAllocateToAdvance":"Alokasi Otomatis Ke Uang Muka",
		"Allocations":"Alokasi",
		"Allocations":"Alokasi",
		"Voucher.lines":"Alokasikan ke Tagihan",
		"Voucher.items":"Alokasikan ke Barang",
		"Voucher.advanceAllocations":"Alokasikan ke Uang Muka",
		"Voucher.advanceReturns":"Pengembalian Uang Muka",
		"Accounting":"Akunting",
		"Accounting":"Akunting",
		"Voucher.accountMove":"Jurnal",
		"Others":"Lain-Lain",
		"Others":"Lain-Lain",
		"Voucher.transactionId":"ID Transaksi",
		"Voucher.transaction":"ID Transaksi",
		"Voucher.state":"Status",
		"Voucher.accountLines":"Daftar Akun",
		"Voucher.contraAccountLines":"Daftar Akun Lawan",
		"Voucher.account":"Akun",
		"Voucher.value":"Nilai",
		"Voucher.confirmTitle.allocateBalance":"Alokasikan Selisih ke Akun",
		"Voucher.difference":"Selisih",
		"Voucher.differenceAccount":"Akun",

		//BulkPayment
		"BulkPayment":"Pembayaran Massal",
		"bulk_payment":"Pembayaran Massal",


		//Fund Summary
		"FundSummary":"Rekap Dana",
		"fund_summary":"Rekap Dana",
		"FundSummary.name":"Nomor",
		"FundSummary.currencyId":"Mata Uang",
		"FundSummary.currency":"Mata Uang",
		"FundSummary.exchangeRate":"Nilai Tukar",
		//Allocation Summary
		"AllocationSummary":"Rekap Alokasi",
		"allocation_summary":"Rekap Alokasi",
		"AllocationSummary.name":"Nomor",
		"AllocationSummary.currencyId":"Mata Uang",
		"AllocationSummary.currency":"Mata Uang",
		"AllocationSummary.exchangeRate":"Nilai Tukar",
		//Voucher Fund
		"VoucherFund":"Sumber Dana",
		"voucher_fund":"Sumber Dana",
		"VoucherFund.name":"Nomor",
		"VoucherFund.date":"Tanggal",
		"VoucherFund.total":"Total",
		"VoucherFund.currencyId":"Mata Uang",
		"VoucherFund.currency":"Mata Uang",
		"VoucherFund.targetCurrencyId":"Mata Uang Tujuan",
		"VoucherFund.targetCurrency":"Mata Uang Tujuan",
		"VoucherFund.exchangeRate":"Nilai Tukar",
		"VoucherFund.targetAccountingCurrencyId":"Mata Uang Akunting Tujuan",
		"VoucherFund.targetAccountingCurrency":"Mata Uang Akunting Tujuan",
		"VoucherFund.accountingExchangeRate":"Nilai Tukar Skrg.",
		"VoucherFund.paymentTypeId":"Cara Bayar",
		"VoucherFund.paymentType":"Cara Bayar",
		"VoucherFund.bankName":"Bank",
		"VoucherFund.bankTransactionId":"Nomor Transaksi",
		"VoucherFund.bankTransaction":"Nomor Transaksi",
		"VoucherFund.giroId":"Giro",
		"VoucherFund.giro":"Giro",
		//Payment Allocation
		"VoucherLine":"Alokasi Pembayaran",
		"voucher_line":"Alokasi Pembayaran",
		"VoucherLine.name":"Nomor",
		"VoucherLine.invoiceId":"Tagihan",
		"VoucherLine.invoice":"Tagihan",
		"VoucherLine.currencyId":"Mata Uang",
		"VoucherLine.currency":"Mata Uang",
		"VoucherLine.targetCurrencyId":"Mata Uang Tujuan",
		"VoucherLine.targetCurrency":"Mata Uang Tujuan",
		"VoucherLine.exchangeRate":"Nilai Tukar",
		"VoucherLine.targetAccountingCurrencyId":"Mata Uang Akunting Tujuan",
		"VoucherLine.targetAccountingCurrency":"Mata Uang Akunting Tujuan",
		"VoucherLine.accountingExchangeRate":"Nilai Tukar Skrg.",
		"VoucherLine.invoiceBalance":"Saldo Tagihan",
		"VoucherLine.payment":"Total",
		"VoucherLine.writeOffId":"Penghapusan Tagihan",
		"VoucherLine.writeOff":"Penghapusan Tagihan",
		"VoucherLine.writeOffAmount":"Nilai Penghapusan",
		"VoucherLine.taxAllocationId":"Alokasi Pajak",
		"VoucherLine.taxAllocation":"Alokasi Pajak",
		"VoucherLine.taxAllocationAmount":"Nilai Alokasi Pajak",
		"VoucherLine.description":"Catatan",
		//Tax Allocation
		"TaxAllocation":"Alokasi Pajak",
		"tax_allocation":"Alokasi Pajak",
		"TaxAllocation.lines":"Alokasi Pemotongan Pajak",
		//Voucher Fund
		"TaxAllocationLine":"Sumber Dana",
		"tax_allocation_line":"Sumber Dana",
		"TaxAllocationLine.taxId":"Pajak",
		"TaxAllocationLine.tax":"Pajak",
		"TaxAllocationLine.allocated":"Alokasi",
		//Voucher Write Off
		"VoucherLineWriteOff":"Penghapusan Tagihan Voucher",
		"voucher_line_write_off":"Penghapusan Tagihan Voucher",
		"VoucherLineWriteOff.lines":"Daftar Penghapusan",
		//Voucher Line Write Off
		"VoucherLineWriteOffLine":"Item Penghapusan Tagihan Voucher",
		"voucher_line_write_off_line":"Item Penghapusan Tagihan Voucher",
		"VoucherLineWriteOffLine.writeOffAccountId":"Akun Penghapusan",
		"VoucherLineWriteOffLine.writeOffAccount":"Akun Penghapusan",
		"VoucherLineWriteOffLine.allocated":"Nilai",
		//Allocation To Advance
		"AdvanceAllocation":"Alokasi Ke Uang Muka",
		"advance_allocation":"Alokasi Ke Uang Muka",
		"AdvanceAllocation.name":"Nomor",
		"AdvanceAllocation.total":"Total",
		"AdvanceAllocation.currencyId":"Mata Uang",
		"AdvanceAllocation.currency":"Mata Uang",
		"AdvanceAllocation.targetCurrencyId":"Mata Uang Tujuan",
		"AdvanceAllocation.targetCurrency":"Mata Uang Tujuan",
		"AdvanceAllocation.exchangeRate":"Nilai Tukar",
		"AdvanceAllocation.targetAccountingCurrencyId":"Mata Uang Akunting Tujuan",
		"AdvanceAllocation.targetAccountingCurrency":"Mata Uang Akunting Tujuan",
		"AdvanceAllocation.accountingExchangeRate":"Nilai Tukar Skrg.",
		"AdvanceAllocation.autoReconcile":"Rekonsiliasi Otomatis",
		"AdvanceAllocation.advanceId":"Pembayaran Dimuka",
		"AdvanceAllocation.advance":"Pembayaran Dimuka",
		//Voucher Fund
		"VoucherItem":"Sumber Dana",
		"voucher_item":"Sumber Dana",
		"VoucherItem.name":"Nomor",
		"VoucherItem.itemId":"Barang",
		"VoucherItem.item":"Barang",
		"VoucherItem.quantity":"Jumlah",
		"VoucherItem.unitId":"Satuan",
		"VoucherItem.unit":"Satuan",
		"VoucherItem.currencyId":"Mata Uang",
		"VoucherItem.currency":"Mata Uang",
		"VoucherItem.targetCurrencyId":"Mata Uang Tujuan",
		"VoucherItem.targetCurrency":"Mata Uang Tujuan",
		"VoucherItem.exchangeRate":"Nilai Tukar",
		"VoucherItem.targetAccountingCurrencyId":"Mata Uang Akunting Tujuan",
		"VoucherItem.targetAccountingCurrency":"Mata Uang Akunting Tujuan",
		"VoucherItem.accountingExchangeRate":"Nilai Tukar Skrg.",
		"VoucherItem.price":"Harga",
		"VoucherItem.discountPercentage":"Diskon (%)",
		"VoucherItem.discountAmount":"Nilai Diskon",
		"VoucherItem.subtotal":"Subtotal",
		"VoucherItem.taxes":"Daftar Pajak",
		"VoucherItem.taxAmount":"Jumlah Pajak",
		"VoucherItem.total":"Total",
		//Advance Payment
		"Advance":"Pembayaran Di Muka",
		"advance":"Pembayaran Di Muka",
		"advance.draft":"Draf",
		"Advance.confirmTitle.action/draft":"Draf",
		"Advance.confirmContent.action/draft":"Draf?",
		"Advance.success.action/draft":"Pembayaran Di Muka berhasil",
		"advance.confirm":"Konfirmasi",
		"Advance.confirmTitle.action/confirm":"Konfirmasi",
		"Advance.confirmContent.action/confirm":"Apakah Anda yakin ingin mengkonfirmasi Pembayaran Di Muka ini?",
		"Advance.success.action/confirm":"Pembayaran Di Muka telah berhasil dikonfirmasi",
		"advance.cancel":"Batalkan",
		"Advance.confirmTitle.action/cancel":"Batalkan",
		"Advance.confirmContent.action/cancel":"Apakah Anda yakin ingin membatalkan Pembayaran Di Muka ini?",
		"Advance.success.action/cancel":"Pembayaran Di Muka telah berhasil dibatalkan",
		"Advance.name":"Nomor",
		"Advance.reference":"Referensi",
		"Advance.date":"Tanggal",
		"Advance.periodId":"Periode",
		"Advance.period":"Periode",
		"Advance.type":"Tipe",
		"Advance.branchId":"Cabang",
		"Advance.branch":"Cabang",
		"Advance.partnerId":"Partner",
		"Advance.partner":"Partner",
		"Advance.currencyId":"Mata Uang",
		"Advance.currency":"Mata Uang",
		"Advance.exchangeRate":"Nilai Tukar",
		"Advance.autoReconcile":"Rekonsiliasi Otomatis",
		"Advance.description":"Deskripsi",
		"Advance.total":"Total",
		"Allocation":"Alokasi",
		"Allocation":"Alokasi",
		"Advance.balance":"Saldo",
		"Advance.lines":"Alokasi",
		"Source Document":"Dokumen Sumber",
		"Source Document":"Dokumen Sumber",
		"Advance.voucherId":"Pembayaran",
		"Advance.voucher":"Pembayaran",
		"Advance.writeOffId":"Dokumen Penghapusan",
		"Advance.writeOff":"Dokumen Penghapusan",
		"Advance.returnId":"Retur",
		"Advance.return":"Retur",
		"Accounting":"Akunting",
		"Accounting":"Akunting",
		"Advance.accountMove":"Jurnal",
		"Advance.state":"Status",
		//Advance Payment Line
		"AdvanceLine":"Baris Pembayaran Di Muka",
		"advance_line":"Baris Pembayaran Di Muka",
		"advance_line.draft":"Draf",
		"AdvanceLine.confirmTitle.action/draft":"Draf",
		"AdvanceLine.confirmContent.action/draft":"Draf?",
		"AdvanceLine.success.action/draft":"Baris Pembayaran Di Muka berhasil",
		"advance_line.confirm":"Konfirmasi",
		"AdvanceLine.confirmTitle.action/confirm":"Konfirmasi",
		"AdvanceLine.confirmContent.action/confirm":"Apakah Anda yakin ingin mengkonfirmasi Baris Pembayaran Di Muka ini?",
		"AdvanceLine.success.action/confirm":"Baris Pembayaran Di Muka telah berhasil dikonfirmasi",
		"advance_line.cancel":"Batalkan",
		"AdvanceLine.confirmTitle.action/cancel":"Batalkan",
		"AdvanceLine.confirmContent.action/cancel":"Apakah Anda yakin ingin membatalkan Baris Pembayaran Di Muka ini?",
		"AdvanceLine.success.action/cancel":"Baris Pembayaran Di Muka telah berhasil dibatalkan",
		"AdvanceLine.name":"Nomor",
		"AdvanceLine.date":"Tanggal",
		"AdvanceLine.periodId":"Periode",
		"AdvanceLine.period":"Periode",
		"AdvanceLine.currencyId":"Mata Uang",
		"AdvanceLine.currency":"Mata Uang",
		"AdvanceLine.targetCurrencyId":"Mata Uang Tujuan",
		"AdvanceLine.targetCurrency":"Mata Uang Tujuan",
		"AdvanceLine.exchangeRate":"Nilai Tukar",
		"AdvanceLine.targetAccountingCurrencyId":"Mata Uang Akunting Tujuan",
		"AdvanceLine.targetAccountingCurrency":"Mata Uang Akunting Tujuan",
		"AdvanceLine.accountingExchangeRate":"Nilai Tukar Skrg.",
		"AdvanceLine.advanceId":"Pembayaran",
		"AdvanceLine.advance":"Pembayaran",
		"AdvanceLine.invoiceId":"Tagihan",
		"AdvanceLine.invoice":"Tagihan",
		"AdvanceLine.voucherId":"Pembayaran",
		"AdvanceLine.voucher":"Pembayaran",
		"AdvanceLine.description":"Deskripsi",
		"AdvanceLine.total":"Total",
		"Accounting":"Akunting",
		"Accounting":"Akunting",
		"AdvanceLine.accountMove":"Jurnal",
		"AdvanceLine.state":"Status",
		"AdvanceLine.returnVoucher":"Pembayaran",
		//Advance Payment Line
		"AdvanceReturn":"Baris Pembayaran Di Muka",
		"advance_return":"Baris Pembayaran Di Muka",
		"advance_return.draft":"Draf",
		"AdvanceReturn.confirmTitle.action/draft":"Draf",
		"AdvanceReturn.confirmContent.action/draft":"Draf?",
		"AdvanceReturn.success.action/draft":"Baris Pembayaran Di Muka berhasil",
		"advance_return.confirm":"Konfirmasi",
		"AdvanceReturn.confirmTitle.action/confirm":"Konfirmasi",
		"AdvanceReturn.confirmContent.action/confirm":"Apakah Anda yakin ingin mengkonfirmasi Baris Pembayaran Di Muka ini?",
		"AdvanceReturn.success.action/confirm":"Baris Pembayaran Di Muka telah berhasil dikonfirmasi",
		"advance_return.cancel":"Batalkan",
		"AdvanceReturn.confirmTitle.action/cancel":"Batalkan",
		"AdvanceReturn.confirmContent.action/cancel":"Apakah Anda yakin ingin membatalkan Baris Pembayaran Di Muka ini?",
		"AdvanceReturn.success.action/cancel":"Baris Pembayaran Di Muka telah berhasil dibatalkan",
		"AdvanceReturn.name":"Nomor",
		"AdvanceReturn.date":"Tanggal",
		"AdvanceReturn.periodId":"Periode",
		"AdvanceReturn.period":"Periode",
		"AdvanceReturn.currencyId":"Mata Uang",
		"AdvanceReturn.currency":"Mata Uang",
		"AdvanceReturn.targetCurrencyId":"Mata Uang Tujuan",
		"AdvanceReturn.targetCurrency":"Mata Uang Tujuan",
		"AdvanceReturn.exchangeRate":"Nilai Tukar",
		"AdvanceReturn.targetAccountingCurrencyId":"Mata Uang Akunting Tujuan",
		"AdvanceReturn.targetAccountingCurrency":"Mata Uang Akunting Tujuan",
		"AdvanceReturn.accountingExchangeRate":"Nilai Tukar",
		"AdvanceReturn.advanceId":"Pembayaran",
		"AdvanceReturn.advance":"Uang Muka",
		"AdvanceReturn.voucherId":"Pembayaran",
		"AdvanceReturn.voucher":"Pembayaran",
		"AdvanceReturn.description":"Deskripsi",
		"AdvanceReturn.total":"Total",
		"Accounting":"Akunting",
		"Accounting":"Akunting",
		"AdvanceReturn.accountMove":"Jurnal",
		"AdvanceReturn.state":"Status",
		//Write Off
		"WriteOff":"Penghapusan Piutang",
		"write_off":"Penghapusan Piutang",
		"write_off.draft":"Draf",
		"WriteOff.confirmTitle.action/draft":"Draf",
		"WriteOff.confirmContent.action/draft":"Draf?",
		"WriteOff.success.action/draft":"Penghapusan Piutang berhasil",
		"write_off.confirm":"Konfirmasi",
		"WriteOff.confirmTitle.action/confirm":"Konfirmasi",
		"WriteOff.confirmContent.action/confirm":"Apakah Anda yakin ingin mengkonfirmasi Penghapusan Piutang ini?",
		"WriteOff.success.action/confirm":"Penghapusan Piutang telah berhasil dikonfirmasi",
		"write_off.cancel":"Batalkan",
		"WriteOff.confirmTitle.action/cancel":"Batalkan",
		"WriteOff.confirmContent.action/cancel":"Apakah Anda yakin ingin membatalkan Penghapusan Piutang ini?",
		"WriteOff.success.action/cancel":"Penghapusan Piutang telah berhasil dibatalkan",
		"WriteOff.confirmTitle.print":"Konfirmasi Cetak",
		"WriteOff.confirmContent.print":"Apakah Anda yakin ingin mencetak Penghapusan Piutang ini?",
		"WriteOff.success.print":"Penghapusan Piutang telah berhasil dicetak",
		"WriteOff.transactionType":"Tipe Transaksi",
		"WriteOff.name":"Nomor",
		"WriteOff.date":"Tanggal",
		"WriteOff.periodId":"Periode",
		"WriteOff.period":"Periode",
		"WriteOff.branchId":"Cabang",
		"WriteOff.branch":"Cabang",
		"WriteOff.partnerId":"Partner",
		"WriteOff.partner":"Partner",
		"WriteOff.currencyId":"Mata Uang",
		"WriteOff.currency":"Mata Uang",
		"WriteOff.exchangeRate":"Nilai Tukar",
		"WriteOff.writeOffAccountId":"Akun Lawan",
		"WriteOff.writeOffAccount":"Akun Lawan",
		"WriteOff.ignoreInvoiceBalance":"Abaikan Saldo Tagihan",
		"WriteOff.overpayment":"Kelebihan Pembayaran",
		"WriteOff.overpaymentAllocation":"Alokasi Kelebihan Pembayaran",
		"WriteOff.noteId":"Nota",
		"WriteOff.note":"Nota",
		"WriteOff.advanceId":"Pembayaran Dimuka",
		"WriteOff.advance":"Pembayaran Dimuka",
		"WriteOff.accountMove":"Jurnal",
		"WriteOff.state":"Status",
		"WriteOff.lines":"Invoices / Items",
		"WriteOff.type":"Tipe",
		//Write Off Invoice
		"WriteOffLine":"Penghapusan Piutang Tagihan",
		"write_off_line":"Penghapusan Piutang Tagihan",
		"write_off_line.draft":"Draf",
		"WriteOffLine.confirmTitle.action/draft":"Draf",
		"WriteOffLine.confirmContent.action/draft":"Draf?",
		"WriteOffLine.success.action/draft":"Penghapusan Piutang Tagihan berhasil",
		"write_off_line.confirm":"Konfirmasi",
		"WriteOffLine.confirmTitle.action/confirm":"Konfirmasi",
		"WriteOffLine.confirmContent.action/confirm":"Apakah Anda yakin ingin mengkonfirmasi Penghapusan Piutang Tagihan ini?",
		"WriteOffLine.success.action/confirm":"Penghapusan Piutang Tagihan telah berhasil dikonfirmasi",
		"write_off_line.cancel":"Batalkan",
		"WriteOffLine.confirmTitle.action/cancel":"Batalkan",
		"WriteOffLine.confirmContent.action/cancel":"Apakah Anda yakin ingin membatalkan Penghapusan Piutang Tagihan ini?",
		"WriteOffLine.success.action/cancel":"Penghapusan Piutang Tagihan telah berhasil dibatalkan",
		"WriteOffLine.name":"Nomor",
		"WriteOffLine.invoiceId":"Tagihan",
		"WriteOffLine.invoice":"Tagihan",
		"WriteOffLine.invoiceLineId":"Barang Tagihan",
		"WriteOffLine.invoiceLine":"Barang Tagihan",
		"WriteOffLine.itemId":"Barang",
		"WriteOffLine.item":"Barang",
		"WriteOffLine.quantity":"Jumlah",
		"WriteOffLine.unitId":"Satuan",
		"WriteOffLine.unit":"Satuan",
		"WriteOffLine.unitPrice":"Harga",
		"WriteOffLine.discountPercentage":"Diskon (%)",
		"WriteOffLine.discountAmount":"Nilai Diskon",
		"WriteOffLine.total":"Diskon Total",
		//Return
		"Return":"Pengembalian",
		"return":"Pengembalian",
		"return.draft":"Draf",
		"Return.confirmTitle.action/draft":"Draf",
		"Return.confirmContent.action/draft":"Draf?",
		"Return.success.action/draft":"Pengembalian berhasil",
		"return.confirm":"Konfirmasi",
		"Return.confirmTitle.action/confirm":"Konfirmasi",
		"Return.confirmContent.action/confirm":"Apakah Anda yakin ingin mengkonfirmasi Pengembalian ini?",
		"Return.success.action/confirm":"Pengembalian telah berhasil dikonfirmasi",
		"return.cancel":"Batalkan",
		"Return.confirmTitle.action/cancel":"Batalkan",
		"Return.confirmContent.action/cancel":"Apakah Anda yakin ingin membatalkan Pengembalian ini?",
		"Return.success.action/cancel":"Pengembalian telah berhasil dibatalkan",
		"Return.confirmTitle.print":"Konfirmasi Cetak",
		"Return.confirmContent.print":"Apakah Anda yakin ingin mencetak Pengembalian ini?",
		"Return.success.print":"Pengembalian telah berhasil dicetak",
		"Return.name":"Nomor",
		"Return.type":"Tipe",
		"Return.date":"Tanggal",
		"Return.periodId":"Periode",
		"Return.period":"Periode",
		"Return.branchId":"Cabang",
		"Return.branch":"Cabang",
		"Return.invoiceId":"Tagihan",
		"Return.invoice":"Tagihan",
		"Return.instantSales":"Penjualan Instan",
		"Return.instantPurchase":"Pembelian Instan",
		"Return.partnerId":"Partner",
		"Return.partner":"Partner",
		"Return.currencyId":"Mata Uang",
		"Return.currency":"Mata Uang",
		"Return.exchangeRate":"Nilai Tukar",
		"Return.taxed":"Pajak",
		"Return.vat":"PPN",
		"Return.defaultVat":"PPN Standar",
		"Return.acctQtyVoid":"Persediaan Tdk Mencukupi",
		"Return.writeOffAccountId":"Akun Lawan",
		"Return.writeOffAccount":"Akun Lawan",
		"Return.overpayment":"Kelebihan Pembayaran",
		"Return.overpaymentAllocation":"Alokasi Kelebihan Pembayaran",
		"Return.ignoreInvoiceBalance":"Abaikan Sisa Tagihan",
		"Return.ignoreFinance":"Tanpa Faktur",
		"Return.advanceId":"Pembayaran Dimuka",
		"Return.advance":"Pembayaran Dimuka",
		"Return.noteId":"Nota",
		"Return.note":"Nota",
		"Items":"Daftar Barang",
		"Items":"Daftar Barang",
		"Return.lines":"Daftar Barang",
		"Return.totalDiscount":"Total Diskon",
		"Return.subtotal":"Subtotal",
		"Return.totalTax":"Total Pajak",
		"Return.total":"Total",
		"Accounting":"Akunting",
		"Accounting":"Akunting",
		"Return.accountMove":"Jurnal",
		"Return.state":"Status",
		"Return.contraAccountId":"Akun Lawan",
		"Return.contraAccount":"Akun Lawan",
		"Return.pickFromInstantSales":"Pilih Dari Penjualan Instan",
		"Return.pickFromInstantPurchase":"Pilih Dari Pembelian Instan",
		"Return.paymentType":"Cara Pembayaran",
		"Return.print.success":"Mencetak Retur...",
		"Return.changeItems":"Ganti Barang",
		"Return.changeItemsDesc":"Retur hanya bisa untuk 1 nomor %x%. Apakah Anda yakin ingin menggantinya ke nomor %y%?",
		"Return.changeItemsInvoice":"Tagihan",
		"Return.changeItemsTrx":"Transaksi",

		//Return Item
		"ReturnLine":"Barang Pengembalian",
		"return_line":"Barang Pengembalian",
		"ReturnLine.name":"Nomor",
		"ReturnLine.invoiceLineId":"Tagihan",
		"ReturnLine.invoiceLine":"Tagihan",
		"ReturnLine.itemId":"Barang",
		"ReturnLine.item":"Barang",
		"ReturnLine.description":"Catatan",
		"ReturnLine.quantity":"Jumlah",
		"ReturnLine.unitId":"Satuan",
		"ReturnLine.unit":"Satuan",
		"ReturnLine.unitPrice":"Harga",
		"ReturnLine.discountPercentage":"D(%)",
		"ReturnLine.discountAmount":"Diskon",
		"ReturnLine.subtotal":"Subtotal",
		"ReturnLine.taxes":"Pajak",
		"ReturnLine.taxAmount":"Jumlah Pajak",
		"ReturnLine.total":"Total",
		"ReturnLine.inventoryMovePlanId":"RPB",
		"ReturnLine.inventoryMovePlan":"RPB",
		//Payable Claim
		"PayableClaim":"Penyesuaian Penjualan",
		"payable_claim":"Penyesuaian Penjualan",
		"payable_claim.draft":"Draf",
		"PayableClaim.confirmTitle.action/draft":"Draf",
		"PayableClaim.confirmContent.action/draft":"Draf?",
		"PayableClaim.success.action/draft":"Penyesuaian Penjualan berhasil",
		"PayableClaim.confirmTitle.action/confirm":"Konfirmasi",
		"PayableClaim.confirmContent.action/confirm":"Apakah Anda yakin ingin mengkonfirmasi Penyesuaian Penjualan ini?",
		"PayableClaim.success.action/confirm":"Penyesuaian Penjualan telah berhasil dikonfirmasi",
		"PayableClaim.confirmTitle.action/cancel":"Batalkan",
		"PayableClaim.confirmContent.action/cancel":"Apakah Anda yakin ingin membatalkan Penyesuaian Penjualan ini?",
		"PayableClaim.success.action/cancel":"Penyesuaian Penjualan telah berhasil dibatalkan",
		"PayableClaim.confirmTitle.print":"Konfirmasi Cetak",
		"PayableClaim.confirmContent.print":"Apakah Anda yakin ingin mencetak Penyesuaian Penjualan ini?",
		"PayableClaim.success.print":"Penyesuaian Penjualan telah berhasil dicetak",
		"PayableClaim.name":"Nomor",
		"PayableClaim.type":"Tipe",
		"PayableClaim.date":"Tanggal",
		"PayableClaim.periodId":"Periode",
		"PayableClaim.period":"Periode",
		"PayableClaim.branchId":"Cabang",
		"PayableClaim.branch":"Cabang",
		"PayableClaim.invoiceId":"Tagihan",
		"PayableClaim.invoice":"Tagihan",
		"PayableClaim.partnerId":"Partner",
		"PayableClaim.partner":"Partner",
		"PayableClaim.currencyId":"Mata Uang",
		"PayableClaim.currency":"Mata Uang",
		"PayableClaim.exchangeRate":"Nilai Tukar",
		"PayableClaim.taxed":"Pajak",
		"PayableClaim.writeOffAccountId":"Akun Lawan",
		"PayableClaim.writeOffAccount":"Akun Lawan",
		"PayableClaim.overpayment":"Kelebihan Pembayaran",
		"PayableClaim.overpaymentAllocation":"Alokasi Kelebihan Pembayaran",
		"PayableClaim.ignoreInvoiceBalance":"Abaikan Sisa Tagihan",
		"PayableClaim.ignoreFinance":"Tanpa Faktur",
		"PayableClaim.advanceId":"Pembayaran Dimuka",
		"PayableClaim.advance":"Pembayaran Dimuka",
		"PayableClaim.noteId":"Nota",
		"PayableClaim.note":"Nota",
		"PayableClaim.lines":"Daftar Barang",
		"PayableClaim.totalDiscount":"Total Diskon",
		"PayableClaim.subtotal":"Subtotal",
		"PayableClaim.totalTax":"Total Pajak",
		"PayableClaim.total":"Total",
		"PayableClaim.accountMove":"Jurnal",
		"PayableClaim.state":"Status",
		"PayableClaim.contraAccountId":"Akun Lawan",
		"PayableClaim.contraAccount":"Akun Lawan",
		"PayableClaim.salesAgent":"Agen Penjualan",
		"PayableClaim.totalDiscount":"Total Diskon Susulan",
		"PayableClaim.totalBonus":"Total Bonus",
		"PayableClaim.description":"Catatan",
		//Payable Claim Item
		"PayableClaimLine":"Barang Pengembalian",
		"payable_claim_line":"Barang Pengembalian",
		"PayableClaimLine.name":"Nomor",
		"PayableClaimLine.invoiceLineId":"Tagihan",
		"PayableClaimLine.invoiceLine":"Tagihan",
		"PayableClaimLine.itemId":"Barang",
		"PayableClaimLine.item":"Barang",
		"PayableClaimLine.description":"Catatan",
		"PayableClaimLine.quantity":"Jumlah",
		"PayableClaimLine.unitId":"Satuan",
		"PayableClaimLine.unit":"Satuan",
		"PayableClaimLine.unitPrice":"Harga",
		"PayableClaimLine.discountPercentage":"D(%)",
		"PayableClaimLine.discountAmount":"Diskon",
		"PayableClaimLine.subtotal":"Subtotal",
		"PayableClaimLine.taxes":"Pajak",
		"PayableClaimLine.taxAmount":"Total",
		"PayableClaimLine.total":"Total",
		"PayableClaimLine.inventoryMovePlanId":"RPB",
		"PayableClaimLine.inventoryMovePlan":"RPB",
		"PayableClaimLine.bonusPercentage":"B(%)",
		"PayableClaimLine.bonusAmount":"Bonus",
		//Giro
		"Giro":"Giro",
		"giro":"Giro",
		"giro.draft":"Draf",
		"Giro.confirmTitle.action/draft":"Draf",
		"Giro.confirmContent.action/draft":"Draf?",
		"Giro.success.action/draft":"Giro berhasil",
		"giro.confirm":"Konfirmasi",
		"Giro.confirmTitle.action/confirm":"Konfirmasi",
		"Giro.confirmContent.action/confirm":"Apakah Anda yakin ingin mengkonfirmasi Giro ini?",
		"Giro.success.action/confirm":"Giro telah berhasil dikonfirmasi",
		"giro.clear":"Cair",
		"Giro.confirmTitle.action/clear":"Pencairan Giro",
		"Giro.confirmContent.action/clear":"Apakah Anda yakin bahwa giro ini sudah cair?",
		"Giro.success.action/clear":"Giro berhasil dicairkan",
		"giro.cancel":"Batalkan",
		"Giro.confirmTitle.action/cancel":"Batalkan",
		"Giro.confirmContent.action/cancel":"Apakah Anda yakin ingin membatalkan Giro ini?",
		"Giro.success.action/cancel":"Giro telah berhasil dibatalkan",
		"giro.reopen":"Buka Kembali",
		"Giro.confirmTitle.action/reopen":"Buka Kembali",
		"Giro.confirmContent.action/reopen":"Apakah Anda yakin ingin membuka kembali Giro ini?",
		"Giro.success.action/reopen":"Giro telah berhasil dibuka kembali",
		"giro.cancel_clearing":"Batalkan Pencairan",
		"Giro.confirmTitle.action/cancel_clearing":"Batalkan Pencairan",
		"Giro.confirmContent.action/cancel_clearing":"Apakah Anda yakin ingin membataklan pencairan Giro ini?",
		"Giro.success.action/cancel_clearing":"Pencairan giro telah berhasil dibatalkan kembali",
		"Giro.name":"Nomor",
		"Giro.date":"Tanggal Diterima",
		"Giro.partnerId":"Partner",
		"Giro.partner":"Partner",
		"Giro.dueDate":"Tanggal Jatuh Tempo",
		"Giro.expireDate":"Tanggal Kadaluarsa",
		"Giro.clearingDate":"Tanggal Pencairan",
		"Giro.type":"Tipe",
		"Giro.total":"Total",
		"Giro.accountMove":"Jurnal",
		"Giro.state":"Status",
		"Giro.cancellationType":"Tipe Pembatalan",
		"Giro.cancellationDate":"Tanggal Pembatalan",
		"Giro.noteDate":"Tanggal Tagihan",
		"Giro.discountedInvoice":"Tagihan yang didiskon",
		"Giro.writeOffAccount":"Akun Penghapusan Piutang",
		"Giro.clearingAccount":"Akun Pencairan",
		"Giro.cancellationAccount":"Akun Pembatalan",
		"Giro.cancellationReason":"Alasan Pembatalan",
		"Cancellation":"Pembatalan",


		//Token Move
		"TokenMove":"Pergerakan Token",
		"token_move":"Pergerakan Token",
		"TokenMove.name":"Nama",
		"TokenMove.date":"Tanggal",
		"TokenMove.userId":"Pengguna",
		"TokenMove.user":"Pengguna",
		"TokenMove.debit":"Debit",
		"TokenMove.credit":"Kredit",
		//Collective Invoice
		"CollectiveInvoice":"Kontra Bon",
		"collective_invoice":"Kontra Bon",
		"collective_invoice.draft":"Draf",
		"CollectiveInvoice.confirmTitle.action/draft":"Draf",
		"CollectiveInvoice.confirmContent.action/draft":"Draf?",
		"CollectiveInvoice.success.action/draft":"Kontra Bon berhasil",
		"collective_invoice.confirm":"Konfirmasi",
		"CollectiveInvoice.confirmTitle.action/confirm":"Konfirmasi",
		"CollectiveInvoice.confirmContent.action/confirm":"Apakah Anda yakin ingin mengkonfirmasi Kontra Bon ini?",
		"CollectiveInvoice.success.action/confirm":"Kontra Bon telah berhasil dikonfirmasi",
		"collective_invoice.cancel":"Batalkan",
		"CollectiveInvoice.confirmTitle.action/cancel":"Batalkan",
		"CollectiveInvoice.confirmContent.action/cancel":"Apakah Anda yakin ingin membatalkan Kontra Bon ini?",
		"CollectiveInvoice.success.action/cancel":"Kontra Bon telah berhasil dibatalkan",
		"CollectiveInvoice.confirmTitle.print":"Konfirmasi Cetak",
		"CollectiveInvoice.confirmContent.print":"Apakah Anda yakin ingin mencetak Kontra Bon ini?",
		"CollectiveInvoice.success.print":"Kontra Bon telah berhasil dicetak",
		"CollectiveInvoice.success.print_customer":"Kontra Bon telah berhasil dicetak",
		"CollectiveInvoice.success.print_supplier":"Kontra Bon telah berhasil dicetak",
		"CollectiveInvoice.name":"Nomor",
		"CollectiveInvoice.date":"Tanggal",
		"CollectiveInvoice.partnerId":"Partner",
		"CollectiveInvoice.partner":"Partner",
		"CollectiveInvoice.alias":"Alias",
		"CollectiveInvoice.startDate":"Tanggal Awal",
		"CollectiveInvoice.endDate":"Tanggal Akhir",
		"CollectiveInvoice.branchId":"Cabang",
		"CollectiveInvoice.branch":"Cabang",
		"CollectiveInvoice.currencyId":"Mata Uang",
		"CollectiveInvoice.currency":"Mata Uang",
		"CollectiveInvoice.invoices":"Daftar Tagihan",
		"CollectiveInvoice.total":"Grand Total",
		"CollectiveInvoice.view":"Induk",
		"CollectiveInvoice.confirmContent.print_customer":"Apakah Anda yakin ingin mencetak Kontra Bon ini?",
		"CollectiveInvoice.confirmTitle.print_customer":"Konfirmasi Print",
		//Country
		"Country":"Negara",
		"country":"Negara",
		"country.activate":"Aktifkan",
		"Country.confirmTitle.action/activate":"Aktifkan",
		"Country.confirmContent.action/activate":"Apakah Anda yakin ingin mengaktifkan Negara ini?",
		"Country.success.action/activate":"Negara telah berhasil diaktifkan",
		"country.deactivate":"Nonaktifkan",
		"Country.confirmTitle.action/deactivate":"Nonaktifkan",
		"Country.confirmContent.action/deactivate":"Apakah Anda yakin ingin menonaktifkan Negara ini?",
		"Country.success.action/deactivate":"Negara telah berhasil dinonaktifkan",
		"Country.name":"Nama",
		"Country.code":"Kode",
		"Country.state":"Status",
		//Province
		"Province":"Provinsi",
		"province":"Provinsi",
		"province.activate":"Aktifkan",
		"Province.confirmTitle.action/activate":"Aktifkan",
		"Province.confirmContent.action/activate":"Apakah Anda yakin ingin mengaktifkan Provinsi ini?",
		"Province.success.action/activate":"Provinsi telah berhasil diaktifkan",
		"province.deactivate":"Nonaktifkan",
		"Province.confirmTitle.action/deactivate":"Nonaktifkan",
		"Province.confirmContent.action/deactivate":"Apakah Anda yakin ingin menonaktifkan Provinsi ini?",
		"Province.success.action/deactivate":"Provinsi telah berhasil dinonaktifkan",
		"Province.name":"Nama",
		"Province.code":"Kode",
		"Province.countryId":"Negara",
		"Province.country":"Negara",
		"Province.state":"Status",
		//City
		"City":"Kota",
		"city":"Kota",
		"city.activate":"Aktifkan",
		"City.confirmTitle.action/activate":"Aktifkan",
		"City.confirmContent.action/activate":"Apakah Anda yakin ingin mengaktifkan Kota ini?",
		"City.success.action/activate":"Kota telah berhasil diaktifkan",
		"city.deactivate":"Nonaktifkan",
		"City.confirmTitle.action/deactivate":"Nonaktifkan",
		"City.confirmContent.action/deactivate":"Apakah Anda yakin ingin menonaktifkan Kota ini?",
		"City.success.action/deactivate":"Kota telah berhasil dinonaktifkan",
		"City.name":"Nama",
		"City.code":"Kode",
		"City.provinceId":"Provinsi",
		"City.province":"Provinsi",
		"City.state":"Status",
		//Node
		"Node":"Titik",
		"node":"Titik",
		"node.activate":"Aktifkan",
		"Node.confirmTitle.action/activate":"Aktifkan",
		"Node.confirmContent.action/activate":"Apakah Anda yakin ingin mengaktifkan Titik ini?",
		"Node.success.action/activate":"Titik telah berhasil diaktifkan",
		"node.deactivate":"Nonaktifkan",
		"Node.confirmTitle.action/deactivate":"Nonaktifkan",
		"Node.confirmContent.action/deactivate":"Apakah Anda yakin ingin menonaktifkan Titik ini?",
		"Node.success.action/deactivate":"Titik telah berhasil dinonaktifkan",
		"Node.name":"Nama",
		"Node.partnerId":"Partner",
		"Node.partner":"Partner",
		"Node.state":"Status",
		//Partner Node
		"PartnerNode":"Titik Partner",
		"partner_node":"Titik Partner",
		//Users
		"User":"Pengguna",
		"user":"Pengguna",
		"User.userAccount":"Akun Pengguna",
		"User.password":"Kata Sandi",
		"User.passwordChangeNotes":"Catatan : Kosongkan 2 kolom diatas jika Anda tidak ingin mengganti Kata Sandi",
		"User.administration":"Administrasi",
		"user.activate":"Aktifkan",
		"User.confirmTitle.action/activate":"Aktifkan",
		"User.confirmContent.action/activate":"Apakah Anda yakin ingin mengaktifkan Pengguna ini?",
		"User.success.action/activate":"Pengguna telah berhasil diaktifkan",
		"user.deactivate":"Nonaktifkan",
		"User.confirmTitle.action/deactivate":"Nonaktifkan",
		"User.confirmContent.action/deactivate":"Apakah Anda yakin ingin menonaktifkan Pengguna ini?",
		"User.success.action/deactivate":"Pengguna telah berhasil dinonaktifkan",
		"User.username":"Nama Pengguna",
		"User.email":"E-mail",
		"User.passwordChange":"Konfirmasi Password",
		"User.mobile":"No. HP",
		"User.partnerId":"Partner",
		"User.groupId":"Grup Pengguna",
		"User.partner":"Partner",
		"User.lastLogin":"Terakhir Login",
		"User.lastActive":"Terakhir Aktif",
		"User.state":"Status",
		"User.allBranch":"Semua Cabang",
		"User.defaultBranchId":"Cabang Standar",
		"User.defaultBranch":"Cabang Standar",
		"User.branches":"Daftar Cabang",
		"User.profitSharingPercentage":"Pembagian Keuntungan (%)",
		//User Group
		"UserGroup":"Grup Pengguna",
		"user_group":"Grup Pengguna",
		"user_group.activate":"Aktifkan",
		"UserGroup.confirmTitle.action/activate":"Aktifkan",
		"UserGroup.confirmContent.action/activate":"Apakah Anda yakin ingin mengaktifkan Grup Pengguna ini?",
		"UserGroup.success.action/activate":"Grup Pengguna telah berhasil diaktifkan",
		"user_group.deactivate":"Nonaktifkan",
		"UserGroup.confirmTitle.action/deactivate":"Nonaktifkan",
		"UserGroup.confirmContent.action/deactivate":"Apakah Anda yakin ingin menonaktifkan Grup Pengguna ini?",
		"UserGroup.success.action/deactivate":"Grup Pengguna telah berhasil dinonaktifkan",
		"UserGroup.name":"Nama",
		"UserGroup.state":"Status",
		"UserGroup.accessRights":"Hak",
		"UserGroup.menus":"Daftar",
		//Branch
		"Branch":"Cabang",
		"branch":"Cabang",
		"branch.activate":"Aktifkan",
		"Branch.confirmTitle.action/activate":"Aktifkan",
		"Branch.confirmContent.action/activate":"Apakah Anda yakin ingin mengaktifkan Cabang ini?",
		"Branch.success.action/activate":"Cabang telah berhasil diaktifkan",
		"branch.deactivate":"Nonaktifkan",
		"Branch.confirmTitle.action/deactivate":"Nonaktifkan",
		"Branch.confirmContent.action/deactivate":"Apakah Anda yakin ingin menonaktifkan Cabang ini?",
		"Branch.success.action/deactivate":"Cabang telah berhasil dinonaktifkan",
		"Branch.name":"Nama",
		"Branch.code":"Kode",
		"Branch.address":"Alamat",
		"Branch.countryId":"Negara",
		"Branch.country":"Negara",
		"Branch.provinceId":"Provinsi",
		"Branch.province":"Provinsi",
		"Branch.cityId":"Kota",
		"Branch.city":"Kota",
		"Branch.state":"Status",
		//Menu Category
		"MenuCategory":"Kategori Menu",
		"menu_category":"Kategori Menu",
		"menu_category.activate":"Aktifkan",
		"MenuCategory.confirmTitle.action/activate":"Aktifkan",
		"MenuCategory.confirmContent.action/activate":"Apakah Anda yakin ingin mengaktifkan Kategori Menu ini?",
		"MenuCategory.success.action/activate":"Kategori Menu telah berhasil diaktifkan",
		"menu_category.deactivate":"Nonaktifkan",
		"MenuCategory.confirmTitle.action/deactivate":"Nonaktifkan",
		"MenuCategory.confirmContent.action/deactivate":"Apakah Anda yakin ingin menonaktifkan Kategori Menu ini?",
		"MenuCategory.success.action/deactivate":"Kategori Menu telah berhasil dinonaktifkan",
		"MenuCategory.name":"Nama",
		"MenuCategory.state":"Status",
		"MenuCategory.orderNum":"Urutan",
		//Menu
		"Menu":"Menu",
		"menu":"Menu",
		"menu.activate":"Aktifkan",
		"Menu.confirmTitle.action/activate":"Aktifkan",
		"Menu.confirmContent.action/activate":"Apakah Anda yakin ingin mengaktifkan Menu ini?",
		"Menu.success.action/activate":"Menu telah berhasil diaktifkan",
		"menu.deactivate":"Nonaktifkan",
		"Menu.confirmTitle.action/deactivate":"Nonaktifkan",
		"Menu.confirmContent.action/deactivate":"Apakah Anda yakin ingin menonaktifkan Menu ini?",
		"Menu.success.action/deactivate":"Menu telah berhasil dinonaktifkan",
		"Menu.code":"Kode",
		"Menu.name":"Nama",
		"Menu.state":"Status",
		"Menu.categoryId":"Kategori",
		"Menu.category":"Kategori",
		"Menu.orderNum":"Nama",
		//Access Right Category
		"AccessRightCategory":"Kategori Hak Akses",
		"access_right_category":"Kategori Hak Akses",
		"access_right_category.activate":"Aktifkan",
		"AccessRightCategory.confirmTitle.action/activate":"Aktifkan",
		"AccessRightCategory.confirmContent.action/activate":"Apakah Anda yakin ingin mengaktifkan Kategori Hak Akses ini?",
		"AccessRightCategory.success.action/activate":"Kategori Hak Akses telah berhasil diaktifkan",
		"access_right_category.deactivate":"Nonaktifkan",
		"AccessRightCategory.confirmTitle.action/deactivate":"Nonaktifkan",
		"AccessRightCategory.confirmContent.action/deactivate":"Apakah Anda yakin ingin menonaktifkan Kategori Hak Akses ini?",
		"AccessRightCategory.success.action/deactivate":"Kategori Hak Akses telah berhasil dinonaktifkan",
		"AccessRightCategory.name":"Nama",
		"AccessRightCategory.state":"Status",
		"AccessRightCategory.orderNum":"Nama",
		//Access Rights
		"AccessRight":"Hak Akses",
		"access_right":"Hak Akses",
		"access_right.activate":"Aktifkan",
		"AccessRight.confirmTitle.action/activate":"Aktifkan",
		"AccessRight.confirmContent.action/activate":"Apakah Anda yakin ingin mengaktifkan Hak Akses ini?",
		"AccessRight.success.action/activate":"Hak Akses telah berhasil diaktifkan",
		"access_right.deactivate":"Nonaktifkan",
		"AccessRight.confirmTitle.action/deactivate":"Nonaktifkan",
		"AccessRight.confirmContent.action/deactivate":"Apakah Anda yakin ingin menonaktifkan Hak Akses ini?",
		"AccessRight.success.action/deactivate":"Hak Akses telah berhasil dinonaktifkan",
		"AccessRight.code":"Kode",
		"AccessRight.description":"Deskripsi",
		"AccessRight.state":"Status",
		"AccessRight.categoryId":"Kategori",
		"AccessRight.category":"Kategori",
		"AccessRight.orderNum":"Nama",
		//Sales Order
		"SalesOrder":"Order Penjualan",
		"sales_order":"Order Penjualan",
		"sales_order.draft":"Draf",
		"SalesOrder.confirmTitle.action/draft":"Draf",
		"SalesOrder.confirmContent.action/draft":"Apakah Anda yakin ingin mengembalikan statusnya Order Penjualan menjadi draf?",
		"SalesOrder.success.action/draft":"Order Penjualan berhasil diubah ke draf",
		"sales_order.payment":"Pembayaran",
		"SalesOrder.confirmTitle.action/payment":"Pembayaran",
		"SalesOrder.confirmContent.action/payment":"Silakan pilih metode pembayaran dan tanggal pembayaran",
		"SalesOrder.success.action/payment":"Order Penjualan berhasil diubah menjadi lunas",
		"sales_order.confirm":"Konfirmasi",
		"SalesOrder.confirmTitle.action/offer":"Beri Penawaran",
		"SalesOrder.confirmContent.action/offer":"Apakah Anda yakin ingin memberi penawaran ke konsumen?",
		"SalesOrder.success.action/offer":"Penawaran telah berhasil dilakukan",
		"sales_order.cancel":"Batalkan",
		"SalesOrder.confirmTitle.action/cancel":"Batalkan",
		"SalesOrder.confirmContent.action/cancel":"Apakah Anda yakin ingin membatalkan Order Penjualan ini?",
		"SalesOrder.success.action/cancel":"Order Penjualan telah berhasil dibatalkan",
		"SalesOrder.success.action/reject":"Order Penjualan telah berhasil ditolak",
		"SalesOrder.failed.action/reject":"Penolakan Order Penjualan gagal, silahkan hubungi administrator",
		"SalesOrder.confirmTitle.action/resolve":"Selesaikan Moderasi",
		"SalesOrder.confirmContent.action/resolve":"Apakah Anda yakin akan menyelesaikan moderasi ini",
		"SalesOrder.success.action/resolve":"Moderasi berhasil diselesaikan",
		"SalesOrder.name":"Nomor",
		"SalesOrder.reference":"Referensi",
		"SalesOrder.date":"Tanggal",
		"SalesOrder.periodId":"Periode",
		"SalesOrder.period":"Periode",
		"SalesOrder.customerId":"Pelanggan",
		"SalesOrder.customer":"Pelanggan",
		"SalesOrder.alias":"Alias",
		"SalesOrder.branchId":"Cabang",
		"SalesOrder.branch":"Cabang",
		"SalesOrder.note":"Catatan",
		"Items":"Daftar Barang",
		"Items":"Daftar Barang",
		"SalesOrder.lines":"Daftar Barang",
		"SalesOrder.totalDiscount":"Diskon Total",
		"SalesOrder.subtotal":"Subtotal",
		"SalesOrder.totalTax":"Total Setelah Pajak",
		"SalesOrder.total":"Grand Total",
		"Customer Additional Info":"Informasi Tambahan Pelanggan",
		"SalesOrder.salesOrderId":"Order Penjualan",
		"SalesOrder.salesOrder":"Order Penjualan",
		"SalesOrder.picName":"Nama PIC",
		"SalesOrder.picPhone":"Kontak PIC",
		"SalesOrder.picEmailAddress":"Email PIC",
		"SalesOrder.picPosition":"Posisi PIC",
		"SalesOrder.shippingAddress":"Alamat Pengiriman",
		"SalesOrder.shippingCityId":"Kota Pengiriman",
		"SalesOrder.shippingCity":"Kota Pengiriman",
		"SalesOrder.shippingProvinceId":"Provinsi Pengiriman",
		"SalesOrder.shippingProvince":"Provinsi Pengiriman",
		"SalesOrder.shippingPostalCode":"Kode Pos Pengiriman",
		"SalesOrder.latitude":"Latitude",
		"SalesOrder.longitude":"Longitude",
		"Other Information":"Informasi Lain",
		"Other Information":"Informasi Lain",
		"SalesOrder.userId":"User Pelanggan",
		"SalesOrder.user":"User Pelanggan",
		"SalesOrder.state":"Status",
		"SalesOrder.paymentMethod":"Metode Pembayaran",
		//Sales Order Line
		"SalesOrderLine":"Baris Order Penjualan",
		"sales_order_line":"Baris Order Penjualan",
		"SalesOrderLine.name":"Nomor",
		"SalesOrderLine.itemId":"Barang",
		"SalesOrderLine.item":"Barang",
		"SalesOrderLine.additionalCharges":"Tambahan",
		"SalesOrderLine.startDate":"Tanggal Mulai",
		"SalesOrderLine.endDate":"Tanggal Selesai",
		"SalesOrderLine.quantity":"Jumlah",
		"SalesOrderLine.unitId":"Satuan",
		"SalesOrderLine.unit":"Satuan",
		"SalesOrderLine.unitPrice":"Harga",
		"SalesOrderLine.discountPercentage":"Diskon (%)",
		"SalesOrderLine.discountAmount":"Nilai Diskon",
		"SalesOrderLine.subtotal":"Subtotal",
		"SalesOrderLine.taxes":"Daftar Pajak",
		"SalesOrderLine.taxAmount":"Jumlah Pajak",
		"SalesOrderLine.total":"Total",
		"SalesOrderLine.accountSplitId":"Pemisahan Akun",
		"SalesOrderLine.accountSplit":"Pemisahan Akun",
		"SalesOrderLine.vendorId":"Pemasok",
		"SalesOrderLine.vendor":"Pemasok",
		"SalesOrderLine.multiplier":"Pengali",
		//sales Order - Cancellation
		"SalesOrder.cancellationCategory":"Kategori Pembatalan",
		"SalesOrder.cancellationReason":"Alasan Pembatalan",
		//Sales Order Rejection
		"SalesOrderRejection":"Penolakan Order",
		"SalesOrderRejection.RejectOrder":"Tolak Order",
		"SalesOrderRejection.RejectionCategory":"Kategori Penolakan",
		"SalesOrderRejection.RejectionReason":"Alasan Penolakan",
		//sales order - customer requested a moderation
		"customerReqestedModeration":"Pelanggan meminta moderasi",
		"SalesOrder.moderationCategory":"Kategori Moderasi",
		"SalesOrder.moderationMessage":"Pesan Moderasi",
		"SalesOrder.callCustomer":"Hubungi Pelanggan",
		"SalesOrder.moderationResolve":"Moderasi Selesai",
		"SalesOrder.BeingModerated":"Pesanan sedang dimoderasi",
		"SalesOrder.BeingModeratedDesc":"Silahkan tunggu sampai admin menyelesaikan moderasi",
		//sales order - history
		"SalesOrder.history":"Riwayat",
		"SalesOrder.history.salesOrderDate":"Tanggal Pesan",
		"SalesOrder.history.offerDate":"Tanggal Ditawarkan",
		"SalesOrder.history.waitingForApprovalDate":"Tanggal Menunggu Ditawarkan",
		"SalesOrder.history.paymentDate":"Tanggal Pembayaran",
		"SalesOrder.history.confirmationDate":"Tanggal Dikonfirmasi",
		"SalesOrder.history.vendorAcceptanceDate":"Tanggal Disetujui Vendor",
		"SalesOrder.history.deliveryDate":"Tanggal Dikirim",
		"SalesOrder.history.deliveredDate":"Tanggal Terkirim",
		"SalesOrder.history.completionDate":"Tanggal Selesai",
		"SalesOrder.history.rejectionDate":"Tanggal Ditolak",
		"SalesOrder.history.cancellationDate":"Tanggal Dibatalkan",


		//Account Split
		"AccountSplit":"Penghapusan Tagihan Voucher",
		"account_split":"Penghapusan Tagihan Voucher",
		"AccountSplit.lines":"Daftar Penghapusan",
		//Account Split Line
		"AccountSplitLine":"Item Penghapusan Tagihan Voucher",
		"account_split_line":"Item Penghapusan Tagihan Voucher",
		"AccountSplitLine.partnerId":"Partner",
		"AccountSplitLine.partner":"Partner",
		"AccountSplitLine.allocated":"Nilai",
		//Sales Order Line Extend Request
		"SalesOrderLineExtendRequest":"Perpanjangan Order Penjualan",
		"sales_order_line_extend_request":"Perpanjangan Order Penjualan",
		"SalesOrderLineExtendRequest.name":"Nomor",
		"Sales Information":"Informasi Penjualan",
		"SalesOrderLineExtendRequest.salesOrderId":"Order Penjualan",
		"SalesOrderLineExtendRequest.salesOrder":"Order Penjualan",
		"SalesOrderLineExtendRequest.itemId":"Barang",
		"SalesOrderLineExtendRequest.item":"Barang",
		"SalesOrderLineExtendRequest.partnerId":"Pelanggan",
		"SalesOrderLineExtendRequest.partner":"Pelanggan",
		"SalesOrderLineExtendRequest.vendorId":"Pemasok",
		"SalesOrderLineExtendRequest.vendor":"Pemasok",
		"SalesOrderLineExtendRequest.startDate":"Tanggal Mulai",
		"SalesOrderLineExtendRequest.endDate":"Tanggal Selesai",
		"Contact And Location":"Kontak Dan Lokasi",
		"SalesOrderLineExtendRequest.picName":"Nama PIC",
		"SalesOrderLineExtendRequest.picPhone":"Kontak PIC",
		"SalesOrderLineExtendRequest.picEmailAddress":"Email PIC",
		"SalesOrderLineExtendRequest.picPosition":"Alamat PIC",
		"SalesOrderLineExtendRequest.ssid":"Nomor KTP",
		"SalesOrderLineExtendRequest.name":"Alamat Pengiriman",
		"SalesOrderLineExtendRequest.shippingCityId":"Kota Pengiriman",
		"SalesOrderLineExtendRequest.shippingCity":"Kota Pengiriman",
		"SalesOrderLineExtendRequest.shippingProvinceId":"Provinsi Pengiriman",
		"SalesOrderLineExtendRequest.shippingProvince":"Provinsi Pengiriman",
		"SalesOrderLineExtendRequest.name":"Kode Pos",
		"SalesOrderLineExtendRequest.latitude":"Latitude",
		"SalesOrderLineExtendRequest.longitude":"Longitude",
		"SalesOrderLineExtendRequest.state":"Status",
		//Vendor Delivery Request
		"VendorDeliveryRequest":"Permintaan Pengiriman Pemasok",
		"vendor_delivery_request":"Permintaan Pengiriman Pemasok",
		"vendor_delivery_request.draft":"Draf",
		"VendorDeliveryRequest.confirmTitle.action/draft":"Draf",
		"VendorDeliveryRequest.confirmContent.action/draft":"Apakah Anda yakin ingin membalikkan statusnya menjadi draf?",
		"VendorDeliveryRequest.success.action/draft":"Permintaan Pengiriman Pemasok berhasil diubah statusnya menjadi draf",
		"vendor_delivery_request.accept":"Terima Permintaan",
		"VendorDeliveryRequest.confirmTitle.action/accept":"Terima Permintaan",
		"VendorDeliveryRequest.confirmContent.action/accept":"Apakah Anda yakin ingin menerima Permintaan Pengiriman ini?",
		"VendorDeliveryRequest.success.action/accept":"Permintaan Pengiriman telah berhasil diterima",
		"vendor_delivery_request.Approve":"Setujui",
		"VendorDeliveryRequest.confirmTitle.action/approve":"Setujui",
		"VendorDeliveryRequest.confirmContent.action/approve":"Apakah Anda yakin ingin menyetujui Permintaan Pengiriman ini?",
		"VendorDeliveryRequest.success.action/approve":"Permintaan Pengiriman berhasil disetujui",
		"vendor_delivery_request.Ready":"Siap Dikirim",
		"VendorDeliveryRequest.confirmTitle.action/ready":"Siap Dikirim",
		"VendorDeliveryRequest.confirmContent.action/ready":"Apakah Barang yang diminta sudah siap dikirim?",
		"VendorDeliveryRequest.success.action/ready":"Barang telah siap dikirim",
		"vendor_delivery_request.reject":"Tolak Permintaan",
		"VendorDeliveryRequest.confirmTitle.action/reject":"Tolak Permintaan",
		"VendorDeliveryRequest.confirmContent.action/reject":"Apakah Anda yakin ingin menolak Permintaan Pengiriman ini?",
		"VendorDeliveryRequest.success.action/reject":"Permintaan Pengiriman telah berhasil ditolak",
		"vendor_delivery_request.ongoing":"Dalam Perjalanan",
		"VendorDeliveryRequest.confirmTitle.action/ongoing":"Dalam Perjalanan",
		"VendorDeliveryRequest.confirmContent.action/ongoing":"Apakah Anda yakin ingin mengubah statusnya menjadi sedang dalam perjalanan?",
		"VendorDeliveryRequest.success.action/ongoing":"Status Permintaan Pengiriman telah berhasil diubah",
		"vendor_delivery_request.delivered":"Tiba Di Lokasi",
		"VendorDeliveryRequest.confirmTitle.action/delivered":"Tiba Di Lokasi",
		"VendorDeliveryRequest.confirmContent.action/delivered":"Apakah Anda yakin ingin mengubah statusnya menjadi tiba dilokasi?",
		"VendorDeliveryRequest.success.action/delivered":"Status Permintaan Pengiriman telah berhasil diubah",
		"vendor_delivery_request.cancel":"Batalkan",
		"VendorDeliveryRequest.confirmTitle.action/cancel":"Batalkan",
		"VendorDeliveryRequest.confirmContent.action/cancel":"Apakah Anda yakin ingin membatalkan Permintaan Pengiriman Pemasok ini?",
		"VendorDeliveryRequest.success.action/cancel":"Permintaan Pengiriman Pemasok telah berhasil dibatalkan",
		"VendorDeliveryRequest.name":"Nomor",
		"VendorDeliveryRequest.reference":"Referensi",
		"VendorDeliveryRequest.date":"Tanggal",
		"VendorDeliveryRequest.periodId":"Periode",
		"VendorDeliveryRequest.period":"Periode",
		"VendorDeliveryRequest.vendorId":"Vendor",
		"VendorDeliveryRequest.vendor":"Vendor",
		"VendorDeliveryRequest.alias":"Alias",
		"VendorDeliveryRequest.branchId":"Cabang",
		"VendorDeliveryRequest.branch":"Cabang",
		"VendorDeliveryRequest.note":"Catatan",
		"Items":"Daftar Barang",
		"Items":"Daftar Barang",
		"VendorDeliveryRequest.lines":"Daftar Barang",
		"VendorDeliveryRequest.totalDiscount":"Diskon Total",
		"VendorDeliveryRequest.subtotal":"Subtotal",
		"VendorDeliveryRequest.totalTax":"Total Setelah Pajak",
		"VendorDeliveryRequest.total":"Grand Total",
		"Customer Additional Info":"Informasi Tambahan Pelanggan",
		"Customer Additional Info":"Informasi Tambahan Pelanggan",
		"VendorDeliveryRequest.salesOrderId":"Order Penjualan",
		"VendorDeliveryRequest.salesOrder":"Order Penjualan",
		"VendorDeliveryRequest.picName":"Nama PIC",
		"VendorDeliveryRequest.picPhone":"Kontak PIC",
		"VendorDeliveryRequest.picEmailAddress":"Email PIC",
		"VendorDeliveryRequest.picPosition":"Posisi PIC",
		"VendorDeliveryRequest.shippingAddress":"Alamat Pengiriman",
		"VendorDeliveryRequest.shippingCityId":"Kota Pengiriman",
		"VendorDeliveryRequest.shippingCity":"Kota Pengiriman",
		"VendorDeliveryRequest.shippingProvinceId":"Provinsi Pengiriman",
		"VendorDeliveryRequest.shippingProvince":"Provinsi Pengiriman",
		"VendorDeliveryRequest.shippingPostalCode":"Kode Pos Pengiriman",
		"VendorDeliveryRequest.latitude":"Latitude",
		"VendorDeliveryRequest.longitude":"Longitude",
		"Other Information":"Informasi Lain",
		"Other Information":"Informasi Lain",
		"VendorDeliveryRequest.userId":"User Pelanggan",
		"VendorDeliveryRequest.user":"User Pelanggan",
		"VendorDeliveryRequest.state":"Status",
		// vendor delivery request - history
		"VendorDeliveryRequest.history":"Riwayat",
		"VendorDeliveryRequest.history.waitingForApprovalDate":"Tanggal Menunggu Disetujui",
		"VendorDeliveryRequest.history.confirmedDate":"Tanggal Dikonfirmasi",
		"VendorDeliveryRequest.history.readyForDeliveryDate":"Tanggal Siap Dikirim",
		"VendorDeliveryRequest.history.ongoingDeliveryDate":"Tanggal Pengiriman",
		"VendorDeliveryRequest.history.deliveredDate":"Tanggal Dikirim",
		"VendorDeliveryRequest.history.rejectedDate":"Tanggal Ditolak",
		"VendorDeliveryRequest.history.cancelledDate":"Tanggal Dibatalkan",

		//Vendor Delivery Request Line
		"VendorDeliveryRequestLine":"Baris Permintaan Pengiriman Vendor",
		"vendor_delivery_request_line":"Baris Permintaan Pengiriman Vendor",
		"VendorDeliveryRequestLine.name":"Nomor",
		"VendorDeliveryRequestLine.itemId":"Barang",
		"VendorDeliveryRequestLine.item":"Barang",
		"VendorDeliveryRequestLine.additionalCharges":"Tambahan",
		"VendorDeliveryRequestLine.startDate":"Tanggal Mulai",
		"VendorDeliveryRequestLine.endDate":"Tanggal Selesai",
		"VendorDeliveryRequestLine.quantity":"Jumlah",
		"VendorDeliveryRequestLine.unitId":"Satuan",
		"VendorDeliveryRequestLine.unit":"Satuan",
		"VendorDeliveryRequestLine.unitPrice":"Harga",
		"VendorDeliveryRequestLine.discountPercentage":"Diskon (%)",
		"VendorDeliveryRequestLine.discountAmount":"Nilai Diskon",
		"VendorDeliveryRequestLine.subtotal":"Subtotal",
		"VendorDeliveryRequestLine.taxes":"Daftar Pajak",
		"VendorDeliveryRequestLine.taxAmount":"Jumlah Pajak",
		"VendorDeliveryRequestLine.total":"Total",
		//Advance Payment Request
		"AdvancePaymentRequest":"Order Penjualan",
		"advance_payment_request":"Order Penjualan",
		"advance_payment_request.draft":"Draf",
		"AdvancePaymentRequest.confirmTitle.action/draft":"Draf",
		"AdvancePaymentRequest.confirmContent.action/draft":"Draf?",
		"AdvancePaymentRequest.success.action/draft":"Order Penjualan berhasil",
		"advance_payment_request.confirm":"Konfirmasi",
		"AdvancePaymentRequest.confirmTitle.action/confirm":"Konfirmasi",
		"AdvancePaymentRequest.confirmContent.action/confirm":"Apakah Anda yakin ingin mengkonfirmasi Order Penjualan ini?",
		"AdvancePaymentRequest.success.action/confirm":"Order Penjualan telah berhasil dikonfirmasi",
		"advance_payment_request.cancel":"Batalkan",
		"AdvancePaymentRequest.confirmTitle.action/cancel":"Batalkan",
		"AdvancePaymentRequest.confirmContent.action/cancel":"Apakah Anda yakin ingin membatalkan Order Penjualan ini?",
		"AdvancePaymentRequest.success.action/cancel":"Order Penjualan telah berhasil dibatalkan",
		"AdvancePaymentRequest.name":"Nomor",
		"AdvancePaymentRequest.partnerId":"Partner",
		"AdvancePaymentRequest.partner":"Partner",
		"AdvancePaymentRequest.salesOrderId":"Order Penjualan",
		"AdvancePaymentRequest.salesOrder":"Order Penjualan",
		"AdvancePaymentRequest.voucherId":"Penerimaan",
		"AdvancePaymentRequest.voucher":"Penerimaan",
		"AdvancePaymentRequest.token":"Token",
		"AdvancePaymentRequest.state":"Status",

		//Bank
		"Bank":"Bank",
		"Bank.name":"Nama",
		"Bank.country":"Negara",
		"Bank.state":"Status",
		"Bank.confirmTitle.action/activate":"Aktifkan",
		"Bank.confirmContent.action/activate":"Apa Anda yakin akan mengkaktifkan bank ini?",
		"Bank.success.action/activate":"Bank telah diaktifkan",
		"Bank.confirmTitle.action/deactivate":"Nonaktifkan",
		"Bank.confirmContent.action/deactivate":"Apa Anda yakin akan menonaktifkan bank ini?",
		"Bank.success.action/deactivate":"Bank telah dinonaktifkan",
		
		//Finance AP withdraw request
		"FinanceAPWithdrawRequest":"Permintaan Penarikan",
		"WithdrawRequest":"Permintaan Penarikan",
		"withdraw_request":"Permintaan Penarikan",
		"WithdrawRequest.name":"Nomor",
		"WithdrawRequest.state":"Status",
		"WithdrawRequest.date":"Tanggal",
		"WithdrawRequest.period":"Periode",
		"WithdrawRequest.voucher":"Voucher",
		"WithdrawRequest.user":"Pengguna",
		"WithdrawRequest.amount":"Jumlah",
		"WithdrawRequest.lines":"List Tagihan",
		"WithdrawRequest.paymentDate":"Tanggal Pembayaran",
		"WithdrawRequest.paymentType":"Tipe Pembayaran",
		"WithdrawRequest.lines":"List Tagihan",
		"WithdrawRequestLine.invoice":"Tagihan",
		"WithdrawRequestLine.balance":"Saldo",
		"WithdrawRequest.pay":"Bayar",
		"WithdrawRequest.reject":"Tolak",
		"WithdrawRequest.confirm":"Konfirmasi",
		"WithdrawRequest.cancel":"Batalkan",
		"WithdrawRequest.force_cancel":"Batal Paksa",
		"WithdrawRequest.confirmTitle.action/pay":"Bayar",
		"WithdrawRequest.confirmTitle.action/reject":"Tolak",
		"WithdrawRequest.confirmTitle.action/confirm":"Konfirmasi",
		"WithdrawRequest.confirmTitle.action/cancel":"Batalkan",
		"WithdrawRequest.confirmTitle.action/force_cancel":"Batal Paksa",
		"WithdrawRequest.confirmContent.action/pay":"Silakan masukan tanggal dan tipe pembayaran.",
		"WithdrawRequest.confirmContent.action/reject":"Apa Anda yakin akan menolak permintaan ini?",
		"WithdrawRequest.confirmContent.action/confirm":"Apa Anda yakin akan mengkonfirmasi permintaan ini?",
		"WithdrawRequest.confirmContent.action/cancel":"Apa Anda yakin akan membatalkan permintaan ini?",
		"WithdrawRequest.confirmContent.action/force_cancel":"Apa Anda yakin akan membatalkan paksa permintaan ini?",
		"WithdrawRequest.success.action/pay":"Permintaan telah sukses dibayarkan",
		"WithdrawRequest.success.action/reject":"Permintaan telah sukses ditolak",
		"WithdrawRequest.success.action/confirm":"Permintaan telah sukses dikonfirmasi",
		"WithdrawRequest.success.action/cancel":"Permintaan telah sukses dibatalkan",
		"WithdrawRequest.success.action/force_cancel":"Permintaan sukses dibatalkan paksa",

		// Item Import
		"ItemImport":"Impor Data Barang",
		"AccountingConfigurationItemImport":"Impor Data Barang",
		"ItemImport.file":"Berkas Excel (.xlsx)",
		"ItemImport.updateStock":"Perbarui Stok",
		"ItemImport.upload":"Unggah",
		"ItemImport.uploadSuccess":"Berkas telah berhasil diunggah",
		"ItemImport.uploadFailed":"Gagal mengunggah Berkas, silakan cek koneksi internet Anda.",

		// Item Import
		"PriceImport":"Impor Data Harga",
		"AccountingConfigurationPriceImport":"Impor Data Harga",
		"PriceImport.file":"Berkas Excel (.xlsx)",
		"PriceImport.updateStock":"Perbarui Stok",
		"PriceImport.upload":"Unggah",
		"PriceImport.download":"Unduh",
		"PriceImport.update":"Perbarui Harga",
		"PriceImport.uploadSuccess":"Berkas telah berhasil diunggah",
		"Priceimport.uploadFailed":"Gagal mengunggah Berkas, silakan cek koneksi internet Anda.",
		"PriceImport.uploadSuccess":"Data barang telah berhasil diperbarui",
		"Priceimport.uploadFailed":"Gagal memperbarui data barang, silakan cek koneksi internet Anda.",
		"PriceImport.itemCode": "Kode Barang",
		"PriceImport.itemName": "Nama Barang",
		"PriceImport.branchCode": "Cabang",
		"PriceImport.unitName": "Satuan",
		"PriceImport.priceBefore": "Harga Sebelum",
		"PriceImport.priceAfter": "Harga Sesudah",
		"PriceImport.changePercentage": "% Perubahan",
		"PriceImport.purchasePrice": "Harga Beli",
		"PriceImport.profitMargin": "Margin Keuntungan",

		// Promotion
		"Promotion":"Promosi",
		"Promotion.generalInfo":"Informasi Umum",
		"Promotion.conditions":"Kondisi",
		"Promotion.limits":"Batasan",
		"Promotion.name":"Nama",
		"Promotion.code":"Kode",
		"Promotion.dateStart":"Tanggal Mulai",
		"Promotion.dateEnd":"Tanggal Akhir",
		"Promotion.discountType":"Tipe Diskon",
		"Promotion.discountAmount":"Jumlah Nominal Diskon",
		"Promotion.discountPercentage":"Diskon (%)",
		"Promotion.items":"Daftar Barang",
		"Promotion.marketplaces":"Daftar MarketPlace",
		"Promotion.branches":"Daftar Cabang",
		"Promotion.total":"Total",
		"Promotion.subtotal":"Subtotal",
		"Promotion.totalTax":"Total Pajak",
		"Promotion.maxPerTransaction":"Maksimum per Transaksi",
		"Promotion.limit":"Batas Pemakaian",
		"Promotion.balance":"Sisa",
		"Promotion.totalDiscount":"Total Diskon",
		"Promotion.schedule":"Jadwal",
		"Promotion.state":"Status",
		"Promotion.maxItems":"Jumlah Maksimum",
		"Promotion.minQuantity":"Jml Min.",
		"Promotion.minSubtotal":"Subtotal Minimum",
		"Promotion.maxDiscountAmount":"Total Diskon Maksimum",
		"Promotion.memberOnly":"Khusus Member",
		"Promotion.accounting":"Akunting",
		"Promotion.activate":"Aktifkan",
		"Promotion.deactivate":"Nonaktifkan",
		"Promotion.confirmTitle.action/activate":"Aktifkan",
		"Promotion.confirmContent.action/activate":"Apakah Anda yakin ingin mengaktifkan Promo ini?",
		"Promotion.success.action/activate":"Promo telah berhasil diaktifkan",
		"Promotion.confirmTitle.action/deactivate":"Nonaktifkan",
		"Promotion.confirmContent.action/deactivate":"Apakah Anda yakin ingin menonaktifkan Promo ini?",
		"Promotion.success.action/deactivate":"Promo telah berhasil dinonaktifkan",
		

		"PromotionItemLine.item":"Barang",
		"PromotionItemLine.minQuantity":"Jml min.",
		"PromotionItemLine.unit":"Satuan",
		"MarketplacePromotion.marketplace":"Marketplace",
		"BranchPromotion.branch":"Cabang",
		"total_discount":"Diskon Total",
		"percentage_discount":"Diskon Persentase",
		"per_item_discount":"Diskon Per-Item",

		//Inventory Conversion
		"InventoryConversion":"Konversi Persediaan",
		"InventoryConversion.originalInventory":"Persediaan Asal",
		"InventoryConversion.convertedInventory":"Persediaan Konversi",
		"InventoryConversion.name":"Nomor",
		"InventoryConversion.date":"Tanggal",
		"InventoryConversion.item":"Barang",
		"InventoryConversion.unit":"Satuan",
		"InventoryConversion.targetUnit":"Satuan Tujuan",
		"InventoryConversion.branch":"Cabang",
		"InventoryConversion.warehouse":"Gudang",
		"InventoryConversion.location":"Lokasi",
		"InventoryConversion.targetBranch":"Cabang Tujuan",
		"InventoryConversion.targetWarehouse":"Gudang Tujuan",
		"InventoryConversion.targetLocation":"Lokasi Tujuan",
		"InventoryConversion.currentQuantity":"Kuantitas Saat Ini",
		"InventoryConversion.convertedQuantity":"Kuantitas Konversi",
		"InventoryConversion.conversionResult":"Hasil Konversi",
		"InventoryConversion.state":"Status",
		"InventoryConversion.conversionRate":"Rasio Konversi",
		"InventoryConversion.desc":"Deskripsi",
		"InventoryConversion.confirmTitle.action/confirm/in":"Konfirmasi Masuk",
		"InventoryConversion.confirmTitle.action/confirm/out":"Konfirmasi keluar",
		"InventoryConversion.confirmTitle.action/confirm":"Konfirmasi",
		"InventoryConversion.confirmContent.action/confirm/in":"Apa Anda yakin akan mengkonfirmasi masuk konversi persediaan ini?",
		"InventoryConversion.confirmContent.action/confirm/out":"Apa Anda yakin akan mengkonfirmasi keluar konversi persediaan ini?",
		"InventoryConversion.confirmContent.action/confirm":"Apa Anda yakin akan mengkonfirmasi konversi persediaan ini?",
		"InventoryConversion.success.action/confirm/in":"Konversi persediaan berhasil dikonfirmasi masuk.",
		"InventoryConversion.success.action/confirm/out":"Konversi persediaan berhasil dikonfirmasi keluar.",
		"InventoryConversion.success.action/confirm":"Konversi persediaan berhasil dikonfirmasi",

		//Price Config
		"PriceConfig":"Harga Barang",
		"ItemPriceConfig":"Harga Barang",
		"PriceConfig.name":"Nama Barang",
		"PriceConfig.unit":"Satuan",
		"PriceConfig.category":"Kategori",
		"PriceConfig.branch":"Cabang",
		"PriceConfig.price":"Harga",
		"PriceConfig.purchasePrice":"Harga Beli",
		"PriceConfig.minimumPrice":"Harga Min.",
		"PriceConfig.allCategories":"Semua Kategori",
		"PriceConfig.allUnits":"Semua Satuan",
		"PriceConfig.allBranches":"Semua Cabang",
		"PriceConfig.bulkEdit":"Ubah Serentak",
		"PriceConfig.save":"Simpan",
		"PriceConfig.cancel":"Batal",
		"PriceConfig.changePriceForXitems":"Ubah harga untuk %s data terpilih",
		"PriceConfig.changesWaiting":"Perubahan yang Anda buat sedang menunggu untuk disetujui",

		//Instant Purchase Item Prices
		"InstantPurchaseItemPricesReport":"Harga Beli",
		"PurchasePrices.name":"Nomor",
		"PurchasePrices.date":"Tanggal",
		"PurchasePrices.quantity":"Jumlah",
		"PurchasePrices.price":"Harga Beli",
		"PurchasePrices.measurementUnit":"Satuan",
		"PurchasePrices.partner":"Supplier",
		"PurchasePrices.location":"Lokasi",
		"PurchasePrices.searchItem":"Cari Barang",
		
		//RequestApproval
		"RequestApproval":"Persetujuan Permintaan",
		"RequestApproval.active":"Aktif",
		"RequestApproval.waiting":"Menunggu",
		"RequestApproval.history":"Riwayat",
		"RequestApproval.confirm":"Konfirmasi",
		"RequestApproval.allClear":"Selesai",
		"RequestApproval.yourRequests":"Permintaan Anda",
		"RequestApproval.noRequest":"Belum ada permintaan baru saat ini",
		"RequestApproval.processed":"Diproses",
		"RequestApproval.pending":"Pending",
		"RequestApproval.item.request_price_save":"Ubah harga %s dari %oldPrice ke %newPrice",
		"RequestApproval.item.request_prices_bulk_save":"Ubah beberapa harga barang ke %newPrice",
		"RequestApproval.complete.confirm":"Permintaan berhasil dikonfirmasi",
		"RequestApproval.complete.reject":"Permintaan berhasil ditolak",

		//Inventory Conversion 
		"InventoryConversionReport":"Konversi Persediaan",
		"InventoryConversionReport.printRequest":"Mencetak Laporan...",

		//Fund Handover
		"FundHandover":"Penyerahan Dana",
		"fund_handover":"Penyerahan Dana",
		"FundHandover.xName":"Nomor",
		"FundHandover.xAccountFrom":"Saya menyerahkan dana dari",
		"FundHandover.xAccountTo":"Yang bertanggung jawab atas akun",
		"FundHandover.xTotal":"Sejumlah",
		"FundHandover.xUser":"Kepada",
		"FundHandover.name":"Nomor",
		"FundHandover.accountFrom":"Akun Pengirim",
		"FundHandover.accountTo":"Akun Penerima",
		"FundHandover.currentBalance":"Saldo Saat Ini",
		"FundHandover.total":"Total Diserahkan",
		"FundHandover.user":"Penerima",
		"FundHandover.creator":"Yang Mengajukan",
		"FundHandover.state":"Status",
		"FundHandover.draft":"Draft",
		"FundHandover.description":"Deskripsi",
		"FundHandover.requestDate":"Tanggal Pengajuan",
		"FundHandover.approveDate":"Tanggal Penyetujuan",
		"FundHandover.request":"Ajukan",
		"FundHandover.cancel":"Batalkan",
		"FundHandover.confirmTitle.action/draft":"Draft",
		"FundHandover.confirmContent.action/draft":"Apa Anda yakin akan membuat penyerahan dana ini menjadi draft?",
		"FundHandover.success.action/draft":"Penyerahan dana telah diubah menjadi draft",
		"FundHandover.confirmTitle.action/request":"Ajukan",
		"FundHandover.confirmContent.action/request":"Apa Anda yakin akan mengajukan penyerahan dana ini?",
		"FundHandover.success.action/request":"Penyerahan dana berhasil diajukan",
		"FundHandover.confirmTitle.action/confirm":"Batalkan",
		"FundHandover.confirmContent.action/confirm":"Apa Anda yakin akan membatalkan penyerahan dana ini?",
		"FundHandover.success.action/confirm":"Penyerahan dana berhasil dibatalkan",

		//Rak/Rack
		"Rack":"Rak",
		"rack":"Rack",
		"Rack.activate":"Aktifkan",
		"Rack.confirmTitle.activate":"Aktifkan",
		"Rack.confirmContent.activate":"Apa Anda yakin akan mengaktifkan rak ini?",
		"Rack.success.activate":"Rak telah diaktifkan",
		"Rack.deactivate":"Nonaktifkan",
		"Rack.confirmTitle.deactivate":"Nonaktifkan",
		"Rack.confirmContent.deactivate":"Apa Anda yakin akan menonaktifkan rak ini?",
		"Rack.success.deactivate":"Rak telah dinonaktifkan",
		"Rack.code":"Kode",
		"Rack.name":"Nama",
		"Rack.state":"Status",

		// Asset
		"Asset":"Aset",
		"Asset.code":"Kode",
		"Asset.name":"Nama Aset",
		"Asset.type":"Tipe",
		"Asset.method":"Metode Depresiasi",
		"Asset.startDate":"Tanggal Mulai",
		"Asset.description":"Deskripsi",
		"Asset.value":"Nilai",
		"Asset.residueValue":"Nilai Residu",
		"Asset.month":"Bulan",
		"Asset.estimatedMonth":"Perkiraan Bulan",
		"Asset.estimatedYear":"Perkiraan Tahun",
		"Asset.depreciationAccount":"Akun Depresiasi",
		"Asset.accumulationAccount":"Akun Akumulasi",
		"Asset.expenseAccount":"Akun Biaya",
		"Asset.state":"Status",
		"Asset.assetLines":"Daftar Depresiasi",
		"AssetLine.name":"Nomor",
		"AssetLine.accountMove":"Jurnal",
		"AssetLine.depreciationDate":"Tgl Depresiasi",
		"AssetLine.amount":"Jumlah",
		"AssetLine.description":"Deskripsi",
		"Asset.deprecate":"Depresiasikan Semua",
		"Asset.makeSchedule":"Buat Jadwal Depresiasi",
		"Asset.confirmTitle.action/deprecate":"Depresiasikan Semua",
		"Asset.confirmContent.action/deprecate":"Apa Anda yakin akan mendepresiasikan semua pada asset ini?",
		"Asset.success.action/deprecate":"Aset telah berhasil di depresiasi",
		"Asset.confirmTitle.action/makeSchedule":"Buat Jadwal Depresiasi",
		"Asset.confirmContent.action/makeSchedule":"Apa Anda yakin akan membuat jadwal depresiasi untuk aset ini?",
		"Asset.success.action/makeSchedule":"Jadwal depresiasi aset telah dibuat",

		//Packaging
		"Packaging":"Kemasan",
		"packaging":"Kemasan",
		"Packaging.name":"Nama",
		"Packaging.width":"Lebar",
		"Packaging.length":"Panjang",
		"Packaging.height":"Tinggi",
		"Packaging.weight":"Berat",
		"Packaging.state":"Status",
		"Packaging.activate":"Aktifkan",
		"Packaging.confirmTitle.action/activate":"Aktifkan",
		"Packaging.confirmContent.action/activate":"Apakah Anda yakin ingin mengaktifkan Kemasan ini?",
		"Packaging.success.action/activate":"Kemasan telah berhasil diaktifkan",
		"Packaging.deactivate":"Nonaktifkan",
		"Packaging.confirmTi`tle.action/deactivate":"Nonaktifkan",
		"Packaging.confirmContent.action/deactivate":"Apakah Anda yakin ingin menonaktifkan Kemasan ini?",
		"Packaging.success.action/deactivate":"Kemasan telah berhasil dinonaktifkan",

		//Courier
		"Courier":"Kurir",
		"country":"Kurir",
		"Courier.activate":"Aktifkan",
		"Courier.confirmTitle.action/activate":"Aktifkan",
		"Courier.confirmContent.action/activate":"Apa Anda yakin akan mengaktifkan kurir ini?",
		"Courier.success.action/activate":"Kurir berhasil diaktifkan",
		"Courier.deactivate":"Nonaktifkan",
		"Courier.confirmTitle.action/deactivate":"Nonaktifkan",
		"Courier.confirmContent.action/deactivate":"Apa Anda yakin akan mengaktifkan kurir ini?",
		"Courier.success.action/deactivate":"Kurir berhasil dinonaktifkan",
		"Courier.name":"Nama",
		"Courier.trackable":"Dapat Dilacak",
		"Courier.urlPrefix":"Prefiks URL",
		"Courier.urlSuffix":"Sufiks URL",
		"Courier.state":"Status",

		//Item Spec
		"ItemSpec":"Spesifikasi",
		"item_spec":"Spesifikasi Barang",
		"ItemSpec.name":"Nama",
		"ItemSpec.image":"Gambar",
		"ItemSpec.priority":"Prioritas",
		"ItemSpec.state":"Status",
		"ItemSpec.activate":"Aktifkan",
		"ItemSpec.deactivate":"Nonaktifkan",
		"ItemSpec.confirmTitle.action/activate":"",
		"ItemSpec.confirmTitle.action/deactivate":"",
		"ItemSpec.confirmContent.action/activate":"",
		"ItemSpec.confirmContent.action/deactivate":"",
		"ItemSpec.success.action/activate":"",
		"ItemSpec.success.action/deactivate":"",

		// Comission Report
		"CommissionReport":"Komisi",

		// Inventory Relocation Report
		"InventoryRelocationReport":"Perpindahan Persediaan",

		// Comission Report
		"WarningLog":"Catatan Perubahan",
		
		//MENUS
		"Accounting":"Akunting",
		"Journal Entries":"Jurnal",
		"AccountingJournalEntries":"Jurnal",
		"AccountingManualJournalEntries":"Jurnal Manual",
		"Exchange Rate":"Nilai Tukar",
		"AccountingExchangeRate":"Nilai Tukar",
		"Partner":"Partner",
		"AccountingPartner":"Partner",
		"Branch":"Cabang",
		"AccountingBranch":"Cabang",
		"Accounting Report":"Laporan Akunting",
		"General Ledger":"Buku Besar",
		"AccountingReportGeneralLedger":"Buku Besar",
		"Profit and Loss":"Laba Rugi",
		"AccountingReportProfitandLoss":"Laba Rugi",
		"Balance Sheet":"Neraca",
		"AccountingReportBalanceSheet":"Neraca",
		"Accounting Configuration":"Konfigurasi Akunting",
		"Brand":"Merk",
		"AccountingConfigurationBrand":"Merk",
		"Additional Charge":"Biaya Tambahan",
		"AccountingConfigurationAdditionalCharge":"Biaya Tambahan",
		"Tag":"Tag",
		"AccountingConfigurationTag":"Tag",
		"Account":"Akun",
		"AccountingConfigurationAccount":"Akun",
		"Account Group":"Kelompok Akun",
		"AccountingConfigurationAccountGroup":"Kelompok Akun",
		"Item":"Barang",
		"AccountingConfigurationItem":"Barang",
		"Item Category":"Kategori Barang",
		"AccountingConfigurationItemCategory":"Kategori Barang",
		"Unit":"Satuan",
		"AccountingConfigurationUnit":"Satuan",
		"Payment Types":"Metode Pembayaran",
		"AccountingConfigurationPaymentTypes":"Metode Pembayaran",
		"Taxes":"Pajak",
		"AccountingConfigurationTaxes":"Pajak",
		"Period":"Periode",
		"AccountingConfigurationPeriod":"Periode",
		"Fiscal":"Fiskal",
		"AccountingConfigurationFiscal":"Fiskal",
		"Policy":"Kebijakan",
		"AccountingConfigurationPolicy":"Kebijakan",
		"Currency":"Mata Uang",
		"AccountingConfigurationCurrency":"Mata Uang",
		"Finance AR":"Keuangan Piutang",
		"FinanceARInstantSales":"Penjualan Instan",
		"Delivery Order":"Surat Jalan",
		"FinanceARDeliveryOrder":"Surat Jalan",
		"Invoice":"Tagihan Masuk",
		"FinanceARInvoice":"Tagihan Keluar",
		"Collective Invoice":"Kontra Bon",
		"FinanceARCollectiveInvoice":"Kontra Bon",
		"Debit Note":"Nota Debit",
		"FinanceARDebitNote":"Nota Debit",
		"Receive":"Penerimaan",
		"FinanceARReceive":"Penerimaan",
		"Write Off":"Penghapusan Piutang",
		"FinanceARReturn":"Retur Penjualan",
		"FinanceARPayableClaim":"Penyesuaian Penjualan",
		"FinanceARWriteOff":"Penghapusan Piutang",
		"FinanceARWriteOff":"Penghapusan Piutang",
		"Advance Receive":"Penerimaan Di Muka",
		"FinanceARAdvanceReceive":"Penerimaan Di Muka",
		"Giro":"Giro",
		"FinanceARGiro":"Giro",
		"Customer":"Pelanggan",
		"FinanceARCustomer":"Pelanggan",
		"Finance AP":"Keuangan Utang",
		"Goods Receive Note":"Penerimaan Barang",
		"FinanceAPGoodsReceiveNote":"Penerimaan Barang",
		"Invoice":"Tagihan",
		"FinanceAPInvoice":"Tagihan Masuk",
		"Collective Invoice":"Kontra Bon",
		"FinanceAPCollectiveInvoice":"Kontra Bon",
		"Credit Note":"Nota Kredit",
		"FinanceAPCreditNote":"Nota Kredit",
		"Payment":"Pembayaran",
		"FinanceAPPayment":"Pembayaran",
		"PurchaseWriteOff":"Penghapusan Utang",
		"FinanceAPReturn":"Retur Pembelian",
		"FinanceAPPurchaseWriteOff":"Penghapusan Utang",
		"Advance Payment":"Pembayaran Di Muka",
		"FinanceAPAdvancePayment":"Pembayaran Di Muka",
		"Giro":"Giro",
		"FinanceAPGiro":"Giro",
		"Supplier":"Pemasok",
		"FinanceAPSupplier":"Pemasok",
		"AR Report":"Laporan Piutang",
		"Invoice":"Tagihan",
		"ARReportInvoice":"Tagihan",
		"Receive":"Penerimaan",
		"receive":"Penerimaan",
		"ARReportReceive":"Penerimaan",
		"Outstanding Giro":"Giro",
		"ARReportOutstandingGiro":"Giro",
		"Outstanding Delivery Order":"Surat Jalan Belum Tertagih",
		"ARReportOutstandingDeliveryOrder":"Surat Jalan Belum Tertagih",
		"Outstanding Invoice":"Tagihan Belum Terlunasi",
		"ARReportOutstandingInvoice":"Tagihan Belum Terlunasi",
		"Customer Credit Limit":"Plafon Pelanggan",
		"ARReportCustomerCreditLimit":"Plafon Pelanggan",
		"ARReportReturn": "Retur Penjualan",
		"AP Report":"Laporan Utang",
		"Invoice":"Tagihan",
		"APReportInvoice":"Tagihan",
		"Payment":"Pembayaran",
		"APReportPayment":"Pembayaran",
		"Outstanding Giro":"Giro",
		"APReportOutstandingGiro":"Giro",
		"Outstanding Goods Receive Note":"Penerimaan Barang Belum Tertagih",
		"APReportOutstandingGoodsReceiveNote":"Penerimaan Barang Belum Tertagih",
		"Outstanding Invoice":"Tagihan Belum Terlunasi",
		"APReportOutstandingInvoice":"Tagihan Belum Terlunasi",
		"APReportReturn": "Retur Pembelian",
		"Finance Config":"Konfigurasi Keuangan",
		"Term":"Term",
		"FinanceConfigTerm":"Term",
		"Token Movement":"Pergerakan Token",
		"FinanceConfigTokenMovement":"Pergerakan Token",
		"Warehouse":"Gudang",
		"Goods Receive Note":"Penerimaan Barang",
		"WarehouseGoodsReceiveNote":"Penerimaan Barang",
		"Delivery Order":"Surat Jalan",
		"WarehouseDeliveryOrder":"Surat Jalan",
		"WarehouseCrossBranchDeliveryOrder":"Surat Jalan Antar Cabang",
		"WarehouseCrossBranchDeliveryOrderActivity":"Surat Jalan Antar Cabang",
		"Return":"Retur",
		"WarehouseSalesReturn":"Retur Penjualan",
		"WarehousePurchaseReturn":"Retur Pembelian",
		"Material Release":"Pemakaian Bahan Baku",
		"WarehouseMaterialRelease":"Pemakaian Bahan Baku",
		"Production Result":"Hasil Produksi",
		"WarehouseProductionResult":"Hasil Produksi",
		"Inventory Issue":"Pemakaian Persediaan",
		"WarehouseInventoryIssue":"Pemakaian Persediaan",
		"Inventory Adjustment":"Penyesuaian Persediaan",
		"WarehouseInventoryAdjustment":"Penyesuaian Persediaan",
		"Inventory Relocation":"Perpindahan Persediaan",
		"WarehouseInventoryRelocation":"Perpindahan Persediaan",
		"Warehouse Report":"Laporan Gudang",
		"Item":"Barang",
		"WarehouseReportItem":"Pergerakan Barang",
		"Daily Stock":"Rekap Stok",
		"WarehouseReportDailyStock":"Rekap Stok",
		"DailyStockReport.itemState":"Status Barang",
		"Stock":"Stok",
		"WarehouseReportStock":"Mutasi Stok",
		"Goods Receive":"Surat Jalan",
		"WarehouseReportGoodsReceive":"Penerimaan Barang",
		"Delivery Order":"Penerimaan Barang",
		"WarehouseReportDeliveryOrder":"Surat Jalan",
		"Return":"Retur",
		"WarehouseReportReturn":"Retur",
		"Warehouse Config":"Konfigurasi Gudang",
		"Location":"Lokasi",
		"WarehouseConfigLocation":"Lokasi",
		"Warehouse":"Gudang",
		"WarehouseConfigWarehouse":"Gudang",
		"Item":"Barang",
		"WarehouseConfigItem":"Barang",
		"Item Category":"Kategori Barang",
		"WarehouseConfigItemCategory":"Kategori Barang",
		"Unit":"Satuan",
		"WarehouseConfigUnit":"Satuan",
		"Sales":"Penjualan",
		"Sales Order":"Pesanan",
		"SalesSalesOrder":"Pesanan",
		"SalesOrderOnline":"Pesanan Online",
		"Order Extend":"Perpanjang Order",
		"SalesOrderExtend":"Perpanjang Order",
		"Delivery Order":"Surat Jalan",
		"SalesDeliveryOrder":"Surat Jalan",
		"Customer":"Pelanggan",
		"SalesCustomer":"Pelanggan",
		"SalesMember":"Member",
		"Item":"Barang",
		"SalesItem":"Barang",
		"Item Category":"Kategori Barang",
		"SalesItemCategory":"Kategori Barang",
		"Unit":"Satuan",
		"SalesUnit":"Satuan",
		"SalesReport":"Laporan Penjualan",
		"SalesReportItemPricelist":"Daftar Harga",
		"ItemPricelist": "Daftar Harga",
		"ItemPriceSalesPurchase": "Harga Jual vs Beli",
		"ItemStatisticReport": "Statistik Barang",
		"Purchasing":"Pembelian",
		"Purchase Order":"Pesanan Pembelian",
		"PurchasingPurchaseOrder":"Pesanan Pembelian",
		"Goods Receive Note":"Penerimaan Barang",
		"PurchasingGoodsReceiveNote":"Penerimaan Barang",
		"Vendor Delivery Request":"Permintaan Pengiriman Pemasok",
		"PurchasingVendorDeliveryRequest":"Permintaan Pengiriman Pemasok",
		"Supplier":"Pemasok",
		"PurchasingSupplier":"Pemasok",
		"Item":"Barang",
		"PurchasingItem":"Barang",
		"Item Category":"Kategori Barang",
		"PurchasingItemCategory":"Kategori Barang",
		"Unit":"Satuan",
		"PurchasingUnit":"Satuan",
		"Purchasing Report":"Laporan Pembelian",
		"Outstanding Purchase Order":"Pembelian Belum Diterima",
		"PurchasingReportOutstandingPurchaseOrder":"Pembelian Belum Diterima",
		"Project":"Proyek",
		"Delivery Request":"Permintaan Pengiriman",
		"ProjectDeliveryRequest":"Permintaan Pengiriman",
		"Project":"Proyek",
		"ProjectProject":"Proyek",
		"Production":"Produksi",
		"Item Request":"Permintaan Barang",
		"ProductionItemRequest":"Permintaan Barang",
		"Production Result":"Hasil Produksi",
		"ProductionProductionResult":"Hasil Produksi",
		"HR":"SDM",
		"Employee":"Karyawan",
		"HREmployee":"Karyawan",
		"Administration":"Administrasi",
		"User":"Pengguna",
		"AdministrationUser":"Pengguna",
		"User Group":"Kelompok Pengguna",
		"AdministrationUserGroup":"Kelompok Pengguna",
		"Branch":"Cabang",
		"AdministrationBranch":"Cabang",
		"City":"Kota",
		"AdministrationCity":"Kota",
		"Province":"Provinsi",
		"AdministrationProvince":"Provinsi",
		"Country":"Negara",
		"AdministrationCountry":"Negara",
		"Node":"Titik",
		"AdministrationNode":"Titik",
		"Menu":"Menu",
		"AdministrationMenu":"Menu",
		"Menu Category":"Kategori Menu",
		"AdministrationMenuCategory":"Kategori Menu",
		"Access Right":"Hak Akses",
		"AdministrationAccessRight":"Hak Akses",
		"Access Right Category":"Kategori Hak Akses",
		"AdministrationAccessRightCategory":"Kategori Hak Akses",
		"Debug":"Debug",
		"Item Value":"Nilai Barang",
		"DebugItemValue":"Nilai Barang",
		"Inventory":"Persediaan",
		"DebugInventory":"Persediaan",
		"Inventory Move Plan":"Rencana Pergerakan Persediaan",
		"DebugInventoryMovePlan":"Rencana Pergerakan Persediaan",
		"PurchasingOutstandingPurchaseOrder": "Laporan Pembelian",
		"AccountingReportTaxNonTaxReport": "Persediaan Non Pajak",
		"AccountingNegativeInventoryValuationReport":"Persediaan Negatif",
		"TaxNonTaxReport": "Persediaan Non Pajak",
		"AccountingConfigurationItemPrice":"Harga Barang",
		"AccountingInventoryValuationReport":"Nilai Persediaan",
		"InventoryValuationReport":"Nilai Persediaan",
		"UserAccountBalanceReport":"Saldo Kas Pengguna",
		"APAgingReport":"Hutang Per Bulan",
		"ARAgingReport":"Piutang Per Bulan",
		"APDetailedReportActivity":"Pembayaran Detail",
		"ARDetailedReportActivity":"Penerimaan Detail",
		"APInvoiceMovementReport":"Mutasi Tagihan Masuk",
		"ARInvoiceMovementReport":"Mutasi Tagihan Keluar",
		"TrialBalance":"Neraca Saldo",
		"Procurement":"Pengadaan",
		"ItemRequestCompleted":"Permintaan Selesai",
		"ItemRequestCancelled":"Permintaan Batal",

		//OPTIONS
		"open":"Buka",
		"closed":"Ditutup",
		"active":"Aktif",
		"inactive":"Nonaktif",
		"assets":"Aset",
		"liabilities":"Liabilitas",
		"equities":"Ekuitas",
		"income_statement":"Laporan Laba/Rugi",
		"current_assets":"Aset Lancar",
		"fixed_assets":"Aset Tetap",
		"intangible_assets":"Aset Tak-Berwujud",
		"current_liabilities":"Liabilitas Lancar",
		"non-current_liabilities":"Non-Liabilitas Lancar",
		"capital":"Modal",
		"retained_earning":"Laba Ditahan",
		"profit/loss":"Laba/Rugi",
		"profit":"Laba",
		"loss":"Rugi",
		"sales":"Penjualan",
		"cost_of_goods_sold":"Biaya Barang Terjual",
		"other_operating_income":"Pendapatan Operasional Lainnya",
		"operating_expense":"Biaya Operasional",
		"other_income/expense":"Pemasukan/Pengeluaran Lainnya",
		"income_tax":"Pajak Penghasilan",
		"cash_bank":"Tunai / Bank",
		"cash_and_bank":"Tunai dan Bank",
		"cash_advance":"Kas Titipan",
		"deposits":"Deposito",
		"trade_receivable":"Piutang Dagang",
		"post_dated_cheque":"Cek Mundur",
		"non_trade_receivables":"Piutang Non-Dagang",
		"tax_receivable":"Piutang Pajak",
		"inventory":"Persediaan",
		"in-transit_inventories":"Persediaan dalam Perjalanan",
		"prepaid_assets":"Aset Prabayar",
		"advance_payment":"Pembayaran Dimuka",
		"land":"Tanah",
		"building":"Bangunan",
		"vehicle":"Kendaraan",
		"equipment":"Perlengkapan",
		"machine":"Mesin",
		"installation":"Instalasi",
		"establishment":"Pendirian",
		"software":"Perangkat Lunak (Software)",
		"goodwill":"Nama Baik",
		"short_term_bank_payable":"Utang Bank Jangka Pendek",
		"trade_payable":"Utang Dagang",
		"outstanding_cheque":"Cek Dalam Peredaran",
		"non_trade_payable":"Utang Non-Dagang",
		"tax_payable":"Utang pajak",
		"accrued_expense":"Beban Terutang",
		"other_allowances":"Tunjangan Lainnya",
		"unearned_revenue":"Pendapatan Dimuka",
		"current_maturities_of_long_term_debt":"Utang jangka panjang yang akan jatuh tempo ",
		"long-term_bank_payable":"Utang Bank Jangka Panjang",
		"long-term_non_trade_payable":"Utang Non-Dagang Jangka Panjang",
		"sales_adjustments":"Penyesuaian Penjualan",
		"other_operating_income_adjustment":"Penyesuaian Biaya Operasional Lain",
		"sales_expense":"Beban Penjualan",
		"administration_expense":"Beban Administrasi",
		"depreciation_expense":"Beban Depresiasi",
		"other_income":"Pendapayan Lainnya",
		"other_expense":"Beban Lainnya",
		"percentage":"Persentase",
		"amount":"Jumlah Nominal",
		"invoice":"Tagihan",
		"payment":"Pembayaran",
		"non-inventory":"Non-Persediaan",
		"services":"Jasa",
		"asset":"Aset",
		"others":"Lainnya",
		"raw_material":"Bahan Mentah",
		"support_material":"Bahan Penunjang",
		"WIP":"Proses Produksi",
		"producible":"Dapat Diproduksi",
		"finished_goods":"Barang Jadi",
		"other":"Lain-lain",
		"unposted":"Belum Diposting",
		"posted":"Diposting",
		"available":"Tersedia",
		"depleted":"Habis",
		"confirmed":"Dikonfirmasi",
		"draft":"Draf",
		"in":"Masuk",
		"out":"Keluar",
		"cancelled":"Dibatalkan",
		"confirmed_out":"Butuh Konfirmasi",
		"confirmed_in":"Selesai",
		"requested":"Diajukan",
		"fulfilled":"Terpenuhi",
		"ongoing":"Sedang Dikerjakan",
		"finished":"Selesai",
		"paid":"Terlunasi",
		"complete":"Selesai",
		"reconciled":"Terekonsiliasi",
		"purchase":"Pembelian",
		"credit_note":"Kredit Nota",
		"debit_note":"Debit Nota",
		"note":"Nota",
		"advance":"Di Muka",
		"return_payment":"Kembalikan Pembayaran",
		"liquidate":"Likuidasi",
		"cleared":"Cair",
		"sales_return":"Retur Penjualan",
		"purchase_return":"Retur Pembelian",
		"autofill":"Isi Otomatis",
		"RelatedDocuments":"Dokumen Terkait",
		"invoiced":"Tertagih",
		"action_do_print_without_price": "Cetak",
		"action_do_print_with_price": "Cetak (Rp)",
		"print_confirmation": "Konfirmasi Cetak",
		"print_confirmation_question": "Apakah Anda yakin akan mencetak dokumen ini?",
		"additional_discount": "Diskon Susulan",
		"sales_commission": "Komisi Penjualan",
		"open":"Dibuka",
		"hold":"Ditahan",
		"closed":"Ditutup",
		"active":"Aktif",
		"inactive":"Nonaktif",
		"undeveloped":"Undeveloped",
		"developing":"Developing",
		"developed":"Developed",
		"auto":"Auto",
		"floor":"Floor",
		"ceil":"Ceil",
		"nearest_auto":"Nearest Auto",
		"nearest_ceil":"Nearest Ceil",
		"nearest_floor":"Nearest Floor",
		"fixed":"Tetap",
		"variable":"Variable",
		"draft":"Draf",
		"invoiced":"Invoiced",
		"cancelled":"Dibatalkan",
		"penalized":"Penalized",
		"mailed":"Mailed",
		"confimed":"Dikonfirmasi",
		"requested":"Diajukan",
		"fulfilled":"Dipenuhi",
		"debit":"Debit",
		"credit":"Kredit",
		"left":"Kiri",
		"right":"Kanan",
		"plain":"Polos",
		"header":"Header",
		"footer":"Footer",
		"header_footer":"Header & Footer",
		"balance_sheet":"Neraca",
		"profit_loss":"Laba Rugi",
		"last_year":"Tahun Lalu",
		"current_year":"Tahun Berjalan",
		"current_month":"Bulan Berjalan",
		"draft":"Draf",
		"cancelled":"Dibatalkan",
		"consignment_sales":"Konsinyasi Penjualan",
		"consignment_purchase":"Konsinyasi Pembelian",
		"online":"Online",
		"credit_card":"Kartu Kredit",
		"manual":"Manual / Tunai",
		"bank_transfer":"Transfer Bank",
		"large":"Partai",
		"small":"Eceran",
		"low":"Rendah",
		"medium":"Sedang",
		"high":"Tinggi",
		"value":"Nilai",
		"straight_line":"Garis Lurus",
		"double_declining":"Pengurangan Ganda",
		"sum_of_the_year_digit":"Jumlah Digit Tahun",
		"slow_moving":"Lambat",
		"medium_moving":"Sedang",
		"fast_moving":"Cepat",
		"supervisor_approved":"Disetujui SPV",
		"manager_approved":"Disetujui Manager MD",
		

		//actions
		action_offer:"Beri Penawaran",
		action_accept:"Terima Permintaan",
		action_approve:"Setujui Permintaan",
		action_ready:"Siap Dikirim",
		action_reject:"Tolak",
		action_ongoing:"Dalam Perjalanan",
		action_delivered:"Tiba Dilokasi",
		action_payment:"Pembayaran",

		//inventory actions
		action_refresh_IMP:"Perbaiki RPB",
		"action_refresh_IMP.confirmTitle":"Perbaiki RPB",
		"action_refresh_IMP.confirmContent":"Memperbaiki RPB akan mengubah rencana pergerakan untuk semua barang di form ini ke settingan standar, perubahan pada RPB yang Anda buat akan hilang. Lanjutkan?",
		"action_refresh_IMP.success":"RPB untuk semua barang telah berhasil di set ke settingan standar, Silakan cek kembali sebelum menyimpan",

		//statuses
		waiting_for_approval: "Menunggu Persetujuan",
		vendor_accepted:"Disetujui Vendor",
		ready_for_delivery:"Siap Dikirim",
		ongoing_delivery: "Pengiriman Berlangsung",
		delivered: "Terkirim",
		complete:"Selesai",
		completed:"Selesai",
		draft:"Draf",
		rejected:"Ditolak",
		approved:"Disetujui",
		requested:"Diajukan",
		supervision:"Supervisi",
		"SalesOrder.cancelled":"Dibatalkan",
		"SalesOrder.rejected":"Ditolak",
		"SalesOrder.draft":"Draf",
		"SalesOrderOnline.reject":"Tolak",
		"SalesOrderOnline.accept":"Terima",
		"SalesOrderOnline.cancel":"Batalkan",
		"SalesOrderOnline.enterShippingCost":"Masukkan Ongkir",
		"SalesOrderOnline.confirmPayment":"Konfirmasi Pembayaran",
		"SalesOrderOnline.confirmShipment":"Konfirmasi Pengiriman",
		"SalesOrderOnline.completeOrder":"Selesaikan Penjualan",


		waiting_approval:"Butuh Persetujuan",
		waiting_shipping_cost:"Butuh Ongkir",
		waiting_payment:"Tunggu Bayar",
		waiting_payment_check:"Butuh Cek Bayar",
		waiting_shipment:"Butuh Kirim",
		shipped:"Dalam Perjalanan",
		arrived:"Terkirim",

		"offerDate":"Tanggal Diajukan",
		"approvalDate":"Tanggal Disetujui",
		"shippingCostOfferDate":"Tanggal Penetapan Ongkir",
		"paymentDate":"Tanggal Pembayaran",
		"paymentConfimedDate":"Tanggal Konfirmasi Pembayaran",
		"shipmentDate":"Tanggal Kirim",
		"arrivalDate":"Tanggal Terkirim",

		//errors
		"credit limit has been reached":"Batas kredit sudah tercapai",
		"still has incomplete invoice or due invoice":"Pelanggan ini memiliki tagihan yang belum lunas atau jatuh tempo",

	},
	en:{
		"accounting":"accounting",

		//Common Accounting Translation
		"Acct.vat":"VAT",
		"Acct.defaultVat":"Default VAT",
		"Acct.delayedVat":"Delayed VAT",
		"Acct.delayedVatDate":"Delayed VAT Date",

		//Accounting Policy
		"AccountingPolicy":"Accounting Policy",
		"accounting_policy":"Accounting Policy",
		"AccountingPolicy.code":"Code",
		"AccountingPolicy.name":"Name",
		"AccountingPolicy.value":"Value",
		//Account Fiscal
		"AccountFiscal":"Account Fiscal",
		"account_fiscal":"Account Fiscal",
		"AccountFiscal.fiscalYear":"Fiscal Year",
		"AccountFiscal.startDate":"Start Date",
		"AccountFiscal.endDate":"End Date",
		//Account Period
		"AccountPeriod":"Account Period",
		"account_period":"Account Period",
		"AccountPeriod.code":"Code",
		"AccountPeriod.name":"Name",
		"AccountPeriod.fiscalYearId":"Fiscal Year",
		"AccountPeriod.fiscalYear":"Fiscal Year",
		"AccountPeriod.dateStart":"Start Date",
		"AccountPeriod.dateStop":"End Date",
		"AccountPeriod.state":"State",

		"AccountPeriod.open":"Open",
		"AccountPeriod.confirmTitle.action/open":"Open",
		"AccountPeriod.confirmContent.action/open":"Are you sure want to open this period account?",
		"AccountPeriod.success.action/open":"Period account is successfully openend",

		"AccountPeriod.close":"Close",
		"AccountPeriod.confirmTitle.action/close":"Close",
		"AccountPeriod.confirmContent.action/close":"Are you sure want to close this period account?",
		"AccountPeriod.success.action/close":"Period account is successfully closed",

		"AccountPeriod.hold":"Hold",
		"AccountPeriod.confirmTitle.action/hold":"Hold",
		"AccountPeriod.confirmContent.action/hold":"Are you sure want to hold this period account?",
		"AccountPeriod.success.action/hold":"Period account is successfully held",

		//Account
		"Account":"Account",
		"account":"Account",
		"account.activate":"Activate",
		"Account.confirmTitle.action/activate":"Activate",
		"Account.confirmContent.action/activate":"Are you sure you want to activate this Account?",
		"Account.success.action/activate":"Account is successfully activated",
		"account.deactivate":"Deactivate",
		"Account.confirmTitle.action/deactivate":"Deactivate",
		"Account.confirmContent.action/deactivate":"Are you sure you want to deactivate this Account?",
		"Account.success.action/deactivate":"Account is successfully deactivated",
		"Account.name":"Name",
		"Account.code":"Code",
		"Account.position":"Position",
		"Account.category":"Category",
		"Account.type":"Type",
		"Account.defaultCurrencyId":"Default Currency",
		"Account.defaultCurrency":"Default Currency",
		"Account.parentId":"Parent",
		"Account.parent":"Parent",
		"Account.view":"View",
		"Account.state":"State",
		"Account.otherBalanceSource":"Alternate Balance Source",
		"Account.group":"Source Group",
		"Account.otherBalancePeriod":"Alternate Balance Period",
		"Account.accountHolder":"Account Holder",
		"Account.trialBalance":"Trial Balance",

		//Account Group
		"AccountGroup":"Account Group",
		"account_group":"Account Group",
		"account_group.activate":"Activate",
		"AccountGroup.confirmTitle.action/activate":"Activate",
		"AccountGroup.confirmContent.action/activate":"Are you sure you want to activate this Account Group?",
		"AccountGroup.success.action/activate":"Account Group is successfully activated",
		"account_group.deactivate":"Deactivate",
		"AccountGroup.confirmTitle.action/deactivate":"Deactivate",
		"AccountGroup.confirmContent.action/deactivate":"Are you sure you want to deactivate this Account Group?",
		"AccountGroup.success.action/deactivate":"Account Group is successfully deactivated",
		"AccountGroup.name":"Name",
		"AccountGroup.code":"Code",
		"AccountGroup.normalBalance":"Normal Balance",
		"AccountGroup.scontroColumn":"Scontro Column",
		"AccountGroup.renderType":"Render Type",
		"AccountGroup.lines":"Lines",
		"AccountGroup.expanded":"Expanded",
		"AccountGroup.showCode":"Show Code",
		"AccountGroup.sequence":"Sequence",
		"AccountGroup.report":"Report",
		"AccountGroup.state":"State",

		//Account Group Line
		"AccountGroupLine":"Account Group Line",
		"account_group_line":"Account Group Line",
		"AccountGroupLine.sequence":"Sequence",
		"AccountGroupLine.accountId":"Account",
		"AccountGroupLine.account":"Account",
		"AccountGroupLine.groupId":"Group",
		"AccountGroupLine.group":"Group",
		"AccountGroupLine.normalBalance":"Normal Balance",
		"AccountGroupLine.expanded":"Expanded",
		"AccountGroupLine.fetchLevel":"Level",
		"AccountGroupLine.skipLevel":"Skip",

		//Currency
		"Currency":"Currency",
		"currency":"Currency",
		"currency.activate":"Activate",
		"Currency.confirmTitle.action/activate":"Activate",
		"Currency.confirmContent.action/activate":"Are you sure you want to activate this Currency?",
		"Currency.success.activate":"Currency is successfully activated",
		"currency.deactivate":"Deactivate",
		"Currency.confirmTitle.action/deactivate":"Deactivate",
		"Currency.confirmContent.action/deactivate":"Are you sure you want to deactivate this Currency?",
		"Currency.success.deactivate":"Currency is successfully deactivated",
		"Basic Information":"Basic Information",
		"Currency.name":"Name",
		"Currency.code":"Code",
		"Currency.symbol":"Symbol",
		"Accounting":"Accounting",
		"Currency.defaultPayableAccountId":"Payable Account",
		"Currency.defaultPayableAccount":"Payable Account",
		"Currency.defaultReceivableAccountId":"Receivable Account",
		"Currency.defaultReceivableAccount":"Receivable Account",
		"Currency.advanceReceiveAccountId":"Advance Receive Account",
		"Currency.advanceReceiveAccount":"Advance Receive Account",
		"Currency.advancePaymentAccountId":"Advance Payment Account",
		"Currency.advancePaymentAccount":"Advance Payment Account",
		"Currency.salesDiscountAccountId":"Sales Discount Account",
		"Currency.salesDiscountAccount":"Sales Discount Account",
		"Currency.realizedGainOrLossAccountId":"Realized Gain Account",
		"Currency.realizedGainOrLossAccount":"Realized Gain Account",
		"Currency.unrealizedGainOrLossAccountId":"Unrealized Gain Or Loss Account",
		"Currency.unrealizedGainOrLossAccount":"Unrealized Gain Or Loss Account",
		"Currency.state":"State",
		//Daily Exchange Rate
		"ExchangeRateDaily":"Daily Exchange Rate",
		"exchange_rate_daily":"Daily Exchange Rate",
		"ExchangeRateDaily.date":"Date",
		"ExchangeRateDaily.periodId":"Period",
		"ExchangeRateDaily.period":"Period",
		"ExchangeRateDaily.currencyFromId":"From Currency",
		"ExchangeRateDaily.currencyFrom":"From Currency",
		"ExchangeRateDaily.currencyToId":"To Currency",
		"ExchangeRateDaily.currencyTo":"To Currency",
		"ExchangeRateDaily.rate":"Rate",
		"ExchangeRateDaily.description":"Description",
		//Taxes
		"Taxes":"Taxes",
		"taxes":"Taxes",
		"taxes.activate":"Activate",
		"Taxes.confirmTitle.action/activate":"Activate",
		"Taxes.confirmContent.action/activate":"Are you sure you want to activate this Taxes?",
		"Taxes.success.activate":"Taxes is successfully activated",
		"taxes.deactivate":"Deactivate",
		"Taxes.confirmTitle.action/deactivate":"Deactivate",
		"Taxes.confirmContent.action/deactivate":"Are you sure you want to deactivate this Taxes?",
		"Taxes.success.deactivate":"Taxes is successfully deactivated",
		"Taxes.name":"Name",
		"Taxes.type":"Type",
		"Taxes.value":"Percentage (%)",
		"Taxes.salesAccountId":"Sales Account",
		"Taxes.salesAccount":"Sales Account",
		"Taxes.purchaseAccountId":"Purchase Account",
		"Taxes.purchaseAccount":"Purchase Account",
		"Taxes.taxedIn":"Taxed In",
		"Taxes.state":"State",
		//Employee
		"Employee":"Employee",
		"employee":"Employee",
		"employee.activate":"Activate",
		"Employee.confirmTitle.activate":"Activate",
		"Employee.confirmContent.activate":"Are you sure you want to activate this Employee?",
		"Employee.success.activate":"Employee is successfully activated",
		"employee.deactivate":"Deactivate",
		"Employee.confirmTitle.deactivate":"Deactivate",
		"Employee.confirmContent.deactivate":"Are you sure you want to deactivate this Employee?",
		"Employee.success.deactivate":"Employee is successfully deactivated",
		"Employee.name":"Name",
		"Employee.state":"State",
		//Project
		"Project":"Project",
		"project":"Project",
		"project.activate":"Activate",
		"Project.confirmTitle.action/activate":"Activate",
		"Project.confirmContent.action/activate":"Are you sure you want to activate this Project?",
		"Project.success.action/activate":"Project is successfully activated",
		"project.deactivate":"Deactivate",
		"Project.confirmTitle.action/deactivate":"Deactivate",
		"Project.confirmContent.action/deactivate":"Are you sure you want to deactivate this Project?",
		"Project.success.action/deactivate":"Project is successfully deactivated",
		"Project.code":"Code",
		"Project.name":"Name",
		"Project.customerId":"Customer",
		"Project.customer":"Customer",
		"Project.coordinators":"Coordinators",
		"Project.state":"State",
		//Project Coordinator
		"ProjectCoordinator":"Project Coordinator",
		"project_coordinator":"Project Coordinator",
		"ProjectCoordinator.projectId":"Project",
		"ProjectCoordinator.project":"Project",
		"ProjectCoordinator.userId":"Coordinator",
		"ProjectCoordinator.user":"Coordinator",
		//Warehouse
		"Warehouse":"Warehouse",
		"warehouse":"Warehouse",
		"warehouse.activate":"Activate",
		"Warehouse.confirmTitle.activate":"Activate",
		"Warehouse.confirmContent.activate":"Are you sure you want to activate this Warehouse?",
		"Warehouse.success.activate":"Warehouse is successfully activated",
		"warehouse.deactivate":"Deactivate",
		"Warehouse.confirmTitle.deactivate":"Deactivate",
		"Warehouse.confirmContent.deactivate":"Are you sure you want to deactivate this Warehouse?",
		"Warehouse.success.deactivate":"Warehouse is successfully deactivated",
		"Warehouse.name":"Name",
		"Warehouse.code":"Code",
		"Warehouse.branchId":"Branch",
		"Warehouse.branch":"Branch",
		"Warehouse.pic":"Person In Charge",
		"Warehouse.expensed":"Expensed?",
		"Warehouse.address":"Address",
		"Warehouse.description":"Description",
		"Warehouse.state":"State",
		"Warehouse.sequence":"Sequence",
		//Location
		"Location":"Location",
		"location":"Location",
		"location.activate":"Activate",
		"Location.confirmTitle.action/activate":"Activate",
		"Location.confirmContent.action/activate":"Are you sure you want to activate this Location?",
		"Location.success.activate":"Location is successfully activated",
		"location.deactivate":"Deactivate",
		"Location.confirmTitle.action/deactivate":"Deactivate",
		"Location.confirmContent.action/deactivate":"Are you sure you want to deactivate this Location?",
		"Location.success.deactivate":"Location is successfully deactivated",
		"Location.code":"Code",
		"Location.name":"Name",
		"Location.warehouseId":"Warehouse",
		"Location.warehouse":"Warehouse",
		"Location.branchId":"Branch",
		"Location.branch":"Branch",
		"Location.description":"Description",
		"Location.sequence":"Sequence",
		"Location.state":"State",
		//Branch Warehouse Location
		"BranchWarehouseLocation":"Branch Warehouse Location",
		"branch_warehouse_location":"Branch Warehouse Location",
		"BranchWarehouseLocation.branchId":"Branch",
		"BranchWarehouseLocation.branch":"Branch",
		"BranchWarehouseLocation.warehouseId":"Warehouse",
		"BranchWarehouseLocation.warehouse":"Warehouse",
		"BranchWarehouseLocation.locationId":"Location",
		"BranchWarehouseLocation.location":"Location",
		//Unit
		"Unit":"Unit",
		"unit":"Unit",
		"unit.activate":"Activate",
		"Unit.confirmTitle.action/activate":"Activate",
		"Unit.confirmContent.action/activate":"Are you sure you want to activate this Unit?",
		"Unit.success.activate":"Unit is successfully activated",
		"unit.deactivate":"Deactivate",
		"Unit.confirmTitle.action/deactivate":"Deactivate",
		"Unit.confirmContent.action/deactivate":"Are you sure you want to deactivate this Unit?",
		"Unit.success.deactivate":"Unit is successfully deactivated",
		"Unit.name":"Name",
		"Unit.timeMeasured":"Time Measured",
		"Unit.durationInHour":"Duration in hour(s)",
		"Unit.units":"Unit Conversion",
		"Unit.state":"State",
		//Unit Conversion
		"UnitConversion":"Unit Conversion",
		"unit_conversion":"Unit Conversion",
		"unit_conversion.activate":"Activate",
		"UnitConversion.confirmTitle.activate":"Activate",
		"UnitConversion.confirmContent.activate":"Are you sure you want to activate this Unit Conversion?",
		"UnitConversion.success.activate":"Unit Conversion is successfully activated",
		"unit_conversion.deactivate":"Deactivate",
		"UnitConversion.confirmTitle.deactivate":"Deactivate",
		"UnitConversion.confirmContent.deactivate":"Are you sure you want to deactivate this Unit Conversion?",
		"UnitConversion.success.deactivate":"Unit Conversion is successfully deactivated",
		"UnitConversion.unitFromId":"Unit From",
		"UnitConversion.unitFrom":"Unit From",
		"UnitConversion.unitToId":"Unit To",
		"UnitConversion.unitTo":"Unit To",
		"UnitConversion.conversionRate":"Conversion Rate",
		"UnitConversion.state":"State",
		//Item Category
		"ItemCategory":"Item Category",
		"item_category":"Item Category",
		"item_category.activate":"Activate",
		"ItemCategory.confirmTitle.action/activate":"Activate",
		"ItemCategory.confirmContent.action/activate":"Are you sure you want to activate this Item Category?",
		"ItemCategory.success.activate":"Item Category is successfully activated",
		"item_category.deactivate":"Deactivate",
		"ItemCategory.confirmTitle.action/deactivate":"Deactivate",
		"ItemCategory.confirmContent.action/deactivate":"Are you sure you want to deactivate this Item Category?",
		"ItemCategory.success.deactivate":"Item Category is successfully deactivated",
		"ItemCategory.code":"Code",
		"ItemCategory.name":"Name",
		"ItemCategory.cleanUrl":"Cleam URL",
		"ItemCategory.parentId":"Parent Category",
		"ItemCategory.parent":"Parent Category",
		"ItemCategory.image":"Image",
		"ItemCategory.thumbnail":"Thumbnail",
		"ItemCategory.state":"State",
		"ItemCategory.hideOnReport":"Hide On Report",
		"ItemCategory.saleableOnline":"Saleable Online",
		//Additional Charge
		"AdditionalCharge":"Additional Charge",
		"additional_charge":"Additional Charge",
		"additional_charge.activate":"Activate",
		"AdditionalCharge.confirmTitle.activate":"Activate",
		"AdditionalCharge.confirmContent.activate":"Are you sure you want to activate this Additional Charge?",
		"AdditionalCharge.success.activate":"Additional Charge is successfully activated",
		"additional_charge.deactivate":"Deactivate",
		"AdditionalCharge.confirmTitle.deactivate":"Deactivate",
		"AdditionalCharge.confirmContent.deactivate":"Are you sure you want to deactivate this Additional Charge?",
		"AdditionalCharge.success.deactivate":"Additional Charge is successfully deactivated",
		"AdditionalCharge.name":"Name",
		"AdditionalCharge.code":"Code",
		"AdditionalCharge.type":"Type",
		"AdditionalCharge.valuePercentage":"Percentage (%)",
		"AdditionalCharge.value":"Value",
		"AdditionalCharge.salesAccountId":"Sales Account",
		"AdditionalCharge.salesAccount":"Sales Account",
		"AdditionalCharge.expenseAccountId":"Expense Account",
		"AdditionalCharge.expenseAccount":"Expense Account",
		"AdditionalCharge.taxedIn":"Taxed In",
		"AdditionalCharge.state":"State",
		//Merek
		"Brand":"Brand",
		"brand":"Brand",
		"brand.activate":"Activate",
		"Brand.confirmTitle.activate":"Activate",
		"Brand.confirmContent.activate":"Are you sure you want to activate this Merek?",
		"Brand.success.activate":"Merek is successfully activated",
		"brand.deactivate":"Deactivate",
		"Brand.confirmTitle.deactivate":"Deactivate",
		"Brand.confirmContent.deactivate":"Are you sure you want to deactivate this Merek?",
		"Brand.success.deactivate":"Merek is successfully deactivated",
		"Brand.code":"Code",
		"Brand.name":"Name",
		"Brand.cleanUrl":"Clean URL",
		"Brand.sequence":"Sequence",
		"Brand.image":"Image",
		"Brand.thumbnail":"Thumbnail",
		"Brand.state":"State",
		//Tag
		"Tag":"Tag",
		"tag":"Tag",
		"tag.activate":"Activate",
		"Tag.confirmTitle.activate":"Activate",
		"Tag.confirmContent.activate":"Are you sure you want to activate this Tag?",
		"Tag.success.activate":"Tag is successfully activated",
		"tag.deactivate":"Deactivate",
		"Tag.confirmTitle.deactivate":"Deactivate",
		"Tag.confirmContent.deactivate":"Are you sure you want to deactivate this Tag?",
		"Tag.success.deactivate":"Tag is successfully deactivated",
		"Tag.name":"Name",
		"Tag.image":"Image",
		"Tag.thumbnail":"Thumbnail",
		"Tag.state":"State",
		//Item Image
		"ItemImage":"Item Image",
		"item_image":"Item Image",
		"ItemImage.title":"Title",
		"ItemImage.image":"Image",
		"ItemImage.itemId":"Item",
		"ItemImage.item":"Item",
		//Item Attachment
		"ItemAttachment":"Item Attachment",
		"item_attachment":"Item Attachment",
		"ItemAttachment.attachment":"Attachment",
		"ItemAttachment.itemId":"Item",
		"ItemAttachment.item":"Item",
		//Item Attribute
		"ItemAttribute":"Item Attribute",
		"item_attribute":"Item Attribute",
		"ItemAttribute.name":"Attribute",
		"ItemAttribute.value":"Value",
		"ItemAttribute.itemId":"Item",
		"ItemAttribute.item":"Item",
		"ItemAttribute.sequence":"Sequence",
		"ItemAttribute.measurementUnit":"Unit",
		//Item
		"Item":"Item",
		"item":"Item",
		"item.activate":"Activate",
		"Item.confirmTitle.action/activate":"Activate",
		"Item.confirmContent.action/activate":"Are you sure you want to activate this Item?",
		"Item.success.activate":"Item is successfully activated",
		"item.deactivate":"Deactivate",
		"Item.confirmTitle.action/deactivate":"Deactivate",
		"Item.confirmContent.action/deactivate":"Are you sure you want to deactivate this Item?",
		"Item.success.deactivate":"Item is successfully deactivated",
		"Item.barcode_print":"Print Barcode",
		"Item.confirmTitle.barcode_print":"Print Barcode",
		"Item.confirmContent.barcode_print":"Are you sure want to print barcodes for this item?",
		"Item.success.barcode_print":"Barcode has successfully printed",
		"Item Information":"Item Information",
		"Item.code":"Code",
		"Item.name":"Name",
		"Item.categoryId":"Category",
		"Item.category":"Category",
		"Item.type":"Type",
		"Item.additionalCharges":"Additonal Charges",
		"Item.position":"Position",
		"Item.brandId":"Brand",
		"Item.brand":"Brand",
		"Item.tags":"Tags",
		"Item.attributes":"Attributes",
		"Item.description":"Description",
		"Prices":"Prices",
		"Prices":"Prices",
		"Item.defaultUnitId":"Base Unit",
		"Item.defaultPurchaseUnit":"Default Purchase Unit",
		"Item.vendor":"Main Vendor",
		"Item.purchase":"Purchase",
		"Item.defaultUnit":"Base Unit",
		"Item.timeMeasured":"Time Measured",
		"Item.UnitId":"Unit",
		"Item.Unit":"Unit",
		"Item.units":"Selectable Units",
		"Item.defaultPrice":"Default Price",
		"Item.purchasePrice":"Purchase Price",
		"Item.branchPrices":"Branch Prices",
		"Inventory":"Inventory",
		"Inventory":"Inventory",
		"Item.inventoryType":"Inventory Type",
		"Item.minQuantity":"Minimum Quantity",
		"Item.warehouseId":"Default Warehouse",
		"Item.warehouse":"Default Warehouse",
		"Item.defaultLocation":"Default Location Out",
		"Item.defaultInLocation":"Default Location In",
		"Item.hasUpc":"Has UPC",
		"Item.hasSku":"Has SKU",
		"Item.generateSKU":"Generate SKU",
		"Item.length":"Length",
		"Item.width":"Width",
		"Item.thickness":"Thickness",
		"Online Store":"Online Store",
		"Item.saleableOnline":"Saleable Online",
		"Item.dropshipped":"Dropshipped",
		"Item.position":"Sequence",
		"Item.image":"Image",
		"Item.thumbnail":"Thumbnail",
		"Item.images":"Images",
		"Item.attachments":"Attachments",
		"Item.cleanUrl":"Clean URL",
		"Item.onlineDescription":"Online Description",
		"Item.listingDate":"Listing Date",
		"Item.viewCount":"View Count",
		"Item.variants":"Variant List",
		"Item.codes":"Codes",
		"Item.options":"Item Options",
		"Accounting":"Accounting",
		"Item.inventoryAccountId":"Inventory Account",
		"Item.inventoryAccount":"Inventory Account",
		"Item.salesAccountId":"Sales Account",
		"Item.salesAccount":"Sales Account",
		"Item.salesReturnAccountId":"Sales Return Account",
		"Item.salesReturnAccount":"Sales Return Account",
		"Item.salesItemDiscAccountId":"Sales Discount Account",
		"Item.salesItemDiscAccount":"Sales Discount Account",
		"Item.goodsInTransitAccountId":"Goods In Transit Account",
		"Item.goodsInTransitAccount":"Goods In Transit Account",
		"Item.purchaseReturnAccountId":"Purchase Return Account",
		"Item.purchaseReturnAccount":"Purchase Return Account",
		"Item.unbilledPayableAccountId":"Unbilled Payable Account",
		"Item.unbilledPayableAccount":"Unbilled Payable Account",
		"Item.unbilledReceivableAccountId":"Unbilled Receivable Account",
		"Item.unbilledReceivableAccount":"Unbilled Receivable Account",
		"Item.salesCommissionAccount":"Sales Commission Account",
		"Item.salesCommissionAccountId":"Sales Commission Account",
		"Item.expenseAccountId":"Expense Account",
		"Item.expenseAccount":"Expense Account",
		"Item.cogsAccountId":"COGS Account",
		"Item.cogsAccount":"COGS Account",
		"Item.assetAccountId":"Asset Account",
		"Item.assetAccount":"Asset Account",
		"Item.depreciationAccountId":"Depreciation Account",
		"Item.depreciationAccount":"Depreciation Account",
		"Item.accumulatedDepreciationAccountId":"Accumulated Depreciation Account",
		"Item.accumulatedDepreciationAccount":"Accumulated Depreciation Account",
		"Item.gainOrLossOnSalesAccountId":"Gain Or Loss On Sales Account",
		"Item.gainOrLossOnSalesAccount":"Gain Or Loss On Sales Account",
		"Item.gainOrLossOnRevaluationAccountId":"Gain Or Loss On Revaluation Account",
		"Item.gainOrLossOnRevaluationAccount":"Gain Or Loss On Revaluation Account",
		"Item.lossOnScrapAccountId":"Loss On Scrap Account",
		"Item.lossOnScrapAccount":"Loss On Scrap Account",
		"Item.disallowSellBelowPrice":"Disallow Sell Below Price",
		"Item.priceIncludesVAT":"Price Includes VAT",
		"Item.byPassPriceCheck":"Bypass Price Check",
		"Item.allowSplitAccount":"Allow Split Account",
		"Item.state":"State",
		"Item.hideOnReport":"Hide On Report",
		"Item.metaDescription":"Meta Description",
		"Item.marketplaces":"Marketplace",
		"Item.variant":"Variant",
		"Item.variantPrices":"Price Variants",
		"Item.packagingPrices":"Packaging Prices",
		"Item.codeAdded":"Code Added",
		"Item.upcAdded":"UPC Added",
		"Item.skuAdded":"SKU Added",
		"Item.marketplacePrices":"Marketplace Prices",
		"Item.MarketplacePricesLine.marketplace":"Marketplace",
		"Item.MarketplacePricesLine.choice":"Variant Option",
		"Item.MarketplacePricesLine.unit":"Unit",
		"Item.MarketplacePricesLine.price":"Price",
		"Item.rack":"Rack",
		"Item.purchaseQuantity":"Purchase Quantity",
		"Item.grade":"Grade",
		"Item.relatedItem":"Related Item",
		"Item.relatedItemConversion":"Related Item Conversion",
		"Item.commission":"Commission",
		"Item.commissionType":"Commission Type",
		"Item.commissionPercentage":"Commission Percentage (%)",
		"Item.commissionValue":"Commission Value",
		"Item.tax":"Tax",
		"Item.customAdditionalTax":"Custom Additional Tax",
		"Item.customAdditionalTaxPercentage":"Custom Additional Tax Percentage",
		"Item.vendorLeadTimeDays":"Lead Time (Days)",
		"Item.turnoverRate":"Turnover Rate",
		"Item.itemSpecs":"Specifications",
		
		//Item Branch Price
		"ItemUnit":"Item Branch Price",
		"item_unit":"Item Branch Price",
		"ItemUnit.itemId":"Item",
		"ItemUnit.item":"Item",
		"ItemUnit.branchId":"Branch",
		"ItemUnit.branch":"Branch",
		"ItemUnit.unit":"Unit",
		"ItemUnit.conversionRate":"Conversion Rate",
		"ItemUnit.price":"Price",
		//Item Branch Price
		"ItemBranchPrice":"Item Branch Price",
		"item_branch_price":"Item Branch Price",
		"ItemBranchPrice.itemId":"Item",
		"ItemBranchPrice.item":"Item",
		"ItemBranchPrice.branchId":"Branch",
		"ItemBranchPrice.branch":"Branch",
		"ItemBranchPrice.price":"Price",
		"ItemBranchPrice.purchasePrice":"PurchasePrice",
		"ItemBranchPrice.minimumPrice":"Min. Price",
		//Item Branch Price
		"ItemPrice":"Item Branch Price",
		"item_price":"Item Branch Price",
		"ItemPrice.date":"Date",
		"ItemPrice.price":"Price",
		//Item Additonal Charge
		"ItemAdditionalCharge.additionalChargeId":"Additional",
		"ItemAdditionalCharge.additionalCharge":"Additional",
		"ItemAdditionalCharge.price":"Price",
		//Item Value
		"ItemValue":"Item Value",
		"item_value":"Item Value",
		"ItemValue.itemId":"Item",
		"ItemValue.item":"Item",
		"ItemValue.dateIn":"Date In",
		"ItemValue.reference":"Reference",
		"ItemValue.quantityIn":"Quantity In",
		"ItemValue.valueIn":"Value In",
		"ItemValue.itemValueMoves":"Item Value Move",
		//Item Value Move
		"ItemValueMove":"Item Value Move",
		"item_value_move":"Item Value Move",
		"ItemValueMove.itemId":"Item",
		"ItemValueMove.item":"Item",
		"ItemValueMove.dateOut":"Date Out",
		"ItemValueMove.QuantityOut":"Jumlah Keluar",
		"ItemValueMove.ValueOut":"Nilai Keluar",
		"ItemValueMove.returnId":"Return",
		"ItemValueMove.return":"Return",
		"ItemValueMove.deliveryOrderId":"Delivery Order",
		"ItemValueMove.deliveryOrder":"Delivery Order",
		"ItemValueMove.adjustmentId":"Adjustment",
		"ItemValueMove.adjustment":"Adjustment",
		"ItemValueMove.inventoryIssueId":"Inventory Issue",
		"ItemValueMove.inventoryIssue":"Inventory Issue",
		"ItemValueMove.materialReleaseId":"Material Release",
		"ItemValueMove.materialRelease":"Material Release",
		"ItemValueMove.warehouseMoveId":"Warehouse Move",
		"ItemValueMove.warehouseMove":"Warehouse Move",
		//codes
		"CodesLine.name":"Name",
		"CodesLine.code":"Code",
		//Item barcode
		"ItemBarcode.paperSize":"Paper Size",
		"ItemBarcode.orientation":"Orientation",
		"ItemBarcode.column":"Columns",
		"ItemBarcode.copy":"Copy",
		"ItemBarcode.margin":"Margin",
		"ItemBarcode.width":"Width",
		"ItemBarcode.height":"height",

		//itemOption
		"ItemOption":"Item Option",
		"item_option":"Item Option",
		"ItemOption.name":"Name",
		"ItemOption.state":"State",
		"ItemOption.activate":"Activate",
		"ItemOption.confirmTitle.action/activate":"Activate",
		"ItemOption.confirmContent.action/activate":"Are you sure you want to activate this Item Option?",
		"ItemOption.success.action/activate":"Item Option is successfully activated",
		"ItemOption.deactivate":"Deactivate",
		"ItemOption.confirmTitle.action/deactivate":"Deactivate",
		"ItemOption.confirmContent.action/deactivate":"Are you sure you want to deactivate this Item Option?",
		"ItemOption.success.action/deactivate":"Item Option is successfully deactivated",
		"ItemOptionLine.option":"Option",
		"ItemOptionLine.sequence":"Sequence",

		//Partner
		"Partner":"Partner",
		"partner":"Partner",
		"partner.activate":"Activate",
		"Partner.confirmTitle.activate":"Activate",
		"Partner.confirmContent.activate":"Are you sure you want to activate this Partner?",
		"Partner.success.activate":"Partner is successfully activated",
		"partner.deactivate":"Deactivate",
		"Partner.confirmTitle.deactivate":"Deactivate",
		"Partner.confirmContent.deactivate":"Are you sure you want to deactivate this Partner?",
		"Partner.success.deactivate":"Partner is successfully deactivated",
		"Partner Information":"Partner Information",
		"Partner.name":"Name",
		"Partner.isSupplier":"Supplier",
		"Partner.isCustomer":"Customer",
		"Partner.email":"Email",
		"Partner.mobile":"Mobile Phone",
		"Partner.contact":"Home Phone",
		"Partner.street":"Address",
		"Partner.cityId":"City",
		"Partner.city":"City",
		"Partner.provinceId":"Province",
		"Partner.province":"Province",
		"Partner.countryId":"Country",
		"Partner.country":"Country",
		"Partner.zip":"Zip",
		"Partner.ssid":"SSID",
		"Partner.idAttachment":"SSID Attachment",
		"Finance and Accounting":"Finance and Accounting",
		"Partner.unlimitedCredit":"Unlimited Credit",
		"Partner.creditLimit":"Credit Limit",
		"Partner.credit":"Credit",
		"Partner.debitLimit":"Debit Limit",
		"Partner.debit":"Debit",
		"Partner.taxable":"Taxable",
		"Partner.npwp":"NPWP",
		"Partner.purchaseInvoiceDaysDue":"Purchase Invoice Due (Days)",
		"Partner.salesInvoiceDaysDue":"Sales Invoice Due (Days)",
		"Partner.defaultCurrencyId":"Currency Default",
		"Partner.defaultCurrency":"Currency Default",
		"Branch Information":"Branch Information",
		"Partner.branchId":"Branch",
		"Partner.branch":"Branch",
		"Company Information":"Company Information",
		"Partner.isCompany":"Company",
		"Partner.companyName":"Company Name",
		"Partner.companyNpwp":"Company NPWP",
		"Partner.companyAddress":"Company Address",
		"Partner.companyCityId":"Company City",
		"Partner.companyCity":"Company City",
		"Partner.companyProvinceId":"Company Province",
		"Partner.companyProvince":"Company Province",
		"Partner.companyCountryId":"Company Country",
		"Partner.companyCountry":"Company Country",
		"Partner.companyZip":"Company Zip",		
		"Other Information":"Other Information",
		"Partner.nodeId":"Node",
		"Partner.node":"Node",
		"Partner.joinDate":"Join Date",
		"Partner.media":"Media",
		"Partner.class":"Class",
		"Partner.code":"Code",
		"Partner.state":"State",
		"Partner.purchasing":"Purchasing",
		"Partner.purchasingUsers":"Purchasing Staffs",
		"Partner.user":"Staff Name",
		"Partner.birthDate":"Birthdate",
		"Partner.idNumber":"ID Number",
		"Partner.idAddress":"ID Address",
		"Partner.address":"Full Address",
		"Partner.occupation":"Occupcation",
		"Partner.maritalStatus":"Marital Status",
		"Partner.incomeRange":"Income Range",
		//Journal Entries
		"AccountMove":"Journal Entries",
		"account_move":"Journal Entries",
		"account_move.post":"Post",
		"AccountMove.confirmTitle.action/post":"Post",
		"AccountMove.confirmContent.action/post":"Post?",
		"AccountMove.success.action/post":"Journal Entries is successful",
		"account_move.unpost":"Unpost",
		"AccountMove.confirmTitle.action/unpost":"Unpost",
		"AccountMove.confirmContent.action/unpost":"Are you sure you want to unpost this Journal Entries?",
		"AccountMove.success.action/unpost":"Journal Entries is successfully unposted",
		"AccountMove.name":"Number",
		"AccountMove.ref":"Reference",
		"AccountMove.partnerId":"Partner",
		"AccountMove.partner":"Partner",
		"AccountMove.date":"Date",
		"AccountMove.periodId":"Period",
		"AccountMove.period":"Period",
		"AccountMove.lines":"Entries",
		"AccountMove.totalDebit":"Total Debit",
		"AccountMove.totalCredit":"Total Credit",
		"AccountMove.state":"State",
		//Journal Item
		"AccountMoveLine":"Journal Item",
		"account_move_line":"Journal Item",
		"AccountMoveLine.description":"Description",
		"AccountMoveLine.accountId":"Account",
		"AccountMoveLine.account":"Account",
		"AccountMoveLine.periodId":"Period",
		"AccountMoveLine.period":"Period",
		"AccountMoveLine.date":"Date",
		"AccountMoveLine.partnerId":"Partner",
		"AccountMoveLine.partner":"Partner",
		"AccountMoveLine.debit":"Debit",
		"AccountMoveLine.credit":"Credit",
		//Inventory
		"Inventory":"Inventory",
		"inventory":"Inventory",
		"Inventory.name":"Number",
		"Inventory.reference":"Reference",
		"Inventory.itemId":"Item",
		"Inventory.item":"Item",
		"Inventory.warehouseId":"Warehouse",
		"Inventory.warehouse":"Warehouse",
		"Inventory.locationId":"Location",
		"Inventory.location":"Location",
		"Inventory.dateIn":"Date In",
		"Inventory.quantityIn":"Quantity In",
		"Inventory.balance":"Balance",
		"Inventory.branchId":"Branch",
		"Inventory.branch":"Branch",
		"Inventory.sku":"SKU",
		"Inventory.taxed":"Taxed",
		"Inventory.adjustmentId":"Inventory Adjustment",
		"Inventory.adjustment":"Inventory Adjustment",
		"Inventory.returnId":"Return",
		"Inventory.return":"Return",
		"Inventory.state":"State",
		"Inventory.inventoryMoves":"Inventory Move",
		//Inventory Move
		"InventoryMove":"Inventory Move",
		"inventory_move":"Inventory Move",
		"InventoryMove.name":"Number",
		"InventoryMove.reference":"Reference",
		"InventoryMove.type":"Type",
		"InventoryMove.dateOut":"Date Out",
		"InventoryMove.deliveryOrderId":"Delivery Order",
		"InventoryMove.deliveryOrder":"Delivery Order",
		"InventoryMove.adjustmentId":"Inventory Adjustment",
		"InventoryMove.adjustment":"Inventory Adjustment",
		"InventoryMove.inventoryIssueId":"Inventory Issue",
		"InventoryMove.inventoryIssue":"Inventory Issue",
		"InventoryMove.materialReleaseId":"Material Release",
		"InventoryMove.materialRelease":"Material Release",
		"InventoryMove.returnId":"Return",
		"InventoryMove.return":"Return",
		"InventoryMove.warehouseMoveId":"Warehouse Move",
		"InventoryMove.warehouseMove":"Warehouse Move",
		//Inventory Move Plan
		"InventoryMovePlan":"Inventory Move Plan",
		"inventory_move_plan":"Inventory Move Plan",
		"InventoryMovePlan.itemId":"Item",
		"InventoryMovePlan.item":"Item",
		"InventoryMovePlan.unitId":"Unit",
		"InventoryMovePlan.unit":"Unit",
		"InventoryMovePlan.lines":"Inventory Move Plan Lines",
		"InventoryMovePlan.direction":"Direction",
		"InventoryMovePlan.quantity":"Quantity",
		"InventoryMovePlan.state":"State",
		"InventoryMovePlan.alert.changed":"IMP for all items has been changed",
		"InventoryMovePlan.alert.noLocation":"No warehouse location found for selected branch",
		//Inventory Move Plan Line
		"InventoryMovePlanLine":"Inventory Move Plan Line",
		"inventory_move_plan_line":"Inventory Move Plan Line",
		"InventoryMovePlanLine.locationId":"Location",
		"InventoryMovePlanLine.location":"Location",
		"InventoryMovePlanLine.quantity":"Quantity",
		"InventoryMovePlanLine.sku":"SKU",
		//Goods Receive Notes
		"GoodsReceiveNote":"Goods Receive Notes",
		"goods_receive_note":"Goods Receive Note",
		"goods_receive_note.confirm":"Confirm",
		"GoodsReceiveNote.confirmTitle.action/confirm":"Confirm",
		"GoodsReceiveNote.confirmContent.action/confirm":"Are you sure you want to confirm this Goods Receive Note?",
		"GoodsReceiveNote.success.action/confirm":"Goods Receive Notes is successfully confirmed",
		"goods_receive_note.cancel":"Cancel",
		"GoodsReceiveNote.confirmTitle.action/cancel":"Cancel",
		"GoodsReceiveNote.confirmContent.action/cancel":"Are you sure you want to cancel this Goods Receive Note?",
		"GoodsReceiveNote.success.action/cancel":"Goods Receive Notes is successfully cancelled",
		"goods_receive_note.draft":"Draft",
		"GoodsReceiveNote.confirmTitle.action/draft":"Draft",
		"GoodsReceiveNote.confirmContent.action/draft":"Draft?",
		"GoodsReceiveNote.success.action/draft":"Goods Receive Note is successful",
		"GoodsReceiveNote.confirmTitle.print":"Print Confirmation",
		"GoodsReceiveNote.confirmContent.print":"Are you sure you want to print this Goods Receive Note?",
		"GoodsReceiveNote.success.print":"Goods Receive Note is successfully printed",
		"General Information":"General Information",
		"GoodsReceiveNote.name":"Number",
		"GoodsReceiveNote.date":"Date",
		"GoodsReceiveNote.periodId":"Period",
		"GoodsReceiveNote.period":"Period",
		"GoodsReceiveNote.vendorId":"Supplier",
		"GoodsReceiveNote.vendor":"Supplier",
		"GoodsReceiveNote.branchId":"Branch",
		"GoodsReceiveNote.branch":"Branch",
		"GoodsReceiveNote.note":"Note",
		"GoodsReceiveNote.currencyId":"Currency",
		"GoodsReceiveNote.currency":"Currency",
		"GoodsReceiveNote.exchangeRate":"Exchange Rate",
		"GoodsReceiveNote.taxed":"Taxed",
		"GoodsReceiveNote.paymentTerm":"Payment Term (Days)",
		"Received Goods":"Received Goods",
		"Received Goods":"Received Goods",
		"GoodsReceiveNote.lines":"Items",
		"GoodsReceiveNote.totalDiscount":"Total Discount",
		"GoodsReceiveNote.subtotal":"Subtotal",
		"GoodsReceiveNote.totalTax":"Tax Total",
		"GoodsReceiveNote.total":"Grand Total",
		"Accounting":"Accounting",
		"Accounting":"Accounting",
		"GoodsReceiveNote.accountMove":"Journals",
		"GoodsReceiveNote.state":"State",
		"GoodsReceiveNote.needFinance":"Need Finance",
		//Goods Receive Notes Lines
		"GoodsReceiveNoteLine":"Goods Receive Notes Lines",
		"goods_receive_note_line":"Goods Receive Notes Lines",
		"GoodsReceiveNoteLine.purchaseOrderLineId":"Purchase",
		"GoodsReceiveNoteLine.purchaseOrderLine":"Purchase",
		"GoodsReceiveNoteLine.purchaseOrder":"PO No.",
		"GoodsReceiveNoteLine.name":"Number",
		"GoodsReceiveNoteLine.itemId":"Item",
		"GoodsReceiveNoteLine.item":"Item",
		"GoodsReceiveNoteLine.itemCode":"Code",
		"GoodsReceiveNoteLine.itemName":"Name",
		"GoodsReceiveNoteLine.unitId":"Unit",
		"GoodsReceiveNoteLine.unit":"Unit",
		"GoodsReceiveNoteLine.quantity":"Quantity",
		"GoodsReceiveNoteLine.unitPrice":"Price",
		"GoodsReceiveNoteLine.discountPercentage":"D(%)",
		"GoodsReceiveNoteLine.discountAmount":"Discount",
		"GoodsReceiveNoteLine.subtotal":"Subtotal",
		"GoodsReceiveNoteLine.taxes":"Taxes",
		"GoodsReceiveNoteLine.taxAmount":"Tax Amount",
		"GoodsReceiveNoteLine.total":"Total",
		"GoodsReceiveNoteLine.expireDate":"Exp. Date",
		"GoodsReceiveNoteLine.quantityBalance":"Quantity Balance",
		"GoodsReceiveNoteLine.inventoryMovePlanId":"IMP",
		"GoodsReceiveNoteLine.inventoryMovePlan":"IMP",
		"GoodsReceiveNoteLine.itemRequest":"Request No.",
		
		//Delivery Order
		"DeliveryOrder":"Delivery Order",
		"delivery_order":"Delivery Order",
		"delivery_order.confirm":"Confirm",
		"DeliveryOrder.confirmTitle.action/confirm":"Confirm",
		"DeliveryOrder.confirmContent.action/confirm":"Are you sure you want to confirm this Delivery Order?",
		"DeliveryOrder.success.action/confirm":"Delivery Order is successfully confirmed",
		"delivery_order.draft":"Draft",
		"DeliveryOrder.confirmTitle.action/draft":"Draft",
		"DeliveryOrder.confirmContent.action/draft":"Draft?",
		"DeliveryOrder.success.action/draft":"Delivery Order is successful",
		"delivery_order.cancel":"Cancel",
		"DeliveryOrder.confirmTitle.action/cancel":"Cancel",
		"DeliveryOrder.confirmContent.action/cancel":"Are you sure you want to cancel this Delivery Order?",
		"DeliveryOrder.success.action/cancel":"Delivery Order is successfully cancelled",
		"DeliveryOrder.confirmTitle.print":"Print Confirmation",
		"DeliveryOrder.confirmContent.print":"Are you sure you want to print this Delivery Order?",
		"DeliveryOrder.success.print":"You have successfully printed the Delivery Order",
		"DeliveryOrder.confirmTitle.print_finance":"Print Confirmation",
		"DeliveryOrder.confirmContent.print_finance":"Are you sure you want to print this Delivery Order with price?",
		"DeliveryOrder.success.print_finance":"You have successfully printed the Delivery Order wih price",
		"DeliveryOrder.name":"Number",
		"DeliveryOrder.date":"Date",
		"DeliveryOrder.periodId":"Period",
		"DeliveryOrder.period":"Period",
		"DeliveryOrder.customerId":"Customer",
		"DeliveryOrder.customer":"Customer",
		"DeliveryOrder.alias":"Alias",
		"DeliveryOrder.branchId":"Branch",
		"DeliveryOrder.branch":"Branch",
		"DeliveryOrder.branchDestination":"Destination Branch",
		"DeliveryOrder.branchOriginId":"Branch",
		"DeliveryOrder.branchOrigin":"Branch",
		"DeliveryOrder.note":"Note",
		"DeliveryOrder.currencyId":"Currency",
		"DeliveryOrder.currency":"Currency",
		"DeliveryOrder.deliveryRequestId":"Delivery Request",
		"DeliveryOrder.deliveryRequest":"Delivery Request",
		"DeliveryOrder.exchangeRate":"Exchange Rate",
		"Items":"Items",
		"Items":"Items",
		"DeliveryOrder.lines":"Items",
		"DeliveryOrder.totalDiscount":"Total Discount",
		"DeliveryOrder.subtotal":"Subtotal",
		"DeliveryOrder.totalTax":"Tax Total",
		"DeliveryOrder.total":"Grand Total",
		"Accounting":"Accounting",
		"Accounting":"Accounting",
		"DeliveryOrder.accountMove":"Journals",
		"DeliveryOrder.state":"State",
		"DeliveryOrder.taxed":"Taxed",
		"DeliveryOrder.acctQtyVoid":"Insufficient Inventory",
		//Delivery Order Line
		"DeliveryOrderLine":"Delivery Order Line",
		"delivery_order_line":"Delivery Order Line",
		"delivery_order_line.confirm":"Confirm",
		"DeliveryOrderLine.confirmTitle.action/confirm":"Confirm",
		"DeliveryOrderLine.confirmContent.action/confirm":"Are you sure you want to confirm this Delivery Order Line?",
		"DeliveryOrderLine.success.action/confirm":"Delivery Order Line is successfully confirmed",
		"delivery_order_line.draft":"Draft",
		"DeliveryOrderLine.confirmTitle.action/draft":"Draft",
		"DeliveryOrderLine.confirmContent.action/draft":"Draft?",
		"DeliveryOrderLine.success.action/draft":"Delivery Order Line is successful",
		"delivery_order_line.cancel":"Cancel",
		"DeliveryOrderLine.confirmTitle.action/cancel":"Cancel",
		"DeliveryOrderLine.confirmContent.action/cancel":"Are you sure you want to cancel this Delivery Order Line?",
		"DeliveryOrderLine.success.action/cancel":"Delivery Order Line is successfully cancelled",
		"DeliveryOrderLine.name":"Number",
		"DeliveryOrderLine.itemId":"Item",
		"DeliveryOrderLine.item":"Item",
		"DeliveryOrderLine.quantity":"Quantity",
		"DeliveryOrderLine.unitId":"Unit",
		"DeliveryOrderLine.unit":"Unit",
		"DeliveryOrderLine.unitPrice":"Unit Price",
		"DeliveryOrderLine.discountPercentage":"D(%)",
		"DeliveryOrderLine.discountAmount":"Discount",
		"DeliveryOrderLine.subtotal":"Subtotal",
		"DeliveryOrderLine.taxes":"Taxes",
		"DeliveryOrderLine.taxAmount":"Tax Amount",
		"DeliveryOrderLine.total":"Total",
		"DeliveryOrderLine.quantityBalance":"Quantity Balance",
		"DeliveryOrderLine.inventoryMovePlanId":"IMP",
		"DeliveryOrderLine.inventoryMovePlan":"IMP",
		"DeliveryOrderLine.itemRequest":"Item Request No.",
		//Instant Sales & lines
		"InstantSales":"Instant Sales",
		"instant_sales":"Instant Sales",
		"InstantSales.confirmTitle.action/cancel":"Cancel Instant Sales",
		"InstantSales.confirmContent.action/cancel":"Are you sure want to cancel this instant sale?",
		"InstantSales.cancelled":"Instant sale has cancelled",
		"recentInstantSales":"Recent Instant Sales",
		"InstantSalesConfiguration":"Instant Sales Configuration",
		"InstantSales.name":"Instant Sales No.",
		"InstantSales.date":"Date",
		"InstantSales.customer":"Customer",
		"InstantSales.customerName":"Customer Name",
		"InstantSales.customerMobile":"Customer Mobile",
		"InstantSales.notes":"Notes",
		"InstantSales.paymentType":"Payment Type",
		"InstantSales.branch":"Branch",
		"InstantSales.specificLocation":"Specific Location",
		"InstantSales.currency":"Currency",
		"InstantSales.exchangeRate":"Exchange Rate",
		"InstantSales.taxed":"Taxed",
		"InstantSales.lines":"Item List",
		"InstantSales.totalDiscount":"Total Discount",
		"InstantSales.totalTax":"Total Tax",
		"InstantSales.totalIncomeTax":"Total Income Tax",
		"InstantSales.subtotal":"Subtotal",
		"InstantSales.total":"Total",
		"InstantSales.noPaymentType":"No payment method",
		"InstantSalesLine.item":"Item",
		"InstantSalesLine.quantity":"Quantity",
		"InstantSalesLine.unit":"Unit",
		"InstantSalesLine.unitPrice":"Unit Price",
		"InstantSalesLine.discountPercentage":"Disc(%)",
		"InstantSalesLine.discountAmount":"Disc Amount",
		"InstantSalesLine.taxes":"Taxes",
		"InstantSalesLine.taxAmount":"Tax Amount",
		"InstantSalesLine.incomeTaxAmount":"Income Tax Amount",
		"InstantSalesLine.subtotal":"Subtotal",
		"InstantSalesLine.total":"Total",
		"InstantSalesLine.inventoryMovePlan":"IMP",
		"InstantSalesLine.onlyFromOriginUnit":"Only From Original Unit",
		"InstantSalesLine.convertToRelatedItem":"Convert to Related Item",
		"InstantSalesLine.usage":"Used",
		"InstantSalesLine.dailyNumber":"Daily Number",
		"InstantSales.marketplace":"Marketplace",
		"InstantSales.promotions":"Promotions",
		"InstantSales.totalPay":"Total Pay",
		"InstantSales.change":"Change",
		"InstantSales.confirmPayment":"Confirm Payment",
		"InstantSales.paymentFee":"Payment Fee",
		"InstantSales.extraDiscount":"Extra Discount",
		"InstantSales.receivable":"Receivable",
		"InstantSales.VAT":"VAT",
		"InstantSales.giro":"Giro",
		"InstantSales.advance":"Advance",
		"InstantSales.editGiro":"Edit Giro",
		"InstantSales.giroNumber":"Giro No.",
		"InstantSales.giroBank":"Bank",
		"InstantSales.giroExpireDate":"Expire Date",
		"InstantSales.giroInterestPercentage":"Interest (%)",
		"InstantSales.giroInterestValue":"Interest Amount",
		"InstantSales.belowStandardPrice":"Unit price is below standard sale price",
		"InstantSales.extraPayment":"Extra Payment",
		"InstantSales.extraPaymentType":"Extra Payment Type",
		"InstantSales.extraPaymentFee":"Extra Payment Fee",
		"InstantSales.extraTotalPay":"Extra Total Pay",
		"InstantSales.extraGiro":"Extra Giro",
		"InstantSales.extraReceivable":"Extra Receivable",
		"InstantSales.confirmTitle.action/cancelDraft":"Cancel Draft",
		"InstantSales.confirmContent.action/cancelDraft":"Are you sure want to cancel this instant sales draft?",
		"InstantSales.success.action/cancelDraft":"Instant sales draft successfully cancelled",
		"thisRow":"this row",
		"deleteRowTitle":"Do you really want to delete %item% from the transaction?",
		"decreaseQtyTitle":"Do you want to decrease quantity of %item% from the transaction?",
		"deleteRowContent":"Your action will be logged by system, *it is not recommended* to cancel items",
		"deleteRowContent":"Your action will be logged by system, *it is not recommended* to decrease items",
		
		"PromotionLine.chosen":"Chosen",
		"PromotionLine.promotion":"Promotion",
		"PromotionLine.active":"Active",
		"InstantSales.discounts":"Applied Discounts",
		"DiscountLine.promotion":"From Promtion",
		"DiscountLine.dicountAmount":"Amount",
		"InstantSalesReport":"Instant Sales Report",
		"CashierReport":"Cashier Report",
		"CashierReport.startDate":"Start Date",
		"CashierReport.endDate":"End Date",
		"CashierReport.clerk":"Clerk",
		"OfflineMode.startOfflineMode":"Start Offline Mode?",
		"OfflineMode.youreOffline":"You're Offline",
		"OfflineMode.clientNotStarted":"Offline ERP not yet started",
		"OfflineMode.clientNotStarted.desc":"Please open the offline ERP first",
		"OfflineMode.loadingClient":"Loading Offline Mode",
		"OfflineMode.loadingClient.desc":"Please wait ...",
		"InstantSales.notEnoughPayment":"The payment is below total sale",
		"InstantSales.overPayment":"Overpayment",
		"InstantSales.underPayment":"Underpayment",
		"InstantSales.overPayment.confirmation":"Are you sure want to allocate overpayment to %s",
		"InstantSales.underPayment.confirmation":"Are you sure want to allocate underpayment to %s",
		"InstantSales.allocateTo":"Allocate to",
		"InstantSales.overPayment.change":"Change",
		"InstantSales.overPayment.advance":"Advance",
		"InstantSales.overPayment.income":"Income",
		"InstantSales.underPayment.write_off":"Write Off",
		"InstantSales.underPayment.invoice":"Invoice",
		"InstantSales.roundingDifference":"Rounding Difference",
		"InstantSales.returnTotal":"Total Return",
		"InstantSales.strictModeNotice":"Strict Mode Activated, click to unlock",
		"InstantSales.login":"Open Access to Supervisor",
		"InstantSales.loginDesc":"Enter supervisor's username and password.",
		"InstantSales.openAccessDesc":"This will allow access To:",
		"InstantSales.openAccessRules":[
			"Update sales",
			"Cancel sales",
			"Manually set dicounts",
			"Return items",
			"Reprint receipts"
		],
		"InstantSales.viewPayments":"View Payments",
		"InstantSales.twoPaymentsWithFeeOrIncome":"It is not allowed to have 2 payments that have administration fee / extra income",
		"InstantSales.strictModeReenabled":"Strict Mode Activated",
		"InstantSales.strictModeReenabledDesc":"Strict Mode has been reactivated after one transaction",

		//"IS Quick Return"
		"ISReturn":"Return",
		"ISReturn.itemName":"Item Name",
		"ISReturn.quantityBalance":"Balance",
		"ISReturn.quantity":"Quantity",
		"ISReturn.unitPrice":"Return Value",
		"ISReturn.totalReturn":"Total Return",
		"ISReturn.updateReturn":"Update Return",
		"ISReturn.addReturn":"Add Return",
		"ISReturn.returnAll":"Return All",
		"ISReturn.reason":"Reason",
		"ISReturn.instantSales":"Instant Sales",
		"ISReturn.instantSalesItems":"Instant Sales Items",
		"ISReturn.nonInstantSalesItems":"Non Instant Sales Items",
		"ISReturn.others":"Other",

		//Instant Transaction (sales & purchase)
		"FinanceARInstantSalesBrowse":"Instant Sales List",
		"FinanceAPInstantPurchaseBrowse":"Instant Purchase List",
		"InstantSalesBrowse":"Instant Sales List",
		"InstantPurchaseBrowse":"Instant Purchase List",
		"instant_sales":"Instant Sales",
		"instant_purchase":"Instant Purchase",
		"InstantTrx.confirmTitle.cancel":"Cancel Instant Transaction",
		"InstantTrx.confirmContent.cancel":"Are you sure want to cancel this %s?",
		"InstantTrx.cancelled":"%s successfully cancelled",
		"recentInstantSales":"Penjualan Instan paling baru",
		"InstantSalesConfiguration":"Konfigurasi penjualan instan",
		"InstantTrx.basicInfo":"Basic Information",
		"InstantTrx.items":"Items",
		"InstantTrx.promoList":"Promotions",
		"InstantTrx.name":"Number",
		"InstantTrx.date":"Date",
		"InstantTrx.customer":"Customer",
		"InstantTrx.vendor":"Vendor",
		"InstantTrx.customerPhone":"Phone",
		"InstantTrx.vendorPhone":"Phone",
		"InstantTrx.PIC":"PIC",
		"InstantTrx.notes":"Notes",
		"InstantTrx.vat":"VAT",
		"InstantTrx.defaultVat":"Default VAT",
		"InstantTrx.paymentType":"Payment Method",
		"InstantTrx.branch":"Branch",
		"InstantTrx.specificLocation":"Specific Location",
		"InstantTrx.currency":"Currency",
		"InstantTrx.exchangeRate":"Exchange Rate",
		"InstantTrx.taxed":"Taxed",
		"InstantTrx.lines":"Items",
		"InstantTrx.totalDiscount":"Total Discount",
		"InstantTrx.totalTax":"Total Tax",
		"InstantTrx.subtotal":"Subtotal",
		"InstantTrx.total":"Total",
		"InstantTrx.noPaymentType":"No Payment Type",
		"InstantTrxLine.item":"Item",
		"InstantTrxLine.itemName":"Item Name",
		"InstantTrxLine.quantity":"Quantity",
		"InstantTrxLine.unit":"Unit",
		"InstantTrxLine.unitPrice":"Unit Price",
		"InstantTrxLine.discountPercentage":"Disc %",
		"InstantTrxLine.discountAmount":"Disc Amount",
		"InstantTrxLine.taxes":"Tax",
		"InstantTrxLine.taxAmount":"Tax Amount",
		"InstantTrxLine.subtotal":"Subtotal",
		"InstantTrxLine.subtotal":"Subtotal",
		"InstantTrxLine.total":"Total",
		"InstantTrxLine.inventoryMovePlan":"IMP",
		"InstantTrxLine.onlyFromOriginUnit":"Only From original unit",
		"InstantTrx.promos.promo":"Promo Name",
		"InstantTrx.promos.stacks":"Stack",
		"InstantTrx.promos.discountAmount":"Disc Amount",
		"InstantTrx.promos.totalDiscPromo":"Total Promo Discount",
		"InstantTrx.promos.extraDiscount":"Extra Discount",
		"InstantTrx.marketplace":"Marketplace",
		"InstantTrx.promotions":"Promotions",
		"InstantTrx.totalPay":"Total Pay",
		"InstantTrx.change":"Change",
		"InstantTrx.receivable":"Receivable",
		"InstantTrx.VAT":"VAT",
		"InstantTrx.giro":"Giro",
		"InstantTrx.editGiro":"Edit Giro",
		"InstantTrx.giroNumber":"No. Giro",
		"InstantTrx.giroBank":"Bank",
		"InstantTrx.giroExpireDate":"Expire Date",
		"InstantTrx.giroInterestPercentage":"Interest (%)",
		"InstantTrx.giroInterestValue":"Interest Value",
		"InstantTrx.belowStandardPrice":"Unit Price is below stadard price",
		"InstantTrx.confirmPayment":"Confirm Payment",
		"InstantTrx.paymentFee":"Payment Fee",
		"InstantTrx.overpaymentAllocation":"Payment Allocation +",
		"InstantTrx.discounts":"Discounts",
		"InstantTrx.payments":"Payments",
		"InstantTrx.payment":"Payment",
		"InstantTrx.extraPayment":"Extra Payment",
		"InstantTrx.refund":"Refund",
		"InstantTrx.reschedule":"Reschedule",
		"InstantTrx.reschedule.title":"Insert new visit date",
		"InstantTrx.reschedule.visitDate":"Visit Date",
		"InstantTrx.reschedule.success":"Visit Date changed successfuly",
		"InstantTrx.return.reason":"Reason",
		"InstantTrx.return.overpaymentAllocation":"Overpayment Allocation",
		"InstantTrx.return.bank":"Bank/Rekening",
		"InstantTrx.return.paymentType":"Cara Pembayaran",
		"InstantTrx.return.success":"Transaction successfully refunded",
		"InstantTrx.accounting":"Accounting",
		"InstantTrx.accountMove":"Accounting Journal",
		"InstantTrx.state":"Status",

		//Due Search & price search
		"DueSearch.customerName":"Customer Name",
		"DueSearch.vendorName":"Vendor Name",
		"DueSearch.customerNameSearch":"Search Customer",
		"DueSearch.vendorNameSearch":"Search Vendor",
		"DueSearch.total":"Total",
		"DueSearch.isDue":"Due",
		"DueSearch.dueDate":"Due Date",
		"PriceSearch.search":"Search Customer Name or Item",
		"PriceSearch.itemName":"Item Name",
		"PriceSearch.date":"Date",
		"PriceSearch.customerName":"Customer Name",
		"PriceSearch.branch":"Branch",
		"PriceSearch.price":"Price",

		//instant purchase
		"InstantPurchase":"Instant Purchase",
		"instant_purchase":"Instant Purchase",
		"InstantPurchase.confirmTitle.cancel":"Cancel Instant Purchase",
		"InstantPurchase.confirmContent.cancel":"Are you sure want to cancel this instant purchase?",
		"InstantPurchase.cancelled":"Instant purchase has cancelled",
		"recentInstantPurchase":"Recent Instant Purchase",
		"InstantPurchaseConfiguration":"Instant Purchase Configuration",
		"InstantPurchase.name":"Instant Purchase No.",
		"InstantPurchase.date":"Date",
		"InstantPurchase.vendor":"Vendor",
		"InstantPurchase.notes":"Notes",
		"InstantPurchase.paymentType":"Payment Type",
		"InstantPurchase.branch":"Branch",
		"InstantPurchase.specificLocation":"Specific Location",
		"InstantPurchase.currency":"Currency",
		"InstantPurchase.exchangeRate":"Exchange Rate",
		"InstantPurchase.taxed":"Taxed",
		"InstantPurchase.lines":"Item List",
		"InstantPurchase.totalDiscount":"Total Discount",
		"InstantPurchase.totalTax":"Total Tax",
		"InstantPurchase.totalIncomeTax":"Total Income Tax",
		"InstantPurchase.subtotal":"Subtotal",
		"InstantPurchase.total":"Total",
		"InstantPurchase.noPaymentType":"No payment method",
		"InstantPurchase.extraDiscount":"Additional Discount",
		"InstantPurchase.extraPayment":"Additional Payment",
		"InstantPurchase.extraPaymentType":"Payment Method +",
		"InstantPurchase.extraPaymentFee":"Payment Fee +",
		"InstantPurchase.extraTotalPay":"Total Pay +",
		"InstantPurchase.extraGiro":"Additional Giro",
		"InstantPurchase.extraReceivable":"Additioanl Giro",
		"InstantPurchaseLine.item":"Item",
		"InstantPurchaseLine.quantity":"Quantity",
		"InstantPurchaseLine.unit":"Unit",
		"InstantPurchaseLine.unitPrice":"Unit Price",
		"InstantPurchaseLine.discountPercentage":"Disc(%)",
		"InstantPurchaseLine.discountAmount":"Disc Amount",
		"InstantPurchaseLine.taxes":"Taxes",
		"InstantPurchaseLine.taxAmount":"Tax Amount",
		"InstantPurchaseLine.incomeTaxAmount":"Income Tax Amount",
		"InstantPurchaseLine.subtotal":"Subtotal",
		"InstantPurchaseLine.total":"Total",
		"InstantPurchaseLine.inventoryMovePlan":"IMP",
		"InstantPurchaseLine.onlyFromOriginUnit":"Only From Original Unit",
		"InstantPurchase.marketplace":"Marketplace",
		"InstantPurchase.promotions":"Promotions",
		"InstantPurchase.totalPay":"Total Pay",
		"InstantPurchase.change":"Change",
		"InstantPurchase.paymentFee":"Payment Fee",
		"InstantPurchase.confirmPayment":"Confirm Payment",
		"InstantPurchase.payable":"Payable",
		"InstantPurchase.giro":"Giro",
		"InstantPurchase.advance":"Advance",
		"InstantPurchase.VAT":"VAT",
		"InstantPurchase.invoiceConfirmedNotice":"Can't cancel instant sales. The invoice for this instant purchase has been confirmed",
		"PromotionLine.chosen":"Chosen",
		"PromotionLine.promotion":"Promotion",
		"PromotionLine.active":"Active",
		"InstantPurchase.discounts":"Applied Discounts",
		"DiscountLine.promotion":"From Promtion",
		"DiscountLine.dicountAmount":"Amount",
		"InstantPurchaseReport":"Instant Purchase Report",
		"ProcurementReport":"ProcurementReport",
		"CashierReport":"Cashier Report",
		"CashierReport.startDate":"Start Date",
		"CashierReport.endDate":"End Date",
		"CashierReport.clerk":"Clerk",
		"OfflineMode.startOfflineMode":"Start Offline Mode?",
		"OfflineMode.youreOffline":"You're Offline",
		"OfflineMode.clientNotStarted":"Offline ERP not yet started",
		"OfflineMode.clientNotStarted.desc":"Please open the offline ERP first",
		"OfflineMode.loadingClient":"Loading Offline Mode",
		"OfflineMode.loadingClient.desc":"Please wait ...",
		"InstantPurchase.notEnoughPayment":"The payment is below total purchase",
		"InstantPurchase.notEnoughPayment":"The payment is below total sale",
		"InstantPurchase.overPayment":"Overpayment",
		"InstantPurchase.overPayment.confirmation":"Are you sure want to allocate overpayment to %s",
		"InstantPurchase.allocateTo":"Allocate to",
		"InstantPurchase.overPayment.change":"Change",
		"InstantPurchase.overPayment.advance":"Advance",
		"InstantPurchase.overPayment.income":"Income",
		"InstantPurchase.roundingDifference":"Rounding Difference",

		//Inventory Issue
		"InventoryIssue":"Inventory Issue",
		"inventory_issue":"Inventory Issue",
		"inventory_issue.confirm":"Confirm",
		"InventoryIssue.confirmTitle.action/confirm":"Confirm",
		"InventoryIssue.confirmContent.action/confirm":"Are you sure you want to confirm this Inventory Issue?",
		"InventoryIssue.success.action/confirm":"Inventory Issue is successfully confirmed",
		"inventory_issue.draft":"Draft",
		"InventoryIssue.confirmTitle.action/draft":"Draft",
		"InventoryIssue.confirmContent.action/draft":"Draft?",
		"InventoryIssue.success.action/draft":"Inventory Issue is successful",
		"General Information":"General Information",
		"InventoryIssue.name":"name",
		"InventoryIssue.date":"Date",
		"InventoryIssue.periodId":"Period",
		"InventoryIssue.period":"Period",
		"InventoryIssue.issuerId":"Issuer",
		"InventoryIssue.issuer":"Issuer",
		"InventoryIssue.branchId":"Branch",
		"InventoryIssue.location":"Location",
		"InventoryIssue.branch":"Branch",
		"InventoryIssue.description":"Deskripsi",
		"InventoryIssue.note":"Note",
		"InventoryIssue.accountId":"Account",
		"InventoryIssue.account":"Account",
		"Items":"Items",
		"Items":"Items",
		"InventoryIssue.lines":"Items",
		"Accounting":"Accounting",
		"Accounting":"Accounting",
		"InventoryIssue.accountMove":"Journals",
		"InventoryIssue.state":"State",
		"InventoryIssue.acctQtyVoid":"Insufficient Inventory",
		//Inventory Issue Line
		"InventoryIssueLine":"Inventory Issue Line",
		"inventory_issue_line":"Inventory Issue Line",
		"InventoryIssueLine.itemId":"Item",
		"InventoryIssueLine.item":"Item",
		"InventoryIssueLine.unit":"Unit",
		"InventoryIssueLine.quantity":"Quantity",
		"InventoryIssueLine.inventoryMovePlanId":"IMP",
		"InventoryIssueLine.inventoryMovePlan":"IMP",
		//Material Release
		"MaterialRelease":"Material Release",
		"material_release":"Material Release",
		"material_release.confirm":"Confirm",
		"MaterialRelease.confirmTitle.action/confirm":"Confirm",
		"MaterialRelease.confirmContent.action/confirm":"Are you sure you want to confirm this Material Release?",
		"MaterialRelease.success.action/confirm":"Material Release is successfully confirmed",
		"material_release.draft":"Draft",
		"MaterialRelease.confirmTitle.action/draft":"Draft",
		"MaterialRelease.confirmContent.action/draft":"Draft?",
		"MaterialRelease.success.action/draft":"Material Release is successful",
		"Informasi Umum":"Informasi Umum",
		"MaterialRelease.name":"Name",
		"MaterialRelease.date":"Date",
		"MaterialRelease.periodId":"Period",
		"MaterialRelease.period":"Period",
		"MaterialRelease.issuerId":"Pemakai",
		"MaterialRelease.issuer":"Pemakai",
		"MaterialRelease.customerId":"Pelanggan",
		"MaterialRelease.customer":"Pelanggan",
		"MaterialRelease.branchId":"Branch",
		"MaterialRelease.branch":"Branch",
		"MaterialRelease.projectId":"Project",
		"MaterialRelease.project":"Project",
		"MaterialRelease.note":"Note",
		"Items":"Items",
		"Items":"Items",
		"MaterialRelease.lines":"Items",
		"Accounting":"Accounting",
		"Accounting":"Accounting",
		"MaterialRelease.accountMove":"Journals",
		"MaterialRelease.state":"State",
		"MaterialRelease.acctQtyVoid":"Insufficient Inventory",
		//Material Release Line
		"MaterialReleaseLine":"Material Release Line",
		"material_release_line":"Material Release Line",
		"MaterialReleaseLine.itemId":"Item",
		"MaterialReleaseLine.item":"Item",
		"MaterialReleaseLine.quantity":"Quantity",
		"MaterialReleaseLine.unitId":"Unit",
		"MaterialReleaseLine.unit":"Unit",
		"MaterialReleaseLine.inventoryMovePlanId":"IMP",
		"MaterialReleaseLine.inventoryMovePlan":"IMP",
		//Production Result
		"ProductionResult":"Production Result",
		"production_result":"Production Result",
		"production_result.confirm":"Confirm",
		"ProductionResult.confirmTitle.action/confirm":"Confirm",
		"ProductionResult.confirmContent.action/confirm":"Are you sure you want to confirm this Production Result?",
		"ProductionResult.success.action/confirm":"Production Result is successfully confirmed",
		"production_result.draft":"Draft",
		"ProductionResult.confirmTitle.action/draft":"Draft",
		"ProductionResult.confirmContent.action/draft":"Draft?",
		"ProductionResult.success.action/draft":"Production Result is successful",
		"General Information":"General Information",
		"ProductionResult.name":"Name",
		"ProductionResult.date":"Date",
		"ProductionResult.periodId":"Period",
		"ProductionResult.period":"Period",
		"ProductionResult.picId":"PIC",
		"ProductionResult.pic":"PIC",
		"ProductionResult.customerId":"Customer",
		"ProductionResult.customer":"Customer",
		"ProductionResult.branchId":"Branch",
		"ProductionResult.branch":"Branch",
		"ProductionResult.projectId":"Project",
		"ProductionResult.project":"Project",
		"ProductionResult.note":"Note",
		"Production Results":"Production Results",
		"Production Results":"Production Results",
		"ProductionResult.lines":"Items",
		"Accounting":"Accounting",
		"Accounting":"Accounting",
		"ProductionResult.accountMove":"Journals",
		"ProductionResult.state":"State",
		//Production Result Line
		"ProductionResultLine":"Production Result Line",
		"production_result_line":"Production Result Line",
		"ProductionResultLine.itemId":"Item",
		"ProductionResultLine.item":"Item",
		"ProductionResultLine.unitId":"Unit",
		"ProductionResultLine.unit":"Unit",
		"ProductionResultLine.quantity":"Quantity",
		"ProductionResultLine.inventoryMovePlanId":"IMP",
		"ProductionResultLine.inventoryMovePlan":"IMP",
		//Inventory Receive
		"InventoryReceive":"Inventory Receive",
		"inventory_receive":"Inventory Receive",
		"inventory_receive.confirm":"Confirm",
		"InventoryReceive.confirmTitle.action/confirm":"Confirm",
		"InventoryReceive.confirmContent.action/confirm":"Are you sure you want to confirm this Inventory Receive?",
		"InventoryReceive.success.action/confirm":"Inventory Receive is successfully confirmed",
		"inventory_receive.draft":"Draft",
		"InventoryReceive.confirmTitle.action/draft":"Draft",
		"InventoryReceive.confirmContent.action/draft":"Draft?",
		"InventoryReceive.success.action/draft":"Inventory Receive is successful",
		"InventoryReceive.name":"Name",
		"InventoryReceive.date":"Date",
		"InventoryReceive.periodId":"Period",
		"InventoryReceive.period":"Period",
		"InventoryReceive.picId":"PIC",
		"InventoryReceive.pic":"PIC",
		"InventoryReceive.customerId":"Customer",
		"InventoryReceive.customer":"Customer",
		"InventoryReceive.branchId":"Branch",
		"InventoryReceive.branch":"Branch",
		"InventoryReceive.projectId":"Project",
		"InventoryReceive.project":"Project",
		"InventoryReceive.note":"Note",
		"InventoryReceive.lines":"Items",
		"InventoryReceive.accountMove":"Journals",
		"InventoryReceive.state":"State",
		//Inventory Receive Item
		"InventoryReceiveLine":"Inventory Receive Item",
		"inventory_receive_line":"Inventory Receive Item",
		"InventoryReceiveLine.itemId":"Item",
		"InventoryReceiveLine.item":"Item",
		"InventoryReceiveLine.unitId":"Unit",
		"InventoryReceiveLine.unit":"Unit",
		"InventoryReceiveLine.quantity":"Quantity",
		"InventoryReceiveLine.inventoryMovePlanId":"IMP",
		"InventoryReceiveLine.inventoryMovePlan":"IMP",
		//Inventory Adjustment
		"InventoryAdjustment":"Inventory Adjustment",
		"inventory_adjustment":"Inventory Adjustment",
		"inventory_adjustment.confirm":"Confirm",
		"InventoryAdjustment.confirmTitle.action/confirm":"Confirm",
		"InventoryAdjustment.confirmContent.action/confirm":"Are you sure you want to confirm this Inventory Adjustment?",
		"InventoryAdjustment.success.action/confirm":"Inventory Adjustment is successfully confirmed",
		"inventory_adjustment.draft":"Draft",
		"InventoryAdjustment.confirmTitle.action/draft":"Draft",
		"InventoryAdjustment.confirmContent.action/draft":"Draft?",
		"InventoryAdjustment.success.action/draft":"Inventory Adjustment is successful",
		"inventory_adjustment.cancel":"Cancel",
		"InventoryAdjustment.confirmTitle.action/cancel":"Cancel",
		"InventoryAdjustment.confirmContent.action/cancel":"Are you sure you want to cancel this Inventory Adjustment?",
		"InventoryAdjustment.success.action/cancel":"Inventory Adjustment is successfully cancelled",
		"General Information":"General Information",
		"InventoryAdjustment.name":"Number",
		"InventoryAdjustment.date":"Date",
		"InventoryAdjustment.periodId":"Period",
		"InventoryAdjustment.period":"Period",
		"InventoryAdjustment.branchId":"Branch",
		"InventoryAdjustment.branch":"Branch",
		"InventoryAdjustment.warehouseId":"Warehouse",
		"InventoryAdjustment.warehouse":"Warehouse",
		"InventoryAdjustment.locationId":"Location",
		"InventoryAdjustment.location":"Location",
		"InventoryAdjustment.picId":"PIC",
		"InventoryAdjustment.pic":"PIC",
		"InventoryAdjustment.description":"Description",
		"InventoryAdjustment.inventoryAdjustmentAccountId":"Adjustment",
		"InventoryAdjustment.inventoryAdjustmentAccount":"Adjustment",
		"Items":"Items",
		"Items":"Items",
		"InventoryAdjustment.lines":"Items",
		"Accounting":"Accounting",
		"Accounting":"Accounting",
		"InventoryAdjustment.accountMove":"Journals",
		"InventoryAdjustment.state":"State",
		"InventoryAdjustment.acctQtyVoid":"Insufficient Inventory",
		"InventoryAdjustment.hideZeroValues":"Hide Zero Values",
		//Inventory Adjustment Lines
		"InventoryAdjustmentLine":"Inventory Adjustment Lines",
		"inventory_adjustment_line":"Inventory Adjustment Lines",
		"InventoryAdjustmentLine.itemId":"Item",
		"InventoryAdjustmentLine.item":"Item",
		"InventoryAdjustmentLine.beginningBalance":"Beginning Balance",
		"InventoryAdjustmentLine.endingBalance":"Ending Balance",
		"InventoryAdjustmentLine.adjustment":"Adjustment",
		"InventoryAdjustmentLine.unit":"Unit",
		"InventoryAdjustmentLine.valuePerQuantity":"Value Per Quantity",
		"InventoryAdjustmentLine.totalValueAdjustment":"Total Value Adjustment",
		"InventoryAdjustmentLine.inventoryMovePlanId":"IMP",
		"InventoryAdjustmentLine.inventoryMovePlan":"IMP",
		//Inventory Move
		"WarehouseMove":"Inventory Move",
		"warehouse_move":"Inventory Move",
		"warehouse_move.confirm":"Confirm",
		"WarehouseMove.confirmTitle.action/confirm/out":"Confirm Out",
		"WarehouseMove.confirmContent.action/confirm/out":"Are you sure you want to confirm out this Inventory Move?",
		"WarehouseMove.success.action/confirm/out":"Inventory Move is successfully confirmed out",
		"WarehouseMove.confirmTitle.action/confirm/in":"Confirm In",
		"WarehouseMove.confirmContent.action/confirm/in":"Are you sure you want to confirm in this Inventory Move?",
		"WarehouseMove.success.action/confirm/in":"Inventory Move is successfully confirmed in",
		"WarehouseMove.confirmTitle.action/confirm":"Confirm Move",
		"WarehouseMove.confirmContent.action/confirm":"Are you sure you want to confirm that the items has been moved to destination location?",
		"WarehouseMove.success.action/confirm":"Inventory Move is successfully confirmed",
		"warehouse_move.draft":"Draft",
		"WarehouseMove.confirmTitle.action/draft":"Draft",
		"WarehouseMove.confirmContent.action/draft":"Draft?",
		"WarehouseMove.success.action/draft":"Inventory Move is successful",
		"warehouse_move.cancel":"Cancel",
		"WarehouseMove.confirmTitle.action/cancel":"Cancel",
		"WarehouseMove.confirmContent.action/cancel":"Are you sure you want to cancel this Inventory Move?",
		"WarehouseMove.success.action/cancel":"Inventory Move is successfully cancelled",
		"WarehouseMove.confirmTitle.print":"Print Confirmation",
		"WarehouseMove.confirmContent.print":"Are you sure you want to print this Inventory Move?",
		"WarehouseMove.success.print":"Inventory Move is successfully printed",
		"General Information":"General Information",
		"WarehouseMove.name":"Name",
		"WarehouseMove.date":"Date",
		"WarehouseMove.periodId":"Period",
		"WarehouseMove.period":"Period",
		"WarehouseMove.branchId":"Origin Branch",
		"WarehouseMove.branch":"Origin Branch",
		"WarehouseMove.branchInId":"Destination Branch",
		"WarehouseMove.branchIn":"Destination Branch",
		"WarehouseMove.location":"Origin Location",
		"WarehouseMove.locationIn":"Destination Location",
		"WarehouseMove.senderId":"Sender",
		"WarehouseMove.sender":"Sender",
		"WarehouseMove.receiverId":"Receiver",
		"WarehouseMove.receiver":"Receiver",
		"WarehouseMove.itemRequestId":"Item Request",
		"WarehouseMove.itemRequest":"Item Request",
		"WarehouseMove.description":"Description",
		"Items":"Items",
		"Items":"Items",
		"WarehouseMove.lines":"Items",
		"Accounting":"Accounting",
		"Accounting":"Accounting",
		"WarehouseMove.accountMove":"Journals",
		"WarehouseMove.state":"State",
		//Inventory Move Lines
		"WarehouseMoveLine":"Inventory Move Lines",
		"warehouse_move_line":"Inventory Move Lines",
		"WarehouseMoveLine.itemId":"Item",
		"WarehouseMoveLine.item":"Item",
		"WarehouseMoveLine.quantity":"Quantity",
		"WarehouseMoveLine.unitId":"Unit",
		"WarehouseMoveLine.unit":"Unit",
		"WarehouseMoveLine.inventoryMovePlanIn":"In",
		"WarehouseMoveLine.inventoryMovePlanOut":"Out",
		//Delivery Request
		"DeliveryRequest":"Delivery Request",
		"delivery_request":"Delivery Request",
		"delivery_request.request":"Request",
		"DeliveryRequest.confirmTitle.action/request":"Request",
		"DeliveryRequest.confirmContent.action/request":"Request?",
		"DeliveryRequest.success.action/request":"Delivery Request is successful",
		"DeliveryRequest.confirmTitle.action/fulfill":"Fulfill",
		"DeliveryRequest.confirmContent.action/fulfill":"Fulfilled?",
		"DeliveryRequest.success.action/fulfill":"Delivery Request is fulfilled",
		"DeliveryRequest.confirmTitle.action/approve_finance":"Approve Finance",
		"DeliveryRequest.confirmContent.action/approve_finance":"Approve Finance?",
		"DeliveryRequest.success.action/approve_finance":"Delivery Request is now approved by finance",
		"DeliveryRequest.confirmTitle.action/approve_production":"Approve Production",
		"DeliveryRequest.confirmContent.action/approve_production":"Approve Production?",
		"DeliveryRequest.success.action/approve_production":"Delivery Request is now approved by production",
		"delivery_request.cancel":"Cancel",
		"DeliveryRequest.confirmTitle.action/cancel":"Cancel",
		"DeliveryRequest.confirmContent.action/cancel":"Are you sure you want to cancel this Delivery Request?",
		"DeliveryRequest.success.action/cancel":"Delivery Request is successfully cancelled",
		"DeliveryRequest.name":"Number",
		"DeliveryRequest.date":"Date",
		"DeliveryRequest.periodId":"Period",
		"DeliveryRequest.period":"Period",
		"DeliveryRequest.projectId":"Project",
		"DeliveryRequest.project":"Project",
		"DeliveryRequest.customerId":"Customer",
		"DeliveryRequest.customer":"Customer",
		"DeliveryRequest.requestorId":"Requestor",
		"DeliveryRequest.requestor":"Requestor",
		"DeliveryRequest.picId":"PIC",
		"DeliveryRequest.pic":"PIC",
		"DeliveryRequest.supervisorApproverId":"Finance Approver",
		"DeliveryRequest.supervisorApprover":"Finance Approver",
		"DeliveryRequest.productionApproverId":"Production Approver",
		"DeliveryRequest.productionApprover":"Production Approver",
		"DeliveryRequest.branchId":"Branch",
		"DeliveryRequest.branch":"Branch",
		"DeliveryRequest.note":"Note",
		"Items":"Items",
		"Participants":"Participants",
		"DeliveryRequest.lines":"Items",
		"DeliveryRequest.state":"State",
		//Delivery Request Line
		"DeliveryRequestLine":"Delivery Request Line",
		"delivery_request_line":"Delivery Request Line",
		"DeliveryRequestLine.name":"Number",
		"DeliveryRequestLine.itemId":"Item",
		"DeliveryRequestLine.item":"Item",
		"DeliveryRequestLine.inventoryId":"Inventory",
		"DeliveryRequestLine.inventory":"Inventory",
		"DeliveryRequestLine.quantity":"Quantity",
		"DeliveryRequestLine.quantityBalance":"Quantity Balance",
		//Item Request
		"ItemRequest":"Item Request",
		"item_request":"Item Request",
		"item_request.request":"Request",
		"ItemRequest.confirmTitle.action/request":"Request",
		"ItemRequest.confirmContent.action/request":"Request?",
		"ItemRequest.success.action/request":"Item Request is successful",
		"item_request.cancel":"Cancel",
		"ItemRequest.confirmTitle.action/cancel":"Cancel",
		"ItemRequest.confirmContent.action/cancel":"Are you sure you want to cancel this Item Request?",
		"ItemRequest.success.action/cancel":"Item Request is successfully cancelled",
		"item_request.supervisor_approve":"Supervisor Approval",
		"ItemRequest.confirmTitle.action/supervisor_approve":"Supervisor Approval",
		"ItemRequest.confirmContent.action/supervisor_approve":"Are you sure you want to approve this Item Request as a supervisor?",
		"ItemRequest.success.action/supervisor_approve":"Item Request is successfully approved by supervisor",
		"item_request.manager_approve":"Manager Approval",
		"ItemRequest.confirmTitle.action/manager_approve":"Manager Approval",
		"ItemRequest.confirmContent.action/manager_approve":"Are you sure you want to approve this Item Request as a manager?",
		"ItemRequest.success.action/manager_approve":"Item Request is successfully approved by manager",
		"ItemRequest.name":"Number",
		"ItemRequest.date":"Date",
		"ItemRequest.periodId":"Period",
		"ItemRequest.period":"Period",
		"ItemRequest.projectId":"Project",
		"ItemRequest.project":"Project",
		"ItemRequest.customerId":"Customer",
		"ItemRequest.customer":"Customer",
		"ItemRequest.requestorId":"Requestor",
		"ItemRequest.requestor":"Requestor",
		"ItemRequest.picId":"PIC",
		"ItemRequest.pic":"PIC",
		"ItemRequest.itemCategory":"Item Category",
		"ItemRequest.itemList":"Item List",
		"ItemRequest.location":"Location",
		"ItemRequest.supervisorApproverId":"Supervisor Approver",
		"ItemRequest.supervisorApprover":"Supervisor Approver",
		"ItemRequest.productionApproverId":"Production Approver",
		"ItemRequest.productionApprover":"Production Approver",
		"ItemRequest.branchId":"Branch",
		"ItemRequest.branch":"Branch",
		"ItemRequest.note":"Note",
		"Items":"Items",
		"Participants":"Participants",
		"ItemRequest.lines":"Items",
		"ItemRequest.state":"State",
		"ItemRequest.fillItemList":"Fill Item List",
		"ItemRequest.sources":"Item Request List",
		//Item Request Line
		"ItemRequestLine":"Item Request Line",
		"item_request_line":"Item Request Line",
		"item_request_source":"Request Source List",
		"ItemRequestLine.name":"Number",
		"ItemRequestLine.itemId":"Item",
		"ItemRequestLine.item":"Item",
		"ItemRequestLine.itemCategory":"Item Category",
		"ItemRequestLine.unit":"Unit",
		"ItemRequestLine.averageSales":"Avg. Sales",
		"ItemRequestLine.currentStock":"Stock",
		"ItemRequestLine.inventoryId":"Inventory",
		"ItemRequestLine.inventory":"Inventory",
		"ItemRequestLine.quantity":"Quantity",
		"ItemRequestLine.pickedQuantity":"Quantity",
		"ItemRequestLine.quantityBalance":"Quantity Balance",
		"ItemRequestLine.itemRequest":"Request No.",
		"ItemRequestLine.itemRequestQuantity":"Request Qty",
		"ItemRequestLine.itemRequestUnit":"Request Unit",
		"ItemRequestLine.itemRequestBranch":"Branch",
		"ItemRequestLine.approved":"Approved",
		"ItemRequestLine.rejected":"Rejected",
		"ItemRequestLine.fromStock":"From Central Stock",
		"ItemRequestLine.fromPurchase":"From Purchase",
		"ItemRequestLine.quantityBalance":"Balance",
		"ItemRequestLine.purchasableQuantityBalance":"Purchasable QuantityBalance",
		
		"ItemRequestLine.purchaseQuantity":"Purchase Qty",
		"ItemRequestLine.receivedQuantity":"Received",
		"ItemRequestLine.pendingShipment":"Pending Shipment",
		"ItemRequestLine.notReceived":"Not Received",
		"ItemRequestLine.shipped":"Shipped",
		
		//Purchase Order
		"PurchaseOrder":"Purchase Order",
		"PurchaseOrderAdvanced":"Purchase Order Advanced",
		"purchase_order":"Purchase Order",
		"purchase_order.confirm":"Confirm",
		"PurchaseOrder.confirmTitle.action/confirm":"Confirm",
		"PurchaseOrder.confirmContent.action/confirm":"Are you sure you want to confirm this Purchase Order?",
		"PurchaseOrder.success.action/confirm":"Purchase Order is successfully confirmed",
		"purchase_order.cancel":"Cancel",
		"PurchaseOrder.confirmTitle.action/cancel":"Cancel",
		"PurchaseOrder.confirmContent.action/cancel":"Are you sure you want to cancel this Purchase Order?",
		"PurchaseOrder.success.action/cancel":"Purchase Order is successfully cancelled",
		"purchase_order.draft":"Draft",
		"PurchaseOrder.confirmTitle.action/draft":"Draft",
		"PurchaseOrder.confirmContent.action/draft":"Draft?",
		"PurchaseOrder.success.action/draft":"Purchase Order is successful",
		"PurchaseOrder.name":"Number",
		"PurchaseOrder.date":"Date",
		"PurchaseOrder.expectedDate":"Expected Arrival Date",
		"PurchaseOrder.currencyId":"Currency",
		"PurchaseOrder.currency":"Currency",
		"PurchaseOrder.vendorId":"Supplier",
		"PurchaseOrder.vendor":"Supplier",
		"PurchaseOrder.address":"Address",
		"PurchaseOrder.state":"State",
		"PurchaseOrder.branchId":"Branch",
		"PurchaseOrder.branch":"Branch",
		"PurchaseOrder.type":"Type",
		"ItemRequest.fillItemList":"Fill Item List",
		"Items":"Items",
		"Items":"Items",
		"PurchaseOrder.lines":"Items",
		"PurchaseOrder.totalDiscount":"Total Discount",
		"PurchaseOrder.subtotal":"Subtotal",
		"PurchaseOrder.totalTax":"Tax Total",
		"PurchaseOrder.total":"Grand Total",
		//Purchase Order Item
		"PurchaseOrderLine":"Purchase Order Item",
		"purchase_order_line":"Purchase Order Item",
		"PurchaseOrderLine.name":"Number",
		"PurchaseOrderLine.itemId":"Item",
		"PurchaseOrderLine.item":"Item",
		"PurchaseOrderLine.unitId":"Unit",
		"PurchaseOrderLine.unit":"Unit",
		"PurchaseOrderLine.quantity":"Quantity",
		"PurchaseOrderLine.request":"Request",
		"PurchaseOrderLine.unitPrice":"Price",
		"PurchaseOrderLine.discountPercentage":"D(%)",
		"PurchaseOrderLine.discountAmount":"Discount",
		"PurchaseOrderLine.subtotal":"Subtotal",
		"PurchaseOrderLine.taxes":"Taxes",
		"PurchaseOrderLine.taxAmount":"Tax Amount",
		"PurchaseOrderLine.total":"Total",
		"PurchaseOrderLine.deleteTitle":"Delete",
		"PurchaseOrderLine.deleteContent":"Are you sure want to delete this row?",
		//Term
		"Terms":"Term",
		"terms":"Term",
		"terms.activate":"Activate",
		"Terms.confirmTitle.activate":"Activate",
		"Terms.confirmContent.activate":"Are you sure you want to activate this Term?",
		"Terms.success.activate":"Term is successfully activated",
		"terms.deactivate":"Deactivate",
		"Terms.confirmTitle.deactivate":"Deactivate",
		"Terms.confirmContent.deactivate":"Are you sure you want to deactivate this Term?",
		"Terms.success.deactivate":"Term is successfully deactivated",
		"Terms.name":"Name",
		"Terms.days":"days",
		"Terms.state":"State",
		//Invoice
		"Invoice":"Invoice",
		"invoice":"Invoice",
		"invoice.draft":"Draft",
		"Invoice.confirmTitle.action/draft":"Draft",
		"Invoice.confirmContent.action/draft":"Draft?",
		"Invoice.success.action/draft":"Invoice is successful",
		"invoice.confirm":"Confirm",
		"Invoice.confirmTitle.action/confirm":"Confirm",
		"Invoice.confirmContent.action/confirm":"Are you sure you want to confirm this Invoice?",
		"Invoice.success.action/confirm":"Invoice is successfully confirmed",
		"invoice.cancel":"Cancel",
		"Invoice.confirmTitle.action/cancel":"Cancel",
		"Invoice.confirmContent.action/cancel":"Are you sure you want to cancel this Invoice?",
		"Invoice.confirmTitle.print":"Print Confirmation",
		"Invoice.confirmContent.print":"Are you sure you want to print this Invoice?",
		"Invoice.success.print":"You have successfully printed the Invoice",
		"Invoice.success.action/cancel":"Invoice is successfully cancelled",
		"Invoice.sendMail":"Send E-mail",
		"Invoice.confirmTitle.send_mail":"Send E-mail",
		"Invoice.confirmContent.send_mail":"Are you sure want to send this invoice to the customer via E-mail",
		"Invoice.success.send_mail":"Invoice E-mail has been sent",
		"Invoice Information":"Invoice Information",
		"Invoice.name":"Number",
		"Invoice.reference":"Reference",
		"Invoice.description":"Description",
		"Invoice.token":"Token",
		"Invoice.paymentMethod":"Payment Method",
		"Invoice.date":"Date",
		"Invoice.dueDate":"Due Date",
		"Invoice.periodId":"Period",
		"Invoice.period":"Period",
		"Invoice.type":"Type",
		"Invoice.taxInvoiceNumber":"Tax Invoice Number",
		"Invoice.shipment_date":"Shipment Date",
		"Invoice.receive_date":"Receive Date",
		"Invoice.termsId":"Terms",
		"Invoice.terms":"Terms",
		"Invoice.partnerId":"Partner",
		"Invoice.partner":"Partner",
		"Invoice.alias":"Alias",
		"Invoice.branchId":"Branch",
		"Invoice.branch":"Branch",
		"Invoice.currencyId":"Currency",
		"Invoice.currency":"Currency",
		"Invoice.exchangeRate":"Exchange Rate",
		"Invoice.receivableAccountId":"Receivable Account",
		"Invoice.receivableAccount":"Receivable Account",
		"Invoice.payableAccountId":"Payable Account",
		"Invoice.payableAccount":"Payable Account",
		"Items":"Items",
		"Items":"Items",
		"Invoice.lines":"Items",
		"Invoice.totalDiscount":"Total Discount",
		"Invoice.subtotal":"Subtotal",
		"Invoice.totalTax":"Tax Total",
		"Invoice.total":"Grand Total",
		"Taxes":"Taxes",
		"Taxes":"Taxes",
		"Invoice.taxSummaries":"Tax Summaries",
		"Reconciliation":"Reconciliation",
		"Reconciliation":"Reconciliation",
		"Invoice.valueBalance":"Balance",
		"Invoice.paid":"Paid",
		"Invoice.advanced":"Paid In Advance",
		"Invoice.discounted":"Additional Discount",
		"Invoice.returned":"Returned",
		"Invoice.writtenOff":"Written Off",
		"Invoice.vouchers":"Payments",
		"Invoice.advances":"Advance Reconciliations",
		"Invoice.returns":"Returns",
		"Invoice.writeoffs":"Write",
		"Accounting":"Accounting",
		"Accounting":"Accounting",
		"Invoice.accountMove":"Journals",
		"Invoicing":"Invoicing",
		"Invoicing":"Invoicing",
		"Invoice.collectiveInvoiceId":"Collective Invoice",
		"Invoice.collectiveInvoice":"Collective Invoice",
		"Invoice.state":"State",

		//invoice payment
		"Invoice.paymentDate":"Payment Date",
		"Invoice.confirmTitle.action/payment":"Payment",
		"Invoice.confirmContent.action/payment":"Please enter the payment date",
		"Invoice.success.action/payment":"Invoice has successfully paid",

		//Invoice Line
		"InvoiceLine":"Invoice Line",
		"invoice_line":"Invoice Line",
		"InvoiceLine.name":"Number",
		"InvoiceLine.itemId":"Item",
		"InvoiceLine.item":"Item",
		"InvoiceLine.description":"Description",
		"InvoiceLine.quantity":"Quantity",
		"InvoiceLine.unitId":"Unit",
		"InvoiceLine.unit":"Unit",
		"InvoiceLine.price":"Price",
		"InvoiceLine.discountPercentage":"D(%)",
		"InvoiceLine.discountAmount":"Discount",
		"InvoiceLine.subtotal":"Subtotal",
		"InvoiceLine.taxes":"Taxes",
		"InvoiceLine.taxAmount":"Taxes Amount",
		"InvoiceLine.total":"Total",
		"InvoiceLine.quantityBalance":"Unreturned Quantity",
		"InvoiceLine.valueBalance":"Value Balance",
		"InvoiceLine.grnLineId":"GRN",
		"InvoiceLine.grnLine":"GRN",
		"InvoiceLine.deliveryOrderLineId":"Delivery Order",
		"InvoiceLine.deliveryOrderLine":"Delivery Order",
		"InvoiceLine.discountedInvoiceId":"Discounted Invoice",
		"InvoiceLine.discountedInvoice":"Discounted Invoice",
		
		//Consignment
		"FinanceAPConsignmentPurchase":"Consignment Purchase",
		"FinanceARConsignmentSales":"Consignment Sales",
		"Consignment":"Consignment",
		"Invoice.parent":"Parent",
		"Invoice.children":"Children",

		//Voucher Fund
		"TaxSummary":"Voucher Fund",
		"tax_summary":"Voucher Fund",
		"TaxSummary.taxId":"Tax",
		"TaxSummary.tax":"Tax",
		"TaxSummary.amount":"Amount",
		"TaxSummary.taxedIn":"Taxed In",
		//Voucher Fund
		"VoucherTaxSummary":"Voucher Fund",
		"voucher_tax_summary":"Voucher Fund",
		"VoucherTaxSummary.taxId":"Tax",
		"VoucherTaxSummary.tax":"Tax",
		"VoucherTaxSummary.amount":"Amount",
		"VoucherTaxSummary.taxedIn":"Taxed In",
		//Payment Type
		"PaymentType":"Payment Method",
		"payment_type":"Payment Method",
		"payment_type.activate":"Activate",
		"PaymentType.confirmTitle.action/activate":"Activate",
		"PaymentType.confirmContent.action/activate":"Are you sure you want to activate this Payment Method?",
		"PaymentType.success.action/activate":"Payment Method is successfully activated",
		"payment_type.deactivate":"Deactivate",
		"PaymentType.confirmTitle.action/deactivate":"Deactivate",
		"PaymentType.confirmContent.action/deactivate":"Are you sure you want to deactivate this Payment Method?",
		"PaymentType.success.action/deactivate":"Payment Method is successfully deactivated",
		"PaymentType.name":"Name",
		"PaymentType.code":"Code When Printing (optional)",
		"PaymentType.type":"Type",
		"PaymentType.accountId":"Account",
		"PaymentType.account":"Account",
		"PaymentType.branchId":"Branch",
		"PaymentType.branch":"Branch",
		"PaymentType.currencyId":"Currency",
		"PaymentType.currency":"Currency",
		"PaymentType.printPaymentCode":"Print Payment Code",
		"PaymentType.giroClearingAccountId":"Giro Clearing Account",
		"PaymentType.giroClearingAccount":"Giro Clearing Account",
		"PaymentType.giroPaymentAccountId":"Giro Payment Account",
		"PaymentType.giroPaymentAccount":"Giro Payment Account",
		"PaymentType.giroReceiveAccountId":"Giro Receive Account",
		"PaymentType.giroReceiveAccount":"Giro Receive Account",
		"PaymentType.state":"State",
		"PaymentType.generalInfo":"General Info",
		"PaymentType.giro":"Giro",
		"PaymentType.additionalFee":"Additional Fee",
		"PaymentType.feeChargeTo":"Fee Charge to",
		"PaymentType.feeAmount":"Fee Amount",
		"PaymentType.feePercentage":"Fee Percentage",
		"PaymentType.feeAccount":"Fee Account",
		"PaymentType.additionalIncome":"Additional Income",
		"PaymentType.incomeAmount":"Income Amount",
		"PaymentType.incomePercentage":"Income Percentage",
		"PaymentType.incomeAccount":"Income Account",
		"PaymentType.isManuallyProcessed":"Manually Processed",
		"PaymentType.processTime":"Process Time (Days)",
		"PaymentType.autoRoundPayment":"Auto Round Payment",
		"PaymentType.roundTo":"Round To",
		//Payment
		"Voucher":"Payment",
		"voucher":"Payment",
		"voucher.draft":"Draft",
		"Voucher.confirmTitle.action/draft":"Draft",
		"Voucher.confirmContent.action/draft":"Draft?",
		"Voucher.success.action/draft":"Payment is successful",
		"voucher.confirm":"Confirm",
		"Voucher.confirmTitle.action/confirm":"Confirm",
		"Voucher.confirmContent.action/confirm":"Are you sure you want to confirm this Payment?",
		"Voucher.success.action/confirm":"Payment is successfully confirmed",
		"voucher.cancel":"Cancel",
		"Voucher.confirmTitle.action/cancel":"Cancel",
		"Voucher.confirmContent.action/cancel":"Are you sure you want to cancel this Payment?",
		"Voucher.success.action/cancel":"Payment is successfully cancelled",
		"Voucher.name":"Number",
		"Voucher.date":"Date",
		"Voucher.periodId":"Period",
		"Voucher.period":"Period",
		"Voucher.partnerId":"Partner",
		"Voucher.partner":"Partner",
		"Voucher.branchId":"Branch",
		"Voucher.branch":"Branch",
		"Voucher.currencyId":"Currency",
		"Voucher.currency":"Currency",
		"Voucher.description":"Description",
		"Voucher.paymentDescription":"Payment Method",
		"Voucher.total":"Payment Total",
		"Voucher.allocated":"Allocated",
		"Voucher.balance":"Balance",
		"Voucher.writeOffTotal":"Write Off Total",
		"Source Fund":"Source Fund",
		"Source Fund":"Source Fund",
		"Voucher.funds":"Funds",
		"Voucher.advanceReconciliations":"Allocate Advance Payments",
		"Voucher.autoAllocateToAdvance":"Auto Allocate to Advance Payment",
		"Allocations":"Allocations",
		"Allocations":"Allocations",
		"Voucher.lines":"Allocate to Invoices",
		"Voucher.items":"Allocate to Items",
		"Voucher.advanceAllocations":"Allocate to Advance Payments",
		"Voucher.advanceReturns":"Advance Payment Returns",
		"Accounting":"Accounting",
		"Accounting":"Accounting",
		"Voucher.accountMove":"Journals",
		"Others":"Others",
		"Others":"Others",
		"Voucher.transactionId":"Transaction ID",
		"Voucher.transaction":"Transaction ID",
		"Voucher.state":"State",
		"Voucher.accountLines":"Account List",
		"Voucher.contraAccountLines":"Contra Account List",
		"Voucher.account":"Account",
		"Voucher.value":"Value",
		"Voucher.confirmTitle.allocateBalance":"Allocate Difference to Account",
		"Voucher.difference":"Difference",
		"Voucher.differenceAccount":"Account",

		//BulkPayment
		"BulkPayment":"Bulk Payment",
		"bulk_payment":"Bulk Payment",

		//Fund Summary
		"FundSummary":"Fund Summary",
		"fund_summary":"Fund Summary",
		"FundSummary.name":"Number",
		"FundSummary.currencyId":"Currency",
		"FundSummary.currency":"Currency",
		"FundSummary.exchangeRate":"Exchange Rate",
		//Allocation Summary
		"AllocationSummary":"Allocation Summary",
		"allocation_summary":"Allocation Summary",
		"AllocationSummary.name":"Number",
		"AllocationSummary.currencyId":"Currency",
		"AllocationSummary.currency":"Currency",
		"AllocationSummary.exchangeRate":"Exchange Rate",
		//Voucher Fund
		"VoucherFund":"Voucher Fund",
		"voucher_fund":"Voucher Fund",
		"VoucherFund.name":"Number",
		"VoucherFund.date":"Date",
		"VoucherFund.total":"Total",
		"VoucherFund.currencyId":"Currency",
		"VoucherFund.currency":"Currency",
		"VoucherFund.targetCurrencyId":"Target Currency",
		"VoucherFund.targetCurrency":"Target Currency",
		"VoucherFund.exchangeRate":"Exchange Rate",
		"VoucherFund.targetAccountingCurrencyId":"Target Accounting Currency",
		"VoucherFund.targetAccountingCurrency":"Target Accounting Currency",
		"VoucherFund.accountingExchangeRate":"Exchange Rate",
		"VoucherFund.paymentTypeId":"Payment Method",
		"VoucherFund.paymentType":"Payment Method",
		"VoucherFund.bankName":"Bank",
		"VoucherFund.bankTransactionId":"Transaction ID",
		"VoucherFund.bankTransaction":"Transaction ID",
		"VoucherFund.giroId":"Giro",
		"VoucherFund.giro":"Giro",
		//Payment Allocation
		"VoucherLine":"Payment Allocation",
		"voucher_line":"Payment Allocation",
		"VoucherLine.name":"Number",
		"VoucherLine.invoiceId":"Invoice",
		"VoucherLine.invoice":"Invoice",
		"VoucherLine.currencyId":"Currency",
		"VoucherLine.currency":"Currency",
		"VoucherLine.targetCurrencyId":"Target Currency",
		"VoucherLine.targetCurrency":"Target Currency",
		"VoucherLine.exchangeRate":"Exchange Rate",
		"VoucherLine.targetAccountingCurrencyId":"Target Accounting Currency",
		"VoucherLine.targetAccountingCurrency":"Target Accounting Currency",
		"VoucherLine.accountingExchangeRate":"Current Exchange Rate",
		"VoucherLine.invoiceBalance":"Invoice Balance",
		"VoucherLine.payment":"Total",
		"VoucherLine.writeOffId":"Write Off",
		"VoucherLine.writeOff":"Write Off",
		"VoucherLine.writeOffAmount":"Write Off Amount",
		"VoucherLine.taxAllocationId":"Tax Allocation",
		"VoucherLine.taxAllocation":"Tax Allocation",
		"VoucherLine.taxAllocationAmount":"Tax Allocation Amount",
		"VoucherLine.description":"Note",
		//Tax Allocation
		"TaxAllocation":"Tax Allocation",
		"tax_allocation":"Tax Allocation",
		"TaxAllocation.lines":"Withholding Tax Allocation",
		//Voucher Fund
		"TaxAllocationLine":"Voucher Fund",
		"tax_allocation_line":"Voucher Fund",
		"TaxAllocationLine.taxId":"Tax",
		"TaxAllocationLine.tax":"Tax",
		"TaxAllocationLine.allocated":"Allocated",
		//Voucher Write Off
		"VoucherLineWriteOff":"Voucher Write Off",
		"voucher_line_write_off":"Voucher Write Off",
		"VoucherLineWriteOff.lines":"Write Off List",
		//Voucher Line Write Off
		"VoucherLineWriteOffLine":"Voucher Line Write Off",
		"voucher_line_write_off_line":"Voucher Line Write Off",
		"VoucherLineWriteOffLine.writeOffAccountId":"Write Off Account",
		"VoucherLineWriteOffLine.writeOffAccount":"Write Off Account",
		"VoucherLineWriteOffLine.allocated":"Amount",
		//Allocation To Advance
		"AdvanceAllocation":"Allocation To Advance",
		"advance_allocation":"Allocation To Advance",
		"AdvanceAllocation.name":"Number",
		"AdvanceAllocation.total":"Total",
		"AdvanceAllocation.currencyId":"Currency",
		"AdvanceAllocation.currency":"Currency",
		"AdvanceAllocation.targetCurrencyId":"Target Currency",
		"AdvanceAllocation.targetCurrency":"Target Currency",
		"AdvanceAllocation.exchangeRate":"Exchange Rate",
		"AdvanceAllocation.targetAccountingCurrencyId":"Target Accounting Currency",
		"AdvanceAllocation.targetAccountingCurrency":"Target Accounting Currency",
		"AdvanceAllocation.accountingExchangeRate":"Exchange Rate",
		"AdvanceAllocation.autoReconcile":"Auto Reconcile",
		"AdvanceAllocation.advanceId":"Advance Payment",
		"AdvanceAllocation.advance":"Advance Payment",
		//Voucher Fund
		"VoucherItem":"Voucher Fund",
		"voucher_item":"Voucher Fund",
		"VoucherItem.name":"Number",
		"VoucherItem.itemId":"Item",
		"VoucherItem.item":"Item",
		"VoucherItem.quantity":"Quantity",
		"VoucherItem.unitId":"Unit",
		"VoucherItem.unit":"Unit",
		"VoucherItem.currencyId":"Currency",
		"VoucherItem.currency":"Currency",
		"VoucherItem.targetCurrencyId":"Target Currency",
		"VoucherItem.targetCurrency":"Target Currency",
		"VoucherItem.exchangeRate":"Exchange Rate",
		"VoucherItem.targetAccountingCurrencyId":"Target Accounting Currency",
		"VoucherItem.targetAccountingCurrency":"Target Accounting Currency",
		"VoucherItem.accountingExchangeRate":"Current Exchange Rate",
		"VoucherItem.price":"Price",
		"VoucherItem.discountPercentage":"Discount (%)",
		"VoucherItem.discountAmount":"Discount Amount",
		"VoucherItem.subtotal":"Subtotal",
		"VoucherItem.taxes":"Taxes",
		"VoucherItem.taxAmount":"Taxes Amount",
		"VoucherItem.total":"Total",
		//Advance Payment
		"Advance":"Advance Payment",
		"advance":"Advance Payment",
		"advance.draft":"Draft",
		"Advance.confirmTitle.action/draft":"Draft",
		"Advance.confirmContent.action/draft":"Draft?",
		"Advance.success.action/draft":"Advance Payment is successful",
		"advance.confirm":"Confirm",
		"Advance.confirmTitle.action/confirm":"Confirm",
		"Advance.confirmContent.action/confirm":"Are you sure you want to confirm this Advance Payment?",
		"Advance.success.action/confirm":"Advance Payment is successfully confirmed",
		"advance.cancel":"Cancel",
		"Advance.confirmTitle.action/cancel":"Cancel",
		"Advance.confirmContent.action/cancel":"Are you sure you want to cancel this Advance Payment?",
		"Advance.success.action/cancel":"Advance Payment is successfully cancelled",
		"Advance.name":"Number",
		"Advance.reference":"Reference",
		"Advance.date":"Date",
		"Advance.periodId":"Period",
		"Advance.period":"Period",
		"Advance.type":"Type",
		"Advance.branchId":"Branch",
		"Advance.branch":"Branch",
		"Advance.partnerId":"Partner",
		"Advance.partner":"Partner",
		"Advance.currencyId":"Currency",
		"Advance.currency":"Currency",
		"Advance.exchangeRate":"Exchange Rate",
		"Advance.autoReconcile":"Auto Reconcile",
		"Advance.description":"Description",
		"Advance.total":"Total",
		"Allocation":"Allocation",
		"Allocation":"Allocation",
		"Advance.balance":"Balance",
		"Advance.lines":"Allocations",
		"Source Document":"Source Document",
		"Source Document":"Source Document",
		"Advance.voucherId":"Payment",
		"Advance.voucher":"Payment",
		"Advance.writeOffId":"Write Off Document",
		"Advance.writeOff":"Write Off Document",
		"Advance.returnId":"Return",
		"Advance.return":"Return",
		"Accounting":"Accounting",
		"Accounting":"Accounting",
		"Advance.accountMove":"Journals",
		"Advance.state":"State",
		//Advance Payment Line
		"AdvanceLine":"Advance Payment Line",
		"advance_line":"Advance Payment Line",
		"advance_line.draft":"Draft",
		"AdvanceLine.confirmTitle.action/draft":"Draft",
		"AdvanceLine.confirmContent.action/draft":"Draft?",
		"AdvanceLine.success.action/draft":"Advance Payment Line is successful",
		"advance_line.confirm":"Confirm",
		"AdvanceLine.confirmTitle.action/confirm":"Confirm",
		"AdvanceLine.confirmContent.action/confirm":"Are you sure you want to confirm this Advance Payment Line?",
		"AdvanceLine.success.action/confirm":"Advance Payment Line is successfully confirmed",
		"advance_line.cancel":"Cancel",
		"AdvanceLine.confirmTitle.action/cancel":"Cancel",
		"AdvanceLine.confirmContent.action/cancel":"Are you sure you want to cancel this Advance Payment Line?",
		"AdvanceLine.success.action/cancel":"Advance Payment Line is successfully cancelled",
		"AdvanceLine.name":"Number",
		"AdvanceLine.date":"Date",
		"AdvanceLine.periodId":"Period",
		"AdvanceLine.period":"Period",
		"AdvanceLine.currencyId":"Currency",
		"AdvanceLine.currency":"Currency",
		"AdvanceLine.targetCurrency":"Target Currency",
		"AdvanceLine.exchangeRate":"Exchange Rate",
		"AdvanceLine.targetAccountingCurrencyId":"Target Accounting Currency",
		"AdvanceLine.targetAccountingCurrency":"Target Accounting Currency",
		"AdvanceLine.accountingExchangeRate":"Current Exchange Rate",
		"AdvanceLine.advanceId":"Advance",
		"AdvanceLine.advance":"Advance",
		"AdvanceLine.invoiceId":"Invoice",
		"AdvanceLine.invoice":"Invoice",
		"AdvanceLine.voucherId":"Payment",
		"AdvanceLine.voucher":"Payment",
		"AdvanceLine.description":"Description",
		"AdvanceLine.total":"Total",
		"Accounting":"Accounting",
		"Accounting":"Accounting",
		"AdvanceLine.accountMove":"Journals",
		"AdvanceLine.state":"State",
		"AdvanceLine.returnVoucher":"Payment",
		//Advance Payment Line
		"AdvanceReturn":"Advance Payment Line",
		"advance_return":"Advance Payment Line",
		"advance_return.draft":"Draft",
		"AdvanceReturn.confirmTitle.action/draft":"Draft",
		"AdvanceReturn.confirmContent.action/draft":"Draft?",
		"AdvanceReturn.success.action/draft":"Advance Payment Line is successful",
		"advance_return.confirm":"Confirm",
		"AdvanceReturn.confirmTitle.action/confirm":"Confirm",
		"AdvanceReturn.confirmContent.action/confirm":"Are you sure you want to confirm this Advance Payment Line?",
		"AdvanceReturn.success.action/confirm":"Advance Payment Line is successfully confirmed",
		"advance_return.cancel":"Cancel",
		"AdvanceReturn.confirmTitle.action/cancel":"Cancel",
		"AdvanceReturn.confirmContent.action/cancel":"Are you sure you want to cancel this Advance Payment Line?",
		"AdvanceReturn.success.action/cancel":"Advance Payment Line is successfully cancelled",
		"AdvanceReturn.name":"Number",
		"AdvanceReturn.date":"Date",
		"AdvanceReturn.periodId":"Period",
		"AdvanceReturn.period":"Period",
		"AdvanceReturn.currencyId":"Currency",
		"AdvanceReturn.currency":"Currency",
		"AdvanceReturn.targetCurrencyId":"Target Currency",
		"AdvanceReturn.targetCurrency":"Target Currency",
		"AdvanceReturn.exchangeRate":"Exchange Rate",
		"AdvanceReturn.targetAccountingCurrencyId":"Target Accounting Currency",
		"AdvanceReturn.targetAccountingCurrency":"Target Accounting Currency",
		"AdvanceReturn.accountingExchangeRate":"Current Exchange Rate",
		"AdvanceReturn.advanceId":"Advance",
		"AdvanceReturn.advance":"Advance",
		"AdvanceReturn.voucherId":"Payment",
		"AdvanceReturn.voucher":"Payment",
		"AdvanceReturn.description":"Description",
		"AdvanceReturn.total":"Total",
		"Accounting":"Accounting",
		"Accounting":"Accounting",
		"AdvanceReturn.accountMove":"Journals",
		"AdvanceReturn.state":"State",
		//Write Off
		"WriteOff":"Write Off",
		"write_off":"Write Off",
		"write_off.draft":"Draft",
		"WriteOff.confirmTitle.action/draft":"Draft",
		"WriteOff.confirmContent.action/draft":"Draft?",
		"WriteOff.success.action/draft":"Write Off is successful",
		"write_off.confirm":"Confirm",
		"WriteOff.confirmTitle.action/confirm":"Confirm",
		"WriteOff.confirmContent.action/confirm":"Are you sure you want to confirm this Write Off?",
		"WriteOff.success.action/confirm":"Write Off is successfully confirmed",
		"write_off.cancel":"Cancel",
		"WriteOff.confirmTitle.action/cancel":"Cancel",
		"WriteOff.confirmContent.action/cancel":"Are you sure you want to cancel this Write Off?",
		"WriteOff.success.action/cancel":"Write Off is successfully cancelled",
		"WriteOff.confirmTitle.print":"Print Confirmation",
		"WriteOff.confirmContent.print":"Are you sure you want to print this Write Off?",
		"WriteOff.success.print":"Write Off is successfully printed",
		"WriteOff.transactionType":"Transaction Type",
		"WriteOff.name":"Number",
		"WriteOff.date":"Date",
		"WriteOff.periodId":"Period",
		"WriteOff.period":"Period",
		"WriteOff.branchId":"Branch",
		"WriteOff.branch":"Branch",
		"WriteOff.partnerId":"Partner",
		"WriteOff.partner":"Partner",
		"WriteOff.currencyId":"Currency",
		"WriteOff.currency":"Currency",
		"WriteOff.exchangeRate":"Exchange Rate",
		"WriteOff.writeOffAccountId":"Contra Account",
		"WriteOff.writeOffAccount":"Contra Account",
		"WriteOff.ignoreInvoiceBalance":"Ignore Invoice Balance",
		"WriteOff.overpayment":"Overpayment",
		"WriteOff.overpaymentAllocation":"Overpayment Allocation",
		"WriteOff.noteId":"Note",
		"WriteOff.note":"Note",
		"WriteOff.advanceId":"Advance Payment",
		"WriteOff.advance":"Advance Payment",
		"WriteOff.accountMove":"Journals",
		"WriteOff.state":"State",
		"WriteOff.lines":"Invoice List / Items",
		"WriteOff.type":"Tipe",
		//Write Off Invoice
		"WriteOffLine":"Write Off Invoice",
		"write_off_line":"Write Off Invoice",
		"write_off_line.draft":"Draft",
		"WriteOffLine.confirmTitle.action/draft":"Draft",
		"WriteOffLine.confirmContent.action/draft":"Draft?",
		"WriteOffLine.success.action/draft":"Write Off Invoice is successful",
		"write_off_line.confirm":"Confirm",
		"WriteOffLine.confirmTitle.action/confirm":"Confirm",
		"WriteOffLine.confirmContent.action/confirm":"Are you sure you want to confirm this Write Off Invoice?",
		"WriteOffLine.success.action/confirm":"Write Off Invoice is successfully confirmed",
		"write_off_line.cancel":"Cancel",
		"WriteOffLine.confirmTitle.action/cancel":"Cancel",
		"WriteOffLine.confirmContent.action/cancel":"Are you sure you want to cancel this Write Off Invoice?",
		"WriteOffLine.success.action/cancel":"Write Off Invoice is successfully cancelled",
		"WriteOffLine.name":"Number",
		"WriteOffLine.invoiceId":"Invoice",
		"WriteOffLine.invoice":"Invoice",
		"WriteOffLine.invoiceLineId":"Invoice Item",
		"WriteOffLine.invoiceLine":"Invoice Item",
		"WriteOffLine.itemId":"Item",
		"WriteOffLine.item":"Item",
		"WriteOffLine.quantity":"Quantity",
		"WriteOffLine.unitId":"Unit",
		"WriteOffLine.unit":"Unit",
		"WriteOffLine.unitPrice":"Price",
		"WriteOffLine.discountPercentage":"Discount (%)",
		"WriteOffLine.discountAmount":"Discount Amount",
		"WriteOffLine.total":"Total Discount",
		//Return
		"Return":"Return",
		"return":"Return",
		"return.draft":"Draft",
		"Return.confirmTitle.action/draft":"Draft",
		"Return.confirmContent.action/draft":"Draft?",
		"Return.success.action/draft":"Return is successful",
		"return.confirm":"Confirm",
		"Return.confirmTitle.action/confirm":"Confirm",
		"Return.confirmContent.action/confirm":"Are you sure you want to confirm this Return?",
		"Return.success.action/confirm":"Return is successfully confirmed",
		"return.cancel":"Cancel",
		"Return.confirmTitle.action/cancel":"Cancel",
		"Return.confirmContent.action/cancel":"Are you sure you want to cancel this Return?",
		"Return.success.action/cancel":"Return is successfully cancelled",
		"Return.confirmTitle.print":"Print Confirmation",
		"Return.confirmContent.print":"Are you sure you want to print this Return?",
		"Return.success.print":"Return is successfully printed",
		"Return.name":"Number",
		"Return.type":"Type",
		"Return.date":"Date",
		"Return.periodId":"Period",
		"Return.period":"Period",
		"Return.branchId":"Branch",
		"Return.branch":"Branch",
		"Return.invoiceId":"Invoice",
		"Return.invoice":"Invoice",
		"Return.instantSales":"Instant Sales",
		"Return.instantPurchase":"Instant Purchase",
		"Return.partnerId":"Partner",
		"Return.partner":"Partner",
		"Return.currencyId":"Currency",
		"Return.currency":"Currency",
		"Return.exchangeRate":"Exchange Rate",
		"Return.taxed":"Taxed",
		"Return.vat":"VAT",
		"Return.defaultVat":"Default VAT",
		"Return.acctQtyVoid":"Insufficient Inventory",
		"Return.writeOffAccountId":"Contra Account",
		"Return.writeOffAccount":"Contra Account",
		"Return.overpayment":"Overpayment",
		"Return.overpaymentAllocation":"Overpayment Allocation",
		"Return.ignoreInvoiceBalance":"Ignore Invoice Balance",
		"Return.ignoreFinance":"Without Invoice",
		"Return.advanceId":"Advance Payment",
		"Return.advance":"Advance Payment",
		"Return.noteId":"Note",
		"Return.note":"Note",
		"Items":"Items",
		"Items":"Items",
		"Invoices":"Invoices",
		"Return.lines":"Items",
		"Return.totalDiscount":"Total Discount",
		"Return.subtotal":"Subtotal",
		"Return.totalTax":"Tax Total",
		"Return.total":"Total",
		"Accounting":"Accounting",
		"Accounting":"Accounting",
		"Return.accountMove":"Journals",
		"Return.state":"State",
		"Return.contraAccountId":"Contra Account",
		"Return.contraAccount":"Contra Account",
		"Return.pickFromInstantSales":"Pick From Instant Sales",
		"Return.pickFromInstantPurchase":"Pick From Instant Purchase",
		"Return.paymentType":"Payment Method",
		"Return.print.success":"Printing Return...",
		"Return.changeItems":"Change Items",
		"Return.changeItemsDesc":"A return can only be processed for one %x% number. Are you sure you want to change it to number %y%?",
		"Return.changeItemsInvoice":"Invoice",
		"Return.changeItemsTrx":"Transaction",

		//Return Item
		"ReturnLine":"Return Item",
		"return_line":"Return Item",
		"ReturnLine.name":"Number",
		"ReturnLine.invoiceLineId":"Invoice",
		"ReturnLine.invoiceLine":"Invoice",
		"ReturnLine.itemId":"Item",
		"ReturnLine.item":"Item",
		"ReturnLine.description":"Note",
		"ReturnLine.quantity":"Quantity",
		"ReturnLine.unitId":"Unit",
		"ReturnLine.unit":"Unit",
		"ReturnLine.unitPrice":"Price",
		"ReturnLine.discountPercentage":"D(%)",
		"ReturnLine.discountAmount":"Discount",
		"ReturnLine.subtotal":"Subtotal",
		"ReturnLine.taxes":"Taxes",
		"ReturnLine.taxAmount":"Tax Amount",
		"ReturnLine.total":"Total",
		"ReturnLine.inventoryMovePlanId":"IMP",
		"ReturnLine.inventoryMovePlan":"IMP",
		//Payable Claim
		"PayableClaim":"Sales Adjustment",
		"payable_claim":"Sales Adjustment",
		"return.draft":"Draft",
		"PayableClaim.confirmTitle.action/draft":"Draft",
		"PayableClaim.confirmContent.action/draft":"Draft?",
		"PayableClaim.success.action/draft":"Sales Adjustment is successful",
		"PayableClaim.confirmTitle.action/confirm":"Confirm",
		"PayableClaim.confirmContent.action/confirm":"Are you sure you want to confirm this Sales Adjustment?",
		"PayableClaim.success.action/confirm":"Sales Adjustment is successfully confirmed",
		"return.cancel":"Cancel",
		"PayableClaim.confirmTitle.action/cancel":"Cancel",
		"PayableClaim.confirmContent.action/cancel":"Are you sure you want to cancel this Sales Adjustment?",
		"PayableClaim.success.action/cancel":"Sales Adjustment is successfully cancelled",
		"PayableClaim.confirmTitle.print":"Print Confirmation",
		"PayableClaim.confirmContent.print":"Are you sure you want to print this Sales Adjustment?",
		"PayableClaim.success.print":"Sales Adjustment is successfully printed",
		"PayableClaim.name":"Number",
		"PayableClaim.type":"Type",
		"PayableClaim.date":"Date",
		"PayableClaim.periodId":"Period",
		"PayableClaim.period":"Period",
		"PayableClaim.branchId":"Branch",
		"PayableClaim.branch":"Branch",
		"PayableClaim.invoiceId":"Invoice",
		"PayableClaim.invoice":"Invoice",
		"PayableClaim.partnerId":"Partner",
		"PayableClaim.partner":"Partner",
		"PayableClaim.currencyId":"Currency",
		"PayableClaim.currency":"Currency",
		"PayableClaim.exchangeRate":"Exchange Rate",
		"PayableClaim.taxed":"Taxed",
		"PayableClaim.writeOffAccountId":"Contra Account",
		"PayableClaim.writeOffAccount":"Contra Account",
		"PayableClaim.overpayment":"Overpayment",
		"PayableClaim.overpaymentAllocation":"Overpayment Allocation",
		"PayableClaim.ignoreInvoiceBalance":"Ignore Invoice Balance",
		"PayableClaim.ignoreFinance":"Without Invoice",
		"PayableClaim.advanceId":"Advance Payment",
		"PayableClaim.advance":"Advance Payment",
		"PayableClaim.noteId":"Note",
		"PayableClaim.note":"Note",
		"PayableClaim.lines":"Items",
		"PayableClaim.totalDiscount":"Total Discount",
		"PayableClaim.subtotal":"Subtotal",
		"PayableClaim.totalTax":"Tax Total",
		"PayableClaim.total":"Total",
		"PayableClaim.accountMove":"Journals",
		"PayableClaim.state":"State",
		"PayableClaim.contraAccountId":"Contra Account",
		"PayableClaim.contraAccount":"Contra Account",
		"PayableClaim.salesAgent":"Sales Agent",
		"PayableClaim.totalDiscount":"Total Discount",
		"PayableClaim.totalBonus":"Total Bonus",
		"PayableClaim.description":"Note",
		//Payable Claim Item
		"PayableClaimLine":"Return Item",
		"payable_claim_line":"Return Item",
		"PayableClaimLine.name":"Number",
		"PayableClaimLine.invoiceLineId":"Invoice",
		"PayableClaimLine.invoiceLine":"Invoice",
		"PayableClaimLine.itemId":"Item",
		"PayableClaimLine.item":"Item",
		"PayableClaimLine.description":"Note",
		"PayableClaimLine.quantity":"Quantity",
		"PayableClaimLine.unitId":"Unit",
		"PayableClaimLine.unit":"Unit",
		"PayableClaimLine.unitPrice":"Price",
		"PayableClaimLine.discountPercentage":"D(%)",
		"PayableClaimLine.discountAmount":"Discount",
		"PayableClaimLine.subtotal":"Subtotal",
		"PayableClaimLine.taxes":"Taxes",
		"PayableClaimLine.taxAmount":"Tax",
		"PayableClaimLine.total":"Total",
		"PayableClaimLine.inventoryMovePlanId":"IMP",
		"PayableClaimLine.inventoryMovePlan":"IMP",
		"PayableClaimLine.bonusPercentage":"B(%)",
		"PayableClaimLine.bonusAmount":"Bonus",
		//Giro
		"Giro":"Giro",
		"giro":"Giro",
		"giro.draft":"Draft",
		"Giro.confirmTitle.action/draft":"Draft",
		"Giro.confirmContent.action/draft":"Draft?",
		"Giro.success.action/draft":"Giro is successfully drafted",
		"giro.confirm":"Confirm",
		"Giro.confirmTitle.action/confirm":"Confirm",
		"Giro.confirmContent.action/confirm":"Are you sure you want to confirm this Giro?",
		"Giro.success.action/confirm":"Giro is successfully confirmed",
		"giro.clear":"Clear",
		"Giro.confirmTitle.action/clear":"Giro Clearing",
		"Giro.confirmContent.action/clear":"Are you sure that this Giro is cleared?",
		"Giro.success.action/clear":"Giro is successfully cleared",
		"giro.cancel":"Cancel",
		"Giro.confirmTitle.action/cancel":"Cancel",
		"Giro.confirmContent.action/cancel":"Are you sure you want to cancel this Giro?",
		"Giro.success.action/cancel":"Giro is successfully cancelled",
		"giro.reopen":"Reopen",
		"Giro.confirmTitle.action/reopen":"Reopen Giro",
		"Giro.confirmContent.action/reopen":"Are you sure you want to reopen this Giro?",
		"Giro.success.action/reopen":"Giro is successfully reopened",
		"giro.cancel_clearing":"Cancel Clearing",
		"Giro.confirmTitle.action/cancel_clearing":"Cancel Clearing",
		"Giro.confirmContent.action/cancel_clearing":"Are you sure you want to cancel this Giro's clearance?",
		"Giro.success.action/cancel_clearing":"Giro's clearance is successfully cancelled",
		"Giro.name":"Number",
		"Giro.partnerId":"Partner",
		"Giro.partner":"Partner",
		"Giro.date":"Date Receive",
		"Giro.dueDate":"Due Date",
		"Giro.expireDate":"Expire Date",
		"Giro.clearingDate":"Clearing Date",
		"Giro.type":"Type",
		"Giro.total":"Total",
		"Giro.accountMove":"Journals",
		"Giro.state":"State",
		"Giro.cancellationType":"Cancellation Type",
		"Giro.cancellationDate":"Cancellation Date",
		"Giro.noteDate":"Note Date",
		"Giro.discountedInvoice":"Discounted Invoice",
		"Giro.writeOffAccount":"Write Off Account",
		"Giro.clearingAccount":"Clearing Account",
		"Giro.cancellationAccount":"Cancellation Account",
		"Giro.cancellationReason":"Cancellation Reason",
		"Cancellation":"Cancellation",
		//Token Move
		"TokenMove":"Token Move",
		"token_move":"Token Move",
		"TokenMove.name":"Name",
		"TokenMove.date":"date",
		"TokenMove.userId":"User",
		"TokenMove.user":"User",
		"TokenMove.debit":"Debit",
		"TokenMove.credit":"Credit",
		//Collective Invoice
		"CollectiveInvoice":"Collective Invoice",
		"collective_invoice":"Collective Invoice",
		"collective_invoice.draft":"Draft",
		"CollectiveInvoice.confirmTitle.action/draft":"Draft",
		"CollectiveInvoice.confirmContent.action/draft":"Draft?",
		"CollectiveInvoice.success.action/draft":"Collective Invoice is successful",
		"collective_invoice.confirm":"Confirm",
		"CollectiveInvoice.confirmTitle.action/confirm":"Confirm",
		"CollectiveInvoice.confirmContent.action/confirm":"Are you sure you want to confirm this Collective Invoice?",
		"CollectiveInvoice.success.action/confirm":"Collective Invoice is successfully confirmed",
		"collective_invoice.cancel":"Cancel",
		"CollectiveInvoice.confirmTitle.action/cancel":"Cancel",
		"CollectiveInvoice.confirmContent.action/cancel":"Are you sure you want to cancel this Collective Invoice?",
		"CollectiveInvoice.confirmTitle.print":"Print Confirmation",
		"CollectiveInvoice.confirmContent.print":"Are you sure you want to print this Collective Invoice?",
		"CollectiveInvoice.success.print":"You have successfully printed the Collective Invoice",
		"CollectiveInvoice.success.print_customer":"You have successfully printed the Collective Invoice",
		"CollectiveInvoice.success.print_supplier":"You have successfully printed the Collective Invoice",
		"CollectiveInvoice.success.action/cancel":"Collective Invoice is successfully cancelled",
		"CollectiveInvoice.name":"Number",
		"CollectiveInvoice.date":"Date",
		"CollectiveInvoice.partnerId":"Partner",
		"CollectiveInvoice.partner":"Partner",
		"CollectiveInvoice.alias":"Alias",
		"CollectiveInvoice.startDate":"Start Date",
		"CollectiveInvoice.endDate":"End Date",
		"CollectiveInvoice.branchId":"Branch",
		"CollectiveInvoice.branch":"Branch",
		"CollectiveInvoice.currencyId":"Currency",
		"CollectiveInvoice.currency":"Currency",
		"CollectiveInvoice.invoices":"Invoices",
		"CollectiveInvoice.total":"Grand Total",
		"CollectiveInvoice.view":"View",
		"CollectiveInvoice.confirmContent.print_customer":"Are you sure you want to print this Collective Invoice",
		"CollectiveInvoice.confirmTitle.print_customer":"Print Confirmation",
		//Country
		"Country":"Country",
		"country":"Country",
		"country.activate":"Activate",
		"Country.confirmTitle.action/activate":"Activate",
		"Country.confirmContent.action/activate":"Are you sure you want to activate this Country?",
		"Country.success.action/activate":"Country is successfully activated",
		"country.deactivate":"Deactivate",
		"Country.confirmTitle.action/deactivate":"Deactivate",
		"Country.confirmContent.action/deactivate":"Are you sure you want to deactivate this Country?",
		"Country.success.action/deactivate":"Country is successfully deactivated",
		"Country.name":"Name",
		"Country.code":"Code",
		"Country.state":"State",
		//Province
		"Province":"Province",
		"province":"Province",
		"province.activate":"Activate",
		"Province.confirmTitle.action/activate":"Activate",
		"Province.confirmContent.action/activate":"Are you sure you want to activate this Province?",
		"Province.success.action/activate":"Province is successfully activated",
		"province.deactivate":"Deactivate",
		"Province.confirmTitle.action/deactivate":"Deactivate",
		"Province.confirmContent.action/deactivate":"Are you sure you want to deactivate this Province?",
		"Province.success.action/deactivate":"Province is successfully deactivated",
		"Province.name":"Name",
		"Province.code":"Code",
		"Province.countryId":"Country",
		"Province.country":"Country",
		"Province.state":"State",
		//City
		"City":"City",
		"city":"City",
		"city.activate":"Activate",
		"City.confirmTitle.action/activate":"Activate",
		"City.confirmContent.action/activate":"Are you sure you want to activate this City?",
		"City.success.action/activate":"City is successfully activated",
		"city.deactivate":"Deactivate",
		"City.confirmTitle.action/deactivate":"Deactivate",
		"City.confirmContent.action/deactivate":"Are you sure you want to deactivate this City?",
		"City.success.action/deactivate":"City is successfully deactivated",
		"City.name":"Name",
		"City.code":"Code",
		"City.provinceId":"Province",
		"City.province":"Province",
		"City.state":"State",
		//Node
		"Node":"Node",
		"node":"Node",
		"node.activate":"Activate",
		"Node.confirmTitle.action/activate":"Activate",
		"Node.confirmContent.action/activate":"Are you sure you want to activate this Node?",
		"Node.success.action/activate":"Node is successfully activated",
		"node.deactivate":"Deactivate",
		"Node.confirmTitle.action/deactivate":"Deactivate",
		"Node.confirmContent.action/deactivate":"Are you sure you want to deactivate this Node?",
		"Node.success.action/deactivate":"Node is successfully deactivated",
		"Node.name":"Name",
		"Node.partnerId":"Partner",
		"Node.partner":"Partner",
		"Node.state":"State",
		//Partner Node
		"PartnerNode":"Partner Node",
		"partner_node":"Partner Node",
		//Users
		"User":"Users",
		"user":"Users",
		"User.userAccount":"User Account",
		"User.password":"Password",
		"User.passwordChangeNotes":"Notes : Leave password and password confirmation blank if you don't want to change the password",
		"User.administration":"Administration",
		"user.activate":"Activate",
		"User.confirmTitle.action/activate":"Activate",
		"User.confirmContent.action/activate":"Are you sure you want to activate this Users?",
		"User.success.action/activate":"Users is successfully activated",
		"user.deactivate":"Deactivate",
		"User.confirmTitle.action/deactivate":"Deactivate",
		"User.confirmContent.action/deactivate":"Are you sure you want to deactivate this Users?",
		"User.success.action/deactivate":"Users is successfully deactivated",
		"User.username":"Username",
		"User.email":"E-mail",
		"User.passwordChange":"Password Confirmation",
		"User.mobile":"Mobile",
		"User.partnerId":"Partner",
		"User.groupId":"User Group",
		"User.partner":"Partner",
		"User.lastLogin":"Last Login",
		"User.lastActive":"Last Active",
		"User.state":"State",
		"User.allBranch":"All",
		"User.defaultBranchId":"Default Branch",
		"User.defaultBranch":"Default Branch",
		"User.branches":"Branches",
		"User.profitSharingPercentage":"Profit Sharing (%)",
		//User Group
		"UserGroup":"User Group",
		"user_group":"User Group",
		"user_group.activate":"Activate",
		"UserGroup.confirmTitle.action/activate":"Activate",
		"UserGroup.confirmContent.action/activate":"Are you sure you want to activate this User Group?",
		"UserGroup.success.action/activate":"User Group is successfully activated",
		"user_group.deactivate":"Deactivate",
		"UserGroup.confirmTitle.action/deactivate":"Deactivate",
		"UserGroup.confirmContent.action/deactivate":"Are you sure you want to deactivate this User Group?",
		"UserGroup.success.action/deactivate":"User Group is successfully deactivated",
		"UserGroup.name":"Name",
		"UserGroup.state":"State",
		"UserGroup.accessRights":"Access",
		"UserGroup.menus":"Menus",
		//Branch
		"Branch":"Branch",
		"branch":"Branch",
		"branch.activate":"Activate",
		"Branch.confirmTitle.action/activate":"Activate",
		"Branch.confirmContent.action/activate":"Are you sure you want to activate this Branch?",
		"Branch.success.action/activate":"Branch is successfully activated",
		"branch.deactivate":"Deactivate",
		"Branch.confirmTitle.action/deactivate":"Deactivate",
		"Branch.confirmContent.action/deactivate":"Are you sure you want to deactivate this Branch?",
		"Branch.success.action/deactivate":"Branch is successfully deactivated",
		"Branch.name":"Name",
		"Branch.code":"Code",
		"Branch.address":"Address",
		"Branch.countryId":"Country",
		"Branch.country":"Country",
		"Branch.provinceId":"Province",
		"Branch.province":"Province",
		"Branch.cityId":"City",
		"Branch.city":"City",
		"Branch.state":"State",
		//Menu Category
		"MenuCategory":"Menu Category",
		"menu_category":"Menu Category",
		"menu_category.activate":"Activate",
		"MenuCategory.confirmTitle.action/activate":"Activate",
		"MenuCategory.confirmContent.action/activate":"Are you sure you want to activate this Menu Category?",
		"MenuCategory.success.action/activate":"Menu Category is successfully activated",
		"menu_category.deactivate":"Deactivate",
		"MenuCategory.confirmTitle.action/deactivate":"Deactivate",
		"MenuCategory.confirmContent.action/deactivate":"Are you sure you want to deactivate this Menu Category?",
		"MenuCategory.success.action/deactivate":"Menu Category is successfully deactivated",
		"MenuCategory.name":"Name",
		"MenuCategory.state":"State",
		"MenuCategory.orderNum":"Order",
		//Menu
		"Menu":"Menu",
		"menu":"Menu",
		"menu.activate":"Activate",
		"Menu.confirmTitle.action/activate":"Activate",
		"Menu.confirmContent.action/activate":"Are you sure you want to activate this Menu?",
		"Menu.success.action/activate":"Menu is successfully activated",
		"menu.deactivate":"Deactivate",
		"Menu.confirmTitle.action/deactivate":"Deactivate",
		"Menu.confirmContent.action/deactivate":"Are you sure you want to deactivate this Menu?",
		"Menu.success.action/deactivate":"Menu is successfully deactivated",
		"Menu.code":"Code",
		"Menu.name":"Name",
		"Menu.state":"State",
		"Menu.categoryId":"Category",
		"Menu.category":"Category",
		"Menu.orderNum":"Name",
		//Access Right Category
		"AccessRightCategory":"Access Right Category",
		"access_right_category":"Access Right Category",
		"access_right_category.activate":"Activate",
		"AccessRightCategory.confirmTitle.action/activate":"Activate",
		"AccessRightCategory.confirmContent.action/activate":"Are you sure you want to activate this Access Right Category?",
		"AccessRightCategory.success.action/activate":"Access Right Category is successfully activated",
		"access_right_category.deactivate":"Deactivate",
		"AccessRightCategory.confirmTitle.action/deactivate":"Deactivate",
		"AccessRightCategory.confirmContent.action/deactivate":"Are you sure you want to deactivate this Access Right Category?",
		"AccessRightCategory.success.action/deactivate":"Access Right Category is successfully deactivated",
		"AccessRightCategory.name":"Name",
		"AccessRightCategory.state":"State",
		"AccessRightCategory.orderNum":"Name",
		//Access Rights
		"AccessRight":"Access Rights",
		"access_right":"Access Rights",
		"access_right.activate":"Activate",
		"AccessRight.confirmTitle.action/activate":"Activate",
		"AccessRight.confirmContent.action/activate":"Are you sure you want to activate this Access Rights?",
		"AccessRight.success.action/activate":"Access Rights is successfully activated",
		"access_right.deactivate":"Deactivate",
		"AccessRight.confirmTitle.action/deactivate":"Deactivate",
		"AccessRight.confirmContent.action/deactivate":"Are you sure you want to deactivate this Access Rights?",
		"AccessRight.success.action/deactivate":"Access Rights is successfully deactivated",
		"AccessRight.code":"Code",
		"AccessRight.description":"Description",
		"AccessRight.state":"State",
		"AccessRight.categoryId":"Category",
		"AccessRight.category":"Category",
		"AccessRight.orderNum":"Name",
		//Sales Order
		"SalesOrder":"Sales Order",
		"sales_order":"Sales Order",
		"sales_order.draft":"Draft",
		"SalesOrder.confirmTitle.action/draft":"Draft",
		"SalesOrder.confirmContent.action/draft":"Are you sure to revert this back to draft?",
		"SalesOrder.success.action/draft":"Sales Order is successfully reverted back to draft",
		"sales_order.payment":"Payment",
		"SalesOrder.confirmTitle.action/payment":"Payment",
		"SalesOrder.confirmContent.action/payment":"Please select payment method and date",
		"SalesOrder.success.action/payment":"Sales order is sucessfully set to paid",
		"sales_order.confirm":"Offer",
		"SalesOrder.confirmTitle.action/offer":"Offer",
		"SalesOrder.confirmContent.action/offer":"Are you sure you want to offer this Sales Order to the customer?",
		"SalesOrder.success.action/offer":"Sales Order is successfully offered",
		"sales_order.cancel":"Cancel",
		"SalesOrder.confirmTitle.action/cancel":"Cancel",
		"SalesOrder.confirmContent.action/cancel":"Are you sure you want to cancel this Sales Order?",
		"SalesOrder.success.action/cancel":"Sales Order is successfully cancelled",
		"SalesOrder.success.action/reject":"Sales Order is successfully rejected",
		"SalesOrder.failed.action/reject":"Sales Order rejection failed, please contact the administrator",
		"SalesOrder.confirmTitle.action/resolve":"Resolve Moderation",
		"SalesOrder.confirmContent.action/resolve":"Are you sure you want to resolve this Sales Order's Moderation?",
		"SalesOrder.success.action/resolve":"Sales Order's Moderation is successfully resolved",
		"SalesOrder.name":"Number",
		"SalesOrder.reference":"Reference",
		"SalesOrder.date":"Date",
		"SalesOrder.periodId":"Period",
		"SalesOrder.period":"Period",
		"SalesOrder.customerId":"Customer",
		"SalesOrder.customer":"Customer",
		"SalesOrder.alias":"Alias",
		"SalesOrder.branchId":"Branch",
		"SalesOrder.branch":"Branch",
		"SalesOrder.note":"Note",
		"Items":"Items",
		"Items":"Items",
		"SalesOrder.lines":"Items",
		"SalesOrder.totalDiscount":"Total Discount",
		"SalesOrder.subtotal":"Subtotal",
		"SalesOrder.totalTax":"After Tax",
		"SalesOrder.total":"Grand Total",
		"Customer Additional Info":"Customer Additional Info",
		"Customer Additional Info":"Customer Additional Info",
		"SalesOrder.salesOrderId":"Sales Order",
		"SalesOrder.salesOrder":"Sales Order",
		"SalesOrder.picName":"PIC Name",
		"SalesOrder.picPhone":"PIC Contact",
		"SalesOrder.picEmailAddress":"PIC Email",
		"SalesOrder.picPosition":"PIC Position",
		"SalesOrder.shippingAddress":"Shipping Address",
		"SalesOrder.shippingCityId":"Shipping City",
		"SalesOrder.shippingCity":"Shipping City",
		"SalesOrder.shippingProvinceId":"Shipping Province",
		"SalesOrder.shippingProvince":"Shipping Province",
		"SalesOrder.shippingPostalCode":"Shipping Postal Code",
		"SalesOrder.latitude":"Latitude",
		"SalesOrder.longitude":"Longitude",
		"Other Information":"Other Information",
		"Other Information":"Other Information",
		"SalesOrder.userId":"Customer User",
		"SalesOrder.user":"Customer User",
		"SalesOrder.state":"State",
		"SalesOrder.paymentMethod":"Payment Method",
		//Sales Order Line
		"SalesOrderLine":"Sales Order Line",
		"sales_order_line":"Sales Order Line",
		"SalesOrderLine.name":"Number",
		"SalesOrderLine.itemId":"Item",
		"SalesOrderLine.item":"Item",
		"SalesOrderLine.additionalCharges":"Additionals",
		"SalesOrderLine.startDate":"Start Date",
		"SalesOrderLine.endDate":"End Date",
		"SalesOrderLine.quantity":"Quantity",
		"SalesOrderLine.unitId":"Unit",
		"SalesOrderLine.unit":"Unit",
		"SalesOrderLine.unitPrice":"Price",
		"SalesOrderLine.discountPercentage":"Discount (%)",
		"SalesOrderLine.discountAmount":"Discount Amount",
		"SalesOrderLine.subtotal":"Subtotal",
		"SalesOrderLine.taxes":"Taxes",
		"SalesOrderLine.taxAmount":"Tax Amount",
		"SalesOrderLine.total":"Total",
		"SalesOrderLine.accountSplitId":"Account Split",
		"SalesOrderLine.accountSplit":"Account Split",
		"SalesOrderLine.vendorId":"Vendor",
		"SalesOrderLine.vendor":"Vendor",
		//sales Order - Cancellation
		"SalesOrder.cancellationCategory":"Cancellation Category",
		"SalesOrder.cancellationReason":"Cancellation Reason",
		//Sales Order - Rejection
		"SalesOrderRejection":"Order Rejection",
		"SalesOrderRejection.RejectOrder":"Reject Order",
		"SalesOrderRejection.RejectionCategory":"Rejection Category",
		"SalesOrderRejection.RejectionReason":"Rejection Reason",
		//sales order - customer requested a moderation
		"customerReqestedModeration":"Customer requested a moderation",
		"SalesOrder.moderationCategory":"Moderation Category",
		"SalesOrder.moderationMessage":"Moderation Message",
		"SalesOrder.callCustomer":"Call Customer",
		"SalesOrder.moderationResolve":"Moderation Resolve",
		"SalesOrder.BeingModerated":"Order is being moderated",
		"SalesOrder.BeingModeratedDesc":"Please wait until admin resolve the moderation",
		//sales order - history
		"SalesOrder.history":"History",
		"SalesOrder.history.salesOrderDate":"Order Date",
		"SalesOrder.history.offerDate":"Offered Date",
		"SalesOrder.history.waitingForApprovalDate":"Waiting Approval Date",
		"SalesOrder.history.paymentDate":"Payment Date",
		"SalesOrder.history.confirmationDate":"Confirmation Date",
		"SalesOrder.history.vendorAcceptanceDate":"Vendor Acceptance Date",
		"SalesOrder.history.deliveryDate":"Delivery Date",
		"SalesOrder.history.deliveredDate":"Delivered Date",
		"SalesOrder.history.completionDate":"Completion Date",
		"SalesOrder.history.rejectionDate":"Rejection Date",
		"SalesOrder.history.cancellationDate":"Cancellation Date",
		//Account Split
		"AccountSplit":"Voucher Write Off",
		"account_split":"Voucher Write Off",
		"AccountSplit.lines":"Write Off List",
		//Account Split Line
		"AccountSplitLine":"Voucher Line Write Off",
		"account_split_line":"Voucher Line Write Off",
		"AccountSplitLine.partnerId":"Partner",
		"AccountSplitLine.partner":"Partner",
		"AccountSplitLine.allocated":"Amount",
		//Sales Order Line Extend Request
		"SalesOrderLineExtendRequest":"Sales Order Extend",
		"sales_order_line_extend_request":"Sales Order Extend",
		"SalesOrderLineExtendRequest.name":"Number",
		"Sales Information":"Sales Information",
		"SalesOrderLineExtendRequest.salesOrderId":"Sales Order",
		"SalesOrderLineExtendRequest.salesOrder":"Sales Order",
		"SalesOrderLineExtendRequest.itemId":"Item",
		"SalesOrderLineExtendRequest.item":"Item",
		"SalesOrderLineExtendRequest.partnerId":"Customer",
		"SalesOrderLineExtendRequest.partner":"Customer",
		"SalesOrderLineExtendRequest.vendorId":"Vendor",
		"SalesOrderLineExtendRequest.vendor":"Vendor",
		"SalesOrderLineExtendRequest.startDate":"Start Date",
		"SalesOrderLineExtendRequest.endDate":"End Date",
		"Contact And Location":"Contact And Location",
		"SalesOrderLineExtendRequest.picName":"PIC Name",
		"SalesOrderLineExtendRequest.picPhone":"PIC Contact",
		"SalesOrderLineExtendRequest.picEmailAddress":"PIC Email",
		"SalesOrderLineExtendRequest.picPosition":"PIC Address",
		"SalesOrderLineExtendRequest.ssid":"SSID",
		"SalesOrderLineExtendRequest.name":"Shipping Address",
		"SalesOrderLineExtendRequest.shippingCityId":"Shipping City",
		"SalesOrderLineExtendRequest.shippingCity":"Shipping City",
		"SalesOrderLineExtendRequest.shippingProvinceId":"Shipping Province",
		"SalesOrderLineExtendRequest.shippingProvince":"Shipping Province",
		"SalesOrderLineExtendRequest.name":"Postal Code",
		"SalesOrderLineExtendRequest.latitude":"Latitude",
		"SalesOrderLineExtendRequest.longitude":"Longitude",
		"SalesOrderLineExtendRequest.state":"State",
		//Vendor Delivery Request
		"VendorDeliveryRequest":"Vendor Delivery Request",
		"vendor_delivery_request":"Vendor Delivery Request",
		"vendor_delivery_request.draft":"Draft",
		"VendorDeliveryRequest.confirmTitle.action/draft":"Draft",
		"VendorDeliveryRequest.confirmContent.action/draft":"Are you sure you want to revert this delivery request to draft?",
		"VendorDeliveryRequest.success.action/draft":"Delivery request is successfully reverted back to draft",
		"vendor_delivery_request.accept":"Accept",
		"VendorDeliveryRequest.confirmTitle.action/accept":"Accept",
		"VendorDeliveryRequest.confirmContent.action/accept":"Are you sure you want to accept this delivery request?",
		"VendorDeliveryRequest.success.action/accept":"Delivery request is successfully accepted",
		"vendor_delivery_request.Approve":"Approve",
		"VendorDeliveryRequest.confirmTitle.action/approve":"Approve",
		"VendorDeliveryRequest.confirmContent.action/approve":"Are you sure you want to approve this delivery request?",
		"VendorDeliveryRequest.success.action/approve":"Delivery request is successfully approved",
		"vendor_delivery_request.Ready":"Ready",
		"VendorDeliveryRequest.confirmTitle.action/ready":"Ready",
		"VendorDeliveryRequest.confirmContent.action/ready":"Are you sure you want to change this delivery request status to ready for delivery",
		"VendorDeliveryRequest.success.action/ready":"Delivery request is ready to delivered",
		"vendor_delivery_request.reject":"Reject",
		"VendorDeliveryRequest.confirmTitle.action/reject":"Reject",
		"VendorDeliveryRequest.confirmContent.action/reject":"Are you sure you want to reject this delivery request?",
		"VendorDeliveryRequest.success.action/reject":"You have rejected this delivery request",
		"vendor_delivery_request.ongoing":"Ongoing Delivery",
		"VendorDeliveryRequest.confirmTitle.action/ongoing":"Ongoing Delivery",
		"VendorDeliveryRequest.confirmContent.action/ongoing":"Are you sure you want change this delivery request status to ongoing delivery?",
		"VendorDeliveryRequest.success.action/ongoing":"Delivery request status is successfully updated",
		"vendor_delivery_request.delivered":"Delivered",
		"VendorDeliveryRequest.confirmTitle.action/delivered":"Delivered",
		"VendorDeliveryRequest.confirmContent.action/delivered":"Are you sure you want change this delivery request status to delivered?",
		"VendorDeliveryRequest.success.action/delivered":"Delivery request status is successfully updated",
		"VendorDeliveryRequest.confirmTitle.action/cancel":"Cancel",
		"VendorDeliveryRequest.confirmContent.action/cancel":"Are you sure you want to cancel this Vendor Delivery Request?",
		"VendorDeliveryRequest.success.action/cancel":"Vendor Delivery Request is successfully cancelled",
		"VendorDeliveryRequest.name":"Number",
		"VendorDeliveryRequest.reference":"Reference",
		"VendorDeliveryRequest.date":"Date",
		"VendorDeliveryRequest.periodId":"Period",
		"VendorDeliveryRequest.period":"Period",
		"VendorDeliveryRequest.vendorId":"Vendor",
		"VendorDeliveryRequest.vendor":"Vendor",
		"VendorDeliveryRequest.alias":"Alias",
		"VendorDeliveryRequest.branchId":"Branch",
		"VendorDeliveryRequest.branch":"Branch",
		"VendorDeliveryRequest.note":"Note",
		"Items":"Items",
		"Items":"Items",
		"VendorDeliveryRequest.lines":"Items",
		"VendorDeliveryRequest.totalDiscount":"Total Discount",
		"VendorDeliveryRequest.subtotal":"Subtotal",
		"VendorDeliveryRequest.totalTax":"After Tax",
		"VendorDeliveryRequest.total":"Grand Total",
		"Customer Additional Info":"Customer Additional Info",
		"Customer Additional Info":"Customer Additional Info",
		"VendorDeliveryRequest.salesOrderId":"Sales Order",
		"VendorDeliveryRequest.salesOrder":"Sales Order",
		"VendorDeliveryRequest.picName":"PIC Name",
		"VendorDeliveryRequest.picPhone":"PIC Contact",
		"VendorDeliveryRequest.picEmailAddress":"PIC Email",
		"VendorDeliveryRequest.picPosition":"PIC Position",
		"VendorDeliveryRequest.shippingAddress":"Shipping Address",
		"VendorDeliveryRequest.shippingCityId":"Shipping City",
		"VendorDeliveryRequest.shippingCity":"Shipping City",
		"VendorDeliveryRequest.shippingProvinceId":"Shipping Province",
		"VendorDeliveryRequest.shippingProvince":"Shipping Province",
		"VendorDeliveryRequest.shippingPostalCode":"Shipping Postal Code",
		"VendorDeliveryRequest.latitude":"Latitude",
		"VendorDeliveryRequest.longitude":"Longitude",
		"Other Information":"Other Information",
		"Other Information":"Other Information",
		"VendorDeliveryRequest.userId":"Customer User",
		"VendorDeliveryRequest.user":"Customer User",
		"VendorDeliveryRequest.state":"State",
		// vendor delivery request - history
		"VendorDeliveryRequest.history":"History",
		"VendorDeliveryRequest.history.waitingForApprovalDate":"Waiting For Approval Date",
		"VendorDeliveryRequest.history.confirmedDate":"Confirmed Date",
		"VendorDeliveryRequest.history.readyForDeliveryDate":"Ready for Delivery Date",
		"VendorDeliveryRequest.history.ongoingDeliveryDate":"Ongoing Delivery Date",
		"VendorDeliveryRequest.history.deliveredDate":"Delivered Date",
		"VendorDeliveryRequest.history.rejectedDate":"Rejected Date",
		"VendorDeliveryRequest.history.cancelledDate":"Cancelled Date",

		//Vendor Delivery Request Line
		"VendorDeliveryRequestLine":"Vendor Delivery Request Line",
		"vendor_delivery_request_line":"Vendor Delivery Request Line",
		"VendorDeliveryRequestLine.name":"Number",
		"VendorDeliveryRequestLine.itemId":"Item",
		"VendorDeliveryRequestLine.item":"Item",
		"VendorDeliveryRequestLine.additionalCharges":"Additionals",
		"VendorDeliveryRequestLine.startDate":"Start Date",
		"VendorDeliveryRequestLine.endDate":"End Date",
		"VendorDeliveryRequestLine.quantity":"Quantity",
		"VendorDeliveryRequestLine.unitId":"Unit",
		"VendorDeliveryRequestLine.unit":"Unit",
		"VendorDeliveryRequestLine.unitPrice":"Price",
		"VendorDeliveryRequestLine.discountPercentage":"Discount (%)",
		"VendorDeliveryRequestLine.discountAmount":"Discount Amount",
		"VendorDeliveryRequestLine.subtotal":"Subtotal",
		"VendorDeliveryRequestLine.taxes":"Taxes",
		"VendorDeliveryRequestLine.taxAmount":"Tax Amount",
		"VendorDeliveryRequestLine.total":"Total",
		//Advance Payment Request
		"AdvancePaymentRequest":"Sales Order",
		"advance_payment_request":"Sales Order",
		"advance_payment_request.draft":"Draft",
		"AdvancePaymentRequest.confirmTitle.action/draft":"Draft",
		"AdvancePaymentRequest.confirmContent.action/draft":"Draft?",
		"AdvancePaymentRequest.success.action/draft":"Sales Order is successful",
		"advance_payment_request.confirm":"Confirm",
		"AdvancePaymentRequest.confirmTitle.action/confirm":"Confirm",
		"AdvancePaymentRequest.confirmContent.action/confirm":"Are you sure you want to confirm this Sales Order?",
		"AdvancePaymentRequest.success.action/confirm":"Sales Order is successfully confirmed",
		"advance_payment_request.cancel":"Cancel",
		"AdvancePaymentRequest.confirmTitle.action/cancel":"Cancel",
		"AdvancePaymentRequest.confirmContent.action/cancel":"Are you sure you want to cancel this Sales Order?",
		"AdvancePaymentRequest.success.action/cancel":"Sales Order is successfully cancelled",
		"AdvancePaymentRequest.name":"Number",
		"AdvancePaymentRequest.partnerId":"Partner",
		"AdvancePaymentRequest.partner":"Partner",
		"AdvancePaymentRequest.salesOrderId":"Sales Order",
		"AdvancePaymentRequest.salesOrder":"Sales Order",
		"AdvancePaymentRequest.voucherId":"Payment Receive",
		"AdvancePaymentRequest.voucher":"Payment Receive",
		"AdvancePaymentRequest.token":"Token",
		"AdvancePaymentRequest.state":"State",
		//Bank
		"Bank":"Bank",
		"Bank.name":"Name",
		"Bank.country":"Country",
		"Bank.state":"Status",
		"Bank.confirmTitle.action/activate":"Activate",
		"Bank.confirmContent.action/activate":"Are you sure want to activate this bank?",
		"Bank.success.action/activate":"Bank is successfuly activated",
		"Bank.confirmTitle.action/deactivate":"Deactivate",
		"Bank.confirmContent.action/deactivate":"Are you sure want to deactivate this bank?",
		"Bank.success.action/deactivate":"Bank successfully deactivated",

		//Finance AP withdraw request
		"FinanceAPWithdrawRequest":"Withdraw Request",
		"WithdrawRequest":"Withdraw Request",
		"withdraw_request":"Withdraw Request",
		"WithdrawRequest.name":"Number",
		"WithdrawRequest.status":"Status",
		"WithdrawRequest.date":"Date",
		"WithdrawRequest.period":"Period",
		"WithdrawRequest.voucher":"Voucher",
		"WithdrawRequest.user":"User",
		"WithdrawRequest.amount":"Amount",
		"WithdrawRequest.lines":"Invoices",
		"WithdrawRequest.paymentDate":"Payment Date",
		"WithdrawRequest.paymentType":"Payment Type",
		"WithdrawRequestLine.invoice":"List",
		"WithdrawRequestLine.balance":"Balance",
		"WithdrawRequest.pay":"Pay",
		"WithdrawRequest.reject":"Reject",
		"WithdrawRequest.confirm":"Confirm",
		"WithdrawRequest.cancel":"Cancel",
		"WithdrawRequest.force_cancel":"Force Cancel",
		"WithdrawRequest.confirmTitle.action/pay":"Payment",
		"WithdrawRequest.confirmTitle.action/reject":"Reject",
		"WithdrawRequest.confirmTitle.action/confirm":"Confirm",
		"WithdrawRequest.confirmTitle.action/cancel":"Cancel",
		"WithdrawRequest.confirmTitle.action/force_cancel":"Force Cancel",
		"WithdrawRequest.confirmContent.action/pay":"Please enter the date and payment method",
		"WithdrawRequest.confirmContent.action/reject":"Are you sure want to reject this withdrawal request?",
		"WithdrawRequest.confirmContent.action/confirm":"Are you sure want to confirm this withdrawal request??",
		"WithdrawRequest.confirmContent.action/cancel":"Are you sure want to cancel this withdrawal request??",
		"WithdrawRequest.confirmContent.action/force_cancel":"Are you sure want to forcibly cancel this withdrawal request?",
		"WithdrawRequest.success.action/pay":"Withdrawal Request is sucessfully paid",
		"WithdrawRequest.success.action/reject":"Withdrawal Request is sucessfully rejected",
		"WithdrawRequest.success.action/confirm":"Withdrawal Request is sucessfully confirmed",
		"WithdrawRequest.success.action/cancel":"Withdrawal Request is sucessfully cancelled",
		"WithdrawRequest.success.action/force_cancel":"Withdrawal Request is sucessfully cancelled forcibly",

		// Item Import
		"ItemImport":"Import Item",
		"AccountingConfigurationItemImport":"Import Item",
		"ItemImport.file":"Excel File (.xlsx)",
		"ItemImport.updateStock":"Update Stock",
		"ItemImport.upload":"Upload",
		"ItemImport.uploadSuccess":"Your file has been uploaded",
		"ItemImport.uploadFailed":"Upload failed, please check your internet connection.",

		// Price Import
		"PriceImport":"Import Prices",
		"AccountingConfigurationPriceImport":"Import Prices",
		"PriceImport.file":"Excel File (.xlsx)",
		"PriceImport.updateStock":"Update Stock",
		"PriceImport.upload":"Upload",
		"PriceImport.download":"Download",
		"PriceImport.update":"Update Prices",
		"PriceImport.uploadSuccess":"Your file has been uploaded",
		"PriceImport.uploadFailed":"Upload failed, please check your internet connection.",
		"PriceImport.uploadSuccess":"Item Prices has successfully updated",
		"Priceimport.uploadFailed":"Failed to update item prices, please check your internet connection",
		"PriceImport.itemCode": "Item Code",
		"PriceImport.itemName": "Item Name",
		"PriceImport.branchCode": "Branch Code",
		"PriceImport.unitName": "Unit Name",
		"PriceImport.priceBefore": "Price Before",
		"PriceImport.priceAfter": "Price After",
		"PriceImport.changePercentage": "Change Percentage",
		"PriceImport.purchasePrice": "Purchase Price",
		"PriceImport.profitMargin": "Profit Margin",

		// Promotion
		"Promotion.generalInfo":"General Info",
		"Promotion.conditions":"Conditions",
		"Promotion.limits":"Limits",
		"Promotion.name":"Name",
		"Promotion.code":"Code",
		"Promotion.dateStart":"Date Start",
		"Promotion.dateEnd":"Date End",
		"Promotion.discountType":"Discount Type",
		"Promotion.discountAmount":"Discount Amount",
		"Promotion.discountPercentage":"Discount (%)",
		"Promotion.items":"Item List",
		"Promotion.marketplaces":"Marketplace List",
		"Promotion.branches":"Branch List",
		"Promotion.maxItems":"Maximum Items",
		"Promotion.minQuantity":"Minimum Quantity",
		"Promotion.minSubtotal":"Minimum Subtotal",
		"Promotion.maxDiscountAmount":"Maximum Dicount Total",
		"Promotion.total":"Total",
		"Promotion.subtotal":"Subtotal",
		"Promotion.totalTax":"Total Tax",
		"Promotion.totalDiscount":"Total Discount",
		"Promotion.maxPerTransaction":"Maximum per Transaction",
		"Promotion.limit":"Limit",
		"Promotion.balance":"Balance",
		"Promotion.schedule":"Schedule",
		"Promotion.state":"Status",
		"Promotion.discounts":"Applied Discounts",
		"Promotion.memberOnly":"Member Only",
		"Promotion.accounting":"Accounting",
		"DiscountLine.promotion":"From Promotion",
		"DiscountLine.dicountAmount":"Disc. Amount",
		"Promotion.activate":"Activate",
		"Promotion.deactivate":"Deactivate",
		"Promotion.confirmTitle.action/activate":"Activate",
		"Promotion.confirmContent.action/activate":"Are you sure want to activate this promo?",
		"Promotion.success.action/activate":"Promo has successfully activated",
		"Promotion.confirmTitle.action/deactivate":"Deactivate",
		"Promotion.confirmContent.action/deactivate":"Are you sure want to deactivate this promo?",
		"Promotion.success.action/deactivate":"Promo has successfully deactivated",

		"PromotionItemLine.item":"Item",
		"PromotionItemLine.minQuantity":"Min Qty",
		"PromotionItemLine.unit":"Unit",

		"MarketplacePromotion.marketplace":"Marketplace",
		"BranchPromotion.branch":"Branch",
		"total_discount":"Total Discount",
		"percentage_discount":"Percentage Discount",
		"per_item_discount":"Per-Item Discount",

		//Inventory Conversion
		"InventoryConversion":"Inventory Conversion",
		"InventoryConversion.originalInventory":"Original Inventory",
		"InventoryConversion.convertedInventory":"Converted Inventory",
		"InventoryConversion.name":"Number",
		"InventoryConversion.date":"Date",
		"InventoryConversion.item":"Item",
		"InventoryConversion.unit":"Unit",
		"InventoryConversion.targetUnit":"Target Unit",
		"InventoryConversion.branch":"Branch",
		"InventoryConversion.warehouse":"Warehouse",
		"InventoryConversion.location":"Location",
		"InventoryConversion.targetBranch":"Target Branch",
		"InventoryConversion.targetWarehouse":"Target Warehouse",
		"InventoryConversion.targetLocation":"Target Location",
		"InventoryConversion.currentQuantity":"Current Quantity",
		"InventoryConversion.convertedQuantity":"Converted Quantity",
		"InventoryConversion.conversionResult":"Conversion Result",
		"InventoryConversion.state":"Status",
		"InventoryConversion.conversionRate":"Conversion Rate",
		"InventoryConversion.desc":"Description",
		"InventoryConversion.confirmTitle.action/confirm/in":"Confirm In",
		"InventoryConversion.confirmTitle.action/confirm/out":"Confirm Out",
		"InventoryConversion.confirmTitle.action/confirm":"Confirm",
		"InventoryConversion.confirmContent.action/confirm/in":"Are you sure want to confirm in this inventory conversion?",
		"InventoryConversion.confirmContent.action/confirm/out":"Are you sure want to confirm out this inventory conversion?",
		"InventoryConversion.confirmContent.action/confirm":"Are you seure want to confirm this inventory conversion?",
		"InventoryConversion.success.action/confirm/in":"Inventory conversion is successfully confirmed in.",
		"InventoryConversion.success.action/confirm/out":"Inventory conversion is successfully confirmed out",
		"InventoryConversion.success.action/confirm":"Inventory conversion is successfully confirmed",

		//Price Config
		"PriceConfig":"Item Price",
		"ItemPriceConfig":"Item Price",
		"PriceConfig.name":"Item Name",
		"PriceConfig.category":"Category",
		"PriceConfig.branch":"Branch",
		"PriceConfig.unit":"Unit",
		"PriceConfig.price":" Price",
		"PriceConfig.purchasePrice":" Purchase Price",
		"PriceConfig.minimumPrice":" Min. Price",
		"PriceConfig.allCategories":"All Categories",
		"PriceConfig.allUnits":"All Units",
		"PriceConfig.allBranches":"All Branches",
		"PriceConfig.bulkEdit":"Bulk Edit",
		"PriceConfig.cancel":"Cancel",
		"PriceConfig.save":"Save",
		"PriceConfig.changePriceForXitems":"Change price for %s selected row(s)",
		"PriceConfig.changesWaiting":"Your changes are waiting to be approved",

		//Instant Purchase Item Prices
		"InstantPurchaseItemPricesReport":"Purchase Prices",
		"PurchasePrices.name":"Number",
		"PurchasePrices.date":"Date",
		"PurchasePrices.quantity":"Quantity",
		"PurchasePrices.measurementUnit":"Unit",
		"PurchasePrices.price":"Purchase Price",
		"PurchasePrices.partner":"Supplier",
		"PurchasePrices.location":"Location",
		"PurchasePrices.searchItem":"Search Item",

		//RequestApproval
		"RequestApproval":"Request Approval",
		"RequestApproval.active":"Active",
		"RequestApproval.waiting":"Waiting",
		"RequestApproval.history":"History",
		"RequestApproval.confirm":"Confirm",
		"RequestApproval.allClear":"All Clear",
		"RequestApproval.noRequest":"There's no new requests at this time",
		"RequestApproval.yourRequests":"Your Requests",
		"RequestApproval.processed":"Processed",
		"RequestApproval.pending":"Pending",
		"RequestApproval.item.request_price_save":"Change %s price from %oldPrice to %newPrice",
		"RequestApproval.item.request_prices_bulk_save":"Change several items' price to %newPrice",
		"RequestApproval.complete.confirm":"Request successfully confirmed",
		"RequestApproval.complete.reject":"Request successfully rejected",

		//Inventory Conversion 
		"InventoryConversionReport":"Inventory Conversion Report",
		"InventoryConversionReport.printRequest":"Printing Report...",

		//Fund Handover
		"FundHandover":"Fund Handover",
		"fund_handover":"Fund Handover",
		"FundHandover.xName":"Number",
		"FundHandover.xAccountFrom":"I handed over funds from",
		"FundHandover.xAccountTo":"Who was in charge of the account",
		"FundHandover.xTotal":"with total of",
		"FundHandover.xUser":"to",
		"FundHandover.name":"Number",
		"FundHandover.accountFrom":"Sender Account",
		"FundHandover.accountTo":"Receiver Account",
		"FundHandover.currentBalance":"Current Balance",
		"FundHandover.total":"Handover Total",
		"FundHandover.user":"Receiver",
		"FundHandover.state":"Status",
		"FundHandover.draft":"Draft",
		"FundHandover.creator":"Requested By",
		"FundHandover.description":"Description",
		"FundHandover.requestDate":"Request Date",
		"FundHandover.approveDate":"Approve Date",
		"FundHandover.request":"Request",
		"FundHandover.cancel":"Cancel",
		"FundHandover.confirmTitle.action/draft":"Draft",
		"FundHandover.confirmContent.action/draft":"Are you sure want to draft this fund handover?",
		"FundHandover.success.action/draft":"Fund handover has successfully drafted",
		"FundHandover.confirmTitle.action/request":"Request",
		"FundHandover.confirmContent.action/request":"Are you sure want to request this fund handover?",
		"FundHandover.success.action/request":"Fund handover has successfully requested",
		"FundHandover.confirmTitle.action/confirm":"Deactivate",
		"FundHandover.confirmContent.action/confirm":"Are you sure want to cancel this fund handover?",
		"FundHandover.success.action/confirm":"Fund handover has successfully cancelled",

		//Rak/Rack
		"Rack":"Rack",
		"rack":"Rack",
		"Rack.activate":"Activate",
		"Rack.confirmTitle.activate":"Activate",
		"Rack.confirmContent.activate":"Are you sure want to activate this rack?",
		"Rack.success.activate":"Rack is successfuly activated",
		"Rack.deactivate":"Deactivate",
		"Rack.confirmTitle.deactivate":"Deactivate",
		"Rack.confirmContent.deactivate":"Are you sure want to deactivate this rack?",
		"Rack.success.deactivate":"Rack is successfuly deactivated",
		"Rack.code":"Code",
		"Rack.name":"Name",
		"Rack.state":"Status",

		// Asset
		"Asset":"Asset",
		"Asset.code":"Code",
		"Asset.name":"Asset Name",
		"Asset.type":"Type",
		"Asset.method":"Depreciation Method",
		"Asset.startDate":"Start Date",
		"Asset.description":"Description",
		"Asset.value":"Value",
		"Asset.residueValue":"Residue Value",
		"Asset.month":"Month",
		"Asset.estimatedMonth":"Estimated Month",
		"Asset.estimatedYear":"Estimated Year",
		"Asset.depreciationAccount":"Depreciation Account",
		"Asset.accumulationAccount":"Accumulation Account",
		"Asset.expenseAccount":"Expense Account",
		"Asset.state":"Status",
		"Asset.assetLines":"Depreciations",
		"AssetLine.name":"Number",
		"AssetLine.accountMove":"Journal",
		"AssetLine.depreciationDate":"Depreciation Date",
		"AssetLine.amount":"Amount",
		"AssetLine.description":"Description",
		"Asset.deprecate":"Deprecate",
		"Asset.makeSchedule":"Make Depreciation Schedule",
		"Asset.confirmTitle.action/deprecate":"Deprecate",
		"Asset.confirmContent.action/deprecate":"Are you sure want to deprecate this asset?",
		"Asset.success.action/deprecate":"Asset has been deprecated",
		"Asset.confirmTitle.action/makeSchedule":"Create Depreciation Schedule",
		"Asset.confirmContent.action/makeSchedule":"Are you sure want to create depreciation schedule for this asset?",
		"Asset.success.action/makeSchedule":"Depreciation Schedule has been created",
		
		//Packaging
		"Packaging":"Packaging",
		"packaging":"Packaging",
		"Packaging.name":"Name",
		"Packaging.width":"Width",
		"Packaging.length":"Length",
		"Packaging.height":"Height",
		"Packaging.weight":"Weight",
		"Packaging.state":"State",
		"Packaging.activate":"Activate",
		"Packaging.confirmTitle.action/activate":"Activate",
		"Packaging.confirmContent.action/activate":"Are you sure you want to activate this Packaging?",
		"Packaging.success.action/activate":"Packaging is successfully activated",
		"Packaging.deactivate":"Deactivate",
		"Packaging.confirmTitle.action/deactivate":"Deactivate",
		"Packaging.confirmContent.action/deactivate":"Are you sure you want to deactivate this Packaging?",
		"Packaging.success.action/deactivate":"Packaging is successfully deactivated",

		//Courier
		"Courier":"Courier",
		"country":"Courier",
		"Courier.activate":"Activate",
		"Courier.confirmTitle.action/activate":"Activate",
		"Courier.confirmContent.action/activate":"Are you sure you want to activate this Courier?",
		"Courier.success.action/activate":"Courier is successfully activated",
		"Courier.deactivate":"Deactivate",
		"Courier.confirmTitle.action/deactivate":"Deactivate",
		"Courier.confirmContent.action/deactivate":"Are you sure you want to deactivate this Courier?",
		"Courier.success.action/deactivate":"Courier is successfully deactivated",
		"Courier.name":"Name",
		"Courier.trackable":"Trackable",
		"Courier.urlPrefix":"URL Prefix",
		"Courier.urlSuffix":"URL Suffix",
		"Courier.state":"State",

		//Item Spec
		"ItemSpec":"Specification",
		"item_spec":"Specification",
		"ItemSpec.name":"Name",
		"ItemSpec.image":"Image",
		"ItemSpec.priority":"Priority",
		"ItemSpec.state":"State",
		"ItemSpec.activate":"Activate",
		"ItemSpec.deactivate":"Deactivate",
		"ItemSpec.confirmTitle.action/activate":"Activate",
		"ItemSpec.confirmTitle.action/deactivate":"Deactivate",
		"ItemSpec.confirmContent.action/activate":"Are you sure want to activate this item specification",
		"ItemSpec.confirmContent.action/deactivate":"Are you sure want to deactivate this item specification",
		"ItemSpec.success.action/activate":"Item specification has successfully activated",
		"ItemSpec.success.action/deactivate":"Item specification has successfully deactivated",

		// Comission Report
		"CommissionReport":"Commission",

		// Inventory Relocation Report
		"InventoryRelocationReport":"Inventory Relocation",

		// Comission Report
		"WarningLog":"Warning Log",

		//MENUS
		"Accounting":"Accounting",
		"Journal Entries":"Journal Entries",
		"AccountingJournalEntries":"Manual Journal Entries",
		"Exchange Rate":"Exchange Rate",
		"AccountingExchangeRate":"Exchange Rate",
		"Partner":"Partner",
		"AccountingPartner":"Partner",
		"Branch":"Branch",
		"AccountingBranch":"Branch",
		"Accounting Report":"Accounting Report",
		"General Ledger":"General Ledger",
		"AccountingReportGeneralLedger":"General Ledger",
		"Profit and Loss":"Profit and Loss",
		"AccountingReportProfitandLoss":"Profit and Loss",
		"Balance Sheet":"Balance Sheet",
		"AccountingReportBalanceSheet":"Balance Sheet",
		"Accounting Configuration":"Accounting Configuration",
		"Account":"Account",
		"AccountingConfigurationAccount":"Account",
		"Account Group":"Account Group",
		"AccountingConfigurationAccountGroup":"Account Group",
		"Item":"Item",
		"AccountingConfigurationItem":"Item",
		"Item Category":"Item Category",		
		"AccountingConfigurationItemCategory":"Item Category",
		"Brand":"Brand",
		"AccountingConfigurationBrand":"Brand",
		"Additional Charge":"Additional Charge",
		"AccountingConfigurationAdditionalCharge":"Additional Charge",
		"Tag":"Tag",
		"AccountingConfigurationTag":"Tag",
		"Unit":"Unit",
		"AccountingConfigurationUnit":"Unit",
		"Payment Types":"Payment Types",
		"AccountingConfigurationPaymentTypes":"Payment Types",
		"Taxes":"Taxes",
		"AccountingConfigurationTaxes":"Taxes",
		"Period":"Period",
		"AccountingConfigurationPeriod":"Period",
		"Fiscal":"Fiscal",
		"AccountingConfigurationFiscal":"Fiscal",
		"Policy":"Policy",
		"AccountingConfigurationPolicy":"Policy",
		"Currency":"Currency",
		"AccountingConfigurationCurrency":"Currency",
		"Finance AR":"Finance AR",
		"FinanceARInstantSales":"Instant Sales",
		"Delivery Order":"Delivery Order",
		"FinanceARDeliveryOrder":"Delivery Order",
		"Invoice":"Invoice",
		"FinanceARInvoice":"Invoice",
		"Collective Invoice":"Collective Invoice",
		"FinanceARCollectiveInvoice":"Collective Invoice",
		"Debit Note":"Debit Note",
		"FinanceARDebitNote":"Debit Note",
		"Receive":"Receive",
		"receive":"Receive",
		"FinanceARReceive":"Receive",
		"Write Off":"Write Off",
		"FinanceARReturn":"Sales Return",
		"FinanceARPayableClaim":"Sales Adjustment",
		"FinanceARWriteOff":"Write Off",
		"Advance Receive":"Advance Receive",
		"FinanceARAdvanceReceive":"Advance Receive",
		"Giro":"Giro",
		"FinanceARGiro":"Giro",
		"Customer":"Customer",
		"FinanceARCustomer":"Customer",
		"Finance AP":"Finance AP",
		"Goods Receive Note":"Goods Receive Note",
		"FinanceAPGoodsReceiveNote":"Goods Receive Note",
		"Invoice":"Invoice",
		"FinanceAPInvoice":"Invoice",
		"Collective Invoice":"Collective Invoice",
		"FinanceAPCollectiveInvoice":"Collective Invoice",
		"Credit Note":"Credit Note",
		"FinanceAPCreditNote":"Credit Note",
		"Payment":"Payment",
		"FinanceAPPayment":"Payment",
		"PurchaseWriteOff":"Write Off",
		"FinanceAPReturn":"Purchase Return",
		"FinanceAPPurchaseWriteOff":"Write Off",
		"Advance Payment":"Advance Payment",
		"FinanceAPAdvancePayment":"Advance Payment",
		"Giro":"Giro",
		"FinanceAPGiro":"Giro",
		"Supplier":"Supplier",
		"FinanceAPSupplier":"Supplier",
		"AR Report":"AR Report",
		"Invoice":"Invoice",
		"ARReportInvoice":"Invoice",
		"Receive":"Receive",
		"ARReportReceive":"Receive",
		"Outstanding Giro":"Cheque",
		"ARReportOutstandingGiro":"Cheque",
		"Outstanding Delivery Order":"Outstanding Delivery Order",
		"ARReportOutstandingDeliveryOrder":"Outstanding Delivery Order",
		"Outstanding Invoice":"Outstanding Invoice",
		"ARReportOutstandingInvoice":"Outstanding Invoice",
		"Customer Credit Limit":"Customer Credit Limit",
		"ARReportCustomerCreditLimit":"Customer Credit Limit",
		"ARReportReturn": "Sales Return",
		"AP Report":"AP Report",
		"Invoice":"Invoice",
		"APReportInvoice":"Invoice",
		"Payment":"Payment",
		"APReportPayment":"Payment",
		"Outstanding Giro":"Cheque",
		"APReportOutstandingGiro":"Cheque",
		"Outstanding Goods Receive Note":"Outstanding Goods Receive Note",
		"APReportOutstandingGoodsReceiveNote":"Outstanding Goods Receive Note",
		"Outstanding Invoice":"Outstanding Invoice",
		"APReportOutstandingInvoice":"Outstanding Invoice",
		"APReportReturn": "Purchase Return",
		"Finance Config":"Finance Config",
		"Term":"Term",
		"FinanceConfigTerm":"Term",
		"Token Movement":"Token Movement",
		"FinanceConfigTokenMovement":"Token Movement",
		"Warehouse":"Warehouse",
		"Goods Receive Note":"Goods Receive Note",
		"WarehouseGoodsReceiveNote":"Goods Receive Note",
		"Delivery Order":"Delivery Order",
		"WarehouseDeliveryOrder":"Delivery Order",
		"WarehouseCrossBranchDeliveryOrder":"Cross Branch Delivery Order",
		"WarehouseCrossBranchDeliveryOrderActivity":"Cross Branch Delivery Order",
		"Return":"Return",
		"WarehouseSalesReturn":"Sales Return",
		"WarehousePurchaseReturn":"Purchase Return",
		"WarehouseReturn":"Return",
		"Material Release":"Material Release",
		"WarehouseMaterialRelease":"Material Release",
		"Production Result":"Production Result",
		"WarehouseProductionResult":"Production Result",
		"Inventory Issue":"Inventory Issue",
		"WarehouseInventoryIssue":"Inventory Issue",
		"Inventory Adjustment":"Inventory Adjustment",
		"WarehouseInventoryAdjustment":"Inventory Adjustment",
		"Inventory Relocation":"Inventory Relocation",
		"WarehouseInventoryRelocation":"Inventory Relocation",
		"Warehouse Report":"Warehouse Report",
		"Item":"Item",
		"WarehouseReportItem":"Item Movement",
		"Daily Stock":"Stock Recap",
		"WarehouseReportDailyStock":"Stock Recap",
		"WarehouseReportDailyStock.itemState":"Item State",
		"Stock":"Stock",
		"WarehouseReportStock":"Stock Movements",
		"Goods Receive":"Goods Receive",
		"WarehouseReportGoodsReceive":"Goods Receive",
		"Delivery Order":"Delivery Order",
		"WarehouseReportDeliveryOrder":"Delivery Order",
		"Return":"Return",
		"WarehouseReportReturn":"Return",
		"Warehouse Config":"Warehouse Config",
		"Location":"Location",
		"WarehouseConfigLocation":"Location",
		"Warehouse":"Warehouse",
		"WarehouseConfigWarehouse":"Warehouse",
		"Item":"Item",
		"WarehouseConfigItem":"Item",
		"Item Category":"Item Category",
		"WarehouseConfigItemCategory":"Item Category",
		"Unit":"Unit",
		"WarehouseConfigUnit":"Unit",
		"Sales":"Sales",
		"Sales Order":"Sales Order",
		"SalesSalesOrder":"Sales Order",
		"SalesOrderOnline":"Online Orders",
		"Order Extend":"Order Extend",
		"SalesOrderExtend":"Order Extend",
		"Delivery Order":"Delivery Order",
		"SalesDeliveryOrder":"Delivery Order",
		"Customer":"Customer",
		"SalesCustomer":"Customer",
		"SalesMember":"Member",
		"Item":"Item",
		"SalesItem":"Item",
		"Item Category":"Item Category",
		"SalesItemCategory":"Item Category",
		"Unit":"Unit",
		"SalesUnit":"Unit",
		"SalesReport":"Sales Report",
		"SalesReportItemPricelist":"Price List",
		"ItemPricelist": "Price List",
		"ItemPriceSalesPurchase": "Sales vs Purchase Price",
		"ItemStatisticReport": "Item Statistic Report",
		"Purchasing":"Purchasing",
		"Purchase Order":"Purchase Order",
		"PurchasingPurchaseOrder":"Purchase Order",
		"Goods Receive Note":"Goods Receive Note",
		"PurchasingGoodsReceiveNote":"Goods Receive Note",
		"Vendor Delivery Request":"Vendor Delivery Request",
		"PurchasingVendorDeliveryRequest":"Vendor Delivery Request",
		"Supplier":"Supplier",
		"PurchasingSupplier":"Supplier",
		"Item":"Item",
		"PurchasingItem":"Item",
		"Item Category":"Item Category",
		"PurchasingItemCategory":"Item Category",
		"Unit":"Unit",
		"PurchasingUnit":"Unit",
		"Purchasing Report":"Purchasing Report",
		"Outstanding Purchase Order":"Outstanding Purchase Order",
		"PurchasingReportOutstandingPurchaseOrder":"Outstanding Purchase Order",
		"Project":"Project",
		"Delivery Request":"Delivery Request",
		"ProjectDeliveryRequest":"Delivery Request",
		"Project":"Project",
		"ProjectProject":"Project",
		"Production":"Production",
		"Item Request":"Item Request",
		"ProductionItemRequest":"Item Request",
		"Production Result":"Production Result",
		"ProductionProductionResult":"Production Result",
		"HR":"HR",
		"Employee":"Employee",
		"HREmployee":"Employee",
		"Administration":"Administration",
		"User":"User",
		"AdministrationUser":"User",
		"User Group":"User Group",
		"AdministrationUserGroup":"User Group",
		"Branch":"Branch",
		"AdministrationBranch":"Branch",
		"City":"City",
		"AdministrationCity":"City",
		"Province":"Province",
		"AdministrationProvince":"Province",
		"Country":"Country",
		"AdministrationCountry":"Country",
		"Node":"Node",
		"AdministrationNode":"Node",
		"Menu":"Menu",
		"AdministrationMenu":"Menu",
		"Menu Category":"Menu Category",
		"AdministrationMenuCategory":"Menu Category",
		"Access Right":"Access Right",
		"AdministrationAccessRight":"Access Right",
		"Access Right Category":"Access Right Category",
		"AdministrationAccessRightCategory":"Access Right Category",
		"Debug":"Debug",
		"Item Value":"Item Value",
		"DebugItemValue":"Item Value",
		"Inventory":"Inventory",
		"DebugInventory":"Inventory",
		"Inventory Move Plan":"Inventory Move Plan",
		"DebugInventoryMovePlan":"Inventory Move Plan",
		"PurchasingOutstandingPurchaseOrder": "Purchasing Report",
		"AccountingReportTaxNonTaxReport": "Inventory Tax Non Tax Report",
		"AccountingNegativeInventoryValuationReport":"Negative Inventory Valuation Report",
		"TaxNonTaxReport": "Inventory Tax Non Tax Report",
		"AccountingConfigurationItemPrice":"Item Price Configuration",
		"AccountingInventoryValuationReport":"Inventory Valuation Report",
		"InventoryValuationReport":"Inventory Valuation Report",
		"UserAccountBalanceReport":"User Balance",
		"APAgingReport":"Monthly Payables",
		"ARAgingReport":"Monthly Receivables",
		"APDetailedReportActivity":"Detailed Payment",
		"ARDetailedReportActivity":"Detailed Receive",
		"APInvoiceMovementReport":"Invoice In Movement",
		"ARInvoiceMovementReport":"Invoice Out Movement",
		"TrialBalance":"Trial Balance",
		"Procurement":"Procurement",
		"ItemRequestCompleted":"Completed Requests",
		"ItemRequestCancelled":"Cancelled Requests",

		//OPTIONS
		"open":"Open",
		"closed":"Closed",
		"active":"Active",
		"inactive":"Inactive",
		"assets":"Assets",
		"liabilities":"Liabilities",
		"equities":"Equities",
		"income_statement":"Income Statement",
		"current_assets":"Current Assets",
		"fixed_assets":"Fixed Assets",
		"intangible_assets":"Intangible Assets",
		"current_liabilities":"Current Liabilities",
		"non-current_liabilities":"Non-Current Liabilities",
		"capital":"Capital",
		"retained_earning":"Retained Earning",
		"profit/loss":"Profit/Loss",
		"profit":"Profit",
		"loss":"Loss",
		"sales":"Sales",
		"cost_of_goods_sold":"Cost of Goods Sold",
		"other_operating_income":"Other Operating Income",
		"operating_expense":"Operating Expense",
		"other_income_expense":"Other Income Expense",
		"income_tax":"Income Tax",
		"cash_bank":"Cash / Bank",
		"cash_and_bank":"Cash and Bank",
		"cash_advance":"Cash Advance",
		"deposits":"Deposits",
		"trade_receivable":"Trade Receivabale",
		"post_dated_cheque":"Post Dated Cheque",
		"non_trade_receivables":"Non-Trade Receivables",
		"tax_receivable":"Tax Receivable",
		"inventory":"Inventory",
		"in-transit_inventories":"In-Transit Inventories",
		"prepaid_assets":"Prepaid Assets",
		"advance_payment":"Advance Payment",
		"land":"Land",
		"building":"Building",
		"vehicle":"Vehicle",
		"equipment":"Equipment",
		"machine":"Machine",
		"installation":"Installation",
		"establishment":"Establishment",
		"software":"Software",
		"goodwill":"Goodwill",
		"short_term_bank_payable":"Short-Term Bank Payable",
		"trade_payable":"Trade Payable",
		"outstanding_cheque":"Outstanding Cheque",
		"non_trade_payable":"Non-Trade Payable",
		"tax_payable":"Tax Payable",
		"accrued_expense":"Accrued Expense",
		"other_allowances":"Other allowance",
		"unearned_revenue":"Unearned Revenue",
		"current_maturities_of_long_term_debt":"Current Maturities of Long-Term Debt",
		"long-term_bank_payable":"Long-Term Bank Payable",
		"long-term_non_trade_payable":"Long-Term Non-Trade Payable",
		"sales_adjustments":"Sales Adjustments",
		"other_operating_income_adjustment":"Other Operating Income Adjustment",
		"sales_expense":"Sales Expense",
		"administration_expense":"Administration Expense",
		"depreciation_expense":"Depreciation Expense",
		"other_income":"Other Income",
		"other_expense":"Other Expense",
		"percentage":"Percentage",
		"amount":"Amount",
		"invoice":"Invoice",
		"payment":"Payment",
		"non-inventory":"Non-Inventory",
		"services":"Services",
		"asset":"Asset",
		"others":"Others",
		"raw_material":"Raw Material",
		"support_material":"Support Material",
		"WIP":"WIP",
		"finished_goods":"Finished Goods",
		"producible":"Producible",
		"other":"Other",
		"unposted":"Unposted",
		"posted":"Posted",
		"available":"Available",
		"depleted":"Depleted",
		"confirmed":"Confirmed",
		"draft":"Draft",
		"in":"In",
		"out":"Out",
		"cancelled":"Cancelled",
		"confirmed_out":"Need Confirmation",
		"confirmed_in":"Complete",
		"requested":"Requested",
		"fulfilled":"Fulfilled",
		"ongoing":"Ongoing",
		"finished":"Finished",
		"paid":"Paid",
		"complete":"Complete",
		"reconciled":"Reconciled",
		"purchase":"Purchase",
		"credit_note":"Credit Note",
		"debit_note":"Debit Note",
		"note":"Note",
		"advance":"Advance",
		"return_payment":"Return Payment",
		"liquidate":"Liquidate",
		"cleared":"Cleared",
		"requested":"Requested",
		"fulfilled":"Fulfilled",
		"sales_return":"Sales Return",
		"purchase_return":"Purchase Return",
		"autofill":"Auto Fill",
		"RelatedDocuments":"Related Documents",
		"invoiced":"Invoiced",
		"additional_discount":"Additional Discount",
		"action_do_print_without_price": "Print",
		"action_do_print_with_price": "Print ($)",
		"print_confirmation": "Print Confirmation",
		"print_confirmation_question": "Are you sure you want to print this document?",
		"additional_discount": "Additional Discount",
		"sales_commission": "Sales Commission",
		"open":"Open",
		"hold":"Hold",
		"closed":"Closed",
		"active":"Active",
		"inactive":"Inactive",
		"undeveloped":"Undeveloped",
		"developing":"Developing",
		"developed":"Developed",
		"auto":"Auto",
		"floor":"Floor",
		"ceil":"Ceil",
		"nearest_auto":"Nearest Auto",
		"nearest_ceil":"Nearest Ceil",
		"nearest_floor":"Nearest Floor",
		"fixed":"Fixed",
		"variable":"Variable",
		"draft":"Draft",
		"invoiced":"Invoiced",
		"cancelled":"Cancelled",
		"penalized":"Penalized",
		"mailed":"Mailed",
		"confimed":"Confimed",
		"debit":"Debit",
		"credit":"Credit",
		"left":"Left",
		"right":"Right",
		"plain":"Plain",
		"header":"Header",
		"footer":"Footer",
		"header_footer":"Header & Footer",
		"balance_sheet":"Balance Sheet",
		"profit_loss":"Profit Loss",
		"last_year":"Last Year",
		"current_year":"Current Year",
		"current_month":"Current Month",
		"draft":"Draft",
		"cancelled":"Cancelled",
		"consignment_sales":"Consignment Sales",
		"consignment_purchase":"Consignment Purchase",
		"online":"Online",
		"credit_card":"Credit Card",
		"manual":"Manual / Cash",
		"bank_transfer":"Bank Transfer",
		"large":"Large",
		"small":"Small",
		"low":"Low",
		"medium":"Medium",
		"high":"High",
		"value":"Value",
		"straight_line":"Straight Line",
		"double_declining":"Double Declining",
		"sum_of_the_year_digit":"Sum of The Year Digit",
		"slow_moving":"Slow Moving",
		"medium_moving":"Medium Moving",
		"fast_moving":"Fast Moving",
		"supervisor_approved":"SPV Approved",
		"manager_approved":"MD Manager Approved",

		//actions
		action_offer:"Offer",
		action_accept:"Accept",
		action_ready:"Ready",
		action_reject:"Reject",
		action_ongoing:"Ongoing Delivery",
		action_delivered:"Delivered",
		action_payment:"Payment",

		//inventory actions
		action_refresh_IMP:"Refresh IMP",
		"action_refresh_IMP.confirmTitle":"Refresh IMP",
		"action_refresh_IMP.confirmContent":"Refreshing IMP will be reset all items' inventory move plan to predefined settings, your changes will be lost. Continue Refresh IMP?",
		"action_refresh_IMP.success":"All items' IMP has been reset to default settings, please check again before saving",

		//statuses
		waiting_for_approval: "Waiting For Approval",
		vendor_accepted:"Vendor Accepted",
		ready_for_delivery:"Ready for Delivery",
		ongoing_delivery: "Ongoing Delivery",
		delivered: "Delivered",
		draft:"Draft",
		complete:"Completed",
		completed:"Completed",
		rejected:"Rejected",
		approved:"Approved",
		requested:"Requested",
		supervision:"Supervision",
		"SalesOrder.cancelled":"Cancelled",
		"SalesOrder.rejected":"Rejected",
		"SalesOrder.draft":"Draft",
		"SalesOrderOnline.reject":"Reject",
		"SalesOrderOnline.accept":"Accept",
		"SalesOrderOnline.cancel":"Cancel",
		"SalesOrderOnline.enterShippingCost":"Enter Shipping Cost",
		"SalesOrderOnline.confirmPayment":"Confirm Payment",
		"SalesOrderOnline.confirmShipment":"Confirm Shipment",
		"SalesOrderOnline.completeOrder":"Complete Order",

		//SO-state
		waiting_approval:"Waiting Approval",
		waiting_shipping_cost:"Waiting Shipping Cost",
		waiting_payment:"Waiting Payment",
		waiting_payment_check:"Waiting Payment Check",
		waiting_shipment:"Waiting Shipment",
		shipped:"Shipped",
		arrived:"Arrived",

		"offerDate":"Sales Offer Date",
		"approvalDate":"Sales Approval Date",
		"shippingCostOfferDate":"Shipping Cost Offer Date",
		"paymentDate":"Payment Date",
		"paymentConfimedDate":"Payment Confirm Date",
		"shipmentDate":"Shipment Date",
		"arrivalDate":"Arrival Date",

		//errors
		"credit limit has been reached":"Credit limit has been reached",
		"still has incomplete invoice or due invoice":"This customer still has incomplete invoice or due invoice",

	}
}

export default Translations
