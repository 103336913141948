const RequestApprovalStyle = {
  customBadge: {
    backgroundColor: "#e50f11",
    color: "#fff",
  },
  mainButton: {
    color: "#747474",
    marginRight: "10px",
    "@media(max-width:560px)": {
      marginRight: "0px",
    },
  },
  notifPane: {
    position: "absolute",
    zIndex: "10000 !important",
    marginTop: "0px",
    top: "50px",
    right: 0,
    opacity: 1,
    height: "500px",
    width: "340px",
    backgroundColor: "#f6f6f6",
    borderRadius: "7px",
    boxShadow: "0px 10px 15px rgba(0,0,0,0.2)",
    transition: "0.3s ease-in",
    overflow: "hidden",
    pointerEvents: "auto",
    "&.hide": {
      opacity: "0",
      top: "-700px",
    },
    "@media(max-width:560px)": {
      position: "fixed",
      top: "0px",
      left: "0px",
      height: "100vh",
      width: "calc(100vw - 0px)",
      "&.hide": {
        top: "-120vh",
      },
    },
  },
  paneHide: {
    position: "fixed",
    zIndex: "10000 !important",
    top: "-560px",
    opacity: 0,
    right: "137px",
    height: "500px",
    width: "300px",
    backgroundColor: "#fff",
    borderRadius: "7px",
    boxShadow: "0px 10px 15px rgba(0,0,0,0.2)",
    transition: "0.3s ease-in",
    padding: "20px",
  },
  mobilePane: {
    position: "fixed",
    zIndex: "10000 !important",
    top: "60px",
    right: "0px",
    opacity: 1,
    height: "500px",
    width: "100vw",
    backgroundColor: "#fff",
    borderRadius: "7px",
    boxShadow: "0px 10px 15px rgba(0,0,0,0.2)",
    transition: "0.3s ease-in",
    padding: "20px",
    pointerEvents: "auto",
  },
  paneContent: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  paneHeading: {
    width: "100%",
    marginBottom: "10px",
    backgroundColor: "#D9D9D9",
    "&>.mainHeading": {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      padding: "5px 10px",
    },
    "&>.tabs": {
      display: "flex",
      alignItems: "center",
      "&>.tab": {
        minWidth: "100px",
        height: "30px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderBottom: "3px solid transparent",
        userSelect: "none",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "rgba(0,0,0,0.1)",
        },
        "&:active": {
          backgroundColor: "rgba(0,0,0,0.4)",
        },
        "&.active": {
          borderBottom: "3px solid #4caf50",
        },
      },
    },
  },
  paneTitle: {
    padding: "0px",
    margin: "0px",
    fontWeight: "bold",
    fontSize: "1.2em",
    lineHeight: "1.6",
    flexGrow: "1",
    marginLeft: "10px",
  },
  paneClose: {
    marginLeft: "auto",
    color: "#888",
    cursor: "pointer",
    "&:hover": {
      color: "#000",
    },
  },
  notificationItems: {
    width: "100%",
    height: "370px",
    overflowY: "scroll",
    padding: "0px 15px",
    "&::-webkit-scrollbar": {
      width: "10px",
      marginRight: "10px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#eee",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ddd",
      borderRadius: "10px",
      transition: "0.2s ease-in-out",
      "&:hover": {
        backgroundColor: "#ccc",
      },
    },
    "@media(max-width:560px)": {
      height: "calc(100vh - 170px)",
    },
  },
  notifItem: {
    width: "100%",
    display: "flex",
    paddingBottom: "5px",
    borderBottom: "1px solid #ddd",
    "&:last-of-type": {
      borderBottom: "none",
    },
    "&.clickable": {
      userSelect: "none",
      cursor: "pointer",
      borderRadius: "4px",
      "&:hover": {
        backgroundColor: "#eee",
      },
    },
  },
  requestCard: {
    width: "100%",
    marginBottom: "20px",
    "&>.heading": {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "flex-end",
      paddingBottom: "10px",
      "&>.mainHeader": {
        display: "flex",
        flexDirection: "column",
        flexGrow: "1",
        "&>.title": {
          fontWeight: "bold",
          marginRight: "10px",
        },
        "&>.subtitle": {
          opacity: "0.8",
          fontSize: "0.9em",
        },
      },
    },
    "&>.card": {
      backgroundColor: "#fff",
      borderRadius: "10px",
      boxShadow: "0px 3px 10px rgba(0,0,0,0.1)",
      "&>.list": {
        padding: "0px 10px",
        borderBottom: "1px solid #ddd",
        display: "flex",
        "&>.details": {
          flexGrow: "1",
          display: "flex",
          flexDirection: "column",
          padding: "7px 0px",
          "@media(max-width:560px)": {
            flexDirection: "column",
            alignItems: "flex-start",
          },
          "&>.desc": {
            flexGrow: "1",
            whiteSpace: "pre-wrap",
          },
          "&>.user": {
            display: "flex",
            alignItems: "center",
          },
        },
      },
    },
  },
  paneBottom: {
    marginTop: "10px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "34px",
    "&>.navigation": {
      display: "flex",
      alignItems: "center",
      marginLeft: "10px",
      flexGrow: "1",
      "&>.navButton": {
        padding: "5px",
      },
      "&>.pageInfo": {
        display: "flex",
        margin: "0px 5px",
        textAlign: "center",
        justifyContent: "center",
      },
    },
    "&>.refresh": {
      height: "34px",
      width: "34px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: "10px",
    },
  },
  bottomButton: {
    fontWeight: "bold",
  },
  notifPushContainer: {
    maxHeight: "auto",
    position: "fixed",
    bottom: "0px",
    right: "0px",
    transition: "0.3s ease-in-out",
    "&.closed": {
      right: "-340px",
      transition: "0.3s ease-in-out",
    },
  },
  notifPush: {
    width: "300px",
    backgroundColor: "#fff",
    padding: "10px 10px 5px 10px",
    borderRadius: "5px",
    pointerEvents: "auto",
    boxShadow: "0px 10px 20px rgba(0,0,0,0.3)",
  },
  notifPushHeader: {
    width: "100%",
    display: "flex",
  },
  notifPushTitle: {
    flexGrow: "1",
    fontWeight: "bold",
    lineHeight: "1.7",
  },
  notifPushClose: {
    width: "20px",
    height: "20px",
    color: "#ccc",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      color: "#aaa",
    },
  },
  notifPushBody: {
    margin: "0px 0px 10px 0px",
  },
  notifPushActions: {
    display: "flex",
    justifyContent: "flex-end",
  },
  notifPushIcon: {
    marginRight: "10px",
    fontSize: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  miniIcon: {
    width: "10px",
  },
  infiniteLoader: {
    width: "100%",
    height: "60px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loadingLabel: {
    margin: "0px 10px",
  },
  loadingPane: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "30px",
  },
  noNotification: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  paneSpinner: {
    marginBottom: "10px",
  },
};

export default RequestApprovalStyle;
