import React, { Component } from "react";
import Activity from "framework/containers/Activity";

class ProfilePageActivity extends Component {
  constructor(props) {
    super(props);
    this.RenderedComponent = import("framework/pages/ProfilePage");
  }

  render() {
    return (
      <Activity
        name="profile"
        RenderedComponent={this.RenderedComponent}
        context="profilePage"
      />
    );
  }
}

export default ProfilePageActivity;
