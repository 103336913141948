const styles = {
  account: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    height: "75px",
    margin: "0px 10px",
    "& h5:first-of-type": {
      marginLeft: "5px",
      fontSize: "15px",
    },
    "@media (max-width:1366px)": {
      height: "10vh",
      padding: "0px",
    },
  },
  accountName: {
    fontSize: "0.95rem",
    margin: "0px 5px",
    color: "#ffffff",
  },
  listRoomChat: {
    cursor: "pointer",
    alignItems: "stretch",
    flex: "auto",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      backgroundColor: "#f5f5f5",
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#f5f5f5",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track:hover": {
      backgroundColor: "#eeeeee",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#babac0",
      borderRadius: "10px",
      border: "1px solid #f5f5f5",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#a0a0a5",
      border: "1px solid #eeeeee",
    },
    "&::-webkit-scrollbar-button": {
      display: "none",
    },
  },
  item: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    padding: 10,
  },
  roomName: {
    padding: "0px 5px",
    fontSize: "0.95rem",
    margin: "0px",
    color: "#3c4858",
  },
  wrapperTop: {
    display: "flex",
    flexDirection: "column",
    height: "auto",
    justifyContent: "space-between",
    backgroundColor: "#747474",
  },
  search: {
    margin: "5px 10px 20px",
    border: "1px solid #fffff",
    borderRadius: "25px",
    backgroundColor: "white",
    padding: "0px 5px",
    height: "30px",
  },
  action: {
    height: "50px",
    color: "#999",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
};

export default styles;
