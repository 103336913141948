import React, { Component } from "react";
import CreatableSelect from "framework/components/CustomCreatableSelect";
import API from "framework/utils/API";
import Text from "framework/inputs/Text";
import Cookies from "js-cookie";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "react-select";
import Button from "components/CustomButtons/Button.jsx";
import Tooltip from "@material-ui/core/Tooltip";
import { ReactSelectStyle } from "assets/jss/react-select-revamped";
import ReportIcon from "@material-ui/icons/Report";
import Modal from "framework/containers/Modal";
import ModalFormActions from "framework/components/ModalFormActions";

//parser
import _ from "lodash";
import { tl } from "framework/utils/Translator";

//icons
import ArrowDown from "@material-ui/icons/KeyboardArrowDown";
import AddIcon from "@material-ui/icons/LibraryAdd";
import EditIcon from "@material-ui/icons/Edit";
import OpenIcon from "@material-ui/icons/OpenInNew";
import LockIcon from "@material-ui/icons/Lock";
import UnlockIcon from "@material-ui/icons/LockOpen";

//redux
import { connect } from "react-redux";
import { mapStateToProps, mapDispatchToProps } from "framework/utils/Redux";

const KEY_BACKSPACE = 8;
const KEY_DELETE = 46;

class ManyToOne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: _.get(props.value, "id")
        ? {
            label: props.labelFunction
              ? props.labelFunction(props.value)
              : props.value[props.fieldAsLabel],
            value: props.value[props.fieldAsValue],
          }
        : null,
      valueToSet: _.get(props.value, "id")
        ? {
            [props.fieldAsLabel]: props.value[props.fieldAsLabel],
            [props.fieldAsValue]: props.value[props.fieldAsValue],
          }
        : null,
      suggestion: [],
      records: [props.value],
      defaultRecords: [],
      defaultSuggestion: [],
      searchInput: "",
      isFirstTime: true,
      popupOpen: false,
      dialog: {
        payload: null,
        open: false,
      },
      _error: "",
      justChanged: false,
      keyPressEvent: false,
      locked: false,
      isLoading: false,
    };

    this.delayedCallback = _.debounce(this.searchOptions, 500);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.MTOContainerRef = React.createRef();

    if (this.props.renderMode === "gridRenderer") {
      let currentRowIndex = props.rowIndex;
      if (
        typeof props.cellErrors === "object" &&
        props.cellErrors !== null &&
        currentRowIndex in props.cellErrors
      ) {
        if (props.fieldId in props.cellErrors[currentRowIndex]) {
          let errorText = _.get(
            props.cellErrors[currentRowIndex],
            props.fieldId,
          );
          if (
            _.get(props.value, props.fieldAsValue) &&
            errorText === "cannot be blank"
          ) {
            this.state._error = "";
          } else {
            this.state._error = tl(Cookies.get("lang"), errorText);
          }
        }
      }
    }

    if (this.props.renderMode === "gridEditor") {
      if (props.keyPress === KEY_BACKSPACE || props.keyPress === KEY_DELETE) {
        this.state.selected = null;
        this.state.valueToSet = null;
      } else if (props.charPress) {
        this.state.keyPressEvent = true;
      }
    }
  }

  //used for callback on edit or create option (recordCallback)
  handleApplyChanges = (record) => {
    if (this.props.renderMode !== "gridEditor") {
      if (this.props.packedValues) {
        this.props.onChange(this.props.field, {
          type: "manyToOne",
          action: record ? "select" : "clear",
          field: this.props.field,
          fieldId: this.props.fieldId,
          values: {
            [this.props.fieldId]: record
              ? record[this.props.fieldAsValue]
              : null,
            [this.props.field]: record ? record : null,
          },
        });
        this.handleCloseDialog();
      } else {
        let passToParent = new Promise((resolve) => {
          this.props.onChange(
            this.props.fieldId,
            record ? record[this.props.fieldAsValue] : null,
          );
          resolve();
        });
        passToParent
          .then(() => {
            this.props.onChange(this.props.field, record);
          })
          .then(() => {
            this.handleCloseDialog();
          });
      }
    } else {
      this.setState(
        {
          selected: record
            ? {
                label: this.props.labelFunction
                  ? this.props.labelFunction(record)
                  : record[this.props.fieldAsLabel],
                value: record[this.props.fieldAsValue],
              }
            : null,
          valueToSet: record
            ? {
                [this.props.fieldAsLabel]: record[this.props.fieldAsLabel],
                [this.props.fieldAsValue]: record[this.props.fieldAsValue],
              }
            : null,
        },
        () => {
          this.handleCloseDialog();
        },
      );
    }
  };

  handleClickEdit = () => {
    if (this.state.selected !== null) {
      API("get", this.props.model, this.state.selected.value)
        .then((response) => {
          let basePayload = {
            name: response.data[this.props.fieldAsLabel],
            viewType: "modal",
            mode: "edit",
            context: this.props.context ? this.props.context : {},
            record: response.data,
            component: this.props.form,
            actions: ModalFormActions,
          };
          if (this.props.renderMode === "gridEditor") {
            this.props.handleOpenModal({
              ...basePayload,
              editorProps: {
                model: this.props.model,
                rowIndex: this.props.node.rowIndex,
                fieldId: this.props.fieldId,
                field: this.props.field,
                fieldAsLabel: this.props.fieldAsLabel,
                fieldAsValue: this.props.fieldAsValue,
                modalType: "manyToOneForm",
              },
            });
          } else {
            this.setState({
              dialog: {
                open: true,
                payload: {
                  ...basePayload,
                  recordCallback: this.handleApplyChanges,
                  closeCallback: this.handleCloseDialog,
                  editorProps: {
                    model: this.props.model,
                  },
                },
              },
            });
          }
        })
        .catch((error) => {
          console.error(error.response);
        });
    } else {
      this.handleCreateOption("");
    }
  };

  handleCloseDialog = () => {
    this.setState({
      dialog: {
        open: false,
        payload: null,
      },
    });
  };

  handleCreateOption = (inputValue) => {
    API("default", this.props.model, null)
      .then((response) => {
        let defaultRecord = response.data;
        delete defaultRecord.id;
        let basePayload = {
          name:
            tl(Cookies.get("lang"), "create") +
            " " +
            tl(Cookies.get("lang"), this.props.model),
          viewType: "modal",
          mode: "create",
          component: this.props.form,
          actions: ModalFormActions,
          context: this.props.context || {},
          record: {
            ...defaultRecord,
            [this.props.fieldAsLabel]: inputValue,
          },
        };

        if (this.props.renderMode === "gridEditor") {
          this.props.handleOpenModal({
            ...basePayload,
            editorProps: {
              rowIndex: this.props.node.rowIndex,
              model: this.props.model,
              fieldId: this.props.fieldId,
              field: this.props.field,
              fieldAsLabel: this.props.fieldAsLabel,
              fieldAsValue: this.props.fieldAsValue,
              modalType: "manyToOneForm",
            },
          });
        } else {
          this.setState({
            dialog: {
              open: true,
              payload: {
                ...basePayload,
                recordCallback: this.handleApplyChanges,
                closeCallback: this.handleCloseDialog,
                editorProps: {
                  model: this.props.model,
                },
              },
            },
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  handleClickOpenTable = () => {
    let context = {};
    if (this.props.context) {
      context = this.props.context;
      if ("_dynamic" in context) {
        if (this.props.formRecord && this.props.formRecord[context._dynamic]) {
          context = {
            ...context,
            [context._dynamic]: String(
              this.props.formRecord[context._dynamic]
                ? this.props.formRecord[context._dynamic]
                : 0,
            ),
          };
        } else {
          context = {
            ...context,
            [context._dynamic]: "0",
          };
        }
      }
    }
    delete context._dynamic;
    let basePayload = {
      name: tl(Cookies.get("lang"), this.props.model),
      viewType: "modal",
      mode: "picker",
      record: {},
      context: context ? context : {},
      component: this.props.browser,
      actions: ModalFormActions,
    };
    if (this.props.renderMode === "gridEditor") {
      this.props.handleOpenModal({
        ...basePayload,
        editorProps: {
          rowIndex: this.props.node.rowIndex,
          fieldId: this.props.fieldId,
          field: this.props.field,
          fieldAsLabel: this.props.fieldAsLabel,
          fieldAsValue: this.props.fieldAsValue,
          modalType: "manyToOneBrowse",
        },
      });
    } else {
      this.setState({
        dialog: {
          open: true,
          payload: {
            ...basePayload,
            recordCallback: this.handleApplyChanges,
            closeCallback: this.handleCloseDialog,
          },
        },
      });
    }
  };

  handleMenuOpen = () => {
    this.refreshOptions();
  };

  handleKeyDown(event) {
    if (["formCreate", "formEdit"].includes(this.props.renderMode)) {
      if (this.props.onInputKeyDown) {
        if (this.props.onInputKeyDown(event)) {
          // event.stopPropagation()
        }
      }
    } else if (this.props.renderMode === "gridEditor") {
      let key = event.which || event.keyCode;
      if (key === 37 || key === 38 || key === 39 || key === 40 || key === 13) {
        switch (event.key) {
          case "ArrowLeft":
            if (
              !this.SelectRef.props.isMulti ||
              this.SelectRef.props.inputValue
            )
              return;
            this.SelectRef.focusValue("previous");
            event.stopPropagation();
            break;
          case "ArrowRight":
            if (
              !this.SelectRef.props.isMulti ||
              this.SelectRef.props.inputValue
            )
              return;
            this.SelectRef.focusValue("next");
            event.stopPropagation();
            break;
          case "Enter":
            if (event.keyCode === 229) {
              break;
            }
            if (this.SelectRef.props.menuIsOpen) {
              if (!this.SelectRef.focusedOption()) break;
              if (this.SelectRef.isComposing()) break;
              this.SelectRef.selectOption(this.SelectRef.focusedOption());
              event.stopPropagation();
              break;
            }
            break;
          case "ArrowUp":
            if (this.SelectRef.props.menuIsOpen) {
              this.SelectRef.focusOption("up");
            } else {
              this.SelectRef.openMenu("last");
            }
            event.stopPropagation();
            break;
          case "ArrowDown":
            if (this.SelectRef.props.menuIsOpen) {
              this.SelectRef.focusOption("down");
            } else {
              this.SelectRef.openMenu("first");
            }
            event.stopPropagation();
            break;
          default:
            return;
        }
      }
    }
  }

  handleChange(selected) {
    if (this.state._error !== "") {
      this.setState({ _error: "" });
    }
    selected = selected === null ? "" : selected;
    this.setState({
      selected:
        selected !== ""
          ? {
              label: selected.label,
              value: selected.value,
            }
          : null,
      valueToSet:
        selected !== ""
          ? {
              [this.props.fieldAsLabel]: selected.label,
              [this.props.fieldAsValue]: selected.value,
            }
          : null,
    });

    if (["formCreate", "formEdit"].includes(this.props.renderMode)) {
      if (selected.isAdditionalOption) {
        if (this.props.onChangeSpecial) {
          this.props.onChangeSpecial(this.props.field, selected);
        }
      } else if (this.props.packedValues) {
        let latestRecords = [
          ...this.state.records,
          ...(this.props.useExternalCache ? this.props.useExternalCache : []),
        ];
        let defaultRecords = [...this.state.defaultRecords];
        let foundDefRecord = _.find(defaultRecords, ["id", selected.value]);
        if (foundDefRecord) {
          this.props.onChange(this.props.field, {
            type: "manyToOne",
            action: selected ? "select" : "clear",
            field: this.props.field,
            fieldId: this.props.fieldId,
            values: {
              [this.props.fieldId]: selected ? selected.value : null,
              [this.props.field]: selected ? foundDefRecord : null,
            },
          });
        } else {
          this.props.onChange(this.props.field, {
            type: "manyToOne",
            action: selected ? "select" : "clear",
            field: this.props.field,
            fieldId: this.props.fieldId,
            values: {
              [this.props.fieldId]: selected ? selected.value : null,
              [this.props.field]: selected
                ? _.find(latestRecords, ["id", selected.value])
                : null,
            },
          });
        }
      } else {
        let passToParent = new Promise((resolve) => {
          this.props.onChange(
            this.props.fieldId,
            selected ? selected.value : null,
          );
          resolve();
        });
        passToParent.then(() => {
          let foundDefRecord = _.find(this.state.defaultRecords, [
            "id",
            selected.value,
          ]);
          if (foundDefRecord) {
            this.props.onChange(
              this.props.field,
              selected ? foundDefRecord : null,
            );
          } else {
            this.props.onChange(
              this.props.field,
              selected
                ? _.find(this.state.records, ["id", selected.value])
                : null,
            );
          }
        });
      }
    }
  }

  refreshOptions = () => {
    let context = {};
    if (this.props.context) {
      context = this.props.context;
      if ("_dynamic" in context) {
        if (this.props.formRecord && this.props.formRecord[context._dynamic]) {
          context = {
            ...context,
            [context._dynamic]: String(
              this.props.formRecord[context._dynamic]
                ? this.props.formRecord[context._dynamic]
                : 0,
            ),
          };
        } else {
          context = {
            ...context,
            [context._dynamic]: "0",
          };
        }
      }
    }
    delete context._dynamic;
    if (this.props.renderMode !== "formView" && this.state.isFirstTime) {
      API("options", this.props.model, {
        search: this.props.searchWith ? this.props.searchWith.toString() : "",
        limit: this.props.limit || 10,
        context: context,
      }).then((response) => {
        var arr = response.data.records;
        if (this.props.filterOption) {
          arr = arr.filter(this.props.filterOption);
        }
        var result = arr.map((record) => ({
          label: this.props.labelFunction
            ? this.props.labelFunction(record)
            : record[this.props.fieldAsLabel],
          value: record[this.props.fieldAsValue],
        }));
        this.setState({
          isLoading: false,
          suggestion: result,
          records: arr,
          defaultSuggestion: result,
          defaultRecords: arr,
          isFirstTime: false,
        });
      });
    }
  };

  searchOptions = () => {
    this.setState({ isLoading: true });
    let context = {};
    if (this.props.context) {
      context = this.props.context;
      if ("_dynamic" in context) {
        if (this.props.formRecord && this.props.formRecord[context._dynamic]) {
          context = {
            ...context,
            [context._dynamic]: String(this.props.formRecord[context._dynamic]),
          };
        } else {
          context = {
            ...context,
            [context._dynamic]: "0",
          };
        }
      }
    }
    delete context._dynamic;

    let isFound = [];
    if (this.props.useExternalCache && this.state.searchInput !== "") {
      if (!this.props.cacheSearchFunction) {
        isFound = this.props.useExternalCache.filter((record) => {
          if (
            record.name
              .toLowerCase()
              .includes(this.state.searchInput.toLowerCase()) ||
            record.code
              .toLowerCase()
              .includes(this.state.searchInput.toLowerCase())
          ) {
            return true;
          }
          return false;
        });
      } else {
        isFound = this.props.useExternalCache.filter((record) =>
          this.props.cacheSearchFunction(record, this.state.searchInput),
        );
      }

      if (isFound.length > 0) {
        // console.log("cache search found", isFound)
        this.setState({
          isLoading: false,
          suggestion: isFound.map((record) => ({
            label: this.props.labelFunction
              ? this.props.labelFunction(record)
              : record[this.props.fieldAsLabel],
            value: record[this.props.fieldAsValue],
          })),
        });
      }
    }

    if (
      !this.props.useExternalCache ||
      (this.props.useExternalCache && isFound.length === 0)
    ) {
      if (
        this.props.renderMode !== "formView" &&
        this.state.searchInput !== ""
      ) {
        API("options", this.props.model, {
          search: this.props.searchWith
            ? this.props.searchWith.toString()
            : this.state.searchInput,
          limit: this.props.limit || 10,
          context: context,
        }).then((response) => {
          var arr = response.data.records;
          if (this.props.filterOption) {
            arr = arr.filter(this.props.filterOption);
          }
          var result = arr.map((record) => ({
            label: this.props.labelFunction
              ? this.props.labelFunction(record)
              : record[this.props.fieldAsLabel],
            value: record[this.props.fieldAsValue],
          }));
          this.setState({
            isLoading: false,
            suggestion: result,
            records: response.data.records,
          });
        });
      }

      if (this.state.searchInput === "") {
        this.setState({
          suggestion: this.state.defaultSuggestion,
          records: this.state.defaultRecords,
        });
      }
    }
  };

  handleSearch = (event) => {
    if (![37, 38, 39, 40].includes(event.keyCode)) {
      event.persist();
      this.delayedCallback();
      if (
        event.keyCode === 13 &&
        this.props.onEnterPressed &&
        !this.SelectRef.props.menuIsOpen
      ) {
        this.props.onEnterPressed();
      }
    }
  };

  componentWillReceiveProps(nextProps) {
    if (
      ["formCreate", "formEdit", "formView"].includes(this.props.renderMode)
    ) {
      this.setState({
        selected: _.get(nextProps.value, "id")
          ? {
              label: this.props.labelFunction
                ? this.props.labelFunction(nextProps.value)
                : nextProps.value[this.props.fieldAsLabel],
              value: nextProps.value[this.props.fieldAsValue],
            }
          : null,
      });
    }
    if (nextProps.locked && nextProps.locked !== this.state.locked) {
      this.setState({
        locked: nextProps.locked,
      });
    }
  }

  componentDidMount() {
    this.mounted = true;
    if (
      ["formCreate", "formEdit"].includes(this.props.renderMode) &&
      this.handleKeyDown
    ) {
      this.Ref.addEventListener("keydown", this.handleKeyDown);
    } else if (this.props.searchWith) {
      this.searchOptions();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    if (this.props.renderMode === "gridEditor") {
      this.SelectRef.blur();
    }
  }

  afterGuiAttached() {
    if (this.props.renderMode === "gridEditor") {
      this.Ref.addEventListener("keydown", this.handleKeyDown);
      setTimeout(() => {
        if (this.select) {
          this.select.focus();
        } else if (this.SelectRef) {
          this.SelectRef.focus();
        }
      }, 50);
    }
  }

  triggerFocus = (openMenu) => {
    setTimeout(() => {
      if (this.SelectRef) {
        this.SelectRef.focus();
        if (openMenu) {
          this.SelectRef.openMenu("first");
        }
      }
    }, 50);
  };

  triggerBlur = () => {
    setTimeout(() => {
      if (this.SelectRef) {
        this.SelectRef.blur();
        if (this.SelectRef.closeMenu) {
          this.SelectRef.closeMenu();
        }
      }
    }, 50);
  };

  getValue() {
    if (this.props.simpleValueGetter) {
      return (this.state.selected && _.find(this.state.records,["id",this.state.selected.value]))
					? _.find(this.state.records,["id",this.state.selected.value])
					:null 
    } else {
      if (
        this.state.selected &&
        this.props.handleChangeIdOnly &&
        typeof this.props.handleChangeIdOnly === "function"
      ) {
        this.props.handleChangeIdOnly(
          this.props.rowIndex,
          this.props.fieldId,
          this.state.selected.value,
        );
        return _.find(this.state.records, ["id", this.state.selected.value])
          ? _.find(this.state.records, ["id", this.state.selected.value])
          : null;
      } else {
        this.props.handleChangeIdOnly(
          this.props.rowIndex,
          this.props.fieldId,
          null,
        );
        return null;
      }
    }
  }

  isPopup() {
    return true;
  }

  applyFilter = () => {
    this.setState({ popupOpen: !this.state.popupOpen });
    this.props.onChange(this.state.selected ? this.state.selected : null);
  };

  handleTogglePopup = () => {
    this.setState({ popupOpen: !this.state.popupOpen });
  };

  handleInputChange = (searchInput) => {
    this.setState({ searchInput });
  };

  handleBlur = () => {
    if (this.mounted) {
      this.setState({
        suggestion: this.state.defaultSuggestion,
        record: this.state.defaultRecord,
      });
    }
  };

  getPlaceholder = () => {
    if (
      this.props.editable === false ||
      this.props.washedOff === false ||
      (this.props.renderMode === "formView" && this.state.selected === null)
    ) {
      return "";
    } else {
      if (this.props.placeholder) {
        return this.props.placeholder;
      } else {
        return tl(Cookies.get("lang"), "typeToSearch");
      }
    }
  };

  freeze = (event) => {
    if (this.state.dialog.open) {
      event.stopPropagation();
    }
  };

  handleViewForm = () => {
    API("get", this.props.model, this.state.selected.value)
      .then((response) => {
        let basePayload = {
          name: response.data[this.props.fieldAsLabel],
          viewType: "modal",
          mode: "view",
          context: this.props.context ? this.props.context : {},
          record: response.data,
          component: this.props.form,
          actions: ModalFormActions,
        };
        if (this.props.renderMode === "gridEditor") {
          this.props.handleOpenModal({
            ...basePayload,
            editorProps: {
              model: this.props.model,
              rowIndex: this.props.node.rowIndex,
              fieldId: this.props.fieldId,
              field: this.props.field,
              fieldAsLabel: this.props.fieldAsLabel,
              fieldAsValue: this.props.fieldAsValue,
              modalType: "manyToOneForm",
            },
          });
        } else {
          this.setState({
            dialog: {
              open: true,
              payload: {
                ...basePayload,
                closeCallback: this.handleCloseDialog,
                editorProps: {
                  model: this.props.model,
                },
              },
            },
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  handleLockValue = () => {
    this.setState({ locked: !this.state.locked });
    if (this.props.onLockChange) {
      this.props.onLockChange(!this.state.locked);
    }
  };

  _getValue = () => { //use only for refs
    let selected = _.find(this.state.records, ["id", this.state.selected.value])
    return selected || null
  }

  render() {
    //--apply styling to select component
    // references : https://react-select.com/components
    const newStyle = ReactSelectStyle;
    //get label text
    let labelText = null;
    if (this.props.label) {
      labelText = this.props.label;
    } else {
      labelText = tl(Cookies.get("lang"), this.props.field);
    }
    let InputComponent = null;
    let renderedSuggestion = [...this.state.suggestion];
    if (
      this.props.additionalOptions &&
      Array.isArray(this.props.additionalOptions)
    ) {
      renderedSuggestion = [
        ...renderedSuggestion,
        ...this.props.additionalOptions.map((opt) => ({
          ...opt,
          isAdditionalOption: true,
        })),
      ];
    }
    if (
      ["formCreate", "formEdit"].includes(this.props.renderMode) 
    ) {
      InputComponent = (
        <div
          ref={(ref) => {
            this.Ref = ref;
          }}
        >
          {!this.props.disableLabel && (
            <InputLabel
              shrink
              className={`input-label ${this.props.white ? "white" : ""}`}
            >
              {labelText}
              {this.props.required ? "*" : ""}
            </InputLabel>
          )}
          <div
            className={`many2one-input-container ${
              this.props.renderMode !== "formView" &&
              this.props.editable === false &&
              this.props.washedOff !== false &&
              "input-disabled"
            }`}
          >
            <div className="many2one-input">
              <CreatableSelect
                isLoading={false}
                isSearchable={
                  this.props.searchWith || this.props.disableInternalSearch
                    ? false
                    : true
                }
                styles={newStyle}
                menuPlacement={this.props.menuPlacement || "auto"}
                placeholder={this.props.customPlaceholder || ""}
                options={renderedSuggestion}
                value={this.state.selected ? this.state.selected : null}
                onChange={this.handleChange}
                onKeyDown={this.handleSearch}
                onInputChange={this.handleInputChange}
                onBlur={this.handleBlur}
                inputValue={this.state.searchInput}
                isDisabled={
                  this.props.renderMode === "formView" ||
                  this.props.editable === false ||
                  this.props.washedOff === false ||
                  this.state.locked
                }
                ref={(ref) => {
                  this.select = ref;
                  if (this.props.innerRef) {
                    this.props.innerRef(ref);
                  }
                }}
                onRef={(ref) => {
                  this.SelectRef = ref;
                  if (this.props.innerSelectRef) {
                    this.props.innerSelectRef(ref);
                  }
                }}
                onMenuOpen={() => this.handleMenuOpen()}
                isValidNewOption={() =>
                  this.props.disableCreate || !this.props.form ? false : true
                }
                onCreateOption={
                  this.props.disableCreate ? null : this.handleCreateOption
                }
                formatCreateLabel={(inputValue) =>
                  inputValue
                    ? tl("module.createX").replace("%s", inputValue)
                    : tl("module.createNew")
                }
                filterOption={() => true}
                isClearable
              />
            </div>
            {this.props.form &&
              this.props.editable !== false &&
              this.props.renderMode !== "formView" &&
              this.props.washedOff !== false && (
                <Tooltip
                  title={tl(
                    Cookies.get("lang"),
                    this.state.selected ? "editData" : "createData",
                  )}
                >
                  <Button
                    justIcon
                    simple
                    color="white"
                    size="sm"
                    tabIndex="-1"
                    onClick={() => this.handleClickEdit()}
                    className="many2one-icon-button"
                  >
                    {this.state.selected ? (
                      <EditIcon className="many2one-icon" />
                    ) : (
                      <AddIcon className="many2one-icon" />
                    )}
                  </Button>
                </Tooltip>
              )}
            {this.props.browser &&
              this.props.editable !== false &&
              this.props.renderMode !== "formView" &&
              this.props.washedOff !== false && (
                <Tooltip title={tl(Cookies.get("lang"), "browse")}>
                  <Button
                    justIcon
                    simple
                    color="white"
                    size="sm"
                    tabIndex="-1"
                    className="many2one-icon-button"
                    onClick={() => this.handleClickOpenTable()}
                  >
                    <OpenIcon
                      className={
                        this.state.selected
                          ? "many2one-icon"
                          : "many2one-icon -disabled"
                      }
                    />
                  </Button>
                </Tooltip>
              )}
            {this.props.form &&
              this.props.editable !== false &&
              this.props.renderMode === "formView" &&
              this.props.washedOff !== false &&
              this.state.selected !== null && (
                <Tooltip title={tl(Cookies.get("lang"), "viewData")}>
                  <Button
                    justIcon
                    simple
                    color="white"
                    size="sm"
                    tabIndex="-1"
                    onClick={() => this.handleViewForm()}
                    className="many2one-icon-button"
                  >
                    <OpenIcon className="many2one-icon" />
                  </Button>
                </Tooltip>
              )}
            {this.props.enableValueLocking && (
              <Tooltip
                title={tl(
                  Cookies.get("lang"),
                  this.state.locked ? "unlockData" : "lockData",
                )}
              >
                <Button
                  justIcon
                  simple
                  color="white"
                  size="sm"
                  tabIndex="-1"
                  onClick={() => this.handleLockValue()}
                  className="many2one-icon-button"
                >
                  {this.state.locked ? (
                    <LockIcon className="many2one-icon" />
                  ) : (
                    <UnlockIcon className="many2one-icon" />
                  )}
                </Button>
              </Tooltip>
            )}
            <Tooltip
              style={{ display: this.props.error ? "inline-block" : "none" }}
              title={this.props.error ? this.props.error : ""}
            >
              <ReportIcon className="basic-input-error-icon select-input-error-icon" />
            </Tooltip>
          </div>
        </div>
      );
    } else if (this.props.renderMode === "formView") {
      InputComponent = <div style={{display: 'flex', gap:'10px', alignItems:"flex-end", width:'100%'}}>
        <div style={{flexGrow:'1'}}>
          <Text
            disableLabel={this.props.disableLabel}
            required={this.props.required}
            label={labelText}
            value={this.state.selected?.label}
            renderMode="formView"
          />
        </div>
        {this.props.form &&
            this.props.editable !== false &&
            this.props.renderMode === "formView" &&
            this.props.washedOff !== false &&
            this.state.selected !== null && (
              <Tooltip title={tl(Cookies.get("lang"), "viewData")}>
                <Button
                  justIcon
                  simple
                  color="white"
                  size="sm"
                  tabIndex="-1"
                  onClick={() => this.handleViewForm()}
                  className="many2one-icon-button"
                  style={{marginBottom:'5px'}}
                >
                  <OpenIcon className="many2one-icon" />
                </Button>
              </Tooltip>
            )}
      </div>
    } else if (this.props.renderMode === "gridEditor") {
      InputComponent = (
        <div
          style={{ width: this.props.width ? this.props.width - 4 : "auto" }}
          ref={(ref) => {
            this.Ref = ref;
          }}
          onMouseDown={(event) => this.freeze(event)}
        >
          <div className="many2one-input-container">
            <div className="many2one-grid-input">
              <CreatableSelect
                styles={newStyle}
                isLoading={false}
                placeholder=""
                options={this.state.suggestion}
                value={this.state.selected ? this.state.selected : null}
                onChange={this.handleChange}
                onKeyDown={this.handleSearch}
                onInputChange={this.handleInputChange}
                onMenuOpen={() => this.handleMenuOpen()}
                onCreateOption={this.handleCreateOption}
                ref={(ref) => {
                  this.select = ref;
                }}
                onRef={(ref) => {
                  this.SelectRef = ref;
                }}
                defaultInputValue={
                  this.state.keyPressEvent ? this.props.charPress : ""
                }
                isClearable
              />
            </div>
            {this.props.form && (
              <Tooltip
                title={tl(
                  Cookies.get("lang"),
                  this.state.selected ? "editData" : "noOptionSelected",
                )}
              >
                <div>
                  <Button
                    justIcon
                    simple
                    color="white"
                    size="sm"
                    tabIndex="-1"
                    disabled={this.state.selected ? false : true}
                    onClick={() => this.handleClickEdit()}
                    className="many2one-icon-button"
                  >
                    {this.state.selected ? (
                      <EditIcon className="many2one-icon" />
                    ) : (
                      <AddIcon className="many2one-icon" />
                    )}
                  </Button>
                </div>
              </Tooltip>
            )}
            {this.props.browser && (
              <Tooltip title={tl(Cookies.get("lang"), "browse")}>
                <div>
                  <Button
                    justIcon
                    simple
                    color="white"
                    size="sm"
                    tabIndex="-1"
                    onClick={() => this.handleClickOpenTable()}
                    className="many2one-icon-button"
                  >
                    <OpenIcon
                      className={
                        this.state.selected
                          ? "many2one-icon"
                          : "many2one-icon -disabled"
                      }
                    />
                  </Button>
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      );
    } else if (this.props.renderMode === "gridRenderer") {
      InputComponent = (
        <div
          className={`select-renderer-container ${
            this.props.washedOff === true && "input-disabled"
          }`}
        >
          <div className="select-value-container" style={{display:"flex", alignItems:"center"}}>
            <input type="text" value={this.state.selected?.label || ""} style={{outline:"none", backgroundColor:'transparent', border:"none", fontFamily:"Quicksand"}} readOnly/>
          </div>
          <div
            className="select-icon-container"
            onClick={() => {
              if (
                this.state.selected !== null &&
                this.props.formRenderMode === "formView"
              ) {
                this.handleViewForm();
              }
            }}
          >
            {this.props.formRenderMode === "formView" ? (
              <OpenIcon className="select-icon active" />
            ) : (
              <ArrowDown className="select-icon" />
            )}
          </div>
          <Tooltip
            style={{ display: this.state._error ? "inline-block" : "none" }}
            title={this.state._error ? this.state._error : ""}
          >
            <ReportIcon className="grid-error-icon" />
          </Tooltip>
        </div>
      );
    } else if (this.props.renderMode === "browserFilter") {
      InputComponent = (
        <div>
          <div
            className="basic-input-container browser-filter"
            onClick={() => this.handleTogglePopup()}
          >
            <div className="filter-value">
              {this.props.value ? this.props.value.label : ""}
            </div>
          </div>
          <div
            className={`browser-filter-popup-container ${
              this.state.popupOpen ? "open" : ""
            }`}
          >
            <div className="many2one-input-container">
              <div
                className="many2one-input"
                ref={(ref) => {
                  this.MTOContainerRef = ref;
                }}
              >
                <Select
                  styles={newStyle}
                  onFocus={() => {
                    this.refreshOptions();
                  }}
                  isLoading={false}
                  placeholder=""
                  className="browser-filter-many2one"
                  options={this.state.suggestion}
                  defaultValue={this.props.value ? this.props.value : null}
                  onInputChange={this.handleInputChange}
                  onChange={this.handleChange}
                  onKeyDown={(event) => this.handleSearch(event)}
                  isClearable
                />
              </div>
              {/* <Tooltip title={tl(Cookies.get("lang"),this.state.selected?"editData":"noOptionSelected")}>
											<div>
												<Button justIcon simple color="white" size="sm" disabled={this.state.selected?false:true} onClick={()=>this.handleClickEdit()} className="many2one-icon-button">
													<EditIcon className={this.state.selected?"many2one-icon":"many2one-icon -disabled"}/>
												</Button>
											</div>
										</Tooltip>
										<Tooltip title={tl(Cookies.get("lang"),"browse")}>
											<div>
												<Button justIcon simple color="white" size="sm" onClick={()=>this.handleClickOpenTable()} className="many2one-icon-button">
													<OpenIcon className={this.state.selected?"many2one-icon":"many2one-icon -disabled"} />
												</Button>
											</div>
										</Tooltip> */}
            </div>
            <div className="browser-filter-popup-buttons many2one">
              <Button size="sm" onClick={() => this.handleTogglePopup()}>
                Cancel
              </Button>
              <Button size="sm" onClick={() => this.applyFilter()}>
                Apply
              </Button>
            </div>
          </div>
        </div>
      );
    }
    return (
      <>
        {InputComponent}
        <Modal
          open={this.state.dialog.open}
          onCloseDialog={this.handleCloseDialog}
          payload={this.state.dialog.payload}
        />
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(ManyToOne);
