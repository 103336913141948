const Translation = {
	id:{
		//Sales Order
		"Bakery":"Bakery",
		"BakeryReport":"Laporan Bakery",
		"BakeryConfiguration":"Konfigurasi Bakery",
		"ItemStatisticReport":"Statistik Barang",
		"Sales":"Penjualan",
		"Configuration":"Konfigurasi",
		"SalesOrderOnline":"Pesanan Online",
		"SalesOrder":"Order Penjualan",
		"SalesOrderHistory":"Riwayat Penjualan",
		"sales_order":"Order Penjualan",
		"sales_order.draft":"Draf",
		"SalesOrder.confirmTitle.action/draft":"Draf",
		"SalesOrder.confirmContent.action/draft":"Apakah Anda yakin ingin mengembalikan statusnya Order Penjualan menjadi draf?",
		"SalesOrder.success.action/draft":"Order Penjualan berhasil diubah ke draf",
		"sales_order.payment":"Pembayaran",
		"SalesOrder.confirmTitle.action/payment":"Pembayaran",
		"SalesOrder.confirmContent.action/payment":"Silakan pilih metode pembayaran dan tanggal pembayaran",
		"SalesOrder.success.action/payment":"Order Penjualan berhasil diubah menjadi lunas",
		"sales_order.confirm":"Konfirmasi",
		"SalesOrder.confirmTitle.action/offer":"Beri Penawaran",
		"SalesOrder.confirmContent.action/offer":"Apakah Anda yakin ingin memberi penawaran ke konsumen?",
		"SalesOrder.success.action/offer":"Penawaran telah berhasil dilakukan",
		"sales_order.cancel":"Batalkan",
		"SalesOrder.confirmTitle.action/cancel":"Batalkan",
		"SalesOrder.confirmContent.action/cancel":"Apakah Anda yakin ingin membatalkan Order Penjualan ini?",
		"SalesOrder.success.action/cancel":"Order Penjualan telah berhasil dibatalkan",
		"SalesOrder.success.action/reject":"Order Penjualan telah berhasil ditolak",
		"SalesOrder.failed.action/reject":"Penolakan Order Penjualan gagal, silahkan hubungi administrator",
		"SalesOrder.confirmTitle.action/resolve":"Selesaikan Moderasi",
		"SalesOrder.confirmContent.action/resolve":"Apakah Anda yakin akan menyelesaikan moderasi ini",
		"SalesOrder.success.action/resolve":"Moderasi berhasil diselesaikan",
		"SalesOrder.name":"Nomor",
		"SalesOrder.reference":"Referensi",
		"SalesOrder.date":"Tanggal",
		"SalesOrder.periodId":"Periode",
		"SalesOrder.period":"Periode",
		"SalesOrder.customerId":"Pelanggan",
		"SalesOrder.customer":"Pelanggan",
		"SalesOrder.alias":"Alias",
		"SalesOrder.branchId":"Cabang",
		"SalesOrder.branch":"Cabang",
		"SalesOrder.note":"Catatan",
		"SalesOrder.selectCategory":"Pilih Kategori",
		"SalesOrder.shippingCostDiscount":"Diskon Ongkir",
		"SalesOrder.extraDiscount":"Diskon Tambahan",
		"SalesOrderOnline.resendInvoice":"Kirim Ulang Tagihan",
		"Items":"Daftar Barang",
		"Items":"Daftar Barang",
		"SalesOrder.lines":"Daftar Barang",
		"SalesOrder.totalDiscount":"Diskon Total",
		"SalesOrder.subtotal":"Subtotal",
		"SalesOrder.totalTax":"Total Setelah Pajak",
		"SalesOrder.total":"Grand Total",
		"Customer Additional Info":"Informasi Tambahan Pelanggan",
		"SalesOrder.salesOrderId":"Order Penjualan",
		"SalesOrder.salesOrder":"Order Penjualan",
		"SalesOrder.picName":"Nama PIC",
		"SalesOrder.picPhone":"Kontak PIC",
		"SalesOrder.picEmailAddress":"Email PIC",
		"SalesOrder.picPosition":"Posisi PIC",
		"SalesOrder.shippingAddress":"Alamat Pengiriman",
		"SalesOrder.shippingCityId":"Kota Pengiriman",
		"SalesOrder.shippingCity":"Kota Pengiriman",
		"SalesOrder.shippingProvinceId":"Provinsi Pengiriman",
		"SalesOrder.shippingProvince":"Provinsi Pengiriman",
		"SalesOrder.shippingPostalCode":"Kode Pos Pengiriman",
		"SalesOrder.latitude":"Latitude",
		"SalesOrder.longitude":"Longitude",
		"Other Information":"Informasi Lain",
		"Other Information":"Informasi Lain",
		"SalesOrder.userId":"User Pelanggan",
		"SalesOrder.user":"User Pelanggan",
		"SalesOrder.shippingCost":"Ongkos Kirim",
		"SalesOrder.state":"Status",
		"SalesOrder.paymentMethod":"Metode Pembayaran",
		"SalesOrder.shippingNumber":"No. Resi",
		"SalesOrder.shippingDate":"Tanggal Kirim",
		"SalesOrder.courier":"Kurir",
		//Sales Order Line
		"SalesOrderLine":"Baris Order Penjualan",
		"sales_order_line":"Baris Order Penjualan",
		"SalesOrderLine.name":"Nomor",
		"SalesOrderLine.itemId":"Barang",
		"SalesOrderLine.item":"Barang",
		"SalesOrderLine.additionalCharges":"Tambahan",
		"SalesOrderLine.startDate":"Tanggal Mulai",
		"SalesOrderLine.endDate":"Tanggal Selesai",
		"SalesOrderLine.quantity":"Jumlah",
		"SalesOrderLine.unitId":"Satuan",
		"SalesOrderLine.unit":"Satuan",
		"SalesOrderLine.unitPrice":"Harga",
		"SalesOrderLine.discountPercentage":"Diskon (%)",
		"SalesOrderLine.discountAmount":"Nilai Diskon",
		"SalesOrderLine.subtotal":"Subtotal",
		"SalesOrderLine.taxes":"Daftar Pajak",
		"SalesOrderLine.taxAmount":"Jumlah Pajak",
		"SalesOrderLine.total":"Total",
		"SalesOrderLine.accountSplitId":"Pemisahan Akun",
		"SalesOrderLine.accountSplit":"Pemisahan Akun",
		"SalesOrderLine.vendorId":"Pemasok",
		"SalesOrderLine.vendor":"Pemasok",
		"SalesOrderLine.multiplier":"Pengali",
		//sales Order - Cancellation
		"SalesOrder.cancellationCategory":"Kategori Pembatalan",
		"SalesOrder.cancellationReason":"Alasan Pembatalan",
		//Sales Order Rejection
		"SalesOrderRejection":"Penolakan Order",
		"SalesOrderRejection.RejectOrder":"Tolak Order",
		"SalesOrderRejection.RejectionCategory":"Kategori Penolakan",
		"SalesOrderRejection.RejectionReason":"Alasan Penolakan",
		//sales order - customer requested a moderation
		"customerReqestedModeration":"Pelanggan meminta moderasi",
		"SalesOrder.moderationCategory":"Kategori Moderasi",
		"SalesOrder.moderationMessage":"Pesan Moderasi",
		"SalesOrder.callCustomer":"Hubungi Pelanggan",
		"SalesOrder.moderationResolve":"Moderasi Selesai",
		"SalesOrder.BeingModerated":"Pesanan sedang dimoderasi",
		"SalesOrder.BeingModeratedDesc":"Silahkan tunggu sampai admin menyelesaikan moderasi",
		//sales order - history
		"SalesOrder.history":"Riwayat",
		"SalesOrder.paymentReceipt":"Bukti Pembayaran",
		"SalesOrder.cancelledBrowse":"Pesanan Dibatalkan",
		"SalesOrder.arrivedBrowse":"Pesanan Terkirim",
		"SalesOrder.customerInfo":"Informasi Pelanggan",
		"SalesOrder.recipientInfo":"Informasi Penerima",
		"SalesOrder.shippingInfo":"Informasi Pengiriman",
		"SalesOrder.cancellationInfo":"Informasi Pembatalan",
		"SalesOrder.customerContact":"Kontak Pelanggan",
		"SalesOrder.savedCustomer":"Pelanggan Tersimpan",
		"SalesOrder.savedAddress":"Alamat Tersimpan",
		"SalesOrder.selectFromSavedCustomer":"Pilih dari pelanggan tersimpan",
		"SalesOrder.customerName":"Nama Pemesan",
		"SalesOrder.customerMobile":"Nomor Pemesan",
		"SalesOrder.recipientName":"Nomor Penerima",
		"SalesOrder.recipientMobile":"Nomor Penerima",
		"SalesOrder.city":"Kota",
		"SalesOrder.shippingAddress":"Alamat Lengkap",
		"SalesOrder.shippingDate":"Tanggal Pengiriman",
		"SalesOrder.shippingZipCode":"Kode Pos",
		"SalesOrder.cencelledByUser":"Dibatalkan Oleh",
		"SalesOrder.cancelledAt":"Tanggal Pembatalan",
		"SalesOrder.cancellationReason":"Alasan Pembatalan",
		"SalesOrder.track":"Lacak",
		"SalesOrder.change":"Ubah",
		"SalesOrder.paymentReceipt":"Bukti Pembayaran",
		"SalesOrder.totalPurchase":"Total Belanja",
		"SalesOrder.history.salesOrderDate":"Tanggal Pesan",
		"SalesOrder.history.offerDate":"Tanggal Ditawarkan",
		"SalesOrder.history.waitingForApprovalDate":"Tanggal Menunggu Ditawarkan",
		"SalesOrder.history.paymentDate":"Tanggal Pembayaran",
		"SalesOrder.history.confirmationDate":"Tanggal Dikonfirmasi",
		"SalesOrder.history.vendorAcceptanceDate":"Tanggal Disetujui Vendor",
		"SalesOrder.history.deliveryDate":"Tanggal Dikirim",
		"SalesOrder.history.deliveredDate":"Tanggal Terkirim",
		"SalesOrder.history.completionDate":"Tanggal Selesai",
		"SalesOrder.history.rejectionDate":"Tanggal Ditolak",
		"SalesOrder.history.cancellationDate":"Tanggal Dibatalkan",

		"SalesOrderOnline.reject":"Tolak",
		"SalesOrderOnline.accept":"Terima",
		"SalesOrderOnline.cancel":"Batalkan",
		"SalesOrderOnline.enterShippingCost":"Masukkan Ongkir",
		"SalesOrderOnline.confirmPayment":"Konfirmasi Pembayaran",
		"SalesOrderOnline.confirmShipment":"Konfirmasi Pengiriman",
		"SalesOrderOnline.completeOrder":"Pesanan Terkirim",
		"SalesOrderOnline.setPaid":"Sudah Bayar",
		"SalesOrderOnline.resendMessage":"Kirim Ulang WhatsApp",
		"SalesOrderOnline.rejectPayment":"Tolak Pembayaran",

		//Online Shop Item
		"ShopItem":"Barang Toko Online",
		"ShopItem.defaultPrice":"Harga Standar",
		"ShopItem.variantPrices":"Harga Varian",
		"ShopItem.options":"Pilihan Barang",
		"Item":"Barang",
		"item":"Barang",
		"item.activate":"Aktifkan",
		"Item.confirmTitle.action/activate":"Aktifkan",
		"Item.confirmContent.action/activate":"Apakah Anda yakin ingin mengaktifkan Barang ini?",
		"Item.success.activate":"Barang telah berhasil diaktifkan",
		"item.deactivate":"Nonaktifkan",
		"Item.confirmTitle.action/deactivate":"Nonaktifkan",
		"Item.confirmContent.action/deactivate":"Apakah Anda yakin ingin menonaktifkan Barang ini?",
		"Item.success.deactivate":"Barang telah berhasil dinonaktifkan",
		"Item.barcode_print":"Cetak Barcode",
		"Item.confirmTitle.barcode_print":"Cetak Barcode",
		"Item.confirmContent.barcode_print":"Apakah Anda yakin ingin mencetak barcode untuk barang ini?",
		"Item.success.barcode_print":"Barcode telah berhasil dicetak",
		"Item Information":"Informasi Barang / Jasa",
		"Item.code":"Kode",
		"Item.name":"Nama",
		"Item.categoryId":"Kategori",
		"Item.category":"Kategori",
		"Item.type":"Tipe",
		"Item.additionalCharges":"Biaya Tambahan",
		"Item.brandId":"Merek",
		"Item.brand":"Merek",
		"Item.tags":"Tag",
		"Item.attributes":"Atribut",
		"Item.position":"Posisi Urutan",
		"Item.description":"Deskripsi",
		"Prices":"Daftar Harga",
		"Prices":"Daftar Harga",
		"Item.defaultUnitId":"Satuan Dasar",
		"Item.defaultUnit":"Satuan Dasar",
		"Item.defaultPurchaseUnit":"Satuan Dasar Pembelian",
		"Item.vendor":"Vendor Utama",
		"Item.purchase":"Pembelian",
		"Item.timeMeasured":"Diukur Waktu",
		"Item.UnitId":"Satuan",
		"Item.Unit":"Satuan",
		"Item.units":"Satuan Yang Dapat Dipilih",
		"Item.defaultPrice":"Harga Standar",
		"Item.purchasePrice":"Harga Pembelian",
		"Item.branchPrices":"Harga Per Cabang",
		"Item.additionalCharges":"Biaya Tambahan",
		"Inventory":"Persediaan",
		"Inventory":"Persediaan",
		"Item.inventoryType":"Tipe Persediaan",
		"Item.minQuantity":"Jumlah Minimum Stok",
		"Item.warehouseId":"Gudang Standar",
		"Item.warehouse":"Gudang Standar",
		"Item.defaultLocation":"Lokasi Standar Masuk",
		"Item.defaultInLocation":"Lokasi Standar Keluar",
		"Item.hasUpc":"Punya UPC",
		"Item.hasSku":"Punya SKU",
		"Item.generateSKU":"SKU Otomatis",
		"Item.length":"Panjang",
		"Item.width":"Lebar",
		"Item.thickness":"Ketebalan",
		"Online Store":"Toko Online",
		"Item.saleableOnline":"Dapat Dijual Online",
		"Item.dropshipped":"Dropship",
		"Item.position":"Posisi Urutan",
		"Item.image":"Gambar",
		"Item.thumbnail":"Cuplikan",
		"Item.images":"Daftar Gambar",
		"Item.attachments":"Lampiran",
		"Item.cleanUrl":"URL Sederhana",
		"Item.onlineDescription":"Deskripsi Online",
		"Item.listingDate":"Tanggal Listing",
		"Item.viewCount":"Jumlah View",
		"Item.variants":"Daftar Varian",
		"Item.codes":"Daftar Kode",
		"Item.options":"Pilihan",
		"Accounting":"Akunting",
		"Item.inventoryAccountId":"Akun Persediaan",
		"Item.inventoryAccount":"Akun Persediaan",
		"Item.salesAccountId":"Akun Penjualan",
		"Item.salesAccount":"Akun Penjualan",
		"Item.salesReturnAccountId":"Akun Retur Penjualan",
		"Item.salesReturnAccount":"Akun Retur Penjualan",
		"Item.salesItemDiscAccountId":"Akun Diskon Penjualan",
		"Item.salesItemDiscAccount":"Akun Diskon Penjualan",
		"Item.goodsInTransitAccountId":"Akun Barang Dalam Transit",
		"Item.goodsInTransitAccount":"Akun Barang Dalam Transit",
		"Item.purchaseReturnAccountId":"Akun Retur Pembelian",
		"Item.purchaseReturnAccount":"Akun Retur Pembelian",
		"Item.unbilledPayableAccountId":"Akun Utang Yang Belum Ditagihkan",
		"Item.unbilledPayableAccount":"Akun Utang Yang Belum Ditagihkan",
		"Item.unbilledReceivableAccountId":"Akun Piutang Yang Belum Ditagihkan",
		"Item.unbilledReceivableAccount":"Akun Piutang Yang Belum Ditagihkan",
		"Item.salesCommissionAccount":"Akun Komisi Penjualan",
		"Item.salesCommissionAccountId":"Akun Komisi Penjualan",
		"Item.expenseAccountId":"Akun Beban",
		"Item.expenseAccount":"Akun Beban",
		"Item.cogsAccountId":"Akun HPP",
		"Item.cogsAccount":"Akun HPP",
		"Item.assetAccountId":"Akun Aset",
		"Item.assetAccount":"Akun Aset",
		"Item.depreciationAccountId":"Akun Depresiasi",
		"Item.depreciationAccount":"Akun Depresiasi",
		"Item.accumulatedDepreciationAccountId":"Akun Akumulasi Depresiasi",
		"Item.accumulatedDepreciationAccount":"Akun Akumulasi Depresiasi",
		"Item.gainOrLossOnSalesAccountId":"Akun Keuntungan Kerugian Penjualan",
		"Item.gainOrLossOnSalesAccount":"Akun Keuntungan Kerugian Penjualan",
		"Item.gainOrLossOnRevaluationAccountId":"Akun Keuntungan Kerugian Penilaian Ulang",
		"Item.gainOrLossOnRevaluationAccount":"Akun Keuntungan Kerugian Penilaian Ulang",
		"Item.lossOnScrapAccountId":"Akun Kerugian Penghapusan",
		"Item.lossOnScrapAccount":"Akun Kerugian Penghapusan",
		"Item.disallowSellBelowPrice":"Tolak Penjualan Dibawah Harga",
		"Item.priceIncludesVAT":"Harga Termasuk PPN",
		"Item.byPassPriceCheck":"Abaikan Pengecekan Harga",
		"Item.allowSplitAccount":"Perbolehkan Pemisahan Akun",
		"Item.state":"Status",
		"Item.hideOnReport":"Sembunyikan Dari Laporan",
		"Item.metaDescription":"Deskripsi Meta",
		"Item.marketplaces":"Marketplace",
		"Item.variant":"Varian",
		"Item.variantPrices":"Varian Harga",
		"Item.packagingPrices":"Daftar Harga Kemasan",
		"Item.codeAdded":"Kode Ditambahkan",
		"Item.upcAdded":"UPC Ditambahkan",
		"Item.skuAdded":"SKU Ditambahkan",
		"Item.marketplacePrices":"Daftar Harga Marketplace",
		"Item.MarketplacePricesLine.marketplace":"Marketplace",
		"Item.MarketplacePricesLine.choice":"Pilihan Varian",
		"Item.MarketplacePricesLine.unit":"Satuan",
		"Item.MarketplacePricesLine.price":"Harga",
		"Item.rack":"Rak",
		"Item.purchaseQuantity":"Kuantitas Pembelian",
		"Item.grade":"Grade",
		"Item.relatedItem":"Relasi Barang",
		"Item.relatedItemConversion":"Konversi Relasi Barang",
		"Item.commission":"Komisi",
		"Item.commissionType":"Tipe Komisi",
		"Item.commissionPercentage":"Persentase Komisi (%)",
		"Item.commissionValue":"Nilai Komisi",
		"Item.tax":"Pajak",
		"Item.customAdditionalTax":"Pajak Tambahan",
		"Item.customAdditionalTaxPercentage":"Persentase Pajak Tambahan",
		"Item.vendorLeadTimeDays":"Lead Time (Hari)",
		"Item.turnoverRate":"Kecepatan Perputaran",

		//Item Option
		"ItemOption":"Pilihan Barang",
		"item_option":"Pilihan Barang",
		"ItemOption.name":"Nama",
		"ItemOption.state":"Status",
		"ItemOption.activate":"Aktifkan",
		"ItemOption.confirmTitle.action/activate":"Aktifkan",
		"ItemOption.confirmContent.action/activate":"Apakah Anda yakin ingin mengaktifkan Pilihan Barang ini?",
		"ItemOption.success.action/activate":"Piilihan Barang telah berhasil diaktifkan",
		"ItemOption.deactivate":"Nonaktifkan",
		"ItemOption.confirmTitle.action/deactivate":"Nonaktifkan",
		"ItemOption.confirmContent.action/deactivate":"Apakah Anda yakin ingin menonaktifkan Pilihan Barang ini?",
		"ItemOption.success.action/deactivate":"Pilihan Barang telah berhasil dinonaktifkan",
		"ItemOptionLine.option":"Pilihan",
		"ItemOptionLine.sequence":"Urutan",

		//Packaging
		"Packaging":"Kemasan",
		"packaging":"Kemasan",
		"Packaging.name":"Nama",
		"Packaging.width":"Lebar",
		"Packaging.length":"Panjang",
		"Packaging.height":"Tinggi",
		"Packaging.weight":"Berat",
		"Packaging.state":"Status",
		"Packaging.activate":"Aktifkan",
		"Packaging.confirmTitle.action/activate":"Aktifkan",
		"Packaging.confirmContent.action/activate":"Apakah Anda yakin ingin mengaktifkan Kemasan ini?",
		"Packaging.success.action/activate":"Kemasan telah berhasil diaktifkan",
		"Packaging.deactivate":"Nonaktifkan",
		"Packaging.confirmTitle.action/deactivate":"Nonaktifkan",
		"Packaging.confirmContent.action/deactivate":"Apakah Anda yakin ingin menonaktifkan Kemasan ini?",
		"Packaging.success.action/deactivate":"Kemasan telah berhasil dinonaktifkan",

		//Item Category
		"ItemCategory":"Kategori Barang",
		"item_category":"Kategori Barang",
		"item_category.activate":"Aktifkan",
		"ItemCategory.confirmTitle.action/activate":"Aktifkan",
		"ItemCategory.confirmContent.action/activate":"Apakah Anda yakin ingin mengaktifkan Kategori Barang ini?",
		"ItemCategory.success.activate":"Kategori Barang telah berhasil diaktifkan",
		"item_category.deactivate":"Nonaktifkan",
		"ItemCategory.confirmTitle.action/deactivate":"Nonaktifkan",
		"ItemCategory.confirmContent.action/deactivate":"Apakah Anda yakin ingin menonaktifkan Kategori Barang ini?",
		"ItemCategory.success.deactivate":"Kategori Barang telah berhasil dinonaktifkan",
		"ItemCategory.code":"Kode",
		"ItemCategory.name":"Nama",
		"ItemCategory.cleanUrl":"Clean URL",
		"ItemCategory.parentId":"Kategori Induk",
		"ItemCategory.parent":"Kategori Induk",
		"ItemCategory.image":"Gambar",
		"ItemCategory.thumbnail":"Cuplikan",
		"ItemCategory.state":"Status",
		"ItemCategory.hideOnReport":"Sembunyikan Dari Laporan",
		"ItemCategory.saleableOnline":"Dapat Dijual Online",

		//Partner
		"Partner":"Partner",
		"partner":"Partner",
		"partner.activate":"Aktifkan",
		"Partner.confirmTitle.activate":"Aktifkan",
		"Partner.confirmContent.activate":"Apakah Anda yakin ingin mengaktifkan Partner ini?",
		"Partner.success.activate":"Partner telah berhasil diaktifkan",
		"partner.deactivate":"Nonaktifkan",
		"Partner.confirmTitle.deactivate":"Nonaktifkan",
		"Partner.confirmContent.deactivate":"Apakah Anda yakin ingin menonaktifkan Partner ini?",
		"Partner.success.deactivate":"Partner telah berhasil dinonaktifkan",
		"Partner Information":"Informasi Partner",
		"Partner.name":"Nama",
		"Partner.isSupplier":"Supplier",
		"Partner.isCustomer":"Pelanggan",
		"Partner.email":"Email",
		"Partner.mobile":"Nomor HP",
		"Partner.contact":"Nomor Telepon Rumah",
		"Partner.street":"Alamat",
		"Partner.cityId":"Kota",
		"Partner.city":"Kota",
		"Partner.provinceId":"Provinsi",
		"Partner.province":"Provinsi",
		"Partner.countryId":"Negara",
		"Partner.country":"Negara",
		"Partner.zip":"Kode Pos",
		"Partner.ssid":"Nomor KTP",
		"Partner.idAttachment":"Lampiran KTP",
		"Finance and Accounting":"Keuangan and Akunting",
		"Partner.unlimitedCredit":"Kredit Tidak Terbatas",
		"Partner.creditLimit":"Plafon Kredit",
		"Partner.credit":"Kredit",
		"Partner.debitLimit":"Plafon Debit",
		"Partner.debit":"Debit",
		"Partner.taxable":"Kena Pajak",
		"Partner.npwp":"NPWP",
		"Partner.defaultCurrencyId":"Kurs Standar",
		"Partner.defaultCurrency":"Kurs Standar",
		"Partner.purchaseInvoiceDaysDue":"Jatuh Tempo Tagihan Pembelian (Hari)",
		"Partner.salesInvoiceDaysDue":"Jatuh Tempo Tagihan Penjualan (Hari)",
		"Branch Information":"Informasi Cabang",
		"Partner.branchId":"Cabang",
		"Partner.branch":"Cabang",
		"Company Information":"Informasi Perusahaan",
		"Partner.isCompany":"Perusahaan",
		"Partner.companyName":"Nama Perusahaan",
		"Partner.companyNpwp":"NPWP Perusahaan",
		"Partner.companyAddress":"Alamat Perusahaan",
		"Partner.companyCityId":"Kota Perusahaan",
		"Partner.companyCity":"Kota Perusahaan",
		"Partner.companyProvinceId":"Provinsi Perusahaan",
		"Partner.companyProvince":"Provinsi Perusahaan",
		"Partner.companyCountryId":"Negara Perusahaan",
		"Partner.companyCountry":"Negara Perusahaan",
		"Partner.companyZip":"Kode Pos Perusahaan",
		"Other Information":"Informasi Lain",
		"Partner.nodeId":"Titik",
		"Partner.node":"Titik",
		"Partner.joinDate":"Tanggal Bergabung",
		"Partner.media":"Media",
		"Partner.class":"Kelas",
		"Partner.code":"Kode",
		"Partner.state":"Status",
		"Partner.purchasing":"Pembelian",
		"Partner.purchasingUsers":"Penanggung Jawab",
		"Partner.user":"Nama Staf",
		"Partner.birthDate":"Tanggal Lahir",
		"Partner.idNumber":"No. KTP",
		"Partner.idAddress":"Alamat KTP",
		"Partner.address":"Alamat Lengkap",
		"Partner.occupation":"Pekerjaan",
		"Partner.maritalStatus":"Status Pernikahan",
		"Partner.incomeRange":"Kisaran Penghasilan",

		//Item Spec
		"ItemSpec":"Spesifikasi",
		"item_spec":"Spesifikasi Barang",
		"ItemSpec.name":"Nama",
		"ItemSpec.image":"Gambar",
		"ItemSpec.priority":"Prioritas",
		"ItemSpec.state":"Status",
		"ItemSpec.activate":"Aktifkan",
		"ItemSpec.deactivate":"Nonaktifkan",
		"ItemSpec.confirmTitle.action/activate":"Aktifkan",
		"ItemSpec.confirmTitle.action/deactivate":"Nonaktifkan",
		"ItemSpec.confirmContent.action/activate":"Apa Anda yakin akan mengaktifkan spesifikasi ini?",
		"ItemSpec.confirmContent.action/deactivate":"Apa Anda yakin akan menonaktifkan spesifikasi ini?",
		"ItemSpec.success.action/activate":"Spesifikasi berhasil diaktifkan",
		"ItemSpec.success.action/deactivate":"Spesifikasi berhasil dinonaktifkan",

		//Seasonal & shipping Discount
		"SeasonalDiscount":"Diskon Musiman",
		"ShippingDiscount":"Diskon Ongkir",
		"SeasonalDiscount.name":"Nama",
		"SeasonalDiscount.startDate":"Tanggal Mulai",
		"SeasonalDiscount.endDate":"Tanggal Akhir",
		"SeasonalDiscount.discountPercentage":"Persentase Diskon",
		"SeasonalDiscount.discountAmount":"Jumlah Diskon",
		"SeasonalDiscount.maxDiscount":"Diskon Maksimal",
		"SeasonalDiscount.minPurchase":"Minimal Pembelanjaan",
		"SeasonalDiscount.description":"Deskripsi",
		"SeasonalDiscount.state":"Status",
		"SeasonalDiscount.confirmTitle.action/activate":"Aktifkan",
		"SeasonalDiscount.confirmTitle.action/deactivate":"Nonaktifkan",
		"SeasonalDiscount.confirmContent.action/activate":"Apa Anda yakin akan mengaktifkan diskon musiman ini?",
		"SeasonalDiscount.confirmContent.action/deactivate":"Apa Anda yakin akan menonaktifkan diskon musiman ini?",
		"SeasonalDiscount.success.action/activate":"Diskon musiman berhasil diaktifkan",
		"SeasonalDiscount.success.action/deactivate":"Dison musiman berhasil dinonaktifkan",

		waiting_approval:"Butuh Persetujuan",
		waiting_shipping_cost:"Butuh Ongkir",
		waiting_payment:"Tunggu Bayar",
		waiting_payment_check:"Butuh Cek Bayar",
		waiting_shipment:"Butuh Kirim",
		shipped:"Dalam Perjalanan",
		arrived:"Terkirim",
		cancelled:"Dibatalkan",

		"offerDate":"Tanggal Diajukan",
		"approvalDate":"Tanggal Disetujui",
		"shippingCostOfferDate":"Tanggal Penetapan Ongkir",
		"paymentDate":"Tanggal Pembayaran",
		"paymentConfimedDate":"Tanggal Konfirmasi Pembayaran",
		"shipmentDate":"Tanggal Kirim",
		"arrivalDate":"Tanggal Terkirim",

	},
	en:{
		//Sales Order
		"Bakery":"Bakery",
		"BakeryReport":"Bakery Report",
		"BakeryConfiguration":"Bakery Configuration",
		"ItemStatisticReport":"Item Statistic Report",
		"Sales":"Sales",
		"Configuration":"Configuration",
		"SalesOrderOnline":"Online Sales",
		"SalesOrderHistory":"Sales History",
		"SalesOrder":"Sales Order",
		"sales_order":"Sales Order",
		"sales_order.draft":"Draft",
		"SalesOrder.confirmTitle.action/draft":"Draft",
		"SalesOrder.confirmContent.action/draft":"Are you sure to revert this back to draft?",
		"SalesOrder.success.action/draft":"Sales Order is successfully reverted back to draft",
		"sales_order.payment":"Payment",
		"SalesOrder.confirmTitle.action/payment":"Payment",
		"SalesOrder.confirmContent.action/payment":"Please select payment method and date",
		"SalesOrder.success.action/payment":"Sales order is sucessfully set to paid",
		"sales_order.confirm":"Offer",
		"SalesOrder.confirmTitle.action/offer":"Offer",
		"SalesOrder.confirmContent.action/offer":"Are you sure you want to offer this Sales Order to the customer?",
		"SalesOrder.success.action/offer":"Sales Order is successfully offered",
		"sales_order.cancel":"Cancel",
		"SalesOrder.confirmTitle.action/cancel":"Cancel",
		"SalesOrder.confirmContent.action/cancel":"Are you sure you want to cancel this Sales Order?",
		"SalesOrder.success.action/cancel":"Sales Order is successfully cancelled",
		"SalesOrder.success.action/reject":"Sales Order is successfully rejected",
		"SalesOrder.failed.action/reject":"Sales Order rejection failed, please contact the administrator",
		"SalesOrder.confirmTitle.action/resolve":"Resolve Moderation",
		"SalesOrder.confirmContent.action/resolve":"Are you sure you want to resolve this Sales Order's Moderation?",
		"SalesOrder.success.action/resolve":"Sales Order's Moderation is successfully resolved",
		"SalesOrder.name":"Number",
		"SalesOrder.reference":"Reference",
		"SalesOrder.date":"Date",
		"SalesOrder.periodId":"Period",
		"SalesOrder.period":"Period",
		"SalesOrder.customerId":"Customer",
		"SalesOrder.customer":"Customer",
		"SalesOrder.alias":"Alias",
		"SalesOrder.branchId":"Branch",
		"SalesOrder.branch":"Branch",
		"SalesOrder.note":"Note",
		"SalesOrder.selectCategort":"Select Category",
		"SalesOrder.extraDiscount":"Additional Discount",
		"SalesOrder.shippingCostDiscount":"Shipping Cost Discount",
		"SalesOrderOnline.resendInvoice":"Resend Invoice",
		"Items":"Items",
		"Items":"Items",
		"SalesOrder.lines":"Items",
		"SalesOrder.totalDiscount":"Total Discount",
		"SalesOrder.subtotal":"Subtotal",
		"SalesOrder.totalTax":"After Tax",
		"SalesOrder.total":"Grand Total",
		"Customer Additional Info":"Customer Additional Info",
		"Customer Additional Info":"Customer Additional Info",
		"SalesOrder.salesOrderId":"Sales Order",
		"SalesOrder.salesOrder":"Sales Order",
		"SalesOrder.picName":"PIC Name",
		"SalesOrder.picPhone":"PIC Contact",
		"SalesOrder.picEmailAddress":"PIC Email",
		"SalesOrder.picPosition":"PIC Position",
		"SalesOrder.shippingAddress":"Shipping Address",
		"SalesOrder.shippingCityId":"Shipping City",
		"SalesOrder.shippingCity":"Shipping City",
		"SalesOrder.shippingProvinceId":"Shipping Province",
		"SalesOrder.shippingProvince":"Shipping Province",
		"SalesOrder.shippingPostalCode":"Shipping Postal Code",
		"SalesOrder.latitude":"Latitude",
		"SalesOrder.longitude":"Longitude",
		"Other Information":"Other Information",
		"Other Information":"Other Information",
		"SalesOrder.userId":"Customer User",
		"SalesOrder.user":"Customer User",
		"SalesOrder.shippingCost":"Shipping Cost",
		"SalesOrder.state":"State",
		"SalesOrder.paymentMethod":"Payment Method",
		"SalesOrder.shippingNumber":"Shipping Number",
		"SalesOrder.shippingDate":"Shipping Date",
		"SalesOrder.courier":"Courier",
		//Sales Order Line
		"SalesOrderLine":"Sales Order Line",
		"sales_order_line":"Sales Order Line",
		"SalesOrderLine.name":"Number",
		"SalesOrderLine.itemId":"Item",
		"SalesOrderLine.item":"Item",
		"SalesOrderLine.additionalCharges":"Additionals",
		"SalesOrderLine.startDate":"Start Date",
		"SalesOrderLine.endDate":"End Date",
		"SalesOrderLine.quantity":"Quantity",
		"SalesOrderLine.unitId":"Unit",
		"SalesOrderLine.unit":"Unit",
		"SalesOrderLine.unitPrice":"Price",
		"SalesOrderLine.discountPercentage":"Discount (%)",
		"SalesOrderLine.discountAmount":"Discount Amount",
		"SalesOrderLine.subtotal":"Subtotal",
		"SalesOrderLine.taxes":"Taxes",
		"SalesOrderLine.taxAmount":"Tax Amount",
		"SalesOrderLine.total":"Total",
		"SalesOrderLine.accountSplitId":"Account Split",
		"SalesOrderLine.accountSplit":"Account Split",
		"SalesOrderLine.vendorId":"Vendor",
		"SalesOrderLine.vendor":"Vendor",
		//sales Order - Cancellation
		"SalesOrder.cancellationCategory":"Cancellation Category",
		"SalesOrder.cancellationReason":"Cancellation Reason",
		//Sales Order - Rejection
		"SalesOrderRejection":"Order Rejection",
		"SalesOrderRejection.RejectOrder":"Reject Order",
		"SalesOrderRejection.RejectionCategory":"Rejection Category",
		"SalesOrderRejection.RejectionReason":"Rejection Reason",
		//sales order - customer requested a moderation
		"customerReqestedModeration":"Customer requested a moderation",
		"SalesOrder.moderationCategory":"Moderation Category",
		"SalesOrder.moderationMessage":"Moderation Message",
		"SalesOrder.callCustomer":"Call Customer",
		"SalesOrder.moderationResolve":"Moderation Resolve",
		"SalesOrder.BeingModerated":"Order is being moderated",
		"SalesOrder.BeingModeratedDesc":"Please wait until admin resolve the moderation",
		//sales order - history
		"SalesOrder.history":"History",
		"SalesOrder.cancelledBrowse":"Cancelled Sales",
		"SalesOrder.arrivedBrowse":"Arrived Sales",
		"SalesOrder.customerInfo":"Customer Information",
		"SalesOrder.recipientInfo":"Recipient Information",
		"SalesOrder.shippingInfo":"Shipping Information",
		"SalesOrder.cancellationInfo":"Cancellation Information",
		"SalesOrder.customerContact":"Customer Contact",
		"SalesOrder.savedCustomer":"Saved Customer",
		"SalesOrder.savedAddress":"Saved Address",
		"SalesOrder.selectFromSavedCustomer":"Select from saved customers",
		"SalesOrder.customerName":"Customer Name",
		"SalesOrder.customerMobile":"Customer Mobile",
		"SalesOrder.recipientName":"Recipient Name",
		"SalesOrder.recipientMobile":"Recipient Mobile",
		"SalesOrder.city":"City",
		"SalesOrder.shippingAddress":"Shipping Address",
		"SalesOrder.shippingDate":"Shipping Date",
		"SalesOrder.shippingZipCode":"Zip Code",
		"SalesOrder.cencelledByUser":"Cancelled By",
		"SalesOrder.cancelledAt":"Cancellation Date",
		"SalesOrder.cancellationReason":"Cancellation Reason",
		"SalesOrder.track":"Track",
		"SalesOrder.change":"Change",
		"SalesOrder.paymentReceipt":"Payment Receipt",
		"SalesOrder.customerName":"Customer Name",
		"SalesOrder.totalPurchase":"Total Purchase",
		"SalesOrder.history.salesOrderDate":"Order Date",
		"SalesOrder.history.offerDate":"Offered Date",
		"SalesOrder.history.waitingForApprovalDate":"Waiting Approval Date",
		"SalesOrder.history.paymentDate":"Payment Date",
		"SalesOrder.history.confirmationDate":"Confirmation Date",
		"SalesOrder.history.vendorAcceptanceDate":"Vendor Acceptance Date",
		"SalesOrder.history.deliveryDate":"Delivery Date",
		"SalesOrder.history.deliveredDate":"Delivered Date",
		"SalesOrder.history.completionDate":"Completion Date",
		"SalesOrder.history.rejectionDate":"Rejection Date",
		"SalesOrder.history.cancellationDate":"Cancellation Date",

		"SalesOrderOnline.reject":"Reject",
		"SalesOrderOnline.accept":"Accept",
		"SalesOrderOnline.cancel":"Cancel",
		"SalesOrderOnline.enterShippingCost":"Enter Shipping Cost",
		"SalesOrderOnline.confirmPayment":"Confirm Payment",
		"SalesOrderOnline.confirmShipment":"Confirm Shipment",
		"SalesOrderOnline.completeOrder":"Set Shipped",
		"SalesOrderOnline.setPaid":"Set Paid",
		"SalesOrderOnline.resendMessage":"Resend WhatsApp Message",
		"SalesOrderOnline.rejectPayment":"Reject Payment",

		"Item":"Item",
		"item":"Item",
		"ShopItem":"Online Shop item",
		"ShopItem.defaultPrice":"Price",
		"ShopItem.options":"Item Options",
		"ShopItem.variantPrices":"Price Variants",
		"item.activate":"Activate",
		"Item.confirmTitle.action/activate":"Activate",
		"Item.confirmContent.action/activate":"Are you sure you want to activate this Item?",
		"Item.success.activate":"Item is successfully activated",
		"item.deactivate":"Deactivate",
		"Item.confirmTitle.action/deactivate":"Deactivate",
		"Item.confirmContent.action/deactivate":"Are you sure you want to deactivate this Item?",
		"Item.success.deactivate":"Item is successfully deactivated",
		"Item.barcode_print":"Print Barcode",
		"Item.confirmTitle.barcode_print":"Print Barcode",
		"Item.confirmContent.barcode_print":"Are you sure want to print barcodes for this item?",
		"Item.success.barcode_print":"Barcode has successfully printed",
		"Item Information":"Item Information",
		"Item.code":"Code",
		"Item.name":"Name",
		"Item.categoryId":"Category",
		"Item.category":"Category",
		"Item.type":"Type",
		"Item.additionalCharges":"Additonal Charges",
		"Item.position":"Position",
		"Item.brandId":"Brand",
		"Item.brand":"Brand",
		"Item.tags":"Tags",
		"Item.attributes":"Attributes",
		"Item.description":"Description",
		"Prices":"Prices",
		"Prices":"Prices",
		"Item.defaultUnitId":"Base Unit",
		"Item.defaultPurchaseUnit":"Default Purchase Unit",
		"Item.vendor":"Main Vendor",
		"Item.purchase":"Purchase",
		"Item.defaultUnit":"Base Unit",
		"Item.timeMeasured":"Time Measured",
		"Item.UnitId":"Unit",
		"Item.Unit":"Unit",
		"Item.units":"Selectable Units",
		"Item.defaultPrice":"Default Price",
		"Item.purchasePrice":"Purchase Price",
		"Item.branchPrices":"Branch Prices",
		"Inventory":"Inventory",
		"Inventory":"Inventory",
		"Item.inventoryType":"Inventory Type",
		"Item.minQuantity":"Minimum Quantity",
		"Item.warehouseId":"Default Warehouse",
		"Item.warehouse":"Default Warehouse",
		"Item.defaultLocation":"Default Location Out",
		"Item.defaultInLocation":"Default Location In",
		"Item.hasUpc":"Has UPC",
		"Item.hasSku":"Has SKU",
		"Item.generateSKU":"Generate SKU",
		"Item.length":"Length",
		"Item.width":"Width",
		"Item.thickness":"Thickness",
		"Online Store":"Online Store",
		"Item.saleableOnline":"Saleable Online",
		"Item.dropshipped":"Dropshipped",
		"Item.position":"Sequence",
		"Item.image":"Image",
		"Item.thumbnail":"Thumbnail",
		"Item.images":"Images",
		"Item.attachments":"Attachments",
		"Item.cleanUrl":"Clean URL",
		"Item.onlineDescription":"Online Description",
		"Item.listingDate":"Listing Date",
		"Item.viewCount":"View Count",
		"Item.variants":"Variant List",
		"Item.codes":"Codes",
		"Item.options":"Item Options",
		"Accounting":"Accounting",
		"Item.inventoryAccountId":"Inventory Account",
		"Item.inventoryAccount":"Inventory Account",
		"Item.salesAccountId":"Sales Account",
		"Item.salesAccount":"Sales Account",
		"Item.salesReturnAccountId":"Sales Return Account",
		"Item.salesReturnAccount":"Sales Return Account",
		"Item.salesItemDiscAccountId":"Sales Discount Account",
		"Item.salesItemDiscAccount":"Sales Discount Account",
		"Item.goodsInTransitAccountId":"Goods In Transit Account",
		"Item.goodsInTransitAccount":"Goods In Transit Account",
		"Item.purchaseReturnAccountId":"Purchase Return Account",
		"Item.purchaseReturnAccount":"Purchase Return Account",
		"Item.unbilledPayableAccountId":"Unbilled Payable Account",
		"Item.unbilledPayableAccount":"Unbilled Payable Account",
		"Item.unbilledReceivableAccountId":"Unbilled Receivable Account",
		"Item.unbilledReceivableAccount":"Unbilled Receivable Account",
		"Item.salesCommissionAccount":"Sales Commission Account",
		"Item.salesCommissionAccountId":"Sales Commission Account",
		"Item.expenseAccountId":"Expense Account",
		"Item.expenseAccount":"Expense Account",
		"Item.cogsAccountId":"COGS Account",
		"Item.cogsAccount":"COGS Account",
		"Item.assetAccountId":"Asset Account",
		"Item.assetAccount":"Asset Account",
		"Item.depreciationAccountId":"Depreciation Account",
		"Item.depreciationAccount":"Depreciation Account",
		"Item.accumulatedDepreciationAccountId":"Accumulated Depreciation Account",
		"Item.accumulatedDepreciationAccount":"Accumulated Depreciation Account",
		"Item.gainOrLossOnSalesAccountId":"Gain Or Loss On Sales Account",
		"Item.gainOrLossOnSalesAccount":"Gain Or Loss On Sales Account",
		"Item.gainOrLossOnRevaluationAccountId":"Gain Or Loss On Revaluation Account",
		"Item.gainOrLossOnRevaluationAccount":"Gain Or Loss On Revaluation Account",
		"Item.lossOnScrapAccountId":"Loss On Scrap Account",
		"Item.lossOnScrapAccount":"Loss On Scrap Account",
		"Item.disallowSellBelowPrice":"Disallow Sell Below Price",
		"Item.priceIncludesVAT":"Price Includes VAT",
		"Item.byPassPriceCheck":"Bypass Price Check",
		"Item.allowSplitAccount":"Allow Split Account",
		"Item.state":"State",
		"Item.hideOnReport":"Hide On Report",
		"Item.metaDescription":"Meta Description",
		"Item.marketplaces":"Marketplace",
		"Item.variant":"Variant",
		"Item.variantPrices":"Price Variants",
		"Item.packagingPrices":"Packaging Prices",
		"Item.codeAdded":"Code Added",
		"Item.upcAdded":"UPC Added",
		"Item.skuAdded":"SKU Added",
		"Item.marketplacePrices":"Marketplace Prices",
		"Item.MarketplacePricesLine.marketplace":"Marketplace",
		"Item.MarketplacePricesLine.choice":"Variant Option",
		"Item.MarketplacePricesLine.unit":"Unit",
		"Item.MarketplacePricesLine.price":"Price",
		"Item.rack":"Rack",
		"Item.purchaseQuantity":"Purchase Quantity",
		"Item.grade":"Grade",
		"Item.relatedItem":"Related Item",
		"Item.relatedItemConversion":"Related Item Conversion",
		"Item.commission":"Commission",
		"Item.commissionType":"Commission Type",
		"Item.commissionPercentage":"Commission Percentage (%)",
		"Item.commissionValue":"Commission Value",
		"Item.tax":"Tax",
		"Item.customAdditionalTax":"Custom Additional Tax",
		"Item.customAdditionalTaxPercentage":"Custom Additional Tax Percentage",
		"Item.vendorLeadTimeDays":"Lead Time (Days)",
		"Item.turnoverRate":"Turnover Rate",

		//itemOption
		"ItemOption":"Item Option",
		"item_option":"Item Option",
		"ItemOption.name":"Name",
		"ItemOption.state":"State",
		"ItemOption.activate":"Activate",
		"ItemOption.confirmTitle.action/activate":"Activate",
		"ItemOption.confirmContent.action/activate":"Are you sure you want to activate this Item Option?",
		"ItemOption.success.action/activate":"Item Option is successfully activated",
		"ItemOption.deactivate":"Deactivate",
		"ItemOption.confirmTitle.action/deactivate":"Deactivate",
		"ItemOption.confirmContent.action/deactivate":"Are you sure you want to deactivate this Item Option?",
		"ItemOption.success.action/deactivate":"Item Option is successfully deactivated",
		"ItemOptionLine.option":"Option",
		"ItemOptionLine.sequence":"Sequence",

		//Packaging
		"Packaging":"Packaging",
		"packaging":"Packaging",
		"Packaging.name":"Name",
		"Packaging.width":"Width",
		"Packaging.length":"Length",
		"Packaging.height":"Height",
		"Packaging.weight":"Weight",
		"Packaging.state":"State",
		"Packaging.activate":"Activate",
		"Packaging.confirmTitle.action/activate":"Activate",
		"Packaging.confirmContent.action/activate":"Are you sure you want to activate this Packaging?",
		"Packaging.success.action/activate":"Packaging is successfully activated",
		"Packaging.deactivate":"Deactivate",
		"Packaging.confirmTitle.action/deactivate":"Deactivate",
		"Packaging.confirmContent.action/deactivate":"Are you sure you want to deactivate this Packaging?",
		"Packaging.success.action/deactivate":"Packaging is successfully deactivated",

		//Item Category
		"ItemCategory":"Item Category",
		"item_category":"Item Category",
		"item_category.activate":"Activate",
		"ItemCategory.confirmTitle.action/activate":"Activate",
		"ItemCategory.confirmContent.action/activate":"Are you sure you want to activate this Item Category?",
		"ItemCategory.success.activate":"Item Category is successfully activated",
		"item_category.deactivate":"Deactivate",
		"ItemCategory.confirmTitle.action/deactivate":"Deactivate",
		"ItemCategory.confirmContent.action/deactivate":"Are you sure you want to deactivate this Item Category?",
		"ItemCategory.success.deactivate":"Item Category is successfully deactivated",
		"ItemCategory.code":"Code",
		"ItemCategory.name":"Name",
		"ItemCategory.cleanUrl":"Cleam URL",
		"ItemCategory.parentId":"Parent Category",
		"ItemCategory.parent":"Parent Category",
		"ItemCategory.image":"Image",
		"ItemCategory.thumbnail":"Thumbnail",
		"ItemCategory.state":"State",
		"ItemCategory.hideOnReport":"Hide On Report",
		"ItemCategory.saleableOnline":"Saleable Online",

		//Partner
		"Partner":"Partner",
		"partner":"Partner",
		"partner.activate":"Activate",
		"Partner.confirmTitle.activate":"Activate",
		"Partner.confirmContent.activate":"Are you sure you want to activate this Partner?",
		"Partner.success.activate":"Partner is successfully activated",
		"partner.deactivate":"Deactivate",
		"Partner.confirmTitle.deactivate":"Deactivate",
		"Partner.confirmContent.deactivate":"Are you sure you want to deactivate this Partner?",
		"Partner.success.deactivate":"Partner is successfully deactivated",
		"Partner Information":"Partner Information",
		"Partner.name":"Name",
		"Partner.isSupplier":"Supplier",
		"Partner.isCustomer":"Customer",
		"Partner.email":"Email",
		"Partner.mobile":"Mobile Phone",
		"Partner.contact":"Home Phone",
		"Partner.street":"Address",
		"Partner.cityId":"City",
		"Partner.city":"City",
		"Partner.provinceId":"Province",
		"Partner.province":"Province",
		"Partner.countryId":"Country",
		"Partner.country":"Country",
		"Partner.zip":"Zip",
		"Partner.ssid":"SSID",
		"Partner.idAttachment":"SSID Attachment",
		"Finance and Accounting":"Finance and Accounting",
		"Partner.unlimitedCredit":"Unlimited Credit",
		"Partner.creditLimit":"Credit Limit",
		"Partner.credit":"Credit",
		"Partner.debitLimit":"Debit Limit",
		"Partner.debit":"Debit",
		"Partner.taxable":"Taxable",
		"Partner.npwp":"NPWP",
		"Partner.purchaseInvoiceDaysDue":"Purchase Invoice Due (Days)",
		"Partner.salesInvoiceDaysDue":"Sales Invoice Due (Days)",
		"Partner.defaultCurrencyId":"Currency Default",
		"Partner.defaultCurrency":"Currency Default",
		"Branch Information":"Branch Information",
		"Partner.branchId":"Branch",
		"Partner.branch":"Branch",
		"Company Information":"Company Information",
		"Partner.isCompany":"Company",
		"Partner.companyName":"Company Name",
		"Partner.companyNpwp":"Company NPWP",
		"Partner.companyAddress":"Company Address",
		"Partner.companyCityId":"Company City",
		"Partner.companyCity":"Company City",
		"Partner.companyProvinceId":"Company Province",
		"Partner.companyProvince":"Company Province",
		"Partner.companyCountryId":"Company Country",
		"Partner.companyCountry":"Company Country",
		"Partner.companyZip":"Company Zip",		
		"Other Information":"Other Information",
		"Partner.nodeId":"Node",
		"Partner.node":"Node",
		"Partner.joinDate":"Join Date",
		"Partner.media":"Media",
		"Partner.class":"Class",
		"Partner.code":"Code",
		"Partner.state":"State",
		"Partner.purchasing":"Purchasing",
		"Partner.purchasingUsers":"Purchasing Staffs",
		"Partner.user":"Staff Name",
		"Partner.birthDate":"Birthdate",
		"Partner.idNumber":"ID Number",
		"Partner.idAddress":"ID Address",
		"Partner.address":"Full Address",
		"Partner.occupation":"Occupcation",
		"Partner.maritalStatus":"Marital Status",
		"Partner.incomeRange":"Income Range",

		//Item Spec
		"ItemSpec":"Specification",
		"item_spec":"Specification",
		"ItemSpec.name":"Name",
		"ItemSpec.image":"Image",
		"ItemSpec.priority":"Priority",
		"ItemSpec.state":"State",
		"ItemSpec.activate":"Activate",
		"ItemSpec.deactivate":"Deactivate",
		"ItemSpec.confirmTitle.action/activate":"Activate",
		"ItemSpec.confirmTitle.action/deactivate":"Deactivate",
		"ItemSpec.confirmContent.action/activate":"Are you sure want to activate this item specification",
		"ItemSpec.confirmContent.action/deactivate":"Are you sure want to deactivate this item specification",
		"ItemSpec.success.action/activate":"Item specification has successfully activated",
		"ItemSpec.success.action/deactivate":"Item specification has successfully deactivated",

		//Seasonal Discount
		"SeasonalDiscount":"Seasonal Discount",
		"SeasonalDiscount.name":"Name",
		"SeasonalDiscount.startDate":"Start Date",
		"SeasonalDiscount.endDate":"End Date",
		"SeasonalDiscount.discountPercentage":"Discount Percentage",
		"SeasonalDiscount.discountAmount":"Discount Amount",
		"SeasonalDiscount.maxDiscount":"Maximum Discount",
		"SeasonalDiscount.minPurchase":"Minimum Purchase",
		"SeasonalDiscount.description":"Description",
		"SeasonalDiscount.state":"State",
		"SeasonalDiscount.confirmTitle.action/activate":"Aktifkan",
		"SeasonalDiscount.confirmTitle.action/deactivate":"Nonaktifkan",
		"SeasonalDiscount.confirmContent.action/activate":"Are you sure want to activate this seasonal discount",
		"SeasonalDiscount.confirmContent.action/deactivate":"Are you sure want to deactivate this seasonal discount",
		"SeasonalDiscount.success.action/activate":"Seasonal discount has successfully activated",
		"SeasonalDiscount.success.action/deactivate":"Seasonal discpunt has successfully deactivated",

		//SO-state
		waiting_approval:"Waiting Approval",
		waiting_shipping_cost:"Waiting Shipping Cost",
		waiting_payment:"Waiting Payment",
		waiting_payment_check:"Waiting Payment Check",
		waiting_shipment:"Waiting Shipment",
		shipped:"Shipped",
		arrived:"Arrived",
		cancelled:"Cancelled",

		"offerDate":"Sales Offer Date",
		"approvalDate":"Sales Approval Date",
		"shippingCostOfferDate":"Shipping Cost Offer Date",
		"paymentDate":"Payment Date",
		"paymentConfimedDate":"Payment Confirm Date",
		"shipmentDate":"Shipment Date",
		"arrivalDate":"Arrival Date",
	}
}
export default Translation