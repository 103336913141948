export default function toRgba(hex, opacity) {
  hex = hex.replace("#", "");
  opacity = opacity || 0;
  var r = parseInt(hex.substring(0, 2), 16);
  var g = parseInt(hex.substring(2, 4), 16);
  var b = parseInt(hex.substring(4, 6), 16);

  var result = "rgba(" + r + "," + g + "," + b + "," + opacity + ")";
  return result;
}
